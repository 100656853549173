/* eslint-disable react/prop-types */
// web only
import React from 'react'
import { Link } from 'react-router-dom'
import { Image, Text, View } from 'react-native'
import { inner } from '../styles/globalClasses'
import { colours } from '../styles/constants'
import websiteVersion from '../helpers/websiteVersion'
import PropTypes from 'prop-types'
import BreakpointWatcher from '../helpers/BreakpointWatcher'
import lineHeight from '../helpers/lineHeight'
import isWeb from '../helpers/isWeb'

const Footer = ({ exceedsMediumBreakpoint, children, ...props }) => {
  const footerStyles = {
    paddingTop: 30,
    paddingLeft: 19,
    paddingRight: 19,
    paddingBottom: 70,
    backgroundColor: '#000000',
    zIndex: -1,
    flexShrink: 0
  }
  const footerStylesExceedsMediumBreakpoint = {
    marginTop: 50,
    backgroundColor: '#ffffff',
    paddingTop: 60,
    paddingBottom: 40
  }
  return (
    <View nativeID={props.nativeID} style={exceedsMediumBreakpoint ? { ...footerStyles, ...footerStylesExceedsMediumBreakpoint } : footerStyles}>{children}</View>
  )
}

const Logo = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const logoStyles = {
    width: 74,
    height: 23,
    marginTop: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 15,
    display: exceedsMediumBreakpoint ? 'none' : 'block'
  }
  return (
    <Image style={logoStyles} source={props.source} />
  )
}

const NavWrapper = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const navWrapperStyles = {
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: props.small ? 30 : 'auto'
  }
  const navWrapperStylesExceedsMediumBreakpoint = {
    marginTop: 0,
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginBottom: 30
  }
  return (
    <View style={exceedsMediumBreakpoint ? { ...navWrapperStyles, ...navWrapperStylesExceedsMediumBreakpoint } : navWrapperStyles}>{props.children}</View>
  )
}

const NavItem = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const navItemStyles = {
    marginBottom: 8
  }
  const navItemStylesExceedsMediumBreakpoint = {
    marginBottom: 0,
    marginRight: 15,
    paddingRight: 15,
    borderRightWidth: 1,
    borderRightColor: colours.primary40,
    height: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
  return (
    <View style={exceedsMediumBreakpoint ? { ...navItemStyles, ...navItemStylesExceedsMediumBreakpoint } : navItemStyles}>{props.children}</View>
  )
}

const LinkShared = (small, exceedsMediumBreakpoint) => {
  const linkSharedStyles = {
    fontSize: small ? 12 : 16,
    lineHeight: lineHeight(small ? 14 : 19),
    fontFamily: 'greycliff-medium',
    color: small ? '#666' : '#fff',
    textDecorationLine: 'none'
  }
  const linkSharedStylesExceedsMediumBreakpoint = {
    color: '#000',
    fontSize: small ? 9 : 11,
    lineHeight: lineHeight(small ? 10 : 13)
  }
  return exceedsMediumBreakpoint ? { ...linkSharedStyles, ...linkSharedStylesExceedsMediumBreakpoint } : linkSharedStyles
}

const NavLink = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const linkStyles = LinkShared(props.small, exceedsMediumBreakpoint)
  return (
    <Link style={linkStyles}>{props.children}</Link>
  )
}

const NavExternal = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const externalStyles = LinkShared(props.small, exceedsMediumBreakpoint)
  return (
    <a style={externalStyles} href={props.href}>{props.children}</a>
  )
}

const CopyRight = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const copyRightStyles = {
    color: '#666',
    marginTop: 60,
    fontFamily: 'greycliff-light',
    fontSize: 11,
    lineHeight: lineHeight(19),
    textAlign: 'center'
  }
  const copyRightStylesExceedsMediumBreakpoint = {
    color: colours.black50,
    marginTop: 0,
    textAlign: 'left'
  }
  return (
    <Text style={exceedsMediumBreakpoint ? { ...copyRightStyles, ...copyRightStylesExceedsMediumBreakpoint } : copyRightStyles}>{props.children}</Text>
  )
}

export default class Component extends React.Component {
  static propTypes = {
    apiVersion: PropTypes.string
  }

  state = {
    navBar: [
      {
        title: 'How it Works',
        external: true,
        link: `${process.env.REACT_APP_BASE_URL}/how-it-works-1`
      },
      {
        title: 'Pricing',
        external: true,
        link: `${process.env.REACT_APP_BASE_URL}/pricing`
      },
      {
        title: 'Blog',
        external: true,
        link: `${process.env.REACT_APP_BASE_URL}/blog`
      },
      {
        title: 'Boost App',
        external: true,
        link: `${process.env.REACT_APP_BASE_URL}/boost-app`
      },
      {
        title: 'About Boost',
        external: true,
        link: `${process.env.REACT_APP_BASE_URL}/about-boost`
      },
      {
        title: 'Contact Us',
        external: true,
        link: `${process.env.REACT_APP_BASE_URL}/enquiry-form`
      }
    ],
    smallNavBar: [
      {
        title: 'Privacy',
        external: true,
        link: `${process.env.REACT_APP_BASE_URL}/privacy-policy/`
      },
      {
        title: 'Terms of Use',
        external: true,
        link: `${process.env.REACT_APP_BASE_URL}/about-boost/boost-user-terms-and-conditions`
      }
    ],
    exceedsMediumBreakpoint: false
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  renderLinks = (links, small = '') => {
    return (
      <NavWrapper exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint} small={small}>
        {links.map((link, i) => {
          return <NavItem exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint} key={i}>
            {link.external
              ? <NavExternal exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint} href={link.link} small={small}>{link.title}</NavExternal>
              : <NavLink exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint} to={link.link} small={small}>
                {link.title}
              </NavLink>
            }
          </NavItem>
        })}
      </NavWrapper>
    )
  }

  renderCopyRight = () => {
    const { apiVersion } = this.props
    const date = new Date()
    return (
      <CopyRight exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint}>
        &copy; {date.getFullYear()} n3 Ltd. All rights reserved
        <br/>{websiteVersion && `Version: ${websiteVersion}`}
        <br/>{apiVersion && `API version: ${apiVersion}`}
      </CopyRight>)
  }

  render () {
    return (
      <React.Fragment>
        {isWeb && <style>
          {`
            @media print {
              #id_boost_footer {
                display: none !important;
              }
            }
          `}
        </style>}
        <Footer exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint} nativeID="id_boost_footer">
          <View style={inner}>
            <Logo exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint} source={require('../images/boost-logo-header.png')}/>
            {this.renderLinks(this.state.navBar)}
            {this.renderLinks(this.state.smallNavBar, 'small')}
            {this.renderCopyRight()}
          </View>
        </Footer>
      </React.Fragment>
    )
  }
}
