import styled from 'styled-components'
import ReactTable from 'react-table'
import { breakpoints, colours } from '../../styles/constants'
import { Checkboxes } from '../../styles/globalClasses'

export const DataTable = styled(ReactTable)`
  border: none;
  box-shadow: none;
  margin-bottom: 30px;

  &.confirm-table {
  }

  .rt-thead.-header {
    box-shadow: none;
    border-bottom: 1px solid ${colours.light};
    margin-bottom: 20px;
  }

  &.confirm-table .rt-thead.-header {
    margin-bottom: 0;
  }

  .rt-thead {
    font-family: greycliff-bold, sans-serif;
    font-size: 16px;
    line-height: 19px;
  }

  .rt-thead .rt-th {
    padding: 12px;
    padding-left: 0;
    text-align: left;
    border-right: 0;

    &:last-child {
      padding-right: 0;
    }
  }

  .rt-thead .rt-th.-sort-asc,
  .rt-thead .rt-th.-sort-desc {
    position: relative;
    padding-right: 72px;
    box-shadow: none;

    &:after {
      content: '';
      width: 14px;
      height: 7px;
      position: absolute;
      right: 50px;
      top: 50%;
      transform: translateY(-50%);
      background: url(${require('../../images/arrow-blue-down.png')}) 0 0 / 100% 100% no-repeat;
      border: 0;
      transition: none;
    }
  }

  .rt-thead .rt-th.-sort-desc {
    &:after {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &.confirm-table .rt-thead .rt-th {
    padding-left: 0;

    &:last-child {
      padding-right: 0;
    }
  }

  .rt-tbody .rt-tr-group,
  .rt-thead .rt-th,
  .rt-thead .rt-td,
  .rt-tbody .rt-td {
    border: 0;
  }

  .rt-tbody .rt-td {
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-right: 15px;
  }

  &.confirm-table .rt-tbody .rt-td {
    padding-top: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid ${colours.light};
    transition: none;

    &:last-child {
      padding-right: 0;
    }
  }

  .rt-noData {
    display: none;
  }

  .index-wrap {
    color: ${colours.black70};

    .index {
      font-family: greycliff, sans-serif;
      font-size: 14px;
      padding-right: 8px;
      margin-right: 8px;
      border-right: 1px solid ${colours.black70};
    }

    .remove {
      cursor: pointer;
      appearance: none;
      padding: 0;
      font-family: greycliff-bold, sans-serif;
      font-size: 12px;
      color: inherit;
      border: 0;
      background: 0;
    }
  }

  .editable-field,
  .field {
    font-size: 11px;
  }

  .editable-wrap {
    width: 100%;
    flex: 1 1 100%;
  }

  .editable-field {
    border: 1px solid ${colours.black30};
    border-radius: 4px;
    padding: 8px;
    flex: 1;
    width: 100%;
    min-height: 31px;
    @media (min-width: ${breakpoints.medium}px) {
      overflow: hidden;
    }
  }

  ${Checkboxes}
  .check-wrap {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;

    .title {
      display: none;
    }

    @media (min-width: ${breakpoints.medium}px) {
      flex-direction: row;
    }
  }

  .custom-check {
    font-size: 14px;
    line-height: 16px;
    width: auto;
    height: auto;
    padding-left: 25px;
    margin: 0 0 10px;
    @media (min-width: ${breakpoints.medium}px) {
      margin: 0;
    }
  }

  &.confirm-table .custom-check {
    font-size: 11px;
    line-height: 13px;
  }

  .action-title {
    text-align: right;
  }

  .edit-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    .index {
      display: none;
    }

    > * {
      cursor: pointer;
      appearance: none;
      border: 0;
      background: 0;
      padding: 0;
      font-size: 11px;
      font-family: greycliff-bold, sans-serif;
    }
  }

  .edit-button {
    color: black;
    position: relative;
    margin-right: 12px;

    &:after {
      content: '';
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 10px;
      margin-left: 5px;
      background: ${colours.black50};
    }
  }

  .remove-button {
    color: ${colours.urgent};
  }

  .editable-field-label {
    display: none;
  }

  // tablet/mobile styles (responsive table)
  @media (max-width: ${breakpoints.medium - 1}px) {
    margin-left: -15px;
    margin-right: -15px;
    .rt-table {
      overflow: visible;
    }

    .rt-tbody {
      min-width: 100% !important;
    }

    .rt-thead.-header {
      display: none;
    }

    .rt-tr {
      display: flex;
      flex: 1 1 100%;
      flex-direction: column;
      padding-left: 15px;
      padding-right: 15px;
      border-bottom: 1px solid ${colours.black60};
      margin-bottom: 15px;
      padding-bottom: 15px;
    }

    .rt-tbody .rt-td {
      width: 100% !important;
      max-width: 100% !important;
      flex: 1 1 100% !important;
      padding-right: 0;
      border-bottom: 0 !important;
    }

    [data-header],
    .editable-field-label {
      position: relative;
      margin-top: 30px;
      font-size: 16px;

      &:before {
        content: attr(data-header);
        font-size: 18px;
        font-family: greycliff-bold, sans-serif;
        position: absolute;
        left: 0;
        bottom: 100%;
        margin-bottom: 7px;
      }
    }

    .editable-field-label {
      display: block;
      font-size: 18px;
      font-family: greycliff-bold, sans-serif;
      margin-bottom: 7px;
    }

    .check-wrap {
      .custom-check {
        font-size: 16px;
      }

      .title {
        display: block;
        font-family: greycliff-bold, sans-serif;
        font-size: 18px;
        margin-bottom: 7px;
      }
    }

    .index-wrap,
    .edit-buttons {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .index {
        border-right: 0;
        font-size: 20px;
        color: black;
        font-family: greycliff-bold, sans-serif;
        display: inline;

        &:before {
          content: 'New User ';
        }
      }

      .remove {
        color: ${colours.urgent};
        font-size: 16px;
      }
    }

    &.confirm-table {
      .rt-tr {
        padding-top: 60px;
        position: relative;

        .rt-td:last-child {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
`
