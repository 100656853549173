import isWeb from './isWeb'
import api from './boost-client-js-library/api'
import consoleLog from './consoleLog'
import timestamp from './date'

// for testing when online
const FORCE_OFFLINE = false

// don't recheck if it's been less than this long since check
const MIN_CHECK_INTERVAL = 60

export default class ConnectionManager {
  apiConnected = null
  lastCheckTime = 0
  resolutions = []

  static connectionManager = null

  static getManager () {
    if (this.connectionManager === null) {
      this.connectionManager = new ConnectionManager()
    }
    return this.connectionManager
  }

  isOnline () {
    /**
     * This method will return immediately if we know the connection state, which will be true, false or null. It is
     * intended to be a shortcut to prevent having to use the checkApiConnectivity promise if we are already aware of
     * the status.
     *
     * If it returns null then the connectivity status is unknown and we should resolve checkApiConnectivity below.
     */
    return isWeb || this.apiConnected
  }

  reset () {
    /**
     * Set apiConnected back to null to force recheck next time, should be done when app is backgrounded
     */
    consoleLog('ConnectionManager reset()')
    this.apiConnected = null
  }

  resolveApiConnected (isConnected, updateLastCheck) {
    /**
     * Resolve the connectivity check promise and update the cached status and timestamp
     */
    if (updateLastCheck) { this.lastCheckTime = timestamp() }
    this.apiConnected = isConnected

    let resolvedCount = 0
    while (this.resolutions.length) {
      this.resolutions.pop()(this.apiConnected)
      ++resolvedCount
    }

    consoleLog(`[ConnectionManager] Resolved ${resolvedCount} resolutions`)
  }

  checkApiConnectivity () {
    /**
     * Use the checkConnection method on `api`
     */
    if (isWeb || FORCE_OFFLINE) {
      // always create new promises that should get resolved instantly
      return new Promise((resolve) => {
        if (isWeb) {
          resolve(true)
        }

        // for testing
        resolve(false)
      })
    }

    return new Promise((resolve) => {
      consoleLog(`[ConnectionManager] Check API connectivity, connection state is: ${global.networkStatus}`)
      if (global.networkStatus === 'none') {
        resolve(false)
        if (this.resolutions.length) {
          this.resolveApiConnected(false, true)
        }
        return
      }

      if (this.apiConnected !== null && (timestamp() - this.lastCheckTime) < MIN_CHECK_INTERVAL) {
        resolve(this.apiConnected)
        if (this.resolutions.length) {
          this.resolveApiConnected(this.apiConnected, false)
        }
        return
      }

      consoleLog('[ConnectionManager] Connecting to API')

      this.resolutions.push(resolve)
      if (this.resolutions.length > 1) {
        consoleLog(`[ConnectionManager] Resolutions length is ${this.resolutions.length} so returning`)
        return
      }

      api.checkConnection().then(res => {
        this.resolveApiConnected(res === 'online', true)
      }).catch(() => {
        this.resolveApiConnected(false, false)
      })
    })
  }
}
