// Maps a status code to an offer type
/*
"-1" ---
"969760000" Limited Time
"969760001" Everyday
"969760002" Stock Offer
"969760003" Limited Time Stock Offer
"969760004" Single Use
*/

const offerType = code => {
  let text = ''
  switch (code) {
  case '-1':
    text = ''
    break
  case '969760000':
    text = 'lto'
    break
  case '969760001':
    text = 'Everyday'
    break
  case '969760002':
    text = 'Stock Offer'
    break
  case '969760003':
    text = 'Limited Time Stock Offer'
    break
  case '969760004':
    text = 'Single Use'
    break
  default:
    text = 'Everyday'
    break
  }
  return text
}

export default offerType
