import React from 'react'
import Layout from '../../components/Layout'
import { View } from 'react-native'
import { backButtonStyle, breakpoints, colours } from '../../styles/constants'
import { H1, P, A, Bold, Labels, Fields, Checkboxes, TextShared } from '../../styles/globalClasses'
import styled from 'styled-components'
import Steps from '../../components/Steps'
import { Primary } from '../../components/Inputs/Buttons'
import Circle from '../../components/Circle'
import DatePicker from 'react-datepicker'
import storage from '../../helpers/storage'
import api from '../../helpers/boost-client-js-library/api'
import Modal from '../../components/Modal'
import LoadingIndicator from '../../components/LoadingIndicator'
import checkRole from '../../helpers/checkRole'
import Notification from '../../components/Notification'
import moment from 'moment'
import consoleLog from '../../helpers/consoleLog'
import BreakpointWatcher from '../../helpers/BreakpointWatcher'

const Sections = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  z-index: 1;

  > * {
    margin-bottom: 10px;
  }

  @media (min-width: ${breakpoints.medium}px) {
    flex-direction: row;
    margin-left: -45px;
    > * {
      flex: 0 0 33.33%;
      padding-left: 45px;
    }
  }

`
const Section = styled.div`
  // global
  display: flex;
  align-items: stretch;
  flex-direction: column;

  ${props => props.step > props.active && props.step !== 0 && `
        display: none;
        @media(min-width: ${breakpoints.medium}px){
            visibility: hidden;
        }
    `}
    // section 1
  .arrow {
    width: 48px;
    margin: 10px 0 25px;
  }

  .intro {
    font-family: greycliff-medium, sans-serif;

    &.spacing {
      margin-bottom: 30px !important;
    }
  }

  .message {
    color: ${props => props.active === 0 ? `${colours.primary}` : 'black'};
    border: 1px solid ${props => props.active === 0 ? `${colours.primary}` : 'black'};
    border-radius: 8px;
    padding: 6px 6px;
    margin-top: 15px;
    font-family: greycliff-medium, sans-serif;
  }

  .message,
  .field-wrap,
  .text {
    margin-bottom: 20px;
  }

  .fields-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -30px;

    > * {
      padding-left: 30px;
      flex: 0 0 50%;
    }
  }

  ${Labels}
  ${Fields}
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="reset"]),
  textarea {
    width: 100%;
    font-family: greycliff-medium;

    &.datepicker {
      font-family: greycliff-bold;
      width: 250px;
      max-width: 100%;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      cursor: pointer;
      appearance: none;

      ::placeholder {
        color: inherit;
      }
    }

    ${props => props.active !== props.step && `
            border-color: #f1f1f1;
            pointer-events: none;
        `}
  }

  // section 2
  .plan-details {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 20px;

    h1 {
      margin: 6px 0;
      font-family: greycliff;

      span {
        font-family: greycliff-bold;
      }
    }

    .bold {
      color: ${colours.primary};
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1px;
    }

    .price {
      color: ${colours.primary};
      font-family: greycliff-medium;
      font-size: 30px;
      line-height: 36px;
    }

    .small {
      font-size: 8px;
      line-height: 10px;
    }

    + p {
      margin-top: 2px;
    }
  }

  .circle-wrap {
    align-items: center;

    > * {
      margin: 0 0 15px;
    }

    @media (min-width: ${breakpoints.large}px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      > * {
        transform: scale(.9);
      }
    }
  }

  .centre-text {
    text-align: center;
    font-size: 12px;
    line-height: 14px;

    a {
      text-decoration: none;
    }
  }

  // section 3
  .radio-label {
    margin-bottom: 15px;

    &.first {
      margin-top: 20px;
    }
  }

  .radios {
    flex-direction: row;
    margin-bottom: 15px;
  }

  ${Checkboxes}
  .check-wrap {
    margin: 30px 20px 0 0;
  }

  .custom-check {
    font-size: 11px;
    font-family: greycliff;
    padding-left: 26px;
    line-height: 16px;
    width: auto;

    a {
      ${TextShared};
      color: black;
      font-family: greycliff-bold;
      text-decoration: underline;
    }
  }

  .input-field-small {
    font-size: 11px;
    line-height: 13px;
    font-family: greycliff-medium;
    color: ${colours.black60};
  }

  .card-message {
    margin: 15px 0 0;
    position: relative;
    padding-left: 20px;

    &:before {
      content: '';
      width: 11px;
      height: 15px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: url(${require('../../images/lock-green.png')}) 0 0 / 100% 100% no-repeat;
    }
  }

  .terms {
    font-family: greycliff;
    color: black;

    a {
      text-decoration: none;
      font-size: inherit;
      line-height: inherit;
    }
  }

  .datepicker-wrap {
    margin: 50px 0;
  }

  .errorInStep3 {
    margin-top: 20px;
  }
`
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`

const Button = {
  Wrapper: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;

    > * {
      margin-bottom: 20px;
    }

    @media (min-width: ${breakpoints.medium}px) {
      flex-direction: ${props => props.active === 0 ? 'row-reverse' : 'row'};
      justify-content: space-between;
    }
  `,
  Back: Primary
}
const paymentMethods = [
  {
    label: 'Credit Card',
    name: 'credit'
  },
  {
    label: 'Invoice',
    name: 'invoice'
  }
]
const credit = [
  {
    label: 'Credit Card',
    name: 'credit'
  }
]
export default class Super extends React.Component {
  state = {
    tier: '',
    type: '',
    steps: ['Organisation Details', this.props.match.path.includes('/profile/pay') ? 'Confirm' : 'Boost Plan', 'Payment'],
    active: 0,
    profile: {},
    planName: '',
    company: '',
    expiry: '',
    address: '',
    paymentFrequency: '',
    paymentMethod: '',
    acceptTerms: false,
    nextButtonText: 'Next ›',
    date: null,
    errorModal: false,
    hashId: null,
    IsErrorPayment: null,
    isLoading: true,
    error: false,
    errorInStep3: false,
    account: '',
    invoiceNumber: '',
    invoiceAmount: ''
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
    const {
      match,
      appKey,
      history
    } = this.props
    const type = () => {
      if (match.path.includes('/profile/pay')) return 'pay'
      if (match.path.includes('/profile/renewal')) return 'renewal'
      if (match.path.includes('/profile/upgrade-plan')) return 'upgrade-plan'
    }

    const action = type()

    this.setState({
      hashId: Super.getParamValue('q'),
      IsErrorPayment: Super.getParamValue('fail')
    })

    storage.token.get(appKey).then(token => {
      if (action === 'upgrade-plan') {
        if (this.state.hashId === '') { return }

        api.quote.get(this.state.hashId).then((quote) => {
          if (quote.code && quote.code !== 200) {
            this.setState({
              isLoading: false,
              planName: ''
            })
            return
          }

          this.setState({
            isLoading: false,
            tier: quote.newTier,
            currentTier: quote.currentTier,
            type: action,
            planName: quote.upgradePlanName,
            company: quote.companyName,
            address: quote.address,
            firstName: quote.firstName,
            currentPlanPrice: quote.priceTotal,
            currentPlanMaxUsers: quote.upgradeMaxUsers,
            numberOfVehicles: quote.currentVehicleCount,
            date: quote.rollOutDate ? new Date(quote.rollOutDate) : null
          }
          )
        })
        return
      }

      api.organisation.get(token).then(profile => {
        if (!profile.tier) {
          history.push('/profile')
          return false
        } else {
          this.setState({
            tier: Number(profile.tier),
            type: action,
            planName: profile.planName,
            phone: profile.phone,
            email: profile.email,
            company: profile.tradingAs || profile.accountName || '',
            expiry: profile.expiry,
            address: (profile.addressPostal && profile.addressPostal.replace(/\n/g, ', ')) || (profile.addressShipping && profile.addressShipping.replace(/\n/g, ', ')) || '',
            currentPlan: profile.planName,
            currentPlanMaxUsers: profile.currentPlanMaxUsers,
            numberOfVehicles: profile.numberOfVehicles,
            isLoading: false,
            date: profile.rollOutDate ? new Date(profile.rollOutDate) : null
          })
        }
      })
      if (action !== 'pay') {
        api.subscriptions.getAmount(token, this.state.hashId).then(res => {
          if (res && (res.totalAmount || res.totalAmount === 0)) {
            this.setState({ currentPlanPrice: res.totalAmount.toFixed(2) })
          } else {
            consoleLog(res)
            this.setState({ error: 'Oops, something went wrong. Please try again later' })
          }
        })
      }

      api.profile.get(token).then(profile => {
        if (!checkRole(profile.roles).includes('bp')) {
          history.push('/profile')
          return false
        }
        this.setState({
          firstName: profile.firstName,
          lastName: profile.lastName
        })
      })
    })
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  handleSetRollOutDate = (date) => {
    const rollOutDate = moment(date).format('YYYY-MM-DD hh:mm:ss')
    const data = { rollOutDate: rollOutDate }

    storage.token.get().then(token => {
      if (token === '') {
        api.quote.updateRollOutDate(this.state.hashId, rollOutDate).then(() => {
          this.setState({ date })
        })
      } else {
        api.organisation.update(token, data).then(() => {
          this.setState({ date })
        })
      }
    })
  }

  static getParamValue (name) {
    const param = window.location.search.split(name + '=')[1]
    if (param) {
      return param.split('&')[0]
    } else {
      return false
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleBack = () => {
    const { active } = this.state

    this.setState({
      active: active - 1,
      nextButtonText: 'Next ›',
      error: false
    })
  }

  handleModalClose = () => {
    this.setState({
      IsErrorPayment: false,
      errorModal: false
    })
  }

  validCheckForPay = async () => {
    const { appKey } = this.props
    const {
      account,
      invoiceNumber
    } = this.state

    if (account === '') {
      this.setState({ error: 'Please fill the account id number' })
      return false
    }

    if (invoiceNumber === '') {
      this.setState({ error: 'Please fill the invoice number' })
      return false
    }

    const token = await storage.token.get(appKey)
    const res = await api.membership.getDetailByInvoice(token, invoiceNumber)
    if (res.ID || res.email) {
      this.setState({
        firstName: res.firstName,
        lastName: res.lastName,
        phone: res.phone,
        email: res.email,
        invoiceAmount: res.totalAmount + ' + GST',
        error: null
      })
    } else {
      this.setState({
        error: 'Something went wrong. Please try again.'
      })
      return false
    }
    return true
  }

  handleNext = async () => {
    const {
      appKey,
      history
    } = this.props
    const {
      type,
      steps,
      active,
      acceptTerms,
      paymentMethod,
      hashId
    } = this.state
    if (type === 'pay' && this.state.active === 0) {
      const isValid = await this.validCheckForPay()
      if (!isValid) {
        return false
      }
    }

    this.setState({
      active: active !== steps.length ? active + 1 : active
    }, () => {
      if ((this.state.active) === steps.length) { // last section
        this.setState({
          nextButtonText: type === 'renewal' ? 'Confirm renewal' : 'Submit',
          errorModal: false
        })
        if (type !== 'pay') {
          if (!paymentMethod) {
            this.setState({
              errorInStep3: 'Please choose a payment method'
            })
            return false
          }
          if (!acceptTerms) {
            this.setState({
              errorInStep3: 'Please accept the terms and conditions'
            })
            return false
          }
        }
        if (type !== 'pay' && !acceptTerms) return false // terms must be checked to proceed
        if (type === 'pay' && paymentMethod !== 'credit') {
          this.setState({
            errorInStep3: 'Please choose a payment method'
          })
          return false
        }

        try {
          if (type === 'pay' && paymentMethod === 'credit') {
            this.setState({ nextButtonText: 'Proceeding payment...' })
            storage.token.get(appKey).then(token => {
              api.membership.payByInvoice(token, this.state.invoiceNumber).then(res => {
                this.setState({
                  nextButtonText: 'Redirecting you to Payment Express...'
                })
                if (res.url) {
                  window.location = res.url
                } else {
                  consoleLog(res)
                  this.setState({
                    nextButtonText: 'Submit',
                    errorModal: true,
                    error: false
                  })
                }
              })
            })
          }

          if (type === 'renewal') {
            this.setState({ nextButtonText: 'Proceeding payment...' })
            storage.token.get().then(token => {
              api.subscriptions.renew(token, hashId, paymentMethod).then(res => {
                if (paymentMethod === 'credit') {
                  this.setState({
                    nextButtonText: 'Redirecting you to Payment Express...'
                  })
                  if (res.url) {
                    window.location = res.url
                  } else {
                    consoleLog(res)
                    this.setState({
                      nextButtonText: 'Confirm renewal',
                      errorModal: true
                    })
                  }
                }
                if (paymentMethod === 'invoice') {
                  if (res.code === 200) {
                    history.push('/profile/confirmation/renewal-membership')
                  } else {
                    consoleLog(res)
                    this.setState({
                      nextButtonText: 'Confirm renewal',
                      errorModal: true
                    })
                  }
                }
              })
            })
          }

          if (type === 'upgrade-plan') {
            this.setState({ nextButtonText: 'Proceeding to payment...' })
            api.subscriptions.upgrade(hashId, paymentMethod).then(res => {
              if (paymentMethod === 'credit') {
                this.setState({
                  nextButtonText: 'Redirecting you to Payment Express...'
                })
                if (res.url) {
                  window.location = res.url
                } else {
                  consoleLog(res)
                  this.setState({
                    nextButtonText: 'Submit',
                    errorModal: true
                  })
                }
              }
              if (paymentMethod === 'invoice') {
                if (res.code === 200) {
                  history.push('/profile/confirmation/upgrade-membership')
                } else {
                  consoleLog(res)
                  this.setState({
                    nextButtonText: 'Submit',
                    errorModal: true
                  })
                }
              }
            })
          }
        } catch (e) {
          consoleLog(e)
          this.setState({
            nextButtonText: type === 'renewal' ? 'Confirm renewal' : 'Submit',
            errorModal: true
          })
        }
        return false
      } else {
        this.setState({ nextButtonText: 'Next ›' })
      }
    })
  }

  handleNiceName = name => {
    const splitName = name.split('–')
    return splitName && splitName[1] ? <span> {splitName[1].trim()}</span> : name
  }

  handleItemRadio = e => this.setState({ [e.target.name]: e.target.value })

  render () {
    const {
      currentTier,
      tier,
      type,
      planName,
      steps,
      active,
      company,
      address,
      firstName,
      lastName,
      phone,
      email,
      numberOfVehicles,
      currentPlanPrice,
      currentPlanMaxUsers,
      nextButtonText,
      acceptTerms,
      date,
      errorModal,
      IsErrorPayment,
      isLoading,
      error,
      invoiceAmount,
      errorInStep3,
      paymentMethod,
      exceedsMediumBreakpoint
    } = this.state
    const isRenewal = type === 'renewal' || type === 'upgrade-plan'
    const title = () => {
      let titleType = ''
      const endTitle = 'your Boost Membership'
      if (type === 'renewal') titleType = `Renew ${endTitle}`
      if (type === 'upgrade-plan') titleType = `Upgrade ${endTitle}`
      if (type === 'pay') titleType = `Pay ${endTitle}`
      return titleType
    }

    const minDate = moment().add(1, 'days').toDate()

    const isAfterToday = date ? (date.getTime() - new Date().getTime() > 0) : false

    const methods = type === 'pay' ? credit : paymentMethods // Check if type is pay
    const isDisabled = () => {
      if (active !== 3) { return false }

      if (isRenewal && (nextButtonText === 'Submit' || nextButtonText === 'Confirm renewal') && (!acceptTerms || !paymentMethod)) {
        return true
      }
      return type === 'pay' && !paymentMethod
    }

    const shouldShowRollOut = () => {
      const currentTierInt = parseInt(currentTier)
      if (isNaN(currentTierInt)) { return false }

      const tierInt = parseInt(tier)
      if (isNaN(tierInt)) { return false }

      if (type !== 'upgrade-plan') { return false }

      if (currentTierInt !== 2) { return false }

      if (tierInt < 3) { return false }

      return (date == null || isAfterToday)
    }

    return (
      <Layout
        title={title()}
        tier={tier}
      >
        {isLoading ? <LoadingIndicator/>
          : <React.Fragment>
            <Steps steps={steps} active={active} showCompleted/>
            <Sections>
              <Section step={0} active={active}>
                {isRenewal
                  ? <React.Fragment>
                    {isRenewal && <img className="arrow"
                      src={type === 'upgrade-plan' ? require('../../images/up-arrow.png') : require('../../images/circle-arrow.png')}
                      alt=""/>}
                    <H1>Hi {firstName},</H1>
                    {type === 'renewal'
                      ? <React.Fragment>
                        <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} className="intro">Your Boost membership is
                          set to renew on <Bold>October 5.</Bold></P>
                      </React.Fragment>
                      : <React.Fragment>
                        <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Thanks for choosing to upgrade your Boost
                          plan.</P>
                        <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} className="text">Please confirm your
                          details below and click next.</P>
                      </React.Fragment>
                    }
                    <div className="field-wrap">
                      <label htmlFor="company">Company name</label>
                      <input type="text" id="company" value={company} readOnly/>
                    </div>

                    <div className="field-wrap">
                      <label htmlFor="address">Postal address <span
                        className="label-text">(for delivery of cards)</span><span className="small">Need to update your address details? Go <a
                        href="/profile/organisation">here</a></span></label>
                      <textarea id="address" rows="4" value={address} readOnly/>
                    </div>
                  </React.Fragment>
                  : <React.Fragment>
                    <H1>Easy as 1, 2, 3!</H1>
                    <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} className="intro spacing">Enter your Account ID
                      number and Invoice Number into the fields below. They can be found on your invoice emailed to
                      you.</P>
                    <div className="field-wrap">
                      <label htmlFor="account">Account ID number</label>
                      <input type="text" id="account" onChange={e => this.handleChange(e)}/>
                    </div>
                    <div className="field-wrap">
                      <label htmlFor="invoiceNumber">Invoice number</label>
                      <input type="text" id="invoiceNumber" onChange={e => this.handleChange(e)}/>
                    </div>
                  </React.Fragment>
                }
                {error && <Notification>{error}</Notification>}
                {active === 0 && <Button.Wrapper active={active}>
                  {active !== 0 &&
                    <Button.Back style={backButtonStyle} onPress={() => this.handleBack()}>‹ Back</Button.Back>}
                  <Primary onPress={() => this.handleNext()} disabled={isDisabled()}>{nextButtonText}</Primary>

                </Button.Wrapper>
                }
              </Section>
              <Section step={1} active={active}>
                {isRenewal
                  ? <React.Fragment>
                    <View className="plan-details">
                      <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}><Bold
                        className="bold">{type === 'upgrade-plan' ? 'UPGRADING TO' : 'CURRENT PLAN'}</Bold></P>

                      <h1>{this.handleNiceName(planName)}</h1>
                      <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} className="price">${currentPlanPrice}</P>
                      <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} className="small">NZD/year (excl. GST)</P>
                      <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}><Bold className="bold">FOR UP
                        TO {currentPlanMaxUsers} USERS</Bold></P>
                    </View>
                    <View className="circle-wrap">
                      <Circle exceedsMediumBreakpoint={exceedsMediumBreakpoint} amount={numberOfVehicles}
                        max={currentPlanMaxUsers}/>
                    </View>
                    {type === 'renewal' && tier > 1 &&
                      <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} className="centre-text">Need more users?<br/><A
                        to="/profile/user-management-and-ordering/add-licenses">Add more licenses</A></P>}
                  </React.Fragment>
                  : <React.Fragment>
                    <H1>How does it look?</H1>
                    <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} className="intro spacing">Please check the
                      details below. If you have any questions please contact your account manager.</P>

                    <div className="field-wrap">
                      <label htmlFor="company">Business name <span className="small">Need to update your profile? Go <a
                        href="/profile">here</a></span></label>
                      <input type="text" id="company" value={company} readOnly/>
                    </div>

                    <div className="fields-wrap">
                      <div className="field-wrap">
                        <label htmlFor="firstName">First name</label>
                        <input type="text" id="firstName" defaultValue={firstName} readOnly/>
                      </div>

                      <div className="field-wrap">
                        <label htmlFor="lastName">Last name</label>
                        <input type="text" id="lastName" defaultValue={lastName} readOnly/>
                      </div>
                    </div>

                    <div className="fields-wrap">
                      <div className="field-wrap">
                        <label htmlFor="phone">Phone</label>
                        <input type="tel" id="phone" defaultValue={phone} readOnly/>
                      </div>

                      <div className="field-wrap">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" defaultValue={email} readOnly/>
                      </div>
                    </div>
                    <div className="field-wrap">
                      <label htmlFor="address">Address <span
                        className="small">Need to update your address details? Go <a
                          href="/profile/organisation">here</a></span></label>
                      <input type="text" id="address" defaultValue={address} readOnly/>
                    </div>

                    <div className="field-wrap">
                      <label htmlFor="invoiceAmount">Invoice amount</label>
                      <input type="text" id="invoiceAmount" defaultValue={invoiceAmount} readOnly/>
                    </div>
                  </React.Fragment>
                }
                {active === 1 && <Button.Wrapper active={active}>
                  {active !== 0 &&
                    <Button.Back style={backButtonStyle} onPress={() => this.handleBack()}>‹ Back</Button.Back>}
                  <Primary onPress={() => this.handleNext()} disabled={isDisabled()}>{nextButtonText}</Primary>

                </Button.Wrapper>
                }
              </Section>
              <Section step={2} active={active}>
                {type === 'renewal' &&
                  <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} className="radio-label first"><Bold>I&rsquo;d
                    like to pay</Bold></P>}
                {type === 'upgrade-plan' &&
                  <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} className="radio-label first"><Bold>Payment
                    method</Bold></P>}
                {type === 'pay' &&
                  <React.Fragment>
                    <H1>I&rsquo;d like to pay</H1>
                    <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} className="radio-label"><Bold>Payment
                      method</Bold></P>
                  </React.Fragment>
                }
                <View className="radios">
                  {methods.map((item, i) => (<div key={i} className="check-wrap">
                    <input
                      type="radio"
                      className="checkbox"
                      id={item.name}
                      name="paymentMethod"
                      value={item.name}
                      onChange={e => this.handleItemRadio(e)}
                    />
                    <label
                      htmlFor={item.name}
                      className="custom-check"
                      style={{ fontSize: exceedsMediumBreakpoint ? 14 : 16 }
                      }>{item.label}</label>
                  </div>))}
                </View>
                {type !== 'pay' && <div className="input-field-small terms">
                  <input
                    type="checkbox"
                    className="checkbox"
                    id="acceptTerms"
                    name="paymentMethod"
                    checked={acceptTerms}
                    onChange={() => this.setState({ acceptTerms: !acceptTerms })}
                  />
                  <label htmlFor="acceptTerms" className="custom-check">I accept the Membership <a
                    href={`${process.env.REACT_APP_BASE_URL}/about-boost/boost-user-terms-and-conditions`}>Terms &amp; Conditions</a></label>
                </div>}
                {errorInStep3 && <Notification className="errorInStep3">{errorInStep3}</Notification>}
                <P style={{
                  marginBottom: 25,
                  marginTop: 20
                }} exceedsMediumBreakpoint={exceedsMediumBreakpoint} className="input-field-small card-message">Transactions
                  are processed securely by Payment Express</P>
                {shouldShowRollOut() &&
                  <div className="datepicker-wrap">
                    <DatePicker
                      placeholderText="Set App Invite Email Send Date"
                      selected={date}
                      onChange={this.handleSetRollOutDate}
                      dateFormat="dd/MM/yyyy"
                      secondary="true"
                      minDate={minDate}
                      className="datepicker"
                    />
                  </div>
                }
                {active === 2 && <Button.Wrapper active={active}>
                  {active !== 0 &&
                    <Button.Back style={backButtonStyle} onPress={() => this.handleBack()}>‹ Back</Button.Back>}
                  <Primary onPress={() => this.handleNext()} disabled={isDisabled()}>{nextButtonText}</Primary>

                </Button.Wrapper>
                }
              </Section>
            </Sections>

          </React.Fragment>}
        {(errorModal || IsErrorPayment) &&
          <Modal
            h1={'Well this sucks'}
            p="The transaction failed. Please try again. If the problem persists contact your account manager."
            close={() => this.handleModalClose()}
          >
            <Right>
              <Primary onPress={() => this.handleModalClose()}>Close</Primary>
            </Right>
          </Modal>
        }
      </Layout>
    )
  }
}
