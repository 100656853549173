/* eslint-disable react/prop-types */
import React from 'react'
import { Text } from 'react-native'
import { colours } from '../styles/constants'
import isWeb from '../helpers/isWeb'
import lineHeight from '../helpers/lineHeight'

const Message = props => {
  const messageStyles = {
    fontSize: 14,
    lineHeight: lineHeight(16),
    fontFamily: 'greycliff-bold',
    marginTop: 5,
    marginBottom: 5,
    marginRight: 0,
    marginLeft: 0,
    color: props.type === 'error' ? colours.urgent : colours.darkGreen
  }

  if (props.alignRight) {
    messageStyles.textAlign = 'right'
  }

  return (
    <>
      {!isWeb ? <Text style={messageStyles}>{props.children}</Text>
        : <div className={props.className} style={{ ...messageStyles, display: 'block' }}>{props.children}</div>}
    </>
  )
}

const Notification = props => {
  const { children, type, ...rest } = props
  return <Message type={type || 'error'} {...rest}>{children}</Message>
}

export default Notification
