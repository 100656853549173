// app only, a search box which filters results
import React from 'react'
import { colours } from '../../styles/constants'
import { Wrapper, Box, BoxInner, Search, Icon } from './styles'

const Component = props => {
  return (
    <Wrapper>
      <Box>
        <BoxInner>
          <Icon source={require('../../images/search-icon.png')} />
          <Search
            placeholder="Search retailers"
            placeholderTextColor={colours.primary}
            onChangeText={props.onChange}
            value={props.text}
            autoCorrect={false}
          />
        </BoxInner>
      </Box>
    </Wrapper>
  )
}

export default Component
