/* eslint-disable react/prop-types */
import React from 'react'
import { View, Text, Image, StyleSheet } from 'react-native'
import { Link } from 'react-router-dom'
import Layout from '../components/Layout'
import isWeb from '../helpers/isWeb'
import getSuperOptions from '../data/superOptions'
import PropTypes from 'prop-types'
import storage from '../helpers/storage'
import api from '../helpers/boost-client-js-library/api'
import checkRole from '../helpers/checkRole'
import BreakpointWatcher from '../helpers/BreakpointWatcher'

const Tick = props => {
  const inSmallBreakpoint = props.inSmallBreakpoint
  const tickStyles = {
    width: 76,
    height: 76,
    marginRight: 30
  }
  const tickStylesInSmallBreakpoint = {
    marginRight: 0,
    marginBottom: 15
  }
  return (
    <Image style={inSmallBreakpoint ? { ...tickStyles, ...tickStylesInSmallBreakpoint } : tickStyles} source={props.source}/>
  )
}

const Content = props => {
  const inSmallBreakpoint = props.inSmallBreakpoint
  const contentStyles = {
    flexWrap: 'wrap',
    maxWidth: 456
  }
  const contentStylesInSmallBreakpoint = {
    width: '100%',
    textAlign: 'center'
  }
  return (
    <View style={(inSmallBreakpoint && isWeb) ? { ...contentStyles, ...contentStylesInSmallBreakpoint } : contentStyles}>{props.children}</View>
  )
}

export const Links = props => {
  const inSmallBreakpoint = props.inSmallBreakpoint
  const linksStyles = {
    flexDirection: 'row'
  }
  const linksStylesInSmallBreakpoint = {
    justifyContent: 'center'
  }
  return (
    <View style={(inSmallBreakpoint && isWeb) ? { ...linksStyles, ...linksStylesInSmallBreakpoint } : linksStyles}>{props.children}</View>
  )
}

export const WebLink = props => {
  const webLinkStyles = {
    fontSize: 14,
    fontFamily: 'greycliff-bold',
    color: '#000',
    textDecorationLine: 'none',
    marginRight: 20
  }
  return (
    <Link style={isWeb ? webLinkStyles : { display: 'none' }} to={props.to}>{props.children}</Link>
  )
}

export default class Component extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    h1: PropTypes.string,
    p: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    image: PropTypes.string,
    isLoggedIn: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  }

  state = {
    showSidebarOffersLink: false,
    inSmallBreakpoint: false
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
    storage.token.get().then(token => {
      api.profile.get(token, true).then(profile => {
        this.setState({
          showSidebarOffersLink: checkRole(profile.roles).includes('su')
        })
      })
    })
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  render () {
    const { title, h1, p, children, image, isLoggedIn } = this.props
    const { showSidebarOffersLink } = this.state
    return (
      <Layout
        title={title}
        optionsData={getSuperOptions(showSidebarOffersLink)}
        isLoggedIn={isLoggedIn}
      >
        <View style={styles.wrapper}>
          <Tick inSmallBreakpoint={this.state.inSmallBreakpoint} source={image || require('../images/tick-circle-green.png')}/>
          <Content inSmallBreakpoint={this.state.inSmallBreakpoint}>
            {h1 !== '' &&
            <Text style={styles.h1}>{h1}</Text>
            }
            <Text style={styles.p}>{p}</Text>
            <Links inSmallBreakpoint={this.state.inSmallBreakpoint}>
              {children
                ? <React.Fragment>{children}</React.Fragment>
                : <React.Fragment>
                  <WebLink to="/profile/dashboard">Back to your Dashboard ›</WebLink>
                  <WebLink to="/offers">View Boost Offers ›</WebLink>
                </React.Fragment>
              }
            </Links>
          </Content>
        </View>
      </Layout>
    )
  }
}

const styles = StyleSheet.create({
  wrapper: {
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '100%',
    paddingBottom: 20
  },
  h1: {
    fontFamily: 'greycliff-bold',
    fontSize: 18,
    marginBottom: 12
  },
  p: {
    fontFamily: 'greycliff-medium',
    fontSize: 14,
    marginBottom: 12
  }
})
