import ConnectionManager from './ConnectionManager'
import storage, { deleteOfflineData } from './storage'
import api from './boost-client-js-library/api'
import getDeviceId from './deviceId'

/*
* Perform the local part of the logout, i.e. delete the stored data/key and going back to login screen
*/
const localLogout = (appKey, history) => {
  storage.token.delete(appKey).then(() => {
    deleteOfflineData().then(() => {
      // redirect to signin
      history.push('/', { logout: true })
    })
  })
}

const handleSignOut = (history, appKey) => {
  ConnectionManager.getManager().checkApiConnectivity().then((isOnline) => {
    if (isOnline) {
      storage.token.get(appKey).then(token => {
        api.auth.logout(token, getDeviceId()).then(() => {
          localLogout(appKey, history)
        })
      })
    } else {
      // user is not online so we can't delete their token from the server, but can still clean up their stored
      // data and cached stuff
      localLogout(appKey, history)
    }
  })
}

export default handleSignOut
