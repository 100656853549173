import React from 'react'
import Confirmation from '../../components/Confirmation'

export default class Profile extends React.Component {
  render () {
    return (
      <Confirmation
        title="Get an Instant Quote"
        h1="It’s in the mail!"
        p="Thanks for requesting an upgrade. We are working on your quote, and you should receive it soon."
      />
    )
  }
}
