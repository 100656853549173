/* eslint-disable react/prop-types */
// component which houses the list offers
import React from 'react'
import { View, ScrollView, Dimensions, StyleSheet } from 'react-native'
import isWeb from '../helpers/isWeb'
import getDeviceId from '../helpers/deviceId'
import { appMaxWidth, headingStyles, colours, gutter, breakpoints } from '../styles/constants'
import ListOffer, { buttonHeight, buttonHeightLarge } from './ListOffer'
import styled from 'styled-components'
import LoadingIndicator from '../components/LoadingIndicator'
import storage from '../helpers/storage'
import api from '../helpers/boost-client-js-library/api'
import 'abortcontroller-polyfill'
import PropTypes from 'prop-types'
import offerCartAddable from '../helpers/offerCartAddable'
import BreakpointWatcher from '../helpers/BreakpointWatcher'
import moment from 'moment'

const { width } = Dimensions.get('window')
const offersGutter = width * (appMaxWidth / 100) / 2
const buttonWidth = 30

const CarouselWrap = isWeb ? styled.div`
` : View

export default class Component extends React.Component {
  state = {
    position: 0,
    scrollPos: 0,
    scrollPosMax: 2,
    scrollAmount: 0,
    carousel: false,
    offers: [],
    loading: true,
    endReached: false,
    loadOffers: this.props.loadOffers,
    exceedsMediumBreakpoint: false,
    exceedsLargeBreakpoint: false
  }

  static propTypes = {
    loadOffers: PropTypes.bool,
    offers: PropTypes.any,
    next: PropTypes.any,
    isLarge: PropTypes.bool,
    offerRenderer: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    last: PropTypes.any,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isOnline: PropTypes.bool,
    shouldCarousel: PropTypes.bool
  }

  carousel = React.createRef()
  controller = new AbortController()
  getOffers = () => {
    const { offers, next } = this.props
    this.setState({ loading: true })

    if (offers instanceof Promise) {
      offers.then(res => this.setState({ offers: res, loading: false }, () => { this.handleCarousel(res) }))
    } else {
      this.setState({ offers, loading: false }, () => { this.handleCarousel(offers) })
    }
    if (next instanceof Promise) {
      next.then(res => this.setState({ next: res, loading: false }))
    } else {
      this.setState({ next, loading: false })
    }
  }

  componentDidMount () {
    this.getOffers()
    BreakpointWatcher.addComponent(this)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.offers !== this.props.offers) {
      this.getOffers()
    }
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  handleCarousel = items => {
    if (!isWeb) { return }
    const carousel = this.carousel.current
    if (carousel) {
      const width = carousel.getBoundingClientRect().width
      const totalItems = items ? items.length : 0
      const isLarge = this.props.isLarge
      const activeItems = isLarge ? 3 : 4
      this.setState({
        scrollAmount: ((width / activeItems) / (width - buttonWidth)) * 100,
        scrollPosMax: totalItems - activeItems,
        maxScrollAmount: 100,
        buttonSize: isLarge ? buttonHeightLarge : buttonHeight,
        carousel: this.props.shouldCarousel && totalItems > activeItems
      })
    }
  }

  handleNav = type => {
    const { scrollPos, scrollPosMax, scrollAmount } = this.state
    if (type === 'prev' && scrollPos !== 0) {
      this.setState({
        scrollPos: scrollPos - 1,
        position: (scrollPos - 1) * scrollAmount
      })
    }
    if (type === 'next' && scrollPos !== scrollPosMax) {
      this.setState({
        scrollPos: scrollPos + 1,
        position: (scrollPos + 1) * scrollAmount
      })
    }
    if (scrollPos === (scrollPosMax - 1)) this.handleLoadMore()
  }

  handleScroll = e => {
    const { endReached } = this.state
    const offset = e.nativeEvent.layoutMeasurement.width + e.nativeEvent.contentOffset.x
    const width = e.nativeEvent.contentSize.width
    if (offset >= width && !endReached) {
      this.setState({ endReached: true })
      this.handleLoadMore()
    }
  }

  handleLoadMore = () => {
    if (isWeb) {
      // we must request to get the rest
      if (this.state.next !== null && this.state.next !== undefined) { // if there are more items to load
        const more = `&${this.state.next.toString().split('?')[1]}`
        const signal = this.controller.signal
        storage.token.get().then(token => {
          const access = { token, device: getDeviceId() }
          api.offers.get(access, signal, more).then(offers => {
            if (!offers && !offers.items) return false
            this.setState({
              offers: [...this.state.offers, ...offers.items],
              next: offers.next
            }, () => this.handleCarousel(this.state.offers))
          })
        })
      }
    } else {
      // do nothing we should already be displaying them all

    }
  }

  renderOffers = () => {
    const { isLarge, isOnline } = this.props
    const { offers } = this.state
    return (
      offers.map((offer, i) => {
        if (offer.expires != null && moment.utc(offer.expires).isBefore(moment.utc())) {
          return null
        }

        if (this.props.offerRenderer) return this.props.offerRenderer(offer, i, isLarge)
        return <ListOffer key={i} cartStatus={isWeb} isLarge={isLarge} isOnline={isOnline} canAddToCart={offerCartAddable(offer)} {...offer} />
      })
    )
  }

  renderInCarousel () {
    const { last } = this.props
    const { offers, carousel, position, buttonSize } = this.state

    const showButtons = isWeb && carousel
    const hasOffers = offers && offers.length > 0

    return <CarouselWrap ref={this.carousel}>
      {hasOffers &&
        <Offers exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint} horizontal={!isWeb} showsHorizontalScrollIndicator={false} last={last} scrollEventThrottle={16} onScroll={e => this.handleScroll(e)}>
          {showButtons && isWeb && <Button type="prev" buttonSize={buttonSize} onClick={() => this.handleNav('prev')}>Previous</Button>}
          <Carousel last={last}>
            {isWeb
              ? <OffersInnerWeb exceedsLargeBreakpoint={this.state.exceedsLargeBreakpoint} position={position} inCarousel={true}>{this.renderOffers()}</OffersInnerWeb>
              : <View style={styles.offersInner}>{this.renderOffers()}</View>
            }
          </Carousel>
          {showButtons && isWeb && <Button type="next" buttonSize={buttonSize} onClick={() => this.handleNav('next')}>Next</Button>}
        </Offers>
      }
    </CarouselWrap>
  }

  renderWithoutCarousel () {
    const { last } = this.props
    const { offers, position } = this.state

    if (!(offers && offers.length > 0)) {
      return null
    }

    return <Offers exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint} horizontal={!isWeb} showsHorizontalScrollIndicator={false} last={last} scrollEventThrottle={16} onScroll={e => this.handleScroll(e)}>
      {isWeb
        ? <OffersInnerWeb exceedsLargeBreakpoint={this.state.exceedsLargeBreakpoint} position={position} inCarousel={false}>{this.renderOffers()}</OffersInnerWeb>
        : <View style={styles.offersInner}>{this.renderOffers()}</View>
      }
    </Offers>
  }

  render () {
    const { children, loadOffers, shouldCarousel } = this.props
    const { offers, loading } = this.state
    const hasOffers = offers && offers.length > 0

    return (
      <React.Fragment>
        {hasOffers && <View style={styles.offersHeader}>{children}</View>}
        {loading && loadOffers && <LoadingIndicator />}
        { shouldCarousel ? this.renderInCarousel() : this.renderWithoutCarousel() }
      </React.Fragment>
    )
  }

  static defaultProps = {
    loadOffers: true
  }
}

const styles = StyleSheet.create({
  offersHeader: {
    marginBottom: headingStyles.marginBottom
  },
  offersInner: {
    flexDirection: 'row',
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: offersGutter,
    paddingRight: offersGutter
  }
})

const Offers = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const offersStyles = {
    position: 'relative',
    marginTop: 0,
    marginBottom: isWeb ? 30 : 15,
    marginLeft: isWeb ? -(gutter.small) : -(offersGutter),
    marginRight: isWeb ? -(gutter.small) : -(offersGutter)
  }
  const offersStylesIsWeb = {
    paddingBottom: exceedsMediumBreakpoint ? 0 : 30,
    borderBottomWidth: exceedsMediumBreakpoint ? 0 : 1,
    borderBottomColor: colours.black60
  }
  const offersStylesIsWebLast = {
    paddingBottom: 0,
    borderBottomWidth: 0
  }
  return (
    <ScrollView
      horizontal={props.horizontal}
      showsHorizontalScrollIndicator={props.showsHorizontalScrollIndicator}
      scrollEventThrottle={props.scrollEventThrottle}
      onScroll={props.onScroll}
      style={isWeb ? { ...offersStyles, ...offersStylesIsWeb } : isWeb && props.last ? { ...offersStyles, ...offersStylesIsWeb, ...offersStylesIsWebLast } : offersStyles}
    >
      {props.children}
    </ScrollView>
  )
}

const Button = props => {
  const buttonStyles = {
    opacity: 1,
    position: 'absolute',
    top: 0,
    height: props.buttonSize ? props.buttonSize : 200,
    width: buttonWidth,
    border: 0,
    fontSize: 0,
    zIndex: 1,
    left: props.type === 'prev' ? gutter.small : 'auto',
    right: props.type === 'prev' ? 'auto' : gutter.small,
    borderTopLeftRadius: props.type === 'prev' ? 4 : 0,
    borderBottomLeftRadius: props.type === 'prev' ? 4 : 0,
    borderTopRightRadius: props.type === 'prev' ? 0 : 4,
    borderBottomRightRadius: props.type === 'prev' ? 0 : 4,
    appearance: 'none',
    cursor: 'pointer'
  }
  const buttonStylesCss = `
    .list-offers-button${props.type} {
      background: black url(${require('../images/arrow-right-white.png')}) center center / 17px 33px no-repeat;
      ${props.type === 'prev' && `background-image: url(${require('../images/arrow-left-white.png')}) !important;`}
    }
    .list-offers-button${props.type}:active {
      background-color: #666;
    }
    @media(max-width: ${breakpoints.large - 1}px) {
      .list-offers-button${props.type} {
        display: none;
      }
    }
  `
  return (
    <React.Fragment>
      {isWeb && <style type="text/css">{buttonStylesCss}</style>}
      <button
        style={buttonStyles}
        onClick={() => props.onClick()}
        className={`list-offers-button${props.type}`}
      >
        {props.children}
      </button>
    </React.Fragment>
  )
}

const Carousel = props => {
  const carouselStylesCss = `
    @media(min-width: ${breakpoints.large}px){
      .carousel-style {
        overflow: hidden;
        margin: 0 ${gutter.small}px;
        ${!props.last && `
          padding-bottom: 30px;
          border-bottom: 1px solid ${colours.black60};
        `}
      }
    }
  `
  return (
    <React.Fragment>
      {isWeb && <style type="text/css">{carouselStylesCss}</style>}
      {isWeb ? <div className='carousel-style'>{props.children}</div> : <View>{props.children}</View>}
    </React.Fragment>
  )
}

const OffersInnerWeb = props => {
  const { exceedsLargeBreakpoint, inCarousel } = props
  const offersInnerWebStyles = {
    flexDirection: 'row',
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: exceedsLargeBreakpoint ? 0 : gutter.small,
    paddingRight: exceedsLargeBreakpoint ? 0 : gutter.small,
    overflowX: inCarousel ? (exceedsLargeBreakpoint ? 'visible' : 'scroll') : 'auto',
    ...((props.position || props.position === 0) && exceedsLargeBreakpoint ? {
      transform: [{ translateX: `${-props.position}%` }]
    } : {})
  }

  if (!inCarousel) {
    offersInnerWebStyles.flexWrap = 'wrap'
  }

  return (
    <View style={offersInnerWebStyles}>{props.children}</View>
  )
}
