import React from 'react'
import Confirmation, { WebLink } from '../../components/Confirmation'

export default class Profile extends React.Component {
  render () {
    return (
      <Confirmation
        title="Add More Licenses"
        h1="Success!"
        p="You now have more licenses added to your account. Please allow up to 10 minutes before you can see these changes."
      >
        <WebLink to="/profile/user-management-and-ordering/">Back to User Management and Ordering ›</WebLink>
        <WebLink to="/profile/user-management-and-ordering/add-users">Add Users ›</WebLink>
      </Confirmation>
    )
  }
}
