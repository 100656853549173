// web only, sidebar menu in pages including profile views
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { breakpoints, colours, headingStyles, gutter } from '../styles/constants'
import Link from './Link'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import isWeb from '../helpers/isWeb'

const Options = {
  Wrapper: isWeb && styled.div`
        display: flex;
        align-items: stretch;
        flex-direction: column;
        flex-shrink: 0;
        margin-top: 40px;
        ${isWeb && `
            .toggle {
                display: none;
            }
            @media (min-width: ${breakpoints.large}px) {
                margin-top: 0;
                flex: 0 0 180px;
                border-left-width: 1px;
                border-left-style: solid;
                border-color: ${colours.light50};
                padding-left: 15px;
                position: relative;
                .toggle {
                    display: block;
                    font-size: 0;
                    cursor: pointer;
                    border: 0;
                    position: absolute;
                    right: 100%;
                    top: 0;
                    width: 15px;
                    height: 24px;
                    background: ${colours.light} url(${require('../images/arrow-right-white.png')}) center center / 6px no-repeat;
                    border-radius: 4px 0 0 4px;
                    &:active {
                        opacity: .7;
                    }
                }
            }
            @media (max-width: ${breakpoints.medium - 1}px) {
                margin: 0;
                padding: 0 ${gutter.medium}px;
                border-bottom: 1px solid ${colours.black50};
                background: white;
                * {
                    border: 0;
                    font-family: greycliff-medium;
                    font-size: 20px;
                }
                &.news {
                    background: ${colours.light20};
                    padding: 0;
                    border: 0;
                    &:after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 0;
                        height: 100%;
                        width: 50px;
                        background: linear-gradient(to right, rgba(204,237,255,.5), ${colours.light20});
                    }
                    > * { // Options.Inner
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        overflow: scroll;
                        padding: 4px ${gutter.small}px;
                        border-bottom: 1px solid ${colours.black50};
                        position: relative;
                        * {
                            font-size: 18px;
                        }
                    }
                }
            }
            @media (max-width: ${breakpoints.medium - 2}px) {
                padding: 0 ${gutter.small}px;
            }

            &.hide-on-medium {
                @media (min-width: ${breakpoints.medium}px) {
                    display: none;
                }
            }
            &.show-on-medium {
                display: none;
                @media (min-width: ${breakpoints.medium}px) {
                    display: flex;
                    z-index: -1;
                }
            }
            .modal-open & {
                z-index: -1;
            }
        `}
        ${props => !props.show && `
            @media (min-width: ${breakpoints.large}px) {
                width: 0;
                flex: 0;
                padding: 0;
                text-indent: -9999px;
            }
        `}
    `,
  Inner: isWeb && styled.div`
        display: flex;
        align-items: stretch;
        flex-direction: column;
        flex-shrink: 0;
        position: static;
        background: inherit;
    `,
  Heading: isWeb && styled.div`
        display: block;
        font-family: ${headingStyles.fontFamily};
        font-size: ${headingStyles.fontSize}px;
        margin-bottom: ${headingStyles.marginBottom}px;
        ${isWeb && `
            @media(min-width: ${breakpoints.large}px){
                font-size: ${headingStyles.fontSizeLarge}px;
                line-height: ${headingStyles.fontSizeLarge}px;
            }
            @media (max-width: ${breakpoints.medium - 1}px) {
                font-family: greycliff-medium;
                font-size: 24px;
                margin: 0;
                padding: 15px 0;
                padding-left: 35px;
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background: url(${require('../images/filter-menu.png')}) left center / 100% 100% no-repeat;
                    width: 25px;
                    height: 19px;
                }
                .news & {
                    font-family: greycliff-extrabold;
                    padding: 0;
                    margin-right: 15px;
                    flex: 1;
                    font-size: 0;
                    &:before {
                        display: none;
                    }
                    &:after {
                        content: 'Categories: ';
                        font-size: 18px;
                    }
                }
                .categories & {
                    font-size: 0;
                    &:after {
                        content: 'Filter';
                        font-size: 24px;
                    }
                }
            }
        `}
        ${props => props.showoptions && `
            @media (max-width: ${breakpoints.medium - 1}px) {
                &:before {
                    background-image: url(${require('../images/close-grey.png')});
                    background-size: 19px;
                }
            }
        `}
    `,
  List: isWeb && styled.div`
        display: flex;
        align-items: stretch;
        flex-direction: column;
        flex-shrink: 0;
        cursor: pointer;
        @media (max-width: ${breakpoints.medium - 1}px) {
            padding: 15px 0;
        }
        ${props => !props.showoptions && `
            @media (max-width: ${breakpoints.medium - 1}px) {
                display: none;
            }
        `}
        @media (max-width: ${breakpoints.medium - 1}px) {
            .news & {
                display: flex;
                flex-direction: row;
                > * {
                    margin: 0 15px 0 0;
                    padding: 0;
                    position: relative;
                    padding-left: 25px;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 5px;
                        top: 50%;
                        transform: translateY(-50%);
                        background: ${colours.primary};
                        border-radius: 100%;
                        width: 3px;
                        height: 3px;
                    }
                }
            }
        }
        @media (min-width: ${breakpoints.small}px) {
            column-count: 2;
            display: table-column;
            ${props => !props.showoptions && ` 
                display: none; 
            `}
        }
        @media (min-width: ${breakpoints.medium}px) {
            display: flex;
        }
    `
}

class Component extends React.Component {
  state = {
    show: true,
    showoptions: false
  }

  render () {
    const { optionsTitle, optionsData, optionsClassName, location, selectedOptionId } = this.props
    const { show, showoptions } = this.state
    if (optionsData) {
      return (
        <Options.Wrapper show={show} className={optionsClassName}>
          <Options.Inner>
            <button className="toggle" onClick={() => this.setState({ show: !show })} title={`${show ? 'Hide' : 'Show'} options`}>Toggle show/hide options</button>
            <Options.Heading showoptions={showoptions ? 1 : 0} onClick={() => this.setState({ showoptions: !showoptions })}>{optionsTitle || 'Options'}</Options.Heading>
            <Options.List showoptions={showoptions ? 1 : 0}>
              {optionsData && optionsData.map((option, i) => {
                const last = (i + 1) === optionsData.length
                const active = location.pathname === option.link || location.pathname.includes(option.id) || selectedOptionId === option.id
                return (
                  <View key={i} style={[styles.option, last && styles.last]}>
                    <Link link={option.link} style={active ? styles.activeLink : styles.link}>{option.title}</Link>
                    {option.subOptions &&
                                            <View style={styles.subLinks}>
                                              {option.subOptions.map((sub, j) => {
                                                const subActive = location.pathname === sub.link
                                                return subActive && <View key={j} style={styles.subLink}><Link link={sub.link} style={styles.activeLink}>{sub.title}</Link></View>
                                              })}
                                            </View>
                    }
                  </View>
                )
              })}
            </Options.List>
          </Options.Inner>
        </Options.Wrapper>
      )
    }
  }
}

export default withRouter(Component)

const styles = StyleSheet.create({
  option: {
    paddingBottom: 12,
    marginBottom: 12,
    borderBottomWidth: 1,
    borderColor: colours.light50
  },
  link: {
    fontFamily: 'greycliff-bold'
  },
  activeLink: {
    fontFamily: 'greycliff'
  },
  last: {
    borderBottomWidth: 0
  },
  subLinks: {
  },
  subLink: {
    marginLeft: 40,
    marginTop: 15
  }
})
