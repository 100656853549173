/* eslint-disable react/prop-types */
import React from 'react'
import { View, ActivityIndicator } from 'react-native'
import { colours } from '../styles/constants'

const InlineLoadingIndicator = (props) => <View style={{ ...props.style, ...indicatorWrap }}><ActivityIndicator color={colours.primary} /></View>

export default InlineLoadingIndicator

const indicatorWrap = {
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 10,
  marginRight: 10,
  alignItems: 'center'
}
