import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../../components/Modal'
import { Primary, Secondary } from '../../components/Inputs/Buttons'
import { DataTable, H2, P } from '../../styles/globalClasses'
import { View } from 'react-native'
import BreakpointWatcher from '../../helpers/BreakpointWatcher'
import LoadingIndicator from '../../components/LoadingIndicator'
import { colours } from '../../styles/constants'
import isWeb from '../../helpers/isWeb'
import styled from 'styled-components'
import foregroundColorFromBackground from '../../helpers/colors'

const modalStyle = {
  height: 500,
  flexDirection: 'column'
}

const createButtonStyle = {
  alignSelf: 'end'
}

const GroupsTable = isWeb && styled(DataTable)`
  margin: -20px 0 20px 0;

  .rt-td {
    display: flex;
    align-content: center;
    vertical-align: middle;
  }

  .actions-td {
    flex-grow: 1;
  }

  .name-td {
    font-size: 14px;
    line-height: 27px;
    flex-shrink: 1;
  }

  .rt-tbody {
    padding-bottom: 1px;
    margin-bottom: -1px;
  }

  .rt-tbody .rt-tr-group:last-child {
    position: relative;

    &:before {
      content: '';
      background: ${colours.light};
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      z-index: 1;
    }
  }
`

const TableWrapper = ({
  style = {},
  children,
  ...props
}) => {
  const _style = {
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    minHeight: 167,
    ...style
  }

  return <View style={_style} {...props}>
    {children}
  </View>
}

const smallButtonStyle = {
  fontSize: 11,
  paddingVertical: 13,
  paddingHorizontal: 9,
  lineHeight: 11
}

const StartDeleteButton = ({
  style = {},
  children,
  ...props
}) => {
  const _style = {
    backgroundColor: colours.urgent,
    marginLeft: 10,
    ...smallButtonStyle,
    ...style
  }

  return <Primary style={_style} {...props}>{children}</Primary>
}

const deleteButtonStyle = {
  backgroundColor: colours.urgent
}

const cancelButtonStyle = {}

const innerStyle = {
  minHeight: 167,
  alignContent: 'space-around',
  justifyContent: 'space-between',
  width: '100%'
}

const inputStyle = {
  margin: '15px 0',
  width: '100%',
  padding: '8px 5px'
}

const buttonContainerStyle = {
  flexDirection: 'row',
  justifyContent: 'space-between'
}

const errorTextStyle = {
  color: colours.urgent,
  fontFamily: 'greycliff-bold'
}

export default class GroupManagementModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    groupsLoading: PropTypes.bool,
    saveGroup: PropTypes.func,
    deleteGroup: PropTypes.func
  }

  state = {
    inCreate: false,
    inEdit: false,
    inDelete: false,
    nameError: null,
    saveError: null,
    saveInProgress: false,
    deleteError: true,
    exceedsMediumBreakpoint: false,
    deleteGroupId: null,
    deleteGroupName: null,
    editGroupId: null,
    editGroupName: null
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
    this.resetEditingGroup()
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  resetEditingGroup () {
    this.setState({
      editingGroup: {
        ID: null,
        name: null,
        logoUrl: null
      }
    })
  }

  backToView () {
    this.setState({
      inCreate: false,
      inEdit: false,
      editingGroup: {
        ID: null,
        name: null,
        logoUrl: null
      }
    })
  }

  enterCreateMode () {
    this.setState({
      inCreate: true,
      saveError: null,
      nameError: null,
      inEdit: false
    })
  }

  enterEditMode (groupId, groupName) {
    this.setState({
      inEdit: true,
      inCreate: false,
      saveError: null,
      nameError: null,
      editingGroup: {
        ID: groupId,
        name: groupName,
        logoUrl: null
      }
    })
  }

  saveGroup (isCreate) {
    this.setState({
      nameError: null,
      saveError: null
    })
    const {
      ID,
      name,
      logoUrl
    } = this.state.editingGroup

    if (name == null || name.trim === '') {
      this.setState({ nameError: 'Name must not be empty.' })
      return
    }

    this.setState({ saveInProgress: true })

    this.props.saveGroup(isCreate, name, logoUrl, ID,
      () => {
        this.resetEditingGroup()
        this.setState({
          inCreate: false,
          inEdit: false,
          saveInProgress: false
        })
      }, (error) => {
        this.setState({
          saveError: error,
          saveInProgress: false
        })
      })
  }

  deleteGroup () {
    const {
      deleteGroupId,
      saveInProgress
    } = this.state

    if (!deleteGroupId || saveInProgress) {
      return
    }

    this.setState({
      saveInProgress: true,
      deleteError: null
    })

    const { deleteGroup } = this.props

    deleteGroup(deleteGroupId, () => {
      this.setState({ saveInProgress: false })
      this.resetDelete()
    },
    (error) => {
      this.setState({
        deleteError: error,
        saveInProgress: false
      })
    }
    )
  }

  startDelete (groupId, groupName) {
    this.setState({
      deleteGroupId: groupId,
      deleteGroupName: groupName,
      inDelete: true
    })
  }

  resetDelete () {
    this.setState({
      deleteGroupId: null,
      deleteGroupName: null,
      inDelete: false
    })
  }

  setGroupName (e) {
    const editingGroup = this.state.editingGroup
    editingGroup.name = e.target.value.substring(0, 30)
    this.setState({ editingGroup })
  }

  commitEdit () {
    const {
      saveInProgress,
      inCreate
    } = this.state

    if (saveInProgress) {
      return
    }

    this.saveGroup(inCreate)
  }

  render () {
    const {
      visible,
      groups,
      close,
      groupsLoading
    } = this.props

    const {
      inCreate,
      inEdit,
      inDelete,
      nameError,
      saveError,
      exceedsMediumBreakpoint,
      deleteGroupName,
      deleteError,
      saveInProgress,
      editingGroup
    } = this.state

    if (!visible) {
      return null
    }

    const showForm = inCreate || inEdit

    return <Modal
      h1="Manage Groups"
      style={modalStyle}
      close={() => {
        if (saveInProgress) {
          return
        }
        close()
      }
      }
    >
      {!showForm && !inDelete && this.renderTable(exceedsMediumBreakpoint, groupsLoading, groups)}
      {showForm && this.renderForm(inCreate, nameError, exceedsMediumBreakpoint, saveError, saveInProgress, editingGroup)}
      {inDelete && this.renderDelete(exceedsMediumBreakpoint, deleteGroupName, deleteError, saveInProgress)}
    </Modal>
  }

  renderTable (exceedsMediumBreakpoint, groupsLoading, groups) {
    const columns = [
      {
        Header: 'Group Name',
        accessor: 'name',
        className: 'name-td',
        Cell: cellInfo => {
          return <div style={{
            backgroundColor: `#${cellInfo.original.colour}`,
            borderRadius: 4,
            padding: '6px 6px 3px',
            color: foregroundColorFromBackground(cellInfo.original.colour)
          }}>{cellInfo.original.name}</div>
        }
      },
      {
        Header: 'Actions',
        Cell: (cellInfo) => {
          const group = cellInfo.original
          return <React.Fragment>
            <Primary style={smallButtonStyle} onPress={
              () => {
                this.enterEditMode(group.ID, group.name)
              }
            }>
              Edit
            </Primary>
            <StartDeleteButton onPress={
              () => {
                this.startDelete(group.ID, group.name)
              }
            }>Remove Group&hellip;</StartDeleteButton>
          </React.Fragment>
        },
        className: 'actions-td'
      }
    ]

    if (groupsLoading) {
      return <TableWrapper>
        <LoadingIndicator/>
      </TableWrapper>
    }

    return <TableWrapper>
      {
        groups.length ? <GroupsTable
          data={groups}
          columns={columns}
          defaultPageSize={5}
          showPagination={groups.length > 5}
          showPageSizeOptions={false}
        />
          : <View>
            <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>You currently do not have any groups set up.</P>
          </View>
      }
      <View>
        <Primary style={createButtonStyle} onPress={() => { this.enterCreateMode() }}>Create Group&hellip;</Primary>
      </View>
    </TableWrapper>
  }

  renderDelete (exceedsMediumBreakpoint, deleteGroupName, deleteError, saveInProgress) {
    return <View style={innerStyle}>
      <View><H2>Remove Group</H2>
        <View>
          <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Are you sure you want to remove the
            group <strong>{deleteGroupName}</strong>?</P>
          {deleteError != null &&
            <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} style={errorTextStyle}>{deleteError}</P>}
        </View>
      </View>
      <View style={buttonContainerStyle}>
        <Secondary style={cancelButtonStyle} onPress={() => this.resetDelete()}
          disabled={saveInProgress}>Cancel</Secondary>
        <Primary style={{ ...(!saveInProgress ? deleteButtonStyle : {}) }} onPress={() => this.deleteGroup()}
          disabled={saveInProgress}>
          {saveInProgress ? 'Removing…' : 'Remove'}
        </Primary>
      </View>
    </View>
  }

  renderForm (inCreate, nameError, exceedsMediumBreakpoint, saveError, saveInProgress, editingGroup) {
    return <View style={innerStyle}>
      <View><H2>{inCreate ? 'Create' : 'Edit'} Group</H2>
        <View>
          <input
            type="text"
            style={inputStyle}
            placeholder="Group Name"
            onChange={(e) => { this.setGroupName(e) }}
            maxLength={30}
            value={editingGroup.name}
          />
          {nameError != null &&
            <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} style={errorTextStyle}>{nameError}</P>}
          {saveError != null &&
            <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} style={errorTextStyle}>{saveError}</P>}
        </View>
      </View>
      <View style={buttonContainerStyle}>
        <Secondary style={cancelButtonStyle} onPress={() => this.backToView()}
          disabled={saveInProgress}>Cancel</Secondary>
        <Primary style={createButtonStyle} onPress={() => this.saveGroup(inCreate)} disabled={saveInProgress}>
          {inCreate ? (saveInProgress ? 'Creating…' : 'Create') : (saveInProgress ? 'Saving…' : 'Save')}
        </Primary>
      </View>
    </View>
  }
}
