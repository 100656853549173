import EntityFacade, { EntityType } from './EntityFacade'
import ImageManager from './ImageManager'
import consoleLog from './consoleLog'
import offerType from './offerType'

const CATEGORIES_TO_PRE_CACHE = 1

const mockSignal = {
  /**
   * Since we are outside of a component and the API function requires a signal, use this mock one
   */
  addEventListener () {
  }
}

function shouldCacheOfferImage (offer, isInitialLoad, categoriesToCacheIds = null) {
  if (!isInitialLoad) {
    return true
  }

  if (offer.featured || offerType(offer.type) === 'lto') {
    return true
  }
  if (categoriesToCacheIds != null && offer.categoryIds) {
    for (const categoryId of offer.categoryIds) {
      if (categoriesToCacheIds.includes(categoryId)) {
        return true
      }
    }
  }
  return false
}

export async function preCacheOffers (access, isInitialLoad, categoriesToCacheIds = null) {
  /**
   * Fetch all the Offers
   */
  const ef = new EntityFacade(true, access)
  const offersResp = await ef.getAllOffers(mockSignal)

  const im = ImageManager.getManager(true)
  for (const offer of offersResp.items) {
    if (!shouldCacheOfferImage(offer, isInitialLoad, categoriesToCacheIds)) {
      continue
    }
    consoleLog(`[PreCache] Performing Cache of ${offer.image}`)
    await Promise.all([
      im.getImageUrl(offer.ID, offer.image, EntityType.OFFER),
      im.getImageUrl(offer.supplierID, offer.supplierLogo, EntityType.SUPPLIER)
    ])
  }
}

async function cacheSupplierLogos (suppliers) {
  const im = ImageManager.getManager(true)

  for (const supplier of suppliers) {
    await im.getImageUrl(supplier.ID, supplier.logo, EntityType.SUPPLIER)
  }
}

async function preCacheSuppliers (access) {
  const ef = new EntityFacade(true, access)
  const supplierResp = await ef.getSuppliers(mockSignal, true)

  if (supplierResp !== null && supplierResp.items) {
    await cacheSupplierLogos(supplierResp.items)
  }

  const extSuppliersResp = await ef.getExternalSuppliers(mockSignal, true)

  if (extSuppliersResp !== null && extSuppliersResp.items) {
    await cacheSupplierLogos(extSuppliersResp.items)
  }
}

async function preCacheOfferCategories (access) {
  const ef = new EntityFacade(true, access)
  return await ef.getOfferCategories()
}

async function preCacheProfile (access) {
  const ef = new EntityFacade(true, access)
  await ef.getProfile(access, true)
}

async function preCacheVehicle (access) {
  const ef = new EntityFacade(true, access)
  await ef.getVehicle(access, true)
}

export default async function preCacheAll (access, updateCallback) {
  consoleLog('Pre-caching everything started')

  updateCallback('offer categories')
  const categories = await preCacheOfferCategories(access)

  const categoriesToCacheIds = []

  for (const category of categories.items) {
    categoriesToCacheIds.push(category.ID)
    if (categoriesToCacheIds.length === CATEGORIES_TO_PRE_CACHE) {
      break
    }
  }

  updateCallback('offers')
  await preCacheOffers(access, true, categoriesToCacheIds)

  updateCallback('suppliers')
  await preCacheSuppliers(access)

  updateCallback('profile')
  await Promise.all([preCacheProfile(access), preCacheVehicle(access)])
  consoleLog('Pre-caching everything finished')
}
