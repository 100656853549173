import React from 'react'
import { StyleSheet } from 'react-native'
import Confirmation, { WebLink } from '../../components/Confirmation'
import { Link } from 'react-router-dom'
import api from '../../helpers/boost-client-js-library/api'
import storage from '../../helpers/storage'
import checkRole from '../../helpers/checkRole'

export default class CustomisationError extends React.Component {
  state = {
    showLink: false,
    showOffers: false
  }

  componentDidMount () {
    storage.token.get().then(token => {
      api.profile.get(token).then(profile => {
        this.setState({ showOffers: checkRole(profile.roles).includes('su') })
      })
      api.organisation.get(token).then(profile => {
        if (!profile.tier || profile.tier !== '4') {
          this.setState({
            showLink: true
          })
        } else {
          return false
        }
      })
    })
  }

  render () {
    const { showLink, showOffers } = this.state
    return (
      <Confirmation
        title="Customisation"
        h1=""
        p={<React.Fragment>No customisations are available on your current plan. If you think this is an error please contact your account manager.
          {showLink &&
              <React.Fragment> Alternatively you can <Link style={styles.underline} to="/profile/upgrade">Upgrade your Plan.</Link></React.Fragment>
          }
        </React.Fragment>}
        image={require('../../images/error-icon.png')}
      >
        <WebLink to="/profile/dashboard">Dashboard ›</WebLink>
        {showOffers && <WebLink to="/offers">View Boost Offers ›</WebLink>}
      </Confirmation>
    )
  }
}

const styles = StyleSheet.create({
  underline: {
    textDecorationLine: 'underline',
    color: '#000',
    fontFamily: 'greycliff-bold'
  }
})
