const reSuperOptions = (tier, options, type, optionsWithSuppliers) => {
  const nTier = Number(tier)
  const isSupplier = type === 'suppliers'
  let newOptions = isSupplier ? optionsWithSuppliers || options : options

  if (nTier === 4) {
    newOptions = newOptions.filter(i => i.link !== '/profile/upgrade')
  }
  if (nTier < 2) {
    newOptions = newOptions.filter(i => i.link !== '/profile/customisation' && i.link !== '/profile/offer-management')
  }
  return newOptions
}

export default reSuperOptions
