import consoleLog from './consoleLog'
import api from './boost-client-js-library/api'
import timestamp from './date'
import isWeb from './isWeb'
import AsyncStorage from '@react-native-async-storage/async-storage'

const RECACHE_DURATION = 3600

const CACHE_VALIDITY_DURATION = 24 * 7 * 3600
export const TEMP_MAX_ITEMS = 10000

const ALL_OFFERS_FILTER = `offset=0&limit=${TEMP_MAX_ITEMS}`

export const EntityType = {
  SUPPLIER: 'supplier',
  SUPPLIER_OFFER: 'supplier-offer',
  EXTERNAL_SUPPLIER: 'external-supplier',
  EXTERNAL_SUPPLIER_OFFER: 'external-supplier-offer',
  CATEGORY: 'category',
  OFFER: 'offer'
}

Object.freeze(EntityType)

const CacheValidity = {
  EXPIRED: 0,
  RECACHE_EXPIRED: 1,
  VALID: 2
}

Object.freeze(CacheValidity)

function safeJsonParse (value, _default) {
  /**
   * Try to parse JSON in `value`. If this fails, then return `_default` (default).
   */
  try {
    return JSON.parse(value)
  } catch (e) {
    consoleLog(`[EntityFacade] Unable to parse JSON: ${e}`)
    return _default
  }
}

export default class EntityFacade {
  isOnline = false
  access = null
  fetchCache = {}
  fetchCacheCount = {}

  // Developers can manipulate this to invalidate the cache
  static cachePrefix = 'boost-v1-'

  constructor (isOnline, access) {
    this.isOnline = isOnline
    this.access = access
  }

  static async clearAll () {
    const keys = await AsyncStorage.getAllKeys()
    await keys.forEach(async (key) => {
      if (key.startsWith(EntityFacade.cachePrefix)) {
        await AsyncStorage.removeItem(key, () => {})
      }
    })
    consoleLog('[EntityFacade] EntityCache cleared all')
  }

  static async storageGet (key, _default) {
    /**
     *  Get an item from the AsyncStorage then decode it as JSON.
     *  If there is an exception thrown during JSON decode (key might not exist or be bad) then return `_default` (default).
     */
    const value = await AsyncStorage.getItem(`${EntityFacade.cachePrefix}${key}`, () => {})
    return safeJsonParse(value, _default)
  }

  static async storageSet (key, data) {
    /**
     * Serialize `data` as JSON and store it to AsyncStorage under the `key` key.
     */
    await AsyncStorage.setItem(`${EntityFacade.cachePrefix}${key}`, JSON.stringify(data), () => {})
  }

  static async getCacheDate (dateKey) {
    const cacheData = parseInt(await EntityFacade.storageGet(dateKey, 0))
    return isNaN(cacheData) ? 0 : cacheData
  }

  static getDateKey (dataKey) {
    /**
     * Generate a key to fetch the date which some data was stored, based on its cache key.
     */
    return `${dataKey}-date`
  }

  static getMultiEntityKey (entityType, fetchArg) {
    return `${entityType}s-${fetchArg}`
  }

  static validityFromCacheDate (date, cacheKey) {
    /**
     * If the cache is still valid and is recent enough that it doesn't need recache, return CacheValidity.VALID
     *
     * If the cache is still valid, but is a little bit old (older than RECACHE_DURATION) return
     * CacheValidity.RECACHE_EXPIRED. The data can still be used but should be re-fetched if we're online.
     *
     * If the cache is too old to be valid (older than CACHE_VALIDITY_DURATION) return CacheValidity.RECACHE_EXPIRED
     */
    const timeDifference = timestamp() - date
    consoleLog(`[EntityFacade] Cache age of '${cacheKey}' is ${timeDifference}`)

    if (timeDifference > CACHE_VALIDITY_DURATION) return CacheValidity.EXPIRED

    return timeDifference > RECACHE_DURATION ? CacheValidity.RECACHE_EXPIRED : CacheValidity.VALID
  }

  static async getCachedMultiEntityMetadata (entityType, fetchArg) {
    /**
     * We need to generate the cache key and date key consistently in different methods
     */
    const cacheKey = EntityFacade.getMultiEntityKey(entityType, fetchArg)
    const dateKey = EntityFacade.getDateKey(cacheKey)
    const cacheDate = await EntityFacade.getCacheDate(dateKey)

    return [cacheKey, cacheDate]
  }

  static async getCachedMultiEntityData (entityType, fetchArg, _default) {
    /**
     * Get entities from the cache. Return an array of [CacheValidity, CacheData]
     */
    const [cacheKey, cacheDate] = await EntityFacade.getCachedMultiEntityMetadata(entityType, fetchArg)
    const data = await EntityFacade.storageGet(cacheKey, _default)

    return [EntityFacade.validityFromCacheDate(cacheDate, cacheKey), data]
  }

  static async setCachedMultiEntityData (entityType, fetchArg, data) {
    /**
     * Set entities to the cache, and update the cacheDate.
     */
    const entityKey = EntityFacade.getMultiEntityKey(entityType, fetchArg)
    await EntityFacade.storageSet(entityKey, data)
    await EntityFacade.storageSet(EntityFacade.getDateKey(entityKey), timestamp())
  }

  static getEntityCacheKey (entityType, entityId) {
    return `${entityType}-${entityId}`
  }

  static async setCachedEntity (entityType, entity) {
    const key = EntityFacade.getEntityCacheKey(entityType, entity.ID)
    await EntityFacade.storageSet(key, entity)
    await EntityFacade.storageSet(EntityFacade.getDateKey(key), timestamp())
  }

  static async getCachedEntity (entityType, entityId) {
    const cacheKey = EntityFacade.getEntityCacheKey(entityType, entityId)
    const dateKey = EntityFacade.getDateKey(cacheKey)
    const cacheDate = await EntityFacade.getCacheDate(dateKey)
    const entity = await EntityFacade.storageGet(cacheKey, null)

    return [EntityFacade.validityFromCacheDate(cacheDate, cacheKey), entity]
  }

  async getEntities (entityType, _default, signal, fetchFunction, fetchArg) {
    fetchArg = fetchArg || ''

    if (isWeb) { // no caching on web
      return this.multiEntityApiFetch(signal, fetchFunction, fetchArg)
    }

    let [validity, response] = await EntityFacade.getCachedMultiEntityData(entityType, fetchArg, _default)

    if (validity === CacheValidity.VALID) {
      consoleLog(`[EntityFacade] Returning ${entityType}s ${fetchArg} from the cache.`)
      return response
    }

    // if we're here, validity is CacheValidity.RECACHE_EXPIRED or CacheValidity.EXPIRED
    if (this.isOnline) {
      // Data is older than RECACHE_DURATION (and possibly older than CACHE_VALIDITY_DURATION) so renew it
      const requestCacheKey = `${entityType}-${fetchArg}`
      try {
        if (this.fetchCache[requestCacheKey] === undefined) {
          consoleLog(`[EntityFacade] Re-fetching and caching ${entityType}s ${fetchArg}.`)
          this.fetchCache[requestCacheKey] = this.multiEntityApiFetch(signal, fetchFunction, fetchArg, response)
          this.fetchCacheCount[requestCacheKey] = 0
        } else {
          consoleLog(`[EntityFacade] Reusing request ${requestCacheKey}`)
        }

        ++this.fetchCacheCount[requestCacheKey]

        response = await this.fetchCache[requestCacheKey]

        --this.fetchCacheCount[requestCacheKey]

        if (this.fetchCacheCount[requestCacheKey] <= 0) {
          consoleLog(`All requests done, deleting ${requestCacheKey}`)
          delete this.fetchCache[requestCacheKey]
        }

        if (response.items === undefined) {
          consoleLog(response)
          return null
        }
      } catch (e) {
        consoleLog(`[EntityFacade] Error when fetching ${entityType}s: ${e}`)
        return null
      }

      // update individual entities for separate retrieval
      if (response.items) {
        await response.items.forEach(async (entity) => {
          // since (EXTERNAL_)SUPPLIER_OFFER queries return offers actually, cache them individually as offers
          const singleEntityType = (
            entityType === EntityType.SUPPLIER_OFFER || entityType === EntityType.EXTERNAL_SUPPLIER_OFFER
          ) ? EntityType.SUPPLIER : entityType
          await EntityFacade.setCachedEntity(singleEntityType, entity)
        })
      }

      await EntityFacade.setCachedMultiEntityData(entityType, fetchArg, response)
    } else if (validity === CacheValidity.EXPIRED) {
      // We are offline but have bad data
      return null
    }

    // Data is older than RECACHE_DURATION but newer than CACHE_VALIDITY_DURATION so just return it
    // validity must be CacheValidity.RECACHE_EXPIRED
    return response
  }

  async getEntity (entityType, entityId, fetchFunction) {
    if (isWeb) { // no caching on web
      return this.entityApiFetch(fetchFunction, entityId)
    }

    let [validity, entity] = await EntityFacade.getCachedEntity(entityType, entityId)

    if (validity === CacheValidity.VALID) {
      consoleLog(`[EntityFacade] Returning single ${entityType} ${entityId} from the cache.`)
      return entity
    }

    if (this.isOnline) {
      // Data is older than RECACHE_DURATION (and possibly older than CACHE_VALIDITY_DURATION) so renew it
      try {
        consoleLog(`[EntityFacade] Re-caching single ${entityType} ${entityId}.`)
        entity = await this.entityApiFetch(fetchFunction, entityId)
      } catch (e) {
        consoleLog(`[EntityFacade] Error when fetching ${entityType} ${entityId}: ${e}`)
        return null
      }
      if (entity !== null) {
        await EntityFacade.setCachedEntity(entityType, entity)
      }
    } else if (validity === CacheValidity.EXPIRED) {
      // We are offline but have bad data
      return null
    }

    // Data is older than RECACHE_DURATION but newer than CACHE_VALIDITY_DURATION so just return it
    return entity
  }

  async entityApiFetch (fetchFunction, entityId) {
    return fetchFunction(this.access, entityId)
  }

  async multiEntityApiFetch (signal, fetchFunction, fetchArg) {
    let response
    if (signal === null) {
      response = await fetchFunction(this.access, fetchArg)
    } else {
      response = await fetchFunction(this.access, signal, fetchArg)
    }
    return response
  }

  async getSuppliers (signal, withOffersOnly = false) {
    return this.getEntities(EntityType.SUPPLIER, [], signal, api.suppliers.get,
      `&offset=0&limit=${TEMP_MAX_ITEMS}${withOffersOnly ? '&has_offers=true' : ''}`)
  }

  async getSupplier (supplierId) {
    return this.getEntity(EntityType.SUPPLIER, supplierId, api.suppliers.single)
  }

  async getSupplierOffers (supplierId) {
    return this.getEntities(`${EntityType.SUPPLIER_OFFER}-${supplierId}`, [], null,
      (access, args) => api.suppliers.singleOffers(access, supplierId, args),
      `offset=0&limit=${TEMP_MAX_ITEMS}`)
  }

  async getExternalSupplier (supplierId) {
    return this.getEntity(EntityType.EXTERNAL_SUPPLIER, supplierId, api.suppliers.singleExternal)
  }

  async getExternalSuppliers (signal, withOffersOnly = false) {
    return this.getEntities(EntityType.EXTERNAL_SUPPLIER, [], signal, api.suppliers.getExternal,
      `&offset=0&limit=${TEMP_MAX_ITEMS}${withOffersOnly ? '&has_offers=true' : ''}`)
  }

  async getExternalSupplierOffers (supplierId) {
    return this.getEntities(`${EntityType.EXTERNAL_SUPPLIER_OFFER}-${supplierId}`, [], null,
      (access, args) => api.suppliers.singleExternalOffers(access, supplierId, args),
      `offset=0&limit=${TEMP_MAX_ITEMS}`)
  }

  async getOfferCategories () {
    return this.getEntities(EntityType.CATEGORY, [], null, api.offers.categories,
      `&offset=0&limit=${TEMP_MAX_ITEMS}`)
  }

  async getAllOffers (signal) {
    /**
     * Download and cache all the Offers. We can then later filter this on client side.
     */
    return this.getEntities(EntityType.OFFER, [], signal, api.offers.get, ALL_OFFERS_FILTER)
  }

  async getOffersInCategory (signal, categoryId, limit) {
    const filterFunction = (offers) => offers.filter(offer => offer.categoryIds.includes(categoryId))

    const cacheKey = `${EntityType.OFFER}s-${categoryId}`

    return this.filterOffers(filterFunction, cacheKey, signal, limit)
  }

  async getOffersForSupplier (signal, supplierId, limit) {
    const filterFunction = (offers) => {
      return offers.filter(offer =>
        offer.supplierID === supplierId
      )
    }

    const cacheKey = `${EntityType.OFFER}s-${supplierId}`

    return this.filterOffers(filterFunction, cacheKey, signal, limit)
  }

  async filterOffers (filterFunction, cacheKey, signal, limit) {
    const metaData = await EntityFacade.getCachedMultiEntityMetadata(EntityType.OFFER, ALL_OFFERS_FILTER)
    const cacheDate = metaData[1]

    let offers = null

    if (EntityFacade.validityFromCacheDate(cacheDate, cacheKey) === CacheValidity.VALID) {
      offers = await EntityFacade.storageGet(cacheKey, null)
    }

    if (offers === null) {
      const allOffersResp = await this.getAllOffers(signal)

      if (allOffersResp === null) return []
      offers = filterFunction(allOffersResp.items)

      // don't set the date as we use the date from the all offers
      await EntityFacade.storageSet(cacheKey, offers)
    }

    if (limit) return offers.slice(0, limit)

    return offers
  }

  async getOffers (signal, fetchArg) {
    return this.getEntities(EntityType.OFFER, [], signal, api.offers.get, fetchArg)
  }

  async getOffer (offerId) {
    return this.getEntity(EntityType.OFFER, offerId, api.offers.single)
  }

  async simpleItemGet (tokenOrAccess, key, fetchFunction, preferFetch) {
    /**
     * Get an item that is fetched and cached "forever" (although should be expired after 7 days by other means)
     */
    if (isWeb) return fetchFunction(tokenOrAccess) // no caching on web

    if (tokenOrAccess === undefined) tokenOrAccess = null // make tokenOrAccess value consistent

    if (tokenOrAccess === null && this.isOnline) tokenOrAccess = this.access

    const canFetch = this.isOnline && tokenOrAccess !== null
    const shouldFetch = canFetch && preferFetch

    let item = await EntityFacade.storageGet(key, null)

    if ((item !== null || !canFetch) && !shouldFetch) {
      // must use the stored profile - even if it is null, since we have no choice
      return item
    }

    item = await fetchFunction(tokenOrAccess)
    await EntityFacade.storageSet(key, item)
    return item
  }

  async getProfile (tokenOrAccess, preferFetch) {
    return this.simpleItemGet(tokenOrAccess, 'profile', api.profile.get, preferFetch)
  }

  async getVehicle (tokenOrAccess, preferFetch) {
    return this.simpleItemGet(tokenOrAccess, 'vehicle', api.vehicle, preferFetch)
  }

  static async updateStoredProfile (profile) {
    const existingProfile = await EntityFacade.storageGet('profile', {})

    for (const profileKey in profile) {
      if (Object.prototype.hasOwnProperty.call(profile, profileKey)) {
        existingProfile[profileKey] = profile[profileKey]
      }
    }

    await EntityFacade.storageSet('profile', existingProfile)
  }
}
