import React from 'react'
import Confirmation, { WebLink } from '../../components/Confirmation'

export default class Profile extends React.Component {
  render () {
    return (
      <Confirmation
        title="Renew your Boost Membership"
        h1="Success!"
        p="You have renewed your Boost membership."
      >
        <WebLink to="/profile/customisation">Go to Customisation ›</WebLink>
      </Confirmation>
    )
  }
}
