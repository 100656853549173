import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { getSuperSectionStyle, heading } from '../../styles/globalClasses'
import { colours } from '../../styles/constants'

export default class DashboardBase extends React.Component {
  handleApiError = profile => {
    if (profile && profile.code && profile.code !== 200) {
      this.mounted && this.setState({ error: profile.message ? profile.message : 'Something went wrong. Please try again.' })
      return false
    }
  }
}

export class Wrapper extends React.Component {
  render () {
    const { children, style, ...rest } = this.props
    return <View style={[{ flexDirection: 'column' }, style || {}]} {...rest}>
      {children}
    </View>
  }
}

export class Section extends React.Component {
  render () {
    const { exceedsMediumBreakpoint, children, style, ...rest } = this.props

    const _style = {
      flexDirection: exceedsMediumBreakpoint ? 'row' : 'column'
    }

    if (exceedsMediumBreakpoint) {
      _style.justifyContent = 'space-between'
    }

    const _children = children || []

    if (_children.length) {
      _children[_children.length - 1] = React.cloneElement(_children[_children.length - 1], { style: { marginLeft: 15 } })
    }

    return <View style={[_style, style || {}]} {...rest}>
      {React.cloneElement(_children, { style: { flex: 1 } })}
    </View>
  }
}

export class SubSection extends React.Component {
  render () {
    const { exceedsMediumBreakpoint, children, style, ...rest } = this.props
    const _style = getSuperSectionStyle(exceedsMediumBreakpoint)
    return <View style={[_style, style]} {...rest}>{children}</View>
  }
}

export class SubSubSection extends React.Component {
  render () {
    const { exceedsMediumBreakpoint, children, style, ...rest } = this.props

    return <View style={[{ flexDirection: exceedsMediumBreakpoint ? 'row' : 'column' }, style || {}]} {...rest}>
      {children}
    </View>
  }
}

export const styles = StyleSheet.create({
  // global
  heading: heading({
    marginBottom: 10
  }),
  text: {
    fontFamily: 'greycliff'
  },
  link: {
    fontFamily: 'greycliff-bold',
    textDecorationLine: 'underline'
  },
  blue: {
    color: colours.primary
  },
  yellow: {
    color: colours.yellow
  },

  // status
  statusAmount: {
    marginTop: 10
  },

  // checkboxes
  fieldWrap: {
    marginBottom: 15
  },
  loading: {
    marginVertical: 100,
    alignItems: 'center'
  }
})

class ListWrapper extends React.Component {
  render () {
    const { exceedsMediumBreakpoint, isFirstWrapper, style, children, ...rest } = this.props

    const _style = exceedsMediumBreakpoint ? {
      paddingTop: 30,
      borderTopWidth: isFirstWrapper ? 1 : 0,
      borderTopStyle: 'solid',
      borderTopColor: colours.light
    } : {}

    return <View style={[getSuperSectionStyle(exceedsMediumBreakpoint), _style, style]} {...rest}>
      {children}
    </View>
  }
}

class ListText extends React.Component {
  render () {
    const { exceedsMediumBreakpoint, style, children, ...rest } = this.props
    const _style = {
      fontSize: exceedsMediumBreakpoint ? 14 : 16,
      fontFamily: 'greycliff',
      marginBottom: exceedsMediumBreakpoint ? 20 : 15
    }
    return <Text style={[_style, style]} {...rest}>{children}</Text>
  }
}

class ListChecks extends React.Component {
  render () {
    const { exceedsLargeBreakpoint, children, ...rest } = this.props

    const childStyle = {
      flexDirection: exceedsLargeBreakpoint ? 'row' : 'column'
    }

    if (exceedsLargeBreakpoint) {
      childStyle.justifyContent = 'space-evenly'
    }
    return <View {...rest}>
      { React.cloneElement(children, { style: childStyle }) }
    </View>
  }
}

export const List = {
  Wrapper: ListWrapper,
  Text: ListText,
  Checks: ListChecks
}
