import React from 'react'
import { Image } from 'react-native'

const SplashCompanyLogo = ({ style = {}, ...props }) => {
  const _style = {
    width: 228,
    height: 178,
    alignSelf: 'center',
    resizeMode: 'contain',
    ...style
  }
  return <Image style={_style} {...props}/>
}

export default SplashCompanyLogo
