import React from 'react'
import Confirmation from '../../components/Confirmation'

export default class Profile extends React.Component {
  render () {
    return (
      <Confirmation
        title="Request a Call Back"
        h1="Thank you!"
        p="One of the Boost team will be in touch with you shortly. If you require immediate assistance please call us on 0508 20 30 40."
      />
    )
  }
}
