/* eslint-disable react/prop-types */
// web only
import React from 'react'
import { withRouter } from 'react-router-dom'
import { View, Text, Image, StyleSheet, TouchableHighlight } from 'react-native'
import { colours, breakpoints, maxWidth, gutter } from '../styles/constants'
import isWeb from '../helpers/isWeb'
import { Primary } from './Inputs/Buttons'
import Modal from '../components/Modal'
import offerType from '../helpers/offerType'
import ExpiryBar from '../components/ExpiryBar'
import storage from '../helpers/storage'
import api from '../helpers/boost-client-js-library/api'
import Notification from '../components/Notification'
import BoostAnalytics, { AnalyticDimension } from '../BoostAnalytics'
import PropTypes from 'prop-types'
import BreakpointWatcher from '../helpers/BreakpointWatcher'
import lineHeight from '../helpers/lineHeight'
import eventBus from '../helpers/eventBus'

class Component extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    userID: PropTypes.string,
    nativeID: PropTypes.string
  }

  state = {
    items: [],
    open: false,
    modal: false,
    error: null,
    buttonDisabled: false,
    emptied: false,
    exceedsMediumBreakpoint: false
  }

  componentDidMount () {
    this.mounted = true
    BreakpointWatcher.addComponent(this)
    const { appKey, onCartItemsLoaded } = this.props
    storage.token.get(appKey).then(token => {
      api.offersCart.get(token).then(cart => {
        if (cart.code || !cart.items) return false
        this.setState({
          cartLoaded: true,
          items: cart.items
        })
        if (onCartItemsLoaded) {
          onCartItemsLoaded(cart.items)
        }
      })
    })

    const _this = this

    eventBus.on('cartEvent', (data) => {
      // "this" becomes undefined when going through event bus sooo we reassign to _this like old school JS
      _this.handleCartEvent(data.offer, data.isAdd)
    })
  }

  componentWillUnmount () {
    this.mounted = false
    eventBus.remove('cartEvent')
    BreakpointWatcher.removeComponent(this)
  }

  handleRemoveItem (item) {
    const { onCartItemsLoaded } = this.props
    const { items } = this.state
    storage.token.get().then(token => {
      api.offersCart.remove(token, item.ID).then(cart => {
        if (cart.code !== 200) return false
        this.mounted && this.setState(state => {
          state.items = items.filter(i => i.ID !== item.ID)
          if (onCartItemsLoaded) {
            onCartItemsLoaded(state.items)
          }
          return state
        })
      })
    })
  }

  handleRemoveAll () {
    const { onCartItemsLoaded } = this.props
    storage.token.get().then(token => {
      api.offersCart.removeAll(token).then(cart => {
        if (cart.code !== 200) return false
        this.mounted && this.setState(state => {
          state.items = []
          return state
        })
        if (onCartItemsLoaded) {
          onCartItemsLoaded([])
        }
      })
    })
  }

  handleCartEvent (offer, isAdd) {
    const { items } = this.state
    const { onCartItemsLoaded } = this.props
    const _this = this

    storage.token.get().then(token => {
      if (isAdd) {
        api.offersCart.add(token, { offerID: offer.ID }).then(cart => {
          if (cart.code !== 200) return false
          _this.mounted && _this.setState(state => {
            if (!state.items.map(i => i.ID).includes(offer.ID)) {
              state.items.push(offer)
            }
            if (onCartItemsLoaded) {
              onCartItemsLoaded(state.items)
            }
            return state
          })
        })
      } else {
        api.offersCart.remove(token, offer.ID).then(cart => {
          if (cart.code !== 200) return false
          _this.mounted && _this.setState(state => {
            state.items = items.filter(i => i.ID !== offer.ID)
            if (onCartItemsLoaded) {
              onCartItemsLoaded(state.items)
            }
            return state
          })
        })
      }
    })
  }

  toggleCheck = type => this.setState({ [type]: !this.state[type] })

  renderItem = (item, index) => {
    return (
      <OfferWrapper exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint} childKey={index}>
        <OfferLeft exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint}>
          {item.image && <OfferImage exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint} source={{ uri: item.image }} />}
          <OfferText exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint}>
            <OfferHeading exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint}>{item.title}{item.supplierName && ` (${item.supplierName})`}</OfferHeading>
            {offerType(item.type) === 'lto' && <ExpiryBar type={'lto'} cart={true} starts={item.startDate} expires={item.endDate} />}
          </OfferText>
        </OfferLeft>

        <OfferButton onPress={() => this.handleRemoveItem(item)}>Remove</OfferButton>
      </OfferWrapper>
    )
  }

  handleModalClose = link => {
    const { history } = this.props
    this.setState({
      modal: false,
      open: false
    })
    if (link) {
      history.push(link)
    }
  }

  handleSubmit = () => {
    this.setState({ buttonDisabled: true })
    storage.token.get().then(token => {
      api.offersCart.checkout(token, 'checkout_type=download').then(res => {
        if (res.url) {
          this.handleGA('download')
          const a = document.createElement('a')
          a.download = 'voucher.pdf'
          a.href = res.url
          a.click()
          this.setState({
            emptied: true
          }, () => setTimeout(() => { window.location.reload() }, 2000))
        }

        if (res.job_ids && res.job_ids.length > 0) {
          this.handleGA('download')
          this.setState({
            modal: true
          })
        }
        if (!res.url) {
          this.setState({
            error: 'Something went wrong. Please try again later'
          }, () => {
            setTimeout(() => this.setState({ error: false }), 3000)
          })
        }
        this.handleRemoveAll()
        this.setState({ buttonDisabled: false })
      })
    })
  }

  handleGA = checkoutType => {
    const { items, userID } = this.props
    const getOffers = items ? JSON.stringify(items.map(i => i.ID)) : ''
    const trimmedOffers = getOffers.replace('[', '').replace(']', '')

    const getCategories = type => {
      const mergeCategories = items ? [].concat.apply([], items.map(i => i[type])) : []
      const categoriesAsJson = JSON.stringify(mergeCategories)
      return categoriesAsJson.replace('[', '').replace(']', '')
    }
    const ba = new BoostAnalytics(userID)

    ba.recordEvent('boost_offer_claim', {
      [AnalyticDimension.offer_id]: trimmedOffers,
      [AnalyticDimension.category_names]: getCategories('categories'),
      [AnalyticDimension.contact_id]: userID,
      [AnalyticDimension.offer_category_ids]: getCategories('categoryIds'),
      [AnalyticDimension.claim_type]: 'cart',
      [AnalyticDimension.checkout_type]: checkoutType
    }, true)
  }

  render () {
    const { items, open, modal, error, buttonDisabled, emptied } = this.state
    const cartCount = items.length
    return (
      <BarWrapper exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint} nativeID={this.props.nativeID}>
        <BarInner>
          <View style={styles.barTop}>
            <View style={styles.barHeader}>
              <Text style={styles.barCount}>{emptied ? 0 : cartCount}</Text>
              <BarHeading exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint}>Offers Cart</BarHeading>
            </View>
            <BarButton exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint} open={open} onPress={() => this.setState({ open: !open })}>{open ? 'Close' : 'Get My Offers'}</BarButton>
          </View>
          {open &&
                    <React.Fragment>
                      <View style={styles.offerList}>
                        {!emptied && items.map((item, i) => this.renderItem(item, i))}
                      </View>
                      <BarDetails exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint}>
                        <BarButton exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint} secondary="true" disabled={items.length === 0 || buttonDisabled} onPress={() => this.handleSubmit()}>Download as PDFs</BarButton>
                        {error && <View style={styles.barError}><Notification>{error}</Notification></View>}
                      </BarDetails>
                    </React.Fragment>
          }
        </BarInner>
        {modal && <Modal
          h1="Your offers are on the way!"
          p="They will be in your inbox in a matter of seconds"
          close={() => this.handleModalClose()}
        >
          <View style={styles.modalButtons}><Primary onPress={() => this.handleModalClose('/offers')}>Back to Offers ›</Primary></View>
        </Modal>}
      </BarWrapper>
    )
  }

  static defaultProps = {
    cartCount: '',
    items: []
  }
}

export default withRouter(Component)

const styles = StyleSheet.create({
  barError: {
    marginTop: 10
  },
  barTop: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  barHeader: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  barCount: {
    borderRadius: 1000,
    textAlign: 'center',
    backgroundColor: colours.primary,
    color: 'white',
    marginRight: 10,
    fontFamily: 'greycliff-bold',
    lineHeight: lineHeight(30),
    width: 30,
    height: 30
  },
  offerList: {
    marginTop: 20,
    marginBottom: 20,
    marginRight: 0,
    marginLeft: 0
  },
  offerLto: {
    fontFamily: 'greycliff-medium',
    fontSize: 14,
    color: colours.urgent
  },
  modalButtons: {
    flex: 1,
    alignItems: 'flex-end'
  }
})

const BarHeading = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const barHeadingStyles = {
    fontFamily: 'greycliff-medium',
    fontSize: 24,
    lineHeight: lineHeight(28),
    ...(isWeb && exceedsMediumBreakpoint ? {
      fontFamily: 'greycliff-extrabold',
      fontSize: 22,
      lineHeight: lineHeight(26)
    } : {})
  }
  const barHeadingStylesSecondary = {
    display: 'none'
  }

  return (
    <>
      <Text style={props.secondary && !exceedsMediumBreakpoint ? { ...barHeadingStyles, ...barHeadingStylesSecondary } : barHeadingStyles}>
        {props.children}
      </Text>
    </>
  )
}

const BarButton = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const barButtonStyles = {
    width: 180,
    ...(props.open ? { backgroundColor: 'black' } : {})
  }
  const barButtonStylesSecondaryExceedsMedium = {
    fontSize: 18,
    width: '100%'
  }
  return (
    <Primary
      style={props.secondary && exceedsMediumBreakpoint ? { ...barButtonStyles, barButtonStylesSecondaryExceedsMedium } : barButtonStyles}
      onPress={props.onPress}
      disabled={props.disabled}
    >
      {props.children}
    </Primary>
  )
}

const BarInner = props => {
  const barInnerStylesCss = `
    .bar-inner-style {
      width: 100%;
      margin: auto;
    }
    @media(min-width: ${breakpoints.large}px){
      .bar-inner-style {
        max-width: ${maxWidth}px;
      }
    }
  `
  return (
    <>
      {isWeb ? <><style type='text/css'>{barInnerStylesCss}</style>
        <div className='bar-inner-style'>{props.children}</div></> : <View>{props.children}</View>}
    </>
  )
}

const BarWrapper = ({ exceedsMediumBreakpoint, children, ...props }) => {
  const barWrapperStyles = {
    position: isWeb ? 'sticky' : 'relative',
    top: 0,
    zIndex: 1,
    backgroundColor: colours.light30,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: isWeb && exceedsMediumBreakpoint ? gutter.medium : gutter.small,
    paddingRight: isWeb && exceedsMediumBreakpoint ? gutter.medium : gutter.small,
    ...(isWeb ? {
      marginBottom: 0,
      borderBottomWidth: 1,
      borderBottomColor: colours.light60
    } : {})
  }
  return (
    <View style={barWrapperStyles} {...props}>{children}</View>
  )
}

const BarDetails = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const barDetailsStyles = {
    paddingBottom: isWeb && exceedsMediumBreakpoint ? 50 : 15,
    ...(isWeb && exceedsMediumBreakpoint ? {
      paddingTop: 20,
      paddingLeft: 0,
      paddingRight: 0,
      alignItems: 'end'
    } : {})
  }
  return (
    <View style={barDetailsStyles}>{props.children}</View>
  )
}

const OfferWrapper = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const offerWrapperStyles = {
    flexDirection: isWeb && exceedsMediumBreakpoint ? 'row' : 'row',
    alignItems: isWeb && exceedsMediumBreakpoint ? 'center' : 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#b3e4ff',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 0,
    paddingRight: 0
  }
  return (
    <View style={offerWrapperStyles} key={props.childKey}>{props.children}</View>
  )
}

const OfferLeft = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const offerLeftStylesIsWeb = {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
    ...(exceedsMediumBreakpoint ? { alignItems: 'center', flexDirection: 'row' } : {}),
    ...(!exceedsMediumBreakpoint ? { paddingRight: 10 } : {})
  }
  return (
    <View style={isWeb ? offerLeftStylesIsWeb : {}}>{props.children}</View>
  )
}

const OfferImage = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const offerImageStyles = {
    width: 67,
    height: 67,
    marginBottom: isWeb && exceedsMediumBreakpoint ? 0 : 10,
    ...(isWeb && exceedsMediumBreakpoint ? {
      marginTop: 0,
      marginRight: 25,
      marginLeft: 0
    } : {}),
    ...(isWeb && !exceedsMediumBreakpoint ? { display: 'none' } : {})
  }
  return (
    <Image style={offerImageStyles} source={props.source}/>
  )
}

const OfferText = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const offerTextStyles = {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
    ...(exceedsMediumBreakpoint ? { alignItems: 'center', flexDirection: 'row' } : {})
  }
  return (
    <View style={isWeb ? offerTextStyles : {}}>{props.children}</View>
  )
}

const OfferHeading = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const offerHeadingStyles = {
    fontSize: isWeb && exceedsMediumBreakpoint ? 18 : 14,
    fontFamily: 'greycliff-medium',
    ...(isWeb ? { marginRight: 15 } : {})
  }
  return (
    <Text style={offerHeadingStyles}>{props.children}</Text>
  )
}

const OfferButton = props => {
  const offerButtonStyles = {
    fontSize: 14,
    fontFamily: 'greycliff-bold',
    textTransform: 'uppercase',
    ...(isWeb ? { cursor: 'pointer' } : {})
  }
  return (
    <TouchableHighlight underlayColor='transparent' activeOpacity={0.7} onPress={props.onPress}>
      <Text style={offerButtonStyles}>{props.children}</Text>
    </TouchableHighlight>
  )
}
