// app only, a dropdown which is usually used to filter a list by a category
import React from 'react'
import { View, Dimensions, ScrollView, Platform, StyleSheet } from 'react-native'
import { withRouter } from 'react-router-dom'
import { gutter, colours } from '../../styles/constants'
import { Box, BoxInner, Dropdown, DropdownBox, DropdownArrow, DropdownArrowBorder, DropdownItem, DropdownLink } from './styles'
const { height } = Dimensions.get('window')

class Component extends React.Component {
  state = {
    open: false
  }

  handleOpen = () => this.setState({ open: !this.state.open })
  handleLink = link => {
    const { history } = this.props
    this.setState({ open: false })
    history.push(link)
  }

  render () {
    const { open } = this.state
    const { categories } = this.props
    return (
      <React.Fragment>
        <View style={styles.block} />
        <View style={styles.wrap}>
          <Box>
            <BoxInner>
              <Dropdown onPress={() => this.handleOpen()}>Browse by category</Dropdown>
            </BoxInner>
          </Box>
          {open && <DropdownBox maxWidth={270}>
            <DropdownArrowBorder/>
            <DropdownArrow />
            <ScrollView style={{ height: height / 3 }} nestedScrollEnabled={true}>
              {categories.map((item, i) => <DropdownItem key={i}><DropdownLink onPress={() => this.handleLink(item.link)}>{item.title}</DropdownLink></DropdownItem>)}
            </ScrollView>
          </DropdownBox>}
        </View>
      </React.Fragment>
    )
  }
}

export default withRouter(Component)

const styles = StyleSheet.create({
  block: {
    height: 20,
    width: '100%',
    backgroundColor: colours.lighterGrey
  },
  wrap: {
    paddingLeft: gutter.small,
    paddingRight: gutter.small,
    alignItems: 'center',
    alignContent: 'center',
    top: -10,
    ...(Platform.OS === 'ios' ? { zIndex: 1 } : {})
  }
})
