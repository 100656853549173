import consoleLog from './consoleLog'
import EntityFacade from './EntityFacade'
import ImageManager from './ImageManager'
import AsyncStorage from '@react-native-async-storage/async-storage'
import globalVars from './globalVars'

function isJSON (str) {
  try {
    return (JSON.parse(str) && !!str)
  } catch (e) {
    return false
  }
}

const storage = {
  // auth
  token: {
    set: async (obj, appKey = false) => {
      try {
        if (appKey) {
          await Promise.all([
            appKey.setItemAsync('boost', JSON.stringify(obj)),
            AsyncStorage.removeItem('offlineTime')
          ])
        } else {
          setCookie(obj)
        }
      } catch (error) {
        consoleLog('Error saving data' + error)
      }
    },
    get: async (appKey = false, getTokenObject = false) => {
      let token = ''
      try {
        if (appKey) {
          token = await appKey.getItemAsync('boost') || ''
        } else {
          token = getTokenFromCookie() ? getTokenFromCookie() : ''
        }
      } catch (error) {
        // Error retrieving data
        consoleLog(error.message)
      }
      return isJSON(token) ? getTokenObject ? JSON.parse(token) : JSON.parse(token).access_token : ''
    },
    delete: async (appKey = false) => {
      try {
        if (appKey) {
          await appKey.deleteItemAsync('boost')
        } else {
          deleteCookie('boost')
        }
      } catch (error) {
        consoleLog('Error saving data' + error)
      }
    }
  },

  // offline stuff
  offline: {
    check: async time => {
      const getItem = await AsyncStorage.getItem('offlineTime')
      const stringTime = JSON.stringify(time)

      if (getItem === null) {
        return await AsyncStorage.setItem('offlineTime', stringTime)
          .then(() => {
            return stringTime
          })
      }
      return getItem
    },
    delete: async () => {
      try {
        await AsyncStorage.removeItem('offlineTime')
      } catch (error) {
        consoleLog('Error saving data' + error)
      }
    }
  }
}

function getTokenFromCookie () {
  const tokenCookie = getCookie('boost')
  return tokenCookie && tokenCookie.split(',path=')[0]
}

function getCookie (cname) {
  const cookieVal = document.cookie.match('(^|[^;]+)\\s*' + cname + '\\s*=\\s*([^;]+)')
  return cookieVal ? cookieVal.pop() : ''
}

function deleteCookie (cname) {
  document.cookie = cname + '= ; expires = Thu, 01 Jan 1970 00:00:00 GMT;path =/;domain=.myboost.co.nz;'
}

function setCookie (tokenCookie) {
  document.cookie = 'boost=' + JSON.stringify(tokenCookie) + ',path=/;domain=myboost.co.nz'
}

export function deleteOfflineData () {
  consoleLog('Deleting all offline data.')
  globalVars.companyLogoUrl = null
  return Promise.all([
    ImageManager.getManager(false).cleanupAll(),
    EntityFacade.clearAll(),
    AsyncStorage.removeItem('localDeviceToken'),
    AsyncStorage.removeItem('emailAddress')
  ])
}

export default storage
