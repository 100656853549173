import React from 'react'
import Confirmation, { WebLink } from '../../components/Confirmation'
import PropTypes from 'prop-types'

export default class Profile extends React.Component {
  static propTypes = {
    isLoggedIn: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  }

  render () {
    const { isLoggedIn } = this.props
    return (
      <Confirmation
        title="Upgrade your Boost Membership"
        h1="Success!"
        p="You have upgraded your Boost membership."
        isLoggedIn={isLoggedIn}
      >
        <WebLink to="/profile/customisation">Go to Customisation ›</WebLink>
      </Confirmation>
    )
  }
}
