import React from 'react'
import { View, Text, Image } from 'react-native'
import { colours } from '../styles/constants'
import isWeb from '../helpers/isWeb'
import moment from 'moment'

const CardImage = (props) => {
  const { style = {}, imageHeight = 150, ...rest } = props
  style.width = '100%'
  style.height = imageHeight
  return (
    <Image style={style} {...rest}/>
  )
}

const CardText = (props) => {
  const cardTextStylesWeb = {
    textTransform: 'uppercase',
    flexShrink: 1,
    wordWrap: 'normal'
  }
  const cardTextStyles = {
    fontFamily: props.bold ? 'greycliff-bold' : 'greycliff-medium',
    fontSize: props.textexp ? 10 : (isWeb ? 13 : 10),
    marginTop: props.textexp ? 2 : 0
  }

  if (props.textexp) {
    cardTextStyles.color = colours.black40
  }

  return (
    <Text style={isWeb ? { ...cardTextStylesWeb, ...cardTextStyles } : cardTextStyles}>{props.children}</Text>
  )
}

const Card = props => {
  const { cardDetails, card, imageHeight } = props
  const data = {
    firstName: cardDetails.firstName,
    company: card.companyName,
    appID: card.appID ? card.appID : 'N/A',
    expiry: moment(new Date(card.expiry)).format('MM/YY')
  }
  return (
    <View style={styles.wrapper}>
      {isWeb &&
      <CardImage imageHeight={imageHeight} resizeMode="stretch" source={require('../images/card-header.png')}/>}

      <View style={styles.inner}>
        <Image style={styles.logo} resizeMode="contain" source={{ uri: cardDetails.companyLogo }}/>
        <View style={ { ...styles.profile, paddingLeft: 0 } }>
          {cardDetails.firstName && card.expiry && Object.entries(data).map((item, i) => {
            let title
            const key = item[0]
            let text = item[1]

            if (key === 'firstName') text = `${text} ${cardDetails.lastName}`

            if (isWeb) {
              title = key === 'expiry' && text ? 'EXP. ' : ''

              if (key === 'appID') return false
              return (
                <View style={styles.row} key={i}>
                  <View style={styles.rowContent}>
                    <CardText textexp={title ? 1 : 0}>
                      {title && title}{text}
                    </CardText>
                  </View>
                </View>
              )
            } else {
              title = key === 'appID' ? 'App ID' : key === 'firstName' ? 'Name' : key.charAt(0).toUpperCase() + key.slice(1) // transform key
              return (
                <View style={styles.row} key={i}>
                  <View style={styles.rowTitle}>
                    <CardText bold>{text && title}</CardText>
                  </View>
                  <View style={styles.rowContent}><CardText>{text}</CardText></View>
                </View>
              )
            }
          })}
        </View>
      </View>
    </View>
  )
}

export default Card

const wrapperWeb = isWeb ? {
  borderWidth: 1,
  borderColor: '#f0f0f0',
  borderStyle: 'solid',
  shadowOffset: { width: 0, height: 0 },
  shadowRadius: 11,
  shadowOpacity: 0.1,
  shadowColor: '#000',
  borderRadius: 20,
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  overflow: 'hidden',
  maxWidth: '100%'
} : {}

const styles = {
  wrapper: {
    paddingBottom: 20,
    paddingLeft: 10,
    marginBottom: 30,
    borderBottomWidth: 1,
    ...wrapperWeb
  },
  inner: {
    padding: isWeb ? 20 : 0,
    flexDirection: isWeb ? 'column' : 'row',
    alignItems: isWeb ? 'flex-start' : 'center'
  },
  logo: {
    width: isWeb ? 80 : 90,
    height: isWeb ? 40 : 90,
    marginBottom: isWeb ? 15 : 0,
    marginRight: isWeb ? 'inherit' : 25
  },
  profile: {
    minHeight: 40,
    maxWidth: isWeb ? '100%' : null,
    paddingLeft: isWeb ? 30 : 0,
    flex: isWeb ? 1 : 0
  },
  row: {
    flexDirection: 'row',
    marginBottom: 4,
    width: '100%'
  },
  rowTitle: {
    flex: 1,
    flexBasis: '33%'
  },
  rowContent: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: isWeb ? 'auto' : '67%',
    width: isWeb ? '100%' : 'auto'
  }
}
