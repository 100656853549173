import ReactGA from 'react-ga4'
import isWeb from './helpers/isWeb'

// This is kind of like an Enum, so we have consistent naming
export const AnalyticDimension = {
  offer_id: 'offer_id',
  page_category: 'page_category',
  category_names: 'category_names',
  contact_id: 'contact_id',
  offer_category_ids: 'offer_category_ids',
  opened_from_notification: 'opened_from_notification',
  offer_url: 'offer_url',
  claim_type: 'claim_type',
  checkout_type: 'checkout_type'
}

export default class BoostAnalytics {
  constructor (userId) {
    if (isWeb) {
      ReactGA.set({ userId: userId })
    }
  }

  recordPageView (route, pageTitle) {
    const data = { path: route, title: pageTitle, category: 'engagement' }
    if (isWeb) {
      ReactGA.event('boost_page_view', data)
    }
  }

  recordEvent (eventName, eventData, webOnly) {
    eventData.category = 'engagement'
    if (isWeb) {
      ReactGA.event(eventName, eventData)
    }
  }
}
