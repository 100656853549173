// web only
import React from 'react'
import { Image, Text, View } from 'react-native'
import { breakpoints, colours, gutter } from '../styles/constants'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import storage from '../helpers/storage'
import api from '../helpers/boost-client-js-library/api'
import isWeb from '../helpers/isWeb'
import getDeviceId from '../helpers/deviceId'
import checkRole from '../helpers/checkRole'
import PropTypes from 'prop-types'
import { MODAL_OPEN_CLASS } from './Modal'
import BreakpointWatcher from '../helpers/BreakpointWatcher'
import childrenWithProps from '../helpers/childrenWithProps'
import UserMenuStandardImage from '../images/UserMenuStandardImage.svg'
import UserMenuHoverImage from '../images/UserMenuHoverImage.svg'
import UserMenuActiveImage from '../images/UserMenuActiveImage.svg'

const FORCE_LOGIN = true

const PROFILE_ALL = [
  {
    title: 'Dashboard',
    link: '/profile/dashboard'
  },
  {
    title: 'Profile',
    link: '/profile'
  },
  {
    title: 'Offers',
    link: '/offers'
  },
  {
    title: 'Change Password',
    link: '/profile/change-password'
  },
  {
    title: 'FAQs',
    external: true,
    link: `${process.env.REACT_APP_BASE_URL}/about-boost/faq`
  },
  {
    title: 'Preferences',
    link: '/profile/preferences'
  },
  {
    title: 'Sign Out',
    link: ''
  }
]

const PROFILE_STANDARD = [
  {
    title: 'My Profile',
    link: '/profile'
  },
  {
    title: 'Get Boost Offers',
    link: '/offers'
  },
  {
    title: 'Change Password',
    link: '/profile/change-password'
  },
  {
    title: 'FAQs',
    external: true,
    link: `${process.env.REACT_APP_BASE_URL}/about-boost/faq`
  },
  {
    title: 'Preferences',
    link: '/profile/preferences'
  },
  {
    title: 'Sign Out'
  }
]

const PROFILE_PRIME = [
  {
    title: 'Dashboard',
    link: '/profile/dashboard'
  },
  {
    title: 'My Profile',
    link: '/profile'
  },
  {
    title: 'Change Password',
    link: '/profile/change-password'
  },
  {
    title: 'FAQs',
    external: true,
    link: `${process.env.REACT_APP_BASE_URL}/about-boost/faq`
  },
  {
    title: 'Preferences',
    link: '/profile/preferences'
  },
  {
    title: 'Sign Out'
  }
]

const getNavLinkStyle = (active) => {
  return {
    color: active ? '#00a5ff' : 'black',
    textDecorationLine: 'none',
    fontFamily: 'greycliff-bold',
    fontSize: 'clamp(16px, 1.5vw, 19px)',
    fontWeight: 700
  }
}

const Header = {
  Wrapper: ({ style = {}, children, ...props }) => {
    const _style = {
      backgroundColor: 'rgba(222, 243, 254, 0.9)',
      backgroundFilter: 'saturate(180%) blur(10px)',
      WebkitBackgroundFilter: 'saturate(180%) blur(10px)',
      paddingVertical: 5,
      paddingHorizontal: 'clamp(30px, 4vw, 60px)',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid white',
      display: 'flex',
      // class is added to body when a modal opens, check for it to adjust z-index
      zIndex: document.body.classList.contains(MODAL_OPEN_CLASS) ? -1 : 1,
      overflow: 'visible',
      ...style
    }

    return <View style={_style} {...props}>{children}</View>
  },
  Inner: ({ exceedsMediumBreakpoint, exceedsLargeBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      paddingHorizontal: gutter.small,
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'space-between',
      maxWidth: 1680,
      width: '100%',
      ...(exceedsMediumBreakpoint ? {
        margin: 'auto',
        justifyContent: 'space-between'
      } : {}),
      ...(exceedsLargeBreakpoint ? {
        paddingLeft: 0,
        paddingRight: 0
      } : {}),
      ...style
    }
    return <View style={_style} {...props}>{children}</View>
  }
}

const Logo = {
  Wrap: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    if (exceedsMediumBreakpoint) {
      style.order = 1
    }
    return <View style={style} {...props}>{children}</View>
  },
  linkStyle: {
    textDecorationLine: 'none'
  },
  Link: Link,
  Image: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      width: exceedsMediumBreakpoint ? 90 : 81,
      height: exceedsMediumBreakpoint ? 28 : 25,
      ...style
    }

    if (exceedsMediumBreakpoint) {
      _style.marginRight = 40
    }

    return <Image style={_style} {...props}>{children}</Image>
  }
}
const Nav = {
  Button: isWeb && styled.div`
        color: black;
        font-size: 0;
        border-top: 2px solid black;
        border-bottom: 2px solid black;
        width: 25px;
        height: 17px;
        position: relative;
        cursor: pointer;
        &:before,
        &:after {
            content: '';
            background: black;
            width: 100%;
            height: 2px;
            position: absolute;
            left: 0;
            top: 6px;
        }
        &:before {
            display: none;
        }
        &:active {
            border-color: rgba(255,255,255,.8);
            &:after {
                background: rgba(255,255,255,.8);
            }
        }
        ${props => props.navOpen && `
            border: 0;
            &:before {
                display: block;
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        `};
        @media (min-width: ${breakpoints.medium - 1}px) {
            display: none;
        }
    `,
  Items: isWeb && styled.div`
        display: ${props => props.navOpen ? 'block' : 'none'};
        @media (min-width: ${breakpoints.medium - 1}px) {
            min-width: 0;
            display: inline-block;
            order: 2;
        }
    `,
  Item: ({ inSmallBreakpoint, exceedsMediumBreakpoint, exceedsLargeBreakpoint, isLastChild, style = {}, children, ...props }) => {
    const _style = {
      paddingVertical: 12,
      paddingHorizontal: gutter.small,
      ...(inSmallBreakpoint ? {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid'
      } : {}),
      ...(exceedsMediumBreakpoint ? {
        display: 'inline-block',
        paddingLeft: 10,
        paddingRight: 10,
        marginRight: 10,
        borderBottomWidth: 0,
        position: 'relative'
      } : {}),
      ...(exceedsLargeBreakpoint ? {
        paddingLeft: 0,
        paddingRight: gutter.small,
        marginRight: 25
      } : {})
    }

    return <React.Fragment>
      <View style={_style} {...props}>
        {children}
      </View>
    </React.Fragment>
  },
  Link: ({ active, style = {}, children, ...props }) => {
    const _style = {
      ...getNavLinkStyle(active),
      ...style
    }

    return <Link style={_style} {...props}>{children}</Link>
  },
  External: ({ active, style = {}, children, ...props }) => {
    const _style = {
      ...getNavLinkStyle(active),
      ...style
    }

    return <Text style={_style} {...props}>{children}</Text>
  }
}

class Hoverable extends React.Component {
  state = {
    isHovered: false
  }

  onMouseEnter () {
    this.setState({ isHovered: true })
  }

  onMouseLeave () {
    this.setState({ isHovered: false })
  }
}

class ProfileLink extends Hoverable {
  render () {
    const { exceedsMediumBreakpoint, isExternal, style = {}, children, ...props } = this.props
    const { isHovered } = this.state

    const _style = {
      fontFamily: 'TT-Norms-Pro',
      color: 'black',
      textDecorationStyle: isHovered ? 'solid' : 'default',
      textDecorationThickness: isHovered ? 'auto' : 1,
      textDecorationSkipInk: 'none',
      textUnderlineOffset: 3,
      fontSize: 20,
      cursor: 'pointer',
      paddingBottom: 3.5,
      ...style
    }

    if (isExternal) {
      return <a
        onMouseEnter={ () => { this.onMouseEnter() } }
        onMouseLeave={ () => { this.onMouseLeave() } }
        style={_style}
        {...props}>
        {children}
      </a>
    }

    return <Link
      onMouseEnter={ () => { this.onMouseEnter() } }
      onMouseLeave={ () => { this.onMouseLeave() } }
      style={_style}
      {...props}>
      {children}
    </Link>
  }
}

class DropDownButton extends Hoverable {
  getBackgroundColor (isHovered) {
    return '#808080'
  }

  getColor (isHovered) {
    return 'black'
  }

  getMarginVertical () {
    return 9
  }

  render () {
    const { children, to, history, doClose, ...props } = this.props
    const { isHovered } = this.state

    const _style = this.getStyle(isHovered)

    const onClick = to ? () => {
      if (window.location.pathname === to) {
        if (doClose) {
          doClose()
        }
      } else {
        history.push(to)
      }
    } : () => {}

    return <View onMouseEnter={() => this.onMouseEnter()} onMouseLeave={() => this.onMouseLeave()} onClick={onClick} style={_style} {...props}>
      {children}
    </View>
  }

  getStyle (isHovered) {
    return {
      paddingVertical: 12,
      paddingHorizontal: 18,
      color: this.getColor(isHovered),
      cursor: 'pointer',
      backgroundColor: this.getBackgroundColor(isHovered),
      fontFamily: 'TT-Norms-Pro, Helvetica, sans-serif',
      fontWeight: 500,
      lineHeight: 16,
      fontSize: 16,
      borderRadius: 10,
      marginVertical: this.getMarginVertical(),
      boxShadow: isHovered ? '0px 0.6px 0.6px rgb(0 0 0 / 1%), ' +
        '0px 1.4px 1.5px rgb(0 0 0 / 2%), ' +
        '0px 2.6px 2.9px rgb(0 0 0 / 2%), ' +
        '0px 4.7px 5.1px rgb(0 0 0 / 2%), ' +
        '0px 8.8px 9.6px rgb(0 0 0 / 3%), ' +
        '0px 21px 23px rgb(0 0 0 / 4%)' : 'none'
    }
  }
}

class DashboardButton extends DropDownButton {
  getBackgroundColor (isHovered) {
    return isHovered ? '#7fdbff' : '#00a5ff'
  }

  getColor (isHovered) {
    return isHovered ? 'black' : 'white'
  }
}

class SignOutButton extends DropDownButton {
  getMarginVertical () {
    return 8
  }

  render () {
    const inner = super.render()

    return <div style={ { marginTop: 3, paddingTop: 0, borderTop: '1px solid #7fdbff' } }>{inner}</div>
  }
}

class ProfileItem extends Hoverable {
  render () {
    const { exceedsMediumBreakpoint, isFirstChild, isLastChild, isButton, style = {}, children, ...props } = this.props
    const { isHovered } = this.state

    const _style = {
      paddingVertical: 2,
      paddingHorizontal: 0,
      ...style
    }

    if (isFirstChild || isLastChild) {
      _style.borderTopWidth = 0
    }

    if (isLastChild) {
      _style.marginBottom = 12
      if (exceedsMediumBreakpoint) {
        _style.marginBottom = 0
        _style.marginTop = 30
        _style.position = 'relative'
      }
    }

    const _subChildStyle = (isFirstChild || isLastChild) ? {
      fontFamily: 'greycliff-extrabold',
      ...(exceedsMediumBreakpoint ? {
        fontFamily: 'greycliff-bold',
        textAlign: 'center',
        padding: 10,
        borderRadius: 4,
        backgroundColor: colours.primary,
        color: isHovered ? 'rgba(255,255,255,.9)' : 'white'
      } : {})
    } : {}

    const _lastChildBeforeStyle = (isLastChild && exceedsMediumBreakpoint) ? {
      backgroundColor: colours.light20,
      width: '100%',
      height: 1,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      marginTop: -15,
      display: 'block'
    } : {}

    const _subLastChildStyle = isLastChild ? {
      fontFamily: 'greycliff-bold',
      ...(exceedsMediumBreakpoint ? {
        color: isHovered ? 'rgba(0,0,0,.9)' : 'black',
        backgroundColor: 'rgba(0,0,0,.15)'
      } : {})
    } : {}

    const _subStyle = { ..._subChildStyle, ..._subLastChildStyle }
    return <React.Fragment>
      <View onMouseEnter={() => this.onMouseEnter()} onMouseLeave={() => this.onMouseLeave()} style={_style} {...props}>
        {(isLastChild && exceedsMediumBreakpoint) && <span style={_lastChildBeforeStyle}> </span>}
        {childrenWithProps(children, { style: _subStyle })}
      </View>
    </React.Fragment>
  }
}

const Profile = {
  Wrapper: isWeb && styled.div`
        display: flex;
        position: relative;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        ${props => props.profileOpen && `
            @media (max-width: ${breakpoints.medium - 1 - 1}px) {
                > * {
                    visibility: hidden;
                }
                &:before,
                &:after {
                    content: '';
                    background: ${colours.primary};
                    width: 25px;
                    height: 2px;
                    position: absolute;
                    right: 0;
                    top: 18px;
                }
                &:before {
                    transform: rotate(45deg);
                }
                &:after {
                    transform: rotate(-45deg);
                }
            }
        `}
        @media (min-width: ${breakpoints.medium - 1}px) {
            order: 3;
        }
    `,
  Name: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      color: 'black',
      fontFamily: 'TT-Norms-Pro',
      display: 'block',
      fontSize: 20,
      paddingRight: 20,
      ...style
    }

    return <Text style={_style} {...props}>{children}</Text>
  },
  Avatar: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      width: 52,
      height: 35,
      ...(exceedsMediumBreakpoint ? {
        display: 'block',
        width: 52,
        height: 35,
        marginLeft: 5
      } : {}),
      ...style
    }
    return <Image style={_style} {...props}>{children}</Image>
  },
  Dropdown: isWeb && styled.div`
        position: absolute;
        right: 0;
        top: 50px;
        z-index: 1;
        background: #f6fcff;
        padding: clamp(15px, 2vw, 25px);
        box-shadow: 0 1.7px 2.2px rgba(0, 0, 0, 0.02),
        0 4px 5.3px rgba(0, 0, 0, 0.028),
        0 7.5px 10px rgba(0, 0, 0, 0.035),
        0 13.4px 17.9px rgba(0, 0, 0, 0.042),
        0 25.1px 33.4px rgba(0, 0, 0, 0.05),
        0 60px 80px rgba(0, 0, 0, 0.07);
        width: clamp(250px, 20vw, 320px);
        border-radius: 20px;

        @media (max-width: ${breakpoints.medium}px) {
            width: 100%;
        }
    `,

  Item: ProfileItem,
  Link: props => <ProfileLink isExternal={false} {...props}/>,
  ExternalLink: props => <ProfileLink isExternal={true} {...props}/>,
  Button: SignOutButton
}

class Component extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string
    }),
    isLoggedIn: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  }

  state = {
    homeLink: '',
    loggedOutPages: [
      {
        title: 'Offers',
        external: true,
        link: `${process.env.REACT_APP_BASE_URL}/offers`
      },
      {
        title: 'Blog',
        external: true,
        link: `${process.env.REACT_APP_BASE_URL}/blog`
      },
      {
        title: 'Boost App',
        external: true,
        link: `${process.env.REACT_APP_BASE_URL}/boost-app`
      }
    ],
    loggedInPages: [
      {
        title: 'Offers',
        link: '/offers'
      },
      {
        title: 'Retailers',
        link: '/retailers'
      },
      {
        title: 'Dashboard',
        link: '/profile/dashboard'
      },
      {
        title: 'Blog',
        external: true,
        link: `${process.env.REACT_APP_BASE_URL}/blog`
      },
      {
        title: 'Boost App',
        external: true,
        link: `${process.env.REACT_APP_BASE_URL}/boost-app`
      }
    ],
    loggedInPagesPrime: [
      {
        title: 'Dashboard',
        link: '/profile/dashboard'
      },
      {
        title: 'Blog',
        external: true,
        link: `${process.env.REACT_APP_BASE_URL}/blog`
      },
      {
        title: 'Boost App',
        external: true,
        link: `${process.env.REACT_APP_BASE_URL}/boost-app`
      }
    ],
    loggedInPagesStandard: [
      {
        title: 'Offers',
        link: '/offers'
      },
      {
        title: 'Retailers',
        link: '/retailers'
      },
      {
        title: 'Profile',
        link: '/profile'
      },
      {
        title: 'Blog',
        external: true,
        link: `${process.env.REACT_APP_BASE_URL}/blog`
      },
      {
        title: 'Boost App',
        external: true,
        link: `${process.env.REACT_APP_BASE_URL}/boost-app`
      }
    ],
    pages: [],
    profile: PROFILE_ALL,
    user_name: '',
    navOpen: false,
    profileOpen: false,
    inSmallBreakpoint: false,
    exceedsMediumBreakpoint: false,
    exceedsLargeBreakpoint: false
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
    this.handlePagesState()
    document.addEventListener('click', e => this.handleClickOutside(e))
  }

  componentDidUpdate (prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        navOpen: false,
        profileOpen: false
      })
    }
    if (prevProps.isLoggedIn !== this.props.isLoggedIn) this.handlePagesState()
  }

  componentWillUnmount () {
    document.removeEventListener('click', e => this.handleClickOutside(e))
    BreakpointWatcher.removeComponent(this)
  }

  handleLoggedOutPagesDisplay () {
    this.setState({
      homeLink: '/offers',
      pages: this.state.loggedOutPages
    })
  }

  handlePagesState = () => {
    const { loggedInPages, loggedInPagesStandard, loggedInPagesPrime } = this.state
    this.setState({ user_name: '' })

    storage.token.get().then(token => {
      if (token === '') {
        this.handleLoggedOutPagesDisplay()
        return
      }

      api.profile.get(token).then(profile => {
        if (profile === null || !profile || !profile.firstName) {
          this.handleLoggedOutPagesDisplay()
          return false
        }
        const check = checkRole(profile.roles)
        this.setState({
          user_name: profile.firstName,
          homeLink: check.includes('su') || check.includes('bpsu') ? '/offers' : '/profile/dashboard',
          pages: check.includes('bp') ? check.includes('su') ? loggedInPages : loggedInPagesPrime : loggedInPagesStandard,
          profile: check.includes('bp') ? check.includes('su') ? PROFILE_ALL : PROFILE_PRIME : PROFILE_STANDARD
        })
      })
    })
  }

  handleOpenMenu = menu => {
    const { navOpen, profileOpen } = this.state
    this.setState({
      navOpen: menu === 'profile' ? false : !navOpen,
      profileOpen: menu === 'nav' ? false : !profileOpen
    })
  }

  handleSignOut = () => {
    const refer = process.env.REACT_APP_BASE_URL
    storage.token.get().then(token => {
      Promise.resolve([
        api.offersCart.removeAll(token), // empty offers cart
        api.auth.logout(token, getDeviceId())
      ]).then(() => {
        storage.token.delete().then(() => {
          this.setState({ pages: [] }, () => { window.location = refer })
        })
      })
    })
  }

  handleClickOutside = event => {
    const dropdown = document.querySelector('#dd')
    const dropdownLink = document.querySelector('#dd-link')
    const target = event.target
    const targetParent = target.parentNode
    const targetGrandparent = targetParent ? targetParent.parentNode : null

    const notDdLink = dropdownLink && !dropdownLink.contains(target) && !dropdownLink.contains(targetParent) && !dropdownLink.contains(targetGrandparent)
    const nav = document.querySelector('#nav')
    if (dropdown && !dropdown.contains(target) && notDdLink) {
      this.setState({ profileOpen: false })
    }
    if (nav && !nav.contains(target) && target.getAttribute('id') !== 'nav-link') {
      this.setState({ navOpen: false })
    }
  }

  renderNavItems = () => {
    const { pages, navOpen, exceedsLargeBreakpoint, exceedsMediumBreakpoint, inSmallBreakpoint } = this.state
    const { location } = this.props

    return (
      <React.Fragment>
        <Nav.Button id="nav-link" navOpen={navOpen} onClick={() => this.handleOpenMenu('nav')}>Open
          navigation</Nav.Button>
        <Nav.Items id="nav" navOpen={navOpen}>
          {pages.map((page, i) => {
            return <Nav.Item inSmallBreakpoint={inSmallBreakpoint} exceedsMediumBreakpoint={exceedsMediumBreakpoint} exceedsLargeBreakpoint={exceedsLargeBreakpoint} key={i} isLastChild={i === (pages.length - 1)}>
              {page.external
                ? <Nav.External onPress={() => { window.location = page.link }}>{page.title}</Nav.External>
                : <Nav.Link to={page.link} active={location.pathname === page.link ? 1 : 0}>{page.title}</Nav.Link>
              }
            </Nav.Item>
          })}
        </Nav.Items>
      </React.Fragment>
    )
  }

  renderProfile = () => {
    // eslint-disable-next-line camelcase
    const { user_name, profileOpen, profile, exceedsMediumBreakpoint, profileHover } = this.state
    const { history } = this.props

    const profileMenuImage = profileOpen ? UserMenuActiveImage : (profileHover ? UserMenuHoverImage : UserMenuStandardImage)

    return (
      <React.Fragment>
        <Profile.Wrapper
          id="dd-link"
          profileOpen={profileOpen}
          onClick={() => this.handleOpenMenu('profile')}
          onMouseEnter={ () => { this.setState({ profileHover: true }) } }
          onMouseLeave={ () => { this.setState({ profileHover: false }) } }
        >
          {/* eslint-disable-next-line camelcase */}
          { user_name && <Profile.Name exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Hi, {user_name}!</Profile.Name> }
          <Profile.Avatar exceedsMediumBreakpoint={exceedsMediumBreakpoint} source={profileMenuImage}/>
        </Profile.Wrapper>
        {profileOpen &&
        <Profile.Dropdown id="dd">
          <View>
            {profile.map((item, i) => {
              const isButton = item.title === 'Dashboard' || item.title === 'Sign Out'
              let itemChild

              if (item.title === 'Sign Out') {
                return <SignOutButton key={item.title} onClick={() => this.handleSignOut()}><span>{item.title}</span></SignOutButton>
              } else if (item.title === 'Dashboard') {
                return <DashboardButton key={item.title} to={item.link} history={history} doClose={() => { this.setState({ profileOpen: false }) } }><span>{item.title}</span></DashboardButton>
              } else if (item.external) {
                itemChild = <Profile.ExternalLink exceedsMediumBreakpoint={exceedsMediumBreakpoint} href={item.link}>{item.title}</Profile.ExternalLink>
              } else {
                itemChild = <Profile.Link exceedsMediumBreakpoint={exceedsMediumBreakpoint} to={item.link}>{item.title}</Profile.Link>
              }

              return (
                <Profile.Item
                  key={item.title}
                  isButton={isButton}
                  isFirstChild={i === 0}
                  isLastChild={i === (profile.length - 1)}
                  exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                >
                  { itemChild }
                </Profile.Item>
              )
            })}
          </View>
        </Profile.Dropdown>
        }
      </React.Fragment>
    )
  }

  render () {
    const { isLoggedIn } = this.props
    const { homeLink, exceedsMediumBreakpoint, exceedsLargeBreakpoint } = this.state
    return (
      <Header.Wrapper nativeID="id_boost_header_wrapper">
        {isWeb && <style>
          {`
          @media print {
            #id_boost_header_wrapper {
              display: none !important;
            }
          }
          `}
        </style>
        }
        <Header.Inner exceedsMediumBreakpoint={exceedsMediumBreakpoint} exceedsLargeBreakpoint={exceedsLargeBreakpoint}>
          {this.renderNavItems()}
          <Logo.Wrap exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
            <Logo.Link style={Logo.linkStyle} to={homeLink}>
              <Logo.Image exceedsMediumBreakpoint={exceedsMediumBreakpoint} source={require('../images/boost-logo-header.png')}/>
            </Logo.Link>
          </Logo.Wrap>
          {(isLoggedIn || FORCE_LOGIN) && this.renderProfile()}
        </Header.Inner>
      </Header.Wrapper>
    )
  }
}

export default withRouter(Component)
