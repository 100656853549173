import React from 'react'
import { Text } from 'react-native'
import { colours, breakpoints } from '../styles/constants'
import isWeb from '../helpers/isWeb'
import styled, { css } from 'styled-components'

const size = 165
const width = 20
const Shared = css`
  width: ${size}px;
  height: ${size}px;
  position: absolute;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
`

const Progress = isWeb && {
  Wrapper: styled.div`
    width: ${size}px;
    height: ${size}px;
    background-color: ${props => props.color ? props.color : '#d1ebee'};
    border-radius: 50%;
    position: relative;
    margin: 0 auto 20px;
    @media (min-width: ${breakpoints.medium}px) {
      margin-right: 40px;
    }
  `,
  Mask: styled.div`
    ${Shared}
    clip: rect(0px, ${size}px, ${size}px, ${size / 2}px);
    transform: rotate(${props => props.progress}deg);
  `,
  Fill: styled.div`
    ${Shared}
    clip: rect(0px, ${size / 2 + 1}px, ${size}px, 0px);
    background-color: ${props => props.color ? props.color : colours.primary};
    transform: rotate(${props => props.progress}deg);
  `,
  Inset: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      width: `calc(100% - ${width * 2}px)`,
      height: `calc(100% - ${width * 2}px)`,
      position: 'absolute',
      backgroundColor: 'white',
      borderRadius: '50%',
      left: '50%',
      top: '50%',
      transform: [{ translate: '-50%, -50%' }],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: exceedsMediumBreakpoint ? 14 : 18,
      textAlign: 'center',
      fontFamily: 'greycliff-bold',
      ...style
    }

    return <Text style={_style} {...props}>{children}</Text>
  },
  Subheading: Text
}

const subheadingStyle = {
  fontFamily: 'greycliff',
  marginBottom: 10
}

class Component extends React.Component {
  render () {
    const { amount, max, heading, subHeading, foreground, type, exceedsMediumBreakpoint, style } = this.props
    const amountPercent = (amount / max) * 100
    const progress = amountPercent * 1.8
    if (isWeb) {
      return (
        <Progress.Wrapper style={style} exceedsMediumBreakpoint={exceedsMediumBreakpoint} color={foreground}>
          <div>
            <Progress.Mask progress={progress}>
              <Progress.Fill color={type} progress={progress} />
            </Progress.Mask>

            <Progress.Mask>
              <Progress.Fill color={type} progress={progress} />
              <Progress.Fill color={type} progress={progress / 2} />
            </Progress.Mask>

            <Progress.Inset exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
              <Text>{heading}</Text>
              <Progress.Subheading style={subheadingStyle}>{subHeading}</Progress.Subheading>
              <Text style={{ color: type }}>{amount}/{max}</Text>
            </Progress.Inset>
          </div>
        </Progress.Wrapper>
      )
    }
  }

  static defaultProps = {
    amount: 0,
    max: 0,
    heading: 'Licenses',
    subHeading: 'allocated',
    foreground: '#d1ebee',
    type: colours.primary
  }
}

export default Component
