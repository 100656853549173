import isWeb from './isWeb'
import consoleLog from './consoleLog'
import { EntityType } from './EntityFacade'
import timestamp from './date'
import BlankPng from './BlankPng'

// max time to store offline data for (in seconds)
const CACHE_MAX_AGE = 7 * 24 * 3600 // one week

// don't re-cache an image if it's been cached less than this many seconds ago
const IMAGE_RECACHE_TIME = 7 * 24 * 3600 // one hour

// not really the ROOT directory but all our caching will be inside this directory inside the document directory
const ROOT_CACHE_DIR_NAME = 'cache'

// timeout image cache downloads after this many seconds
const IMAGE_DOWNLOAD_TIMEOUT_SECONDS = 10

const OFFER_IMAGES_CACHE_DIR_NAME = 'offers'
const SUPPLIER_IMAGES_CACHE_DIR_NAME = 'suppliers'

// Since the module is not importable on the web, re-reference it from the global variable
let FileSystem = null

function pathJoin (...components) {
  components = components.map(component =>
    component.endsWith('/') ? component.substr(0, component.length - 1) : component
  )

  return components.join('/')
}

function ensureDirectoryExists (path) {
  return FileSystem.getInfoAsync(path).then(({ exists }) => {
    if (!exists) {
      return FileSystem.makeDirectoryAsync(path, { intermediates: true })
    } else {
      return Promise.resolve(true)
    }
  })
}

export default class ImageManager {
  static manager = null
  isOnline = false
  imageCacheRequests = {}

  constructor () {
    if (!isWeb) {
      if (FileSystem === null) {
        FileSystem = global.FileSystem
      }
      this.documentDirectory = FileSystem.documentDirectory
    }

    this.cacheTimes = {}
  }

  static getManager (isOnline) {
    if (this.manager === null) {
      this.manager = new ImageManager()
    }

    this.manager.isOnline = isOnline
    return this.manager
  }

  rootCacheDirectoryPath () {
    /**
     * Get the root path of the cache directory
     */
    return pathJoin(this.documentDirectory, ROOT_CACHE_DIR_NAME)
  }

  offerImageCacheDirectoryPath () {
    /**
     * Get the directory in which we cache Offer images
     */
    return pathJoin(this.rootCacheDirectoryPath(), OFFER_IMAGES_CACHE_DIR_NAME)
  }

  supplierImageCacheDirectoryPath () {
    /**
     * Get the directory in which we cache Supplier images
     */
    return pathJoin(this.rootCacheDirectoryPath(), SUPPLIER_IMAGES_CACHE_DIR_NAME)
  }

  static imageCachePath (directory, itemId) {
    /**
     * Get the path to a cached image inside a cache directory.
     */
    // the source URLs don't have extension so we don't know their format (from the URL at least). Saving with PNG
    // extension seems to work as the device will determine the actual image type from the data itself
    return pathJoin(directory, `${itemId}.png`)
  }

  offerImageCachePath (itemId) {
    /**
     * Get the path to an Offer image based on its itemId
     */
    return ImageManager.imageCachePath(this.offerImageCacheDirectoryPath(), itemId)
  }

  supplierImageCachePath (itemId) {
    /**
     * Get the path to a supplier image based on its itemId
     */

    return ImageManager.imageCachePath(this.supplierImageCacheDirectoryPath(), itemId)
  }

  rootImageCachePath (fileName) {
    /**
     * Get the path to an image from the root cache directory. The fileName should have an extension.
     */
    return pathJoin(this.rootCacheDirectoryPath(), fileName)
  }

  async getImageUrl (itemId, url, itemType) {
    /**
     * Attempt to determine the URL to use for this item's image. If we are online then get the image from the cache
     * if it's less than IMAGE_RECACHE_TIME old.
     *
     * The item may be an Offer or a Supplier.
     */
    if (isWeb) {
      // No caching on the web
      return url
    }

    if (url === null || url === undefined) {
      return null
    }

    let cachePath

    if (this.isOnline) {
      // use original image, cache it first (`cacheImage` method takes care of whether it should actually be cached)
      if (!Object.prototype.hasOwnProperty.call(this.imageCacheRequests, url)) {
        this.imageCacheRequests[url] = this.cacheImage(itemId, url, itemType)
      } else {
        consoleLog(`Reusing existing image promise for ${url}`)
      }
      cachePath = await this.imageCacheRequests[url]

      delete this.imageCacheRequests[url]
    } else {
      // offline, so return the cached (file) url if the file exists, otherwise return null
      cachePath = this.getCachePath(itemId, itemType)
    }

    if (cachePath === null) {
      // fallback to just giving the original URL
      return url
    }

    const fileInfo = await FileSystem.getInfoAsync(cachePath)
    return fileInfo.exists ? fileInfo.uri : null
  }

  getCachePath (itemId, itemType) {
    switch (itemType) {
    case EntityType.OFFER:
      return this.offerImageCachePath(itemId)
    case EntityType.SUPPLIER:
      return this.supplierImageCachePath(itemId)
    default:
      return this.rootImageCachePath(itemId) // in this case it will be the file name
    }
  }

  async cacheImage (itemId, url, itemType) {
    /**
     * Request the caching of an image from a remote URL to the filesystem. The image might not be cached:
     * - If the image was already cached and is less than IMAGE_RECACHE_TIME seconds old, then return its file system
     *   URI (file:///...). Do not cache again.
     * - If the image was not cached or is older than IMAGE_RECACHE_TIME, start the caching, then return its files
     *   system URL
     */
    const cachePath = this.getCachePath(itemId, itemType)

    const modificationTime = await this.getModificationTime(cachePath)

    const currentTime = timestamp()

    if (currentTime - modificationTime > IMAGE_RECACHE_TIME) {
      consoleLog(`Caching ${itemType} Image at ${url}`)

      try {
        const cacheSubDir = itemType === EntityType.OFFER
          ? this.offerImageCacheDirectoryPath() : this.supplierImageCacheDirectoryPath()

        await ensureDirectoryExists(cacheSubDir)

        let downloadFailed = false

        const fetchWithTimeout = Promise.race([
          FileSystem.downloadAsync(url, cachePath),
          new Promise((resolve, reject) => {
            setTimeout(() => {
              reject(new Error('timeout'))
            }, IMAGE_DOWNLOAD_TIMEOUT_SECONDS * 1000)
          }
          )
        ]
        )

        try {
          await fetchWithTimeout
        } catch (e) {
          downloadFailed = true
        }

        if (downloadFailed) {
          await FileSystem.writeAsStringAsync(cachePath, BlankPng, {
            encoding: FileSystem.EncodingType.Base64
          })
        }

        this.cacheTimes[cachePath] = timestamp()
        await this.cleanupDirectory(cacheSubDir, true)
      } catch (e) {
        consoleLog(`Error caching image at ${url}: ${e}`)
        return null
      }
    }

    return cachePath
  }

  async getModificationTime (cachePath) {
    if (cachePath == null) { return 0 }

    if (this.cacheTimes[cachePath] !== undefined) {
      // prevent having to query the filesystem all the time, by caching the edit times
      return this.cacheTimes[cachePath]
    }
    const fileInfo = await FileSystem.getInfoAsync(cachePath)
    if (fileInfo.exists) {
      this.cacheTimes[cachePath] = fileInfo.modificationTime
      return fileInfo.modificationTime
    }
    return 0
  }

  async cleanupDirectory (cacheDirectory, expiredOnly) {
    /**
     * Check all files in cacheDirectory and delete any with modification time more than CACHE_MAX_AGE seconds ago
     */
    if (cacheDirectory == null) { return }

    const fileInfo = await FileSystem.getInfoAsync(cacheDirectory)
    if (!fileInfo.exists || !fileInfo.isDirectory) {
      // don't need to delete if it doesn't exist or is not a directory
      return
    }

    const directoryList = await FileSystem.readDirectoryAsync(cacheDirectory)
    for (const entry of directoryList) {
      const entryPath = pathJoin(cacheDirectory, entry)
      const entryInfo = await FileSystem.getInfoAsync(entryPath)
      const currentTime = timestamp()

      if (!entryInfo.exists) {
        continue
      }

      if (!expiredOnly || (currentTime - entryInfo.modificationTime > CACHE_MAX_AGE)) {
        if (this.cacheTimes[entryPath] !== undefined) delete this.cacheTimes[entryPath]
        await FileSystem.deleteAsync(entryPath, { idempotent: true })
      }
    }
  }

  async cleanupAll () {
    /**
     * Run `cleanupDirectory` for each cache directory (Offers and Suppliers)
     */
    this.reset()
    await this.cleanupDirectory(this.offerImageCacheDirectoryPath(), false)
    await this.cleanupDirectory(this.supplierImageCacheDirectoryPath(), false)
  }

  reset () {
    /**
     *  Remove all the cache times, after cleanup, so recheck is forced
     */
    this.cacheTimes = {}
  }
}
