import React from 'react'
import { View, ScrollView, Platform, BackHandler, SafeAreaView } from 'react-native'
import { SafeAreaView as RNSACSafeAreaView } from 'react-native-safe-area-context'

import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import isWeb from './helpers/isWeb'
import getDeviceId from './helpers/deviceId'
import Header from './components/Header'
import Footer from './components/Footer'
import TabBar from './components/TabBar'
import Login from './auth/Login'
import Offers from './Offers'
import Offer from './Offer'
import Profile from './profile/Profile'
import Preferences from './profile/Preferences'
import storage, { deleteOfflineData } from './helpers/storage'
import api from './helpers/boost-client-js-library/api'
import consoleLog from './helpers/consoleLog'
import ConnectionManager from './helpers/ConnectionManager'
import timestamp from './helpers/date'
import PropTypes from 'prop-types'
import BoostAnalytics from './BoostAnalytics'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as SplashScreen from 'expo-splash-screen'

const MAX_OFFLINE_ALLOWED_TIME = 7 * 24 * 3600

class Wrapper extends React.Component {
  render () {
    const { children, ...rest } = this.props
    const _style = {
      height: '100%'
    }

    if (isWeb) {
      _style.display = 'flex'
      _style.flexDirection = 'column'
    } else {
      _style.backgroundColor = 'white'
    }

    return <View style={_style} {...rest}>
      {children}
    </View>
  }
}

const Inner = ScrollView
const innerStyle = isWeb ? {
  overflow: 'scroll',
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: 'auto'
} : {}

export const routes = [
  // non logged in routes
  { path: '/', component: Login },

  // top level pages
  { path: '/offers', component: Offers, loggedIn: true },
  { path: '/offers/:id', component: Offer, loggedIn: true },
  { path: '/offers/categories/:id/:name', component: Offers, loggedIn: true },
  { path: '/profile', component: Profile, loggedIn: true },

  // profile
  { path: '/profile/preferences', component: Preferences, loggedIn: true }
]

// const sitemap = `<?xml version="1.0" encoding="UTF-8"?>
//     <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
//         ${routes.map(i => {
//             return `<url>
//                 <loc>http://staging.myboost.co.nz${i.path}</loc>
//                 <lastmod>2019-04-10</lastmod>
//                 <changefreq>weekly</changefreq>
//             </url>`
//         })}
//     </urlset>`

// consoleLog(sitemap)

const CustomSafeAreaView = function ({ shouldBeSafe, children, ...props }) {
  if (Platform.OS === 'ios' && shouldBeSafe) {
    return <SafeAreaView {...props}>
      {children}
    </SafeAreaView>
  }

  if (Platform.OS === 'android') {
    return <RNSACSafeAreaView {...props}>
      {children}
    </RNSACSafeAreaView>
  }
  return children
}

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn, protectedRoute, appKey, offlineExpiredMessage, invalidLoginMessage, appVersion, apiVersion, scrollview } = { ...rest }
  return (
    <Route
      {...rest}
      render={(props) => (
        isLoggedIn || !protectedRoute // if logged in or on a public route
          ? <Component
            {...props}
            isLoggedIn={isLoggedIn}
            appKey={appKey}
            offlineExpiredMessage={offlineExpiredMessage}
            invalidLoginMessage={invalidLoginMessage}
            appVersion={appVersion}
            apiVersion={apiVersion}
            scrollview={scrollview}
          />
          : <Redirect to={{
            pathname: '/',
            // eslint-disable-next-line react/prop-types
            state: { from: props.location }
          }}/>
      )}
    />
  )
}

class AppRouter extends React.Component {
  static propTypes = {
    appKey: PropTypes.object,
    Notifications: PropTypes.object,
    Permissions: PropTypes.object,
    appVersion: PropTypes.string,
    isDev: PropTypes.bool,
    location: PropTypes.object,
    history: PropTypes.object,
    routes: PropTypes.array
  }

  backHandler = null

  state = {
    routes
  }

  onlineState = null

  notificationCheckInProgress = false

  // Flag to prevent too many pinpoint calls when running in dev mode
  devPinpointFirstRun = true

  componentIsMounted = false

  splashHidden = false

  boostAnalytics = null

  resetScroll = () => {
    if (isWeb) {
      window.scroll(0, 0)
    } else {
      this._scrollView && this._scrollView.scrollTo({ x: 0, y: 0, animated: false })
    }
  }

  handleConnectionTestResult (appKey, token, isOnline) {
    this.setState({ offline: !isOnline })

    if (isOnline === this.onlineState) return // don't redo everything if online-ness hasn't changed

    this.onlineState = isOnline

    const currentTime = timestamp()

    if (!isOnline) {
      consoleLog('handleConnectionTestResult offline')
      // check offline time, if greater than MAX_OFFLINE_ALLOWED_TIME remove token and set isLoggedIn to false
      storage.offline.check(currentTime).then(offLineTime => {
        // offlineTime = last time we knew the user was online
        // boot them off offline, log them out
        consoleLog(`appRouter storage.offline offLine for: ${currentTime - Number(offLineTime)}`)
        consoleLog(`appRouter storage.offline offlineLength: ${MAX_OFFLINE_ALLOWED_TIME}`)
        if (currentTime - Number(offLineTime) > MAX_OFFLINE_ALLOWED_TIME) {
          this.handleOfflineTimeExpired(appKey)
        } else {
          consoleLog('appRouter api.checkConnection offline time OK')
          this.setState({ token })
          // user is still offline, offline time is not expired
          this.setState({
            validated: true,
            isLoggedIn: true
          })
        }
      })
    } else {
      consoleLog('handleConnectionTestResult online')
      // user is online
      storage.offline.delete()
      this.setState({
        offlineExpired: false
      })
    }
  }

  handleOfflineTimeExpired (appKey) {
    consoleLog('appRouter checkConnection offline time expired')

    Promise.all([deleteOfflineData(), storage.token.delete(appKey)]).then(() => {
      this.setState({
        validated: true,
        isLoggedIn: false,
        offlineExpired: true
      })
    })
  }

  handleValidation = () => {
    const { appKey } = this.props

    storage.token.get(appKey, true).then(token => {
      if (isWeb) {
        // Always Validate against API
        this.apiValidate(appKey, token, true)
      } else {
        ConnectionManager.getManager().checkApiConnectivity().then((isOnline) => {
          // Only run the apiValidate if we are online
          this.handleConnectionTestResult(appKey, token, isOnline)

          if (isOnline && token !== '') { // don't bother validating token if we are offline or if token is blank
            this.apiValidate(appKey, token, isOnline)
          } else if (token === '') {
            // null values as we don't know who the user is
            this.handleAuthCheck(null, token, false, null)
          }
        })
      }
    })
  }

  apiValidate (appKey, token, isOnline) {
    /**
     * Perform validation of token against the API
     */
    if (token === '') {
      // No need to validate we know it's not valid
      this.handleAuthCheck(null, token, false, null)
      return
    }

    // eslint-disable-next-line camelcase
    const { access_token, expires_in, refresh_token, time } = token
    // eslint-disable-next-line camelcase
    const access = { token: access_token, device: getDeviceId() }
    const currentTime = timestamp()
    // eslint-disable-next-line camelcase
    const tokenExpired = currentTime - time > expires_in

    if (!isOnline) {
      // can't check, just do nothing
      return
    }

    api.auth.validate(access).then(res => {
      if (res.data === undefined) {
        // we might be offline
        consoleLog('[AppRouter] Auth validation data was undefined')
        return
      }

      this.setState({ apiVersion: res.apiVersion }) // get API version

      if (res.data && res.data.code && res.data.code === 401) {
        this.setState({ invalidLogin: res.data.message ? res.data.message : 'There was an issue logging in. If the problem persists, please contact your account manager.' })
      } else {
        this.setState({ invalidLogin: false })
      }

      // if current time minus time is greater than expiry then request refreshToken
      if (tokenExpired) {
        // eslint-disable-next-line camelcase
        const refreshAccess = { token: refresh_token, device: getDeviceId(), scope: isWeb ? 'website' : 'app' }
        api.auth.refresh(refreshAccess).then(refresh => {
          const newToken = token

          if (refresh.access_token && refresh.refresh_token) {
            // get new access_token and refresh_token
            newToken.access_token = refresh.access_token
            newToken.refresh_token = refresh.refresh_token
            newToken.time = timestamp()
          }

          storage.token.set(newToken, appKey).then(() => {
            const valid = refresh && refresh.access_token
            this.handleRefreshAuth(newToken, valid, refresh.access_token)
          })
        })
      } else {
        const valid = res && res.data && res.data.email
        const email = res && res.data ? res.data.email : false
        this.handleAuthCheck(access, token, valid, email)
      }
    })
  }

  handlePushNotificationSetup = async () => {
    const emailAddress = await AsyncStorage.getItem('emailAddress') // check if email address is in local storage

    if (this.props.isDev) {
      // return mock data in dev since real notifications don't work
      const firstRun = this.devPinpointFirstRun
      this.devPinpointFirstRun = false

      return [
        { data: 'dev-device-push-token' },
        'dev-expo-push-token',
        { status: 'granted' },
        firstRun,
        emailAddress
      ]
    }

    const { Notifications, Permissions } = this.props
    const localDeviceToken = await AsyncStorage.getItem('localDeviceToken') // gets stringified token object, if nothing then it's null
    const deviceToken = await Notifications.getDevicePushTokenAsync() // gets token object
    const deviceTokenString = JSON.stringify(deviceToken)
    let deviceTokenUpdated = false
    if (localDeviceToken !== deviceTokenString) {
      AsyncStorage.setItem('localDeviceToken', deviceTokenString).then(() => {})
      deviceTokenUpdated = true
    }
    const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS)
    let finalStatus = existingStatus

    // only ask if permissions have not already been determined, because
    // iOS won't necessarily prompt the user a second time.
    if (existingStatus !== 'granted') {
      // Android remote notification permissions are granted during the app
      // install, so this will only ask on iOS
      const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS)
      finalStatus = status
    }

    // Stop here if the user did not grant permissions
    if (finalStatus !== 'granted') {
      return ['', '', { status: 'denied' }, false, emailAddress]
    }
    consoleLog('n3hub-boost notification status: ' + finalStatus)
    // Get the tokens that uniquely identify this device
    return Promise.all([
      Notifications.getDevicePushTokenAsync(),
      Notifications.getExpoPushTokenAsync(),
      Permissions.getAsync(Permissions.NOTIFICATIONS),
      deviceTokenUpdated,
      emailAddress
    ])
  }

  handleAuthCheck = (access, token, valid, type) => {
    const { appKey, appVersion } = this.props
    this.setState({
      validated: true,
      isLoggedIn: valid ? type : false
    }, () => {
      if (valid) {
        api.profile.get(access).then(profile => {
          if (!profile.ID) return false

          this.boostAnalytics = new BoostAnalytics(profile.ID)
          this.handleAnalytics()

          if (!isWeb) {
            if (this.notificationCheckInProgress) return

            this.notificationCheckInProgress = true

            // amplify analytics - need to run when logged in as we get user details
            this.handlePushNotificationSetup().then(res => {
              this.pushNotificationRegister(res, profile, appVersion)
            })
          }
        })
      } else { // logout user if invalid
        this.handleLogout(token, appKey)
      }
    })
  }

  pushNotificationRegister (res, profile, appVersion) {
    this.notificationCheckInProgress = false
    consoleLog('n3hub-boost push response: ' + JSON.stringify(res))
    const pushToken = res && res[0]
    const permission = res && res[2]
    const tokenUpdated = res && res[3]
    const emailAddress = res && res[4]

    if (global.Analytics === undefined || global.pinPointId === undefined) return

    const Analytics = global.Analytics
    const pinPointId = global.pinPointId

    if (tokenUpdated || emailAddress !== profile.email) { // if device token has changed, or email address has not been set call PN
      AsyncStorage.setItem('emailAddress', profile.email).then(() => {})
      consoleLog('n3hub-boost pushToken: ' + JSON.stringify(pushToken))
      consoleLog('n3hub-boost permission: ' + JSON.stringify(permission))

      const endpointData = {
        userId: profile.ID, // profile ID
        userAttributes: {
          username: [profile.firstName], // profile firstName
          permission: permission ? [permission.status] : ['N/A'],
          appVersion: [appVersion]
        },
        channelType: Platform.OS === 'ios' ? 'APNS' : 'GCM', // apple = APNS or android = GCM
        address: pushToken ? pushToken.data : 'N/A', // device token, from getDevicePushTokenAsync()
        optOut: permission && permission.status !== 'denied' ? 'NONE' : 'ALL' // check if user as opted out
      }

      const analyticsConfig = {
        AWSPinpoint: {
          // Amazon Pinpoint App Client ID
          appId: pinPointId,
          // Amazon service region
          region: 'ap-southeast-2',
          mandatorySignIn: false,
          endpoint: endpointData
        }
      }

      consoleLog('n3hub-boost updateEndpoint data: ' + JSON.stringify(analyticsConfig))

      Analytics.configure(analyticsConfig)

      if (Platform.OS === 'android') {
        const { Notifications } = this.props
        Notifications.setNotificationChannelAsync('default', {
          name: 'default',
          sound: true,
          importance: Notifications.AndroidImportance.MAX,
          vibrate: [0, 250, 250, 250]
        })
      }
    } else {
      consoleLog('Token unchanged, not registering to pinpoint.')
    }
  }

  static apiLogout (token) {
    if (token === '') {
      // no token so no point trying to deauthorize nothing
      return new Promise(resolve => resolve)
    }

    return api.auth.logout(token, getDeviceId())
  }

  handleLogout (token, appKey) {
    if (isWeb) {
      AppRouter.apiLogout(token).then(() => storage.token.delete(appKey))
    } else {
      // logout and delete offline content
      AppRouter.apiLogout(token).then(() => {
        deleteOfflineData().then(() => {
          consoleLog('Offline data deleted.')
          storage.token.delete(appKey)
        })
      })
    }
  }

  handleRefreshAuth = (token, valid, type) => {
    consoleLog('Token was refreshed')

    const { appKey } = this.props
    if (!this.state.offline || isWeb) { // if online
      this.setState({
        validated: true,
        isLoggedIn: valid ? type : false
      }, () => {
        if (!valid) { // logout user if invalid
          api.auth.logout(token, getDeviceId()).then(() => storage.token.delete(appKey))
        }
      })
    }
  }

  handleAnalytics = () => {
    // GA stuff
    const { location } = this.props
    const route = location.pathname
    if (this.ba == null) {
      return
    }
    this.ba.recordPageView(route, null)
  }

  handleBackPress = () => {
    const { history } = this.props
    history.goBack()
    return true
  }

  componentDidMount () {
    const propRoutes = this.props.routes
    this.handleValidation()
    this.setState(state => {
      if (propRoutes) state.routes = propRoutes
      return state
    })
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleBackPress)

    if (global !== undefined && global.Linking !== undefined) {
      const Linking = global.Linking
      Linking.addEventListener('url', (urlEvent) => {
        this.urlEventReceived(urlEvent)
      })
    }

    this.componentIsMounted = true
  }

  urlEventReceived (urlEvent) {
    // global.Linking won't exist on the web but this shouldn't get triggered if we're on the web
    const Linking = global.Linking
    if (!this.componentIsMounted) {
      return
    }

    // this callback is called if a link is opened when the app is already opened
    consoleLog(`URL event received: ${urlEvent.url}`)

    let { path } = Linking.parse(urlEvent.url)

    if (path == null) {
      return
    }

    if (!path.startsWith('/')) {
      path = '/' + path
    }

    if (path.startsWith('/@')) { // expo style link
      return
    }

    if (path !== '/') {
      consoleLog(`Setting post auth path: ${path}`)
      const { isLoggedIn } = this.state
      if (isLoggedIn) {
        const { history } = this.props

        if (history.location.pathname !== '/offers') {
          // Switching from one offer to another or one retailer to another doesn't work, so if not on the home screen,
          // then go back one.
          history.goBack()
        }
        history.push(path)
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    // check if logged in state, route or offline status has changed
    if (isWeb) {
      if (prevProps.location.pathname !== this.props.location.pathname) this.handleValidation()
    } else {
      // try to prevent this being called multiple times on first mount
      if (prevState.isLoggedIn !== undefined && prevState.offline !== undefined) {
        if (prevState.isLoggedIn !== this.state.isLoggedIn || prevProps.location.pathname !== this.props.location.pathname || prevState.offline !== this.state.offline) {
          this.handleValidation()
        }
      }

      if (prevProps.initialUrl !== this.state.initialUrl) {
        if (this.props.initialUrl != null) {
          this.props.history.push(this.props.initialUrl, { openedByUrl: true })
          this.props.resetInitialUrl()
        }
      }
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handleAnalytics()
      this.resetScroll()
    }

    if (!this.splashHidden) {
      if (this.state.validated && !prevState.validated) {
        SplashScreen.hideAsync().then(() => { this.splashHidden = true })
      }
    }
  }

  componentWillUnmount () {
    if (this.backHandler !== null) { this.backHandler.remove() }
    this.componentIsMounted = false
  }

  renderRoutes = () => {
    const { appKey, location, appVersion } = this.props
    const { isLoggedIn, routes, offlineExpired, invalidLogin, apiVersion } = this.state
    const offlineExpiredMessage = location.pathname === '/' && offlineExpired // offline message for Login page only
    const invalidLoginMessage = location.pathname === '/' && invalidLogin
    return routes.map((r, i) => {
      if (r.restricted === 'app' && isWeb) return false // if app only route and on web
      if (r.restricted === 'web' && !isWeb) return false // if web only and not on web
      return <PrivateRoute
        key={i}
        exact
        path={r.path}
        isLoggedIn={isLoggedIn}
        protectedRoute={r.loggedIn}
        component={r.component}
        appKey={appKey}
        offlineExpiredMessage={offlineExpiredMessage}
        invalidLoginMessage={invalidLoginMessage}
        appVersion={appVersion}
        apiVersion={apiVersion}
        scrollview={this._scrollView}
      />
    })
  }

  render () {
    const { isLoggedIn, validated, apiVersion } = this.state
    const innerProps = {}

    if (isWeb) {
      innerProps.transform = []
    }

    const shouldBeSafe = (
      this.props.location.pathname !== '/' &&
      this.props.location.pathname !== '/sign-in' &&
      this.props.location.pathname !== '/card-register'
    )

    return (
      <Wrapper>
        <CustomSafeAreaView shouldBeSafe={shouldBeSafe}>
          <Inner style={innerStyle} ref={(c) => { this._scrollView = c }} bounces={false} keyboardShouldPersistTaps="handled" {...innerProps}>
            {isWeb && <Header isLoggedIn={isLoggedIn}/>}
            <Switch>
              {validated ? this.renderRoutes() : null}
            </Switch>
          </Inner>
        </CustomSafeAreaView>
        {isWeb ? <Footer isLoggedIn={isLoggedIn} apiVersion={apiVersion}/> : <TabBar isLoggedIn={isLoggedIn}/>}
      </Wrapper>
    )
  }
}

export default withRouter(AppRouter)
