import React from 'react'
import { Redirect } from 'react-router-dom'
import Layout from '../../components/Layout'
import { Text, View } from 'react-native'
import isWeb from '../../helpers/isWeb'
import { breakpoints, colours } from '../../styles/constants'
import { Bold, DataTable as DTable, getSuperSectionStyle, H1, P } from '../../styles/globalClasses'
import { Primary, Secondary } from '../../components/Inputs/Buttons'
import getSuperOptions from '../../data/superOptions'
import ProgressBar from '../../components/ProgressBar'
import Steps from '../../components/Steps'
import TableSearch from '../../components/TableSearch'
import Modal from '../../components/Modal'
import CSVReader from 'react-csv-reader'
import storage from '../../helpers/storage'
import api from '../../helpers/boost-client-js-library/api'
import Notification from '../../components/Notification'
import LoadingIndicator from '../../components/LoadingIndicator'
import exportCSVFile, { convertToCSV } from '../../helpers/exportCSVFile'
import checkRole from '../../helpers/checkRole'
import consoleLog from '../../helpers/consoleLog'
import reSuperOptions from '../../helpers/reSuperOptions'
import PropTypes from 'prop-types'
import BreakpointWatcher from '../../helpers/BreakpointWatcher'
import { DataTable } from './AddUsersDataTable'
import lineHeight from '../../helpers/lineHeight'

const MAX_APP_ORDER_BATCH_SIZE = 5
const MAX_APP_ORDER_COUNT = 100

const ADDING_USERS_TEXT = 'Adding users…'

const StepsWrapper = View

const Progress = {
  Wrapper: View,
  Bar: ProgressBar,
  NewUsers: View,
  TextWrapper: View,
  Text: ({ exceedsMediumBreakpoint, style = {}, children, colour, amount, ...props }) => {
    if (exceedsMediumBreakpoint) {
      if (colour) {
        style.color = colour
      }
      if (amount) {
        style.flexBasis = `${amount}%`
        style.minWidth = 70
      }

      if (props.new) { // can't use spread on "new" because it's reserved keyword
        style.flex = 1
        style.margin = '0 5px'
        style.minWidth = 60
        delete props.new
      }
    } else if (props.new) {
      style.display = 'none'
      delete props.new
    }

    return <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} style={style} {...props}>{children}</P>
  }
}

const Search = {
  Wrapper: View
}

const Users = {
  Wrapper: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = { ...getSuperSectionStyle(exceedsMediumBreakpoint), ...style }
    if (exceedsMediumBreakpoint) {
      _style.marginBottom = 20
    }
    return <View style={_style} {...props}>{children}</View>
  },
  Header: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      marginTop: 0,
      marginLeft: -15,
      marginRight: -15,
      marginBottom: 15,
      paddingTop: 0,
      padding: 15,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: colours.black60,
      ...style
    }

    if (exceedsMediumBreakpoint) {
      _style.display = 'none'
    }

    return <View style={_style} {...props}>{children}</View>
  },
  Buttons: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      flexDirection: 'row',
      display: exceedsMediumBreakpoint ? 'flex' : 'none',
      ...style
    }

    return <View style={_style} {...props}>{children}</View>
  },
  ButtonsSmall: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      flexDirection: 'column',
      ...style
    }

    if (exceedsMediumBreakpoint) {
      _style.display = 'none'
    }

    return <View style={_style} {...props}>{children}</View>
  },
  Add: ({ exceedsMediumBreakpoint, style = {}, children, active, ...props }) => {
    const _style = {
      flexDirection: 'row',
      backgroundColor: active ? colours.green : '#666',
      ...style
    }
    return <Primary exceedsMediumBreakpoint={exceedsMediumBreakpoint} style={_style} {...props}>{children}</Primary>
  },
  Next: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    style.marginTop = 15
    return <Primary exceedsMediumBreakpoint={exceedsMediumBreakpoint} style={style} {...props}>{children}</Primary>
  }
}

const getButtonSharedStyle = (exceedsMediumBreakpoint) => {
  const _style = {
    marginTop: exceedsMediumBreakpoint ? 0 : 20
  }

  if (exceedsMediumBreakpoint) {
    _style.marginRight = 15
  }

  return _style
}

const multiWrapChildStyle = {
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: '360px'
}

const detailsAStyle = {
  color: 'black'
}

const Options = {
  MultiWrap: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      ...(exceedsMediumBreakpoint ? {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
      } : {}),
      ...style
    }
    return <View style={_style} {...props}>{children}</View>
  },
  Wrapper: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      ...getSuperSectionStyle(exceedsMediumBreakpoint),
      ...style
    }

    if (exceedsMediumBreakpoint) {
      _style.paddingRight = 15
    }

    return <View style={_style} {...props}>{children}</View>
  },
  Details: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      marginBottom: 20,
      ...(exceedsMediumBreakpoint ? {
        marginBottom: 40,
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
        alignItems: 'center'
      } : {}),
      ...style
    }
    return <View style={_style} {...props}>{children}</View>
  },
  Button: ({ exceedsMediumBreakpoint, style = {}, children, active, ...props }) => {
    const _style = {
      ...getButtonSharedStyle(exceedsMediumBreakpoint),
      backgroundColor: 'white',
      color: 'black',
      ...(exceedsMediumBreakpoint ? {
        backgroundColor: colours.green,
        color: 'white',
        borderWidth: 0
      } : {}),
      ...(!active ? {
        backgroundColor: '#666',
        cursor: 'not-allowed',
        opacity: 1
      } : {}),
      ...style
    }
    return <Secondary style={ _style} {...props}>
      {children}
    </Secondary>
  },
  Secondary: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    return <Secondary style={{ ...getButtonSharedStyle(exceedsMediumBreakpoint), ...style }} {...props}>
      {children}
    </Secondary>
  }
}

const Controls = {
  Wrapper: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      display: 'none',
      ...(exceedsMediumBreakpoint ? {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10
      } : {}),
      ...style
    }

    return <View style={_style} {...props}>{children}</View>
  },
  WrapperWrapper: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      display: exceedsMediumBreakpoint ? 'block' : 'none',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#7fdbff',
      paddingBottom: 20,
      marginBottom: 20,
      ...style
    }
    return <View style={_style} {...props}>{children}</View>
  },
  ConfirmMessage: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      marginTop: 15,
      width: '40%',
      lineHeight: lineHeight(18),
      float: 'right',
      textAlign: 'right',
      ...style
    }

    if (!exceedsMediumBreakpoint) {
      _style.display = 'none'
    }

    return <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} style={_style} {...props}>{children}</P>
  },
  ConfirmMessageSmall: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      marginTop: 10,
      display: exceedsMediumBreakpoint ? 'none' : 'block'
    }

    return <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} style={_style} {...props}>{children}</P>
  },
  Left: View,
  Right: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    if (exceedsMediumBreakpoint) {
      style.flexDirection = 'row'
    }

    return <View style={style} {...props}>{children}</View>
  },
  Back: ({ style = {}, children, firstStep, ...props }) => {
    const _style = {
      marginRight: 0,
      backgroundColor: firstStep ? colours.taupe : colours.green,
      ...style
    }

    return <Primary style={_style} {...props}>{children}</Primary>
  },
  Save: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      marginVertical: exceedsMediumBreakpoint ? 0 : 10,
      marginHorizontal: 0,
      backgroundColor: colours.orange,
      ...style
    }

    return <Primary style={_style} {...props}>{children}</Primary>
  },
  Next: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      marginRight: 0,
      ...style
    }
    if (exceedsMediumBreakpoint) {
      _style.marginLeft = 10
    }
    return <Primary style={_style} {...props}>{children}</Primary>
  }
}

const ModalClose = ({ style = {}, children, ...props }) => {
  style.backgroundColor = colours.urgent
  return <Primary style={style} {...props}>{children}</Primary>
}

const csvReaderClassName = 'add-users-csv-reader'

// The rules for .${csvReaderClassName} .csv-reader-input label
// are to make it look like a button. If a button changes its look, please update these rules!
const csvReaderCss = `
.${csvReaderClassName} {
  margin-top: 20px;
}

.${csvReaderClassName} .csv-reader-input {
  display: flex;
}

.${csvReaderClassName} .csv-reader-input label {
  color: white;
  font-family: greycliff-bold;
  border-radius: 4px;
  padding: 10px 15px;
  text-align: center;
  overflow: hidden;
  font-size: 16px;
  line-height: 19px;
  cursor: 'pointer';
  flex: 0 0 auto;
  user-select: none;
  transition: opacity .1s;
  background-color: ${colours.green};
}
.${csvReaderClassName} .csv-reader-input label:hover {
  opacity: .8;
}
.${csvReaderClassName} .csv-reader-input label:active {
  opacity: 1;
}

@media(min-width: ${breakpoints.medium}px){
  .${csvReaderClassName} .csv-reader-input label {
    font-size: 14px;
    line-height: 17px;
  }
}
 
.${csvReaderClassName} .csv-input {
  position: absolute;
  left: -9999px;
}
`

const M = {
  Steps: View,
  Step: View,
  P: ({ style = {}, children, ...props }) => {
    const _style = {
      fontFamily: 'greycliff, sans-serif',
      fontSize: 16,
      lineHeight: lineHeight(22),
      ...style
    }
    return <Text style={_style} {...props}>{children}</Text>
  },
  B: ({ style = {}, children, ...props }) => {
    const _style = {
      fontFamily: 'greycliff-bold, sans-serif',
      fontSize: 16,
      lineHeight: lineHeight(22),
      paddingTop: 15,
      ...style
    }
    return <Text style={_style} {...props}>{children}</Text>
  },
  Amount: ({ style = {}, children, ...props }) => {
    const _style = {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colours.black30,
      borderRadius: 4,
      padding: 8,
      width: 190,
      maxWidth: '100%',
      marginTop: 10,
      ...style
    }

    return <input style={_style} {...props}>{children}</input>
  },
  Error: ({ style = {}, children, ...props }) => {
    const _style = {
      color: colours.urgent,
      fontFamily: 'greycliff, sans-serif',
      marginTop: 5,
      ...style
    }
    return <Text style={_style} {...props}>{children}</Text>
  },

  Table: isWeb && DTable,
  Upload: ({ style = {}, children, uploaded, ...props }) => {
    const _style = {
      backgroundColor: uploaded ? colours.primary : colours.black60,
      ...style
    }
    return <Primary style={_style} {...props}>{children}</Primary>
  }
}

const dataRow = {
  firstName: '',
  lastName: '',
  email: '',
  card: true,
  app: true
}

const orderProcessLoadingIndicatorStyle = {
  marginLeft: 15,
  marginRight: 0,
  marginTop: 0,
  marginBottom: 5,
  top: 5
}

const orderProcessLoadingContainerStyle = { paddingTop: 5, paddingBottom: 10 }

const maxUsers = 100

const emailValidation = new RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)

// max number of cards Tier 1 can order – must be a multiple of 8
const MAX_TIER_ONE_CARDS_ORDER = 56

const MAX_CARDS_ORDER = 250

export default class Super extends React.Component {
  static propTypes = {
    history: PropTypes.object

  }

  state = {
    tier: '',
    options: [],
    profile: {},
    steps: ['Add', 'Confirm'],
    active: 0,
    search: '',
    searchData: [],
    data: [],
    editable: [],
    addEnabled: true,
    nextDisabled: true,
    confirmDisabled: false,
    modal: {},
    cardsOrdered: 0,
    cardsActiveStep: 0,
    cardsOrderedDisabled: false,
    loginsOrdered: 0,
    loginsActiveStep: 0,
    csvData: {},
    error: null,
    notification: null,
    notificationType: null,
    notificationAlignRight: false,
    appsOrdered: 0,
    appsOrderedOutput: 0,
    appsActiveStep: 0,
    appsOrderedCompleted: 0,
    isBulkUploading: false,
    bulkUploadComplete: false,
    resetCsvReader: true,
    exceedsMediumBreakpoint: false
  }

  componentDidMount () {
    const { history } = this.props
    storage.token.get().then(token => {
      api.profile.get(token).then(profile => {
        const roles = checkRole(profile.roles)
        if (!roles.includes('bp')) {
          history.push('/profile')
          return false
        }
        api.organisation.get(token).then(organisation => {
          if (organisation.code && organisation.code !== 200) this.setState({ error: organisation.message ? organisation.message : 'Something went wrong. Please try again.' })
          if (!organisation.tier) {
            history.push('/profile')
            return false
          }
          this.setState({
            tier: Number(organisation.tier),
            options: reSuperOptions(organisation.tier, getSuperOptions(roles.includes('su'))),
            profile: organisation
          }, () => this.checkMax())
        })
      })
      api.locked.get(token).then(res => {
        this.setState({ creation_locked: res.status ? res.status : false })
      })
    })
    BreakpointWatcher.addComponent(this)
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  checkMax = (isBulk = false) => {
    const { data, profile } = this.state
    const maximumNotReached = !isBulk && (data.length + 1) <= (profile.currentPlanMaxUsers - profile.numberOfVehicles)
    this.setState({ addEnabled: maximumNotReached, nextDisabled: !isBulk && data.length === 0 })
  }

  setState (state, callback) {
    if (state.notification !== 'undefined') {
      state.notificationAlignRight = state.notification === ADDING_USERS_TEXT
    }
    super.setState(state, callback)
  }

  handleAddUser = () => {
    const { addEnabled } = this.state
    const row = Object.assign({}, dataRow)
    if (addEnabled) {
      this.setState(state => {
        state.data.push(row)
        return state
      }, () => this.checkMax())
    }
    this.setState({
      notification: null,
      notificationType: null,
      searchData: [],
      search: ''
    })
  }

  handleRemoveUser = item => {
    const { data } = this.state
    this.setState(state => {
      state.data = data.filter((_, i) => i !== item)
      return state
    }, () => this.checkMax())
  }

  handleCheck = (index, type) => {
    const { tier } = this.state
    if (tier === 4 && type === 'app') return false
    this.setState(state => {
      state.data[index][type] = !this.state.data[index][type]
      return state
    })
  }

  handleConfirm = () => {
    if (!this.validateUserData()) {
      return
    }

    const { history } = this.props
    const { data } = this.state
    this.setState({
      notification: ADDING_USERS_TEXT,
      notificationType: 'success',
      confirmDisabled: true
    })
    storage.token.get().then(token => {
      api.users.add(token, { users: data }).then(users => {
        if (users.taskID !== null && users.taskID !== undefined) {
          history.push('/profile/confirmation/add-user')
        } else {
          let failureUserErrors

          if (users.failed && users.failed.length) {
            failureUserErrors = <div>
              <p>There were errors with the information you entered and we were unable to load your users.</p>
              <ul>{
                users.failed.map(failedUser => <li key={failedUser[0]}>{failedUser[0]} - {failedUser[1]}</li>)
              }
              </ul>
              <p>Please try again and if the errors persists please contact your account manager for assistance.</p>
            </div>
          } else {
            failureUserErrors = <p>There were errors with the information you entered and we were unable to load your
              users. Please try again and if the errors persists please contact your account manager for assistance.</p>
          }

          this.setState({
            notification: failureUserErrors,
            notificationType: 'error',
            confirmDisabled: false
          })
        }
      })
    })
  }

  handlePrevious = () => {
    const { history } = this.props
    const { active } = this.state
    if (active === 0) {
      history.goBack()
    } else {
      this.setState({
        active: 0,
        notification: null
      })
    }
  }

  validateUserData = () => {
    const { data } = this.state
    let valid = true
    let emailValid = true
    let duplicateEmails = false
    const seenEmails = []

    data && data.length > 0 && data.forEach(detail => {
      Object.entries(detail).forEach(items => {
        if (items[0] === '' || items[1] === '' || items[2] === '') { // all items are required
          valid = false
        }
        if (items[0] === 'email') {
          let email = items[1]
          if (email.includes('&nbsp;')) {
            email = email.replace(/&nbsp;/g, ' ') // remove blank/non-breaking space
          }
          emailValid = emailValid && emailValidation.test(email)

          if (emailValid) {
            if (seenEmails.indexOf(email.toLowerCase()) !== -1) {
              duplicateEmails = true
            }
            seenEmails.push(email.toLowerCase())
          }
        }
      })
    })
    if (data && data.length > 0 && valid === false) {
      this.setState({
        notification: 'Please fill in the required fields',
        notificationType: 'error'
      })
      return false
    }

    if (data && data.length === 0) {
      this.setState({
        notification: 'Please add at least 1 user',
        notificationType: 'error'
      })
      return false
    }

    if (duplicateEmails) {
      this.setState({
        notification: 'Please make sure that each user has a unique email addresses.',
        notificationType: 'error'
      })
      return false
    }

    if (!emailValid) {
      this.setState({
        notification: 'Please add a valid email address',
        notificationType: 'error'
      })
      return false
    }

    return true
  }

  handleNext = () => {
    if (!this.validateUserData()) {
      return
    }

    this.setState({
      active: 1,
      notification: null,
      notificationType: null,
      searchData: [],
      search: ''
    })
  }

  handleModalOpen = type => {
    window.scrollTo(0, 150)
    const { addEnabled } = this.state
    this.setState(state => {
      if (type === 'upload' || type === 'bulk_upload') {
        if (addEnabled) {
          state.modal[type] = true
        }
      } else {
        state.modal[type] = true
      }
      return state
    })
  }

  handleModalClose = type => {
    const { cardsOrderedDisabled } = this.state
    if (type === 'cards' && cardsOrderedDisabled) return false
    this.setState(state => {
      state.modal[type] = false
      if (type === 'cards') {
        state.cardsActiveStep = 0
        state.cardsOrdered = 0
        state.cardsOrderedOutput = 0
      }
      if (type === 'logins') {
        state.loginsActiveStep = 0
        state.loginsOrdered = 0
      }
      if (type === 'upload' || type === 'bulk_upload') {
        state.csvData = {}
        state.csvUploaded = false
        state.csvError = null
        state.isBulkUploading = false
        state.bulkUploadComplete = false
      }
      state.modalNotification = false
      return state
    })
  }

  handleOrderCards = () => {
    const { history } = this.props
    const { cardsActiveStep, tier, cardsOrdered, cardsOrderedOutput, cardsOrderedDisabled, profile } = this.state
    const orderNumber = tier < 2 ? cardsOrderedOutput : cardsOrdered
    if (cardsOrderedDisabled) return false // if button is disabled stop process
    if (orderNumber > profile.availableVehicles) {
      if (tier < 2) {
        return false // if calculated is greater than max, stop process
      } else {
        this.setState({
          cardsActiveStep: 1,
          modalNotification: 'It seems you don’t have enough licences available to fulfil the order, please add more or reduce the number of uploaded contacts',
          modalNotificationType: 'error'
        })
        return false
      }
    }
    if (cardsActiveStep === 0) {
      this.setState({
        cardsActiveStep: 1
      })
    } else {
      this.setState({
        modalNotification: 'Ordering cards...',
        modalNotificationType: 'success',
        cardsOrderedDisabled: true
      })
      const defaultMessage = 'Oops, something went wrong. Please try again later'
      storage.token.get().then(token => {
        api.orders.add(token, { quantity: Number(orderNumber) }).then(res => {
          if (!res.taskID || (res && res.code && res.code !== 200)) { // if no taskID or error code
            this.setState({
              modalNotification: res.message ? res.message : defaultMessage,
              modalNotificationType: 'error',
              cardsOrderedDisabled: false
            }, () => {
              setTimeout(() => {
                this.setState({ modalNotification: false })
              }, 2000)
            })
            return false
          }
          history.push('/profile/confirmation/add-user')
        })
      })
    }
  }

  handleOrderCardsChange = e => {
    const { tier, profile } = this.state
    const inputValue = parseInt(e.target.value)
    const value = Math.min(this.maxCards(tier, profile.availableVehicles), isNaN(inputValue) ? 0 : inputValue)
    if (tier < 2) {
      const multiple = 8
      const rounded = n => Math.ceil(n) * multiple
      this.setState({ cardsOrdered: value, cardsOrderedOutput: rounded(value / multiple) })
    } else {
      this.setState({ cardsOrdered: value })
    }
  }

  maxCards = (tier, remaining) => {
    return Math.min(tier < 2 ? MAX_TIER_ONE_CARDS_ORDER : MAX_CARDS_ORDER, remaining == null ? MAX_CARDS_ORDER : remaining)
  }

  handleOrderLogins = () => {
    const { loginsActiveStep, loginsOrdered } = this.state
    const closeModal = () => {
      setTimeout(() => {
        this.setState({
          modalNotification: false,
          modal: [],
          loginsActiveStep: 0,
          loginsOrdered: 0
        })
      }, 2000)
    }
    if (loginsActiveStep === 0) {
      this.setState({
        loginsActiveStep: 1
      })
    } else {
      if (loginsOrdered === 0) {
        this.setState({
          modalNotification: 'Please select at least 1 App Login',
          modalNotificationType: 'error'
        }, closeModal)
        return false
      }
      this.setState({
        modalNotification: 'Downloading App Logins...',
        modalNotificationType: 'success'
      })
      storage.token.get().then(token => {
        api.users.getLogins(token, `offset=0&limit=${loginsOrdered}`).then(res => {
          const defaultMessage = 'Oops, something went wrong. Please try again later'
          if (!res.items) {
            this.setState({
              modalNotification: res.message ? res.message : defaultMessage,
              modalNotificationType: 'error'
            }, closeModal)
            return false
          }
          this.handleAppBvCsvDownload(res.items, closeModal)
        })
      })
    }
  }

  handleAppBvCsvDownload (items, closeModal) {
    // do csv download stuff
    const headers = {
      appID: 'App ID',
      PIN: 'PIN'
    }
    const itemsFormatted = []
    items.forEach((item) => {
      itemsFormatted.push({
        appID: item.appID,
        PIN: item.PIN
      })
    })
    exportCSVFile(headers, itemsFormatted, 'boost-app-logins')
    this.setState({
      modalNotification: 'App Logins downloaded!',
      modalNotificationType: 'success'
    }, closeModal())
  }

  handleOrderApps = () => {
    const { appsActiveStep, appsOrderedOutput } = this.state

    const closeModal = () => {
      setTimeout(() => {
        this.setState({
          modalNotification: false,
          modal: {},
          appsActiveStep: 0,
          appsOrdered: 0,
          appsOrderedOutput: 0
        })
      }, 2000)
    }

    if (appsActiveStep === 0) {
      this.setState({
        appsActiveStep: 1
      })
    } else if (appsActiveStep === 3) {
      window.location.reload()
    } else {
      if (appsOrderedOutput <= 0) {
        this.setState({
          modalNotification: 'Please enter at least 1 App',
          modalNotificationType: 'error'
        }, closeModal)
        return false
      }
      this.setState({
        modalNotification: 'Ordering App Logins...',
        modalNotificationType: 'success',
        appsOrderedCompleted: 0,
        appsActiveStep: 2
      })
      const vehicles = []
      this.orderAppBatch(appsOrderedOutput, vehicles, closeModal)
    }
  }

  orderAppBatch (batchSize, items) {
    const { appsOrderedOutput } = this.state
    this.setState({
      modalNotification: `Ordering App Logins (${items.length}/${appsOrderedOutput})`,
      appsOrderedCompleted: items.length
    }
    )

    if (batchSize <= 0) {
      this.handleAppBvCsvDownload(items, () => {
        this.setState({
          appsOrdered: 0,
          appsOrderedOutput: 0,
          appsActiveStep: 3
        })
      })
      return
    }

    storage.token.get().then(token => {
      api.users.createVehicles(token, Math.min(batchSize, MAX_APP_ORDER_BATCH_SIZE)).then(res => {
        items = items.concat(res.items)
        this.orderAppBatch(appsOrderedOutput - items.length, items)
      })
    })
  }

  maxAppsOrder = () => {
    const { profile } = this.state
    return Math.min(profile.currentPlanMaxUsers - profile.numberOfVehicles, MAX_APP_ORDER_COUNT)
  }

  handleOrderAppsChange = (e) => {
    const { tier } = this.state
    const inputValue = parseInt(e.target.value)
    const value = Math.min(this.maxAppsOrder(), isNaN(inputValue) ? 0 : inputValue)

    this.setState({
      appsOrdered: tier < 3 ? 0 : value,
      appsOrderedOutput: tier < 3 ? 0 : value
    })
  }

  setCsvError = (message) => {
    this.setState({ csvError: true })
    if (message) { this.setState({ customCsvMessage: message }) }

    this.setState({ resetCsvReader: false }, () => {
      this.setState({ resetCsvReader: true })
    })
  }

  handleCsvUpload = (data, isBulk = false) => {
    const { profile } = this.state

    this.setState({ csvError: false, customCsvMessage: null })

    // check first column name is firstName
    const hasData = data && data[0]
    const header = item => {
      if (item === 'firstName' || item === 'First Name') return 'First Name'
      if (item === 'lastName' || item === 'Last Name') return 'Last Name'
      if (item === 'email' || item === 'User Email') return 'Email'
    }
    const accessor = item => {
      if (item === 'firstName' || item === 'First Name') return 'firstName'
      if (item === 'lastName' || item === 'Last Name') return 'lastName'
      if (item === 'email' || item === 'User Email') return 'email'
    }
    if (hasData && !data[0][0].includes('firstName') && !data[0][0].includes('First Name')) {
      this.setCsvError()
      return false
    }
    const csvData = data.filter(String) // remove empty rows
    let invalidEmail = null
    let duplicateEmail = null

    const seenEmails = []

    for (let i = 1; i < csvData.length; ++i) {
      const emailAddress = csvData[i][2].trim()
      csvData[i][2] = emailAddress

      if (!emailValidation.test(emailAddress)) { // check for invalid email addresses
        invalidEmail = emailAddress
        break
      }

      const normalisedEmail = emailAddress.toLowerCase()
      if (seenEmails.indexOf(normalisedEmail) !== -1) {
        duplicateEmail = emailAddress
        break
      }
      seenEmails.push(normalisedEmail)
    }
    if (invalidEmail !== null) {
      this.setCsvError(`Email address '${invalidEmail}' is not valid.`)
      return false
    }

    if (duplicateEmail !== null) {
      this.setCsvError(`Please make sure all email addresses in the file are unique. '${duplicateEmail}' was found more than once.`)
      return false
    }

    consoleLog(csvData.length - 1)
    if (!isBulk && ((csvData.length - 1) > maxUsers)) { // -1 to account that length starts at 0
      this.setCsvError(`The number of contacts is greater than the maximum value of ${maxUsers} per stock. Please 
      contact your Account Manager if you wish to upload more than ${maxUsers} users.`)
      return false
    }
    if ((csvData.length - 1) > profile.availableVehicles) {
      this.setCsvError(`You have ${profile.availableVehicles} remaining spaces for users. Please contact your Account 
      Manager if you wish to add more.`)
      return false
    }
    this.setState(state => {
      state.csvData.columns = csvData[0].map(i => {
        return {
          Header: header(i),
          accessor: accessor(i)
        }
      })
      state.csvData.data = csvData.slice(1, 6).map(i => {
        return {
          firstName: i[0],
          lastName: i[1],
          email: i[2]
        }
      })

      if (isBulk) {
        state.csvData.submitData = convertToCSV(csvData)
      } else {
        state.csvData.submitData = csvData.slice(1).map(i => {
          return {
            firstName: i[0],
            lastName: i[1],
            email: i[2],
            card: true,
            app: true
          }
        })
      }

      state.csvUploaded = true
      state.csvError = false
      state.customCsvMessage = ''
      return state
    })
  }

  handleCsvError = () => this.setState({ csvError: true })

  handleCsvSubmit = (isBulk) => {
    const { data, csvData, profile } = this.state
    if (csvData.submitData) {
      if (isBulk) {
        this.setState(state => {
          state.csvUploaded = false
          state.isBulkUploading = true
          state.bulkUploadComplete = false
          state.csvError = false
          return state
        }, () => this.handleBulkUpload(csvData.submitData))
      } else {
        const maxUploadLength = Number(profile.currentPlanMaxUsers) - (data.length + Number(profile.numberOfVehicles))
        const maxLength = maxUploadLength < maxUsers ? maxUploadLength : maxUsers
        const submitData = csvData.submitData.slice(0, maxLength)
        this.setState(state => {
          state.active = 1
          state.data = data.concat(submitData)
          return state
        }, () => this.checkMax())
        this.handleModalClose('upload')
      }
    }
  }

  handleBulkUpload = (submitData) => {
    storage.token.get().then(token => {
      api.uploads.userList(token, submitData).then((res) => {
        this.setState({
          isBulkUploading: false
        })
        if (res === 'OK') {
          this.setState({
            bulkUploadComplete: true,
            csvError: false
          })
        } else {
          this.setState({
            bulkUploadComplete: false,
            csvError: true,
            customCsvMessage: res.message
          })
        }
      })
    })
  }

  handleSearch = e => {
    e.preventDefault()
    const { search, data } = this.state
    const searchTerm = search.toLowerCase()
    const searchData = data.filter(i => {
      return i.firstName.toLowerCase().includes(searchTerm) || i.lastName.toLowerCase().includes(searchTerm) || i.email.toLowerCase().includes(searchTerm)
    })
    this.setState({ searchData })
  }

  renderItem = cellInfo => {
    const { active, editable, searchData } = this.state
    // TODO: need to filter here somehow
    const id = cellInfo.index
    if (id >= this.state.data.length) {
      // after deleting it can still try to render data that is no longer there
      return <div className="editable-wrap"/>
    }

    const data = searchData.length !== 0 && active === 0 ? 'searchData' : 'data'
    if ((active === 0 || editable.includes(id)) && searchData.length === 0) {
      return (
        <div className="editable-wrap">
          <label htmlFor={`${id}-${cellInfo.column.id}`}
            className="editable-field-label">{cellInfo.column.Header}</label>
          <input
            id={`${id}-${cellInfo.column.id}`}
            className="editable-field"
            onChange={e => {
              e.persist()
              this.setState(state => {
                state.data[id][cellInfo.column.id] = e && e.target && e.target.value ? e.target.value : ''
                return state
              })
            }}
            value={this.state.data[id][cellInfo.column.id]}
          />
        </div>
      )
    } else {
      return <div className="field"
        data-header={cellInfo.column.Header}>{this.state[data][id][cellInfo.column.id]}</div>
    }
  }

  getStyles () {
    const {
      profile,
      data,
      exceedsMediumBreakpoint
    } = this.state

    const newUsersStyle = {
      width: `${(data.length / profile.currentPlanMaxUsers) * 100}%`,
      position: 'absolute',
      top: 0,
      left: `${(profile.currentPlanMaxUsers === 0 ? 0 : profile.numberOfVehicles / profile.currentPlanMaxUsers) * 100}%`,
      height: '100%',
      backgroundColor: colours.urgent
    }

    if (!profile.numberOfVehicles || !exceedsMediumBreakpoint) {
      newUsersStyle.display = 'none'
    }

    const stepsWrapperStyle = {}

    if (exceedsMediumBreakpoint) {
      stepsWrapperStyle.borderBottomWidth = 1
      stepsWrapperStyle.borderStyle = 'solid'
      stepsWrapperStyle.borderColor = colours.light50
      stepsWrapperStyle.marginBottom = 20
    }

    const progressWrapperStyle = getSuperSectionStyle(exceedsMediumBreakpoint)

    progressWrapperStyle.marginBottom = 20
    if (exceedsMediumBreakpoint) {
      progressWrapperStyle.borderBottomWidth = 1
      progressWrapperStyle.borderBottomStyle = 'solid'
      progressWrapperStyle.borderBottomColor = colours.light50
      progressWrapperStyle.paddingBottom = 20
    }

    const searchWrapperStyle = {
      marginBottom: 20,
      display: 'none'
    }

    if (exceedsMediumBreakpoint) {
      searchWrapperStyle.display = 'flex'
      searchWrapperStyle.flexDirection = 'row'
      searchWrapperStyle.justifyContent = 'space-between'
      searchWrapperStyle.alignItems = 'center'
    }

    const searchPStyle = {
      marginBottom: 10
    }

    if (exceedsMediumBreakpoint) {
      searchPStyle.marginBottom = 0
      searchPStyle.marginRight = 20
    }

    return {
      Progress: {
        NewUsers: newUsersStyle,
        Wrapper: progressWrapperStyle,
        TextWrapper: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 10
        }
      },
      StepsWrapper: stepsWrapperStyle,
      Search: {
        Wrapper: searchWrapperStyle,
        P: searchPStyle
      }
    }
  }

  render () {
    // eslint-disable-next-line camelcase
    const {
      tier,
      options,
      profile,
      steps,
      active,
      search,
      data,
      addEnabled,
      nextDisabled,
      confirmDisabled,
      modal,
      cardsOrdered,
      cardsOrderedOutput,
      cardsActiveStep,
      cardsOrderedDisabled,
      loginsOrdered,
      loginsActiveStep,
      csvUploaded,
      csvError,
      customCsvMessage,
      csvData,
      // eslint-disable-next-line camelcase
      creation_locked,
      error,
      modalNotification,
      modalNotificationType,
      notification,
      notificationType,
      notificationAlignRight,
      searchData,
      appsActiveStep,
      appsOrdered,
      appsOrderedOutput,
      appsOrderedCompleted,
      isBulkUploading,
      bulkUploadComplete,
      resetCsvReader,
      exceedsMediumBreakpoint
    } = this.state
    const firstStep = active === 0
    const columns = [
      {
        Header: 'First Name',
        accessor: 'firstName',
        Cell: this.renderItem
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        Cell: this.renderItem
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: this.renderItem
      },
      {
        Header: 'Access',
        sortable: false,
        Cell: cell => {
          const checks = () => {
            if (tier < 3) return ['card']
            if (tier === 3) return ['app']
            if (tier === 4) return ['card', 'app']
          }
          return (
            <div className="check-wrap">
              <span className="title">Access</span>
              {checks().map((check, i) => (
                <React.Fragment key={i}>
                  <input
                    type="checkbox"
                    className="checkbox"
                    id={`${cell.index}-${check}`}
                    checked={data[cell.index][check]}
                    onChange={() => this.handleCheck(cell.index, check)}
                    disabled={(tier === 4 && check === 'app') || searchData.length > 0}
                  />
                  <label htmlFor={`${cell.index}-${check}`} className={`custom-check ${!firstStep && 'small-check'}`}>
                    {check === 'card' ? 'Card' : 'App'}
                  </label>
                </React.Fragment>
              ))}
            </div>
          )
        }
      },
      {
        Header: '',
        sortable: false,
        width: 97,
        Cell: cell => {
          if (searchData.length > 0) return false
          return (
            <div className="edit-buttons">
              <span className="index">{cell.viewIndex + 1}</span>
              <button className="remove-button" onClick={() => this.handleRemoveUser(cell.index)}>Remove</button>
            </div>
          )
        },
        show: firstStep
      },
      {
        Header: <div className="action-title">Actions</div>,
        sortable: false,
        show: !firstStep,
        Cell: cell => {
          const id = cell.index
          return (
            <div className="edit-buttons">
              <span className="index">{cell.viewIndex + 1}</span>
              <button className="remove-button" onClick={() => this.handleRemoveUser(id)}>Remove</button>
            </div>
          )
        }
      }
    ]

    const maxApps = this.maxAppsOrder()

    const pageSize = profile && (profile.currentPlanMaxUsers - profile.numberOfVehicles)
    // eslint-disable-next-line camelcase
    if (creation_locked === true) return <Redirect to={{ pathname: '/profile/user-management-and-ordering/' }}/>

    let appNextButtonText

    if (appsActiveStep === 0) {
      appNextButtonText = 'Next >'
    } else if (appsActiveStep === 3) {
      appNextButtonText = 'Done'
    } else {
      appNextButtonText = 'Order >'
    }

    const styles = this.getStyles()

    return (
      <Layout
        title="Add Users"
        optionsData={options}
        isSuperLayout={true}
      >
        {isWeb && <style>{ csvReaderCss }</style>}
        {error && <Notification>{error}</Notification>}
        {tier.length === 0 && !error ? (
          <LoadingIndicator/>
        ) : (
          !error &&
          <View>
            {tier > 1 && <React.Fragment>
              <StepsWrapper style={styles.StepsWrapper}>
                <Steps steps={steps} active={active}/>
              </StepsWrapper>

              {firstStep
                ? <React.Fragment>
                  <Progress.Wrapper style={styles.Progress.Wrapper}>
                    <H1>Active Users</H1>
                    <Progress.Bar progress={profile.numberOfVehicles}
                      new={(data.length / profile.currentPlanMaxUsers) * 100}
                      max={profile.currentPlanMaxUsers}>
                      <Progress.NewUsers style={styles.Progress.NewUsers}/>
                    </Progress.Bar>
                    <Progress.TextWrapper style={styles.Progress.TextWrapper}>
                      <Progress.Text exceedsMediumBreakpoint={exceedsMediumBreakpoint} amount={profile.numberOfVehicles}
                        colour={colours.primary}><Bold>Current:</Bold> {profile.numberOfVehicles}
                      </Progress.Text>
                      <Progress.Text exceedsMediumBreakpoint={exceedsMediumBreakpoint} new colour={colours.urgent}><Bold>New:</Bold> {data.length}</Progress.Text>
                      <Progress.Text exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                        <Bold>Limit:</Bold> {profile.currentPlanMaxUsers}
                      </Progress.Text>
                    </Progress.TextWrapper>
                  </Progress.Wrapper>
                  <Search.Wrapper style={styles.Search.Wrapper}>
                    <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} style={styles.Search.P}>Review the new Users below and make any required edits.<br/>When you’re ready to confirm, click
                      Next.</P>
                    <TableSearch
                      id="search"
                      value={search}
                      onChange={e => this.setState({ search: e.target.value })}
                      onSubmit={e => this.handleSearch(e)}
                      onReset={() => this.setState({ search: '', searchData: [] })}
                    />
                  </Search.Wrapper>
                </React.Fragment>
                : <Search.Wrapper style={styles.Search.Wrapper}>
                  <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} style={styles.Search.P}>Review the new Users below and make any required edits. When you’re ready, click Confirm.</P>
                </Search.Wrapper>
              }

              <Users.Wrapper exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                <Users.Header exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                  <H1>Add users</H1>
                  {firstStep &&
                  <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Select Add another User to add another user. You can create multiple users on this screen.</P>}
                </Users.Header>
                <DataTable
                  columns={columns}
                  data={searchData.length > 0 ? searchData : data}
                  showPagination={false}
                  minRows={0}
                  className={!firstStep && 'confirm-table'}
                  resizable={false}
                  pageSize={pageSize}
                />
                {firstStep
                  ? <React.Fragment>
                    <Users.Buttons exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                      <Users.Add exceedsMediumBreakpoint={exceedsMediumBreakpoint} onPress={() => this.handleAddUser()} active={addEnabled}>+ Add User</Users.Add>
                    </Users.Buttons>
                    <Users.ButtonsSmall exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                      <Users.Add exceedsMediumBreakpoint={exceedsMediumBreakpoint} onPress={() => this.handleAddUser()} active={addEnabled}>+ Add another User</Users.Add>
                      <Users.Next exceedsMediumBreakpoint={exceedsMediumBreakpoint} onPress={() => this.handleNext()} disabled={nextDisabled}>Next</Users.Next>
                    </Users.ButtonsSmall>
                  </React.Fragment>
                  : <Users.ButtonsSmall exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                    <Controls.Back onPress={() => this.handlePrevious()}>‹ Back</Controls.Back>
                    <Controls.Save exceedsMediumBreakpoint={exceedsMediumBreakpoint} disabled={confirmDisabled} onPress={() => this.handleConfirm()}>Confirm
                      &gt;</Controls.Save>
                    <Controls.ConfirmMessageSmall exceedsMediumBreakpoint={exceedsMediumBreakpoint}>By clicking the Confirm button you confirm that you have received
                      permission from the email address
                      that we may send communications to that address.</Controls.ConfirmMessageSmall>
                  </Users.ButtonsSmall>
                }
              </Users.Wrapper>
              {notification && <Notification type={notificationType} alignRight={notificationAlignRight}>{notification}</Notification>}
              <Controls.WrapperWrapper exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                <Controls.Wrapper exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                  <Controls.Left>
                    <Controls.Back firstStep={firstStep}
                      onPress={() => this.handlePrevious()}>{firstStep ? '‹ Back' : '‹ Edit'}</Controls.Back>
                  </Controls.Left>
                  <Controls.Right exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                    {firstStep
                      ? <Controls.Next exceedsMediumBreakpoint={exceedsMediumBreakpoint} onPress={() => this.handleNext()}
                        disabled={nextDisabled}>Next &gt;</Controls.Next>
                      : <Controls.Save exceedsMediumBreakpoint={exceedsMediumBreakpoint} disabled={confirmDisabled} onPress={() => this.handleConfirm()}>Confirm
                        &gt;</Controls.Save>
                    }
                  </Controls.Right>
                </Controls.Wrapper>
                {
                  !firstStep &&
                  <Controls.ConfirmMessage exceedsMediumBreakpoint={exceedsMediumBreakpoint}>By clicking the Confirm button you confirm that you have received permission
                    from the email address
                    that we may send communications to that address.</Controls.ConfirmMessage>
                }
              </Controls.WrapperWrapper>
            </React.Fragment>}

            {firstStep &&
            <React.Fragment>
              {tier > 1 && <React.Fragment>
                <Options.Wrapper exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                  <H1>Upload Users</H1>
                  <Options.Details exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                    <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Upload a CSV file for import of up to 100 users. Download the <a href="/csv-template.csv" style={detailsAStyle} download>CSV
                    template</a> for help. For uploads over {maxUsers} users, please use the Bulk Upload below.</P>
                    <Options.Button exceedsMediumBreakpoint={exceedsMediumBreakpoint} active={addEnabled} onPress={() => this.handleModalOpen('upload')}>Upload Users</Options.Button>
                  </Options.Details>
                </Options.Wrapper>

                <Options.Wrapper exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                  <H1>Bulk Upload</H1>
                  <Options.Details exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                    <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Use this to upload a CSV file with over {maxUsers} users. Download the <a href="/csv-template.csv" style={detailsAStyle} download>CSV
                      template</a> for help. The file will be reviewed and user creation may take 3-4 business days.</P>
                    <Options.Button exceedsMediumBreakpoint={exceedsMediumBreakpoint} active={addEnabled} onPress={() => this.handleModalOpen('bulk_upload')}>Bulk
                      Upload</Options.Button>
                  </Options.Details>
                </Options.Wrapper>
              </React.Fragment>
              }

              <Options.MultiWrap exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                {(tier < 3 || tier === 4) &&
                <Options.Wrapper exceedsMediumBreakpoint={exceedsMediumBreakpoint} style={multiWrapChildStyle}>
                  <H1>Blank Cards</H1>
                  <Options.Details exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                    {tier === 4 ? <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>This will order blank cards. The card ID and PIN can be used to log into the
                      App</P> : <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Don’t know the contact details? You can order blank cards</P>}
                    <Options.Secondary exceedsMediumBreakpoint={exceedsMediumBreakpoint} onPress={() => this.handleModalOpen('cards')}>Order Cards</Options.Secondary>
                  </Options.Details>
                </Options.Wrapper>
                }
                {(tier >= 3) && <Options.Wrapper exceedsMediumBreakpoint={exceedsMediumBreakpoint} style={multiWrapChildStyle}>
                  <H1>App Logins</H1>
                  <Options.Details exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                    <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Don’t know the contact details? Download app logins and distribute them yourself.</P>
                    <Options.Secondary exceedsMediumBreakpoint={exceedsMediumBreakpoint} onPress={() => this.handleModalOpen('apps')}>Download</Options.Secondary>
                  </Options.Details>
                </Options.Wrapper>
                }
              </Options.MultiWrap>
            </React.Fragment>
            }
            {modal.upload &&
            <Modal
              h1="Upload Users"
              close={() => this.handleModalClose('upload')}
              extra={
                <M.Step>
                  {csvUploaded
                    ? <M.P>Please check the first 5 lines then click Upload to proceed.</M.P>
                    : <React.Fragment>
                      <M.P>Select a CSV file to upload</M.P>
                      <div className={csvReaderClassName}>
                        {resetCsvReader && <CSVReader
                          cssClass="csv-reader-input"
                          label="Choose a file"
                          onFileLoaded={data => this.handleCsvUpload(data)}
                          onError={err => this.handleCsvError(err)}
                          inputId="csv"
                        />}
                      </div>
                    </React.Fragment>
                  }
                  {csvError &&
                  <M.Error>{customCsvMessage || 'There was an issue with your file, please try again with another file and make sure it matches the CSV template.'}</M.Error>}
                  {csvUploaded && <M.Table
                    columns={csvData.columns}
                    data={csvData.data}
                    showPagination={false}
                    minRows={0}
                    resizable={false}
                  />}
                </M.Step>
              }
            >
              <ModalClose onPress={() => this.handleModalClose('upload')}>Cancel</ModalClose>
              <M.Upload uploaded={csvUploaded} onPress={() => this.handleCsvSubmit()}>Upload ›</M.Upload>
            </Modal>
            }

            {modal.bulk_upload &&
            <Modal
              h1="Bulk Upload"
              close={() => {
                if (isBulkUploading) { return }
                this.handleModalClose('bulk_upload')
              }
              }
              extra={
                <React.Fragment>
                  {(!isBulkUploading && !bulkUploadComplete) && <M.Step>
                    {csvUploaded
                      ? <M.P>Please check the first 5 lines then click Upload to proceed.</M.P>
                      : <React.Fragment>
                        <M.P>Select a CSV file to upload</M.P>
                        <div className={csvReaderClassName}>
                          {resetCsvReader && <CSVReader
                            cssClass="csv-reader-input"
                            label="Choose a file"
                            onFileLoaded={(data, fileInfo) => this.handleCsvUpload(data, true, fileInfo)}
                            onError={err => this.handleCsvError(err, true)}
                            inputId="bulk_csv"
                          />}
                        </div>
                      </React.Fragment>
                    }
                    {csvError &&
                  <M.Error>Error: {customCsvMessage || 'There was an issue with your file, please try again with another file and make sure it matches the CSV template.'}</M.Error>}
                    {(!isBulkUploading && csvUploaded) && <M.Table
                      columns={csvData.columns}
                      data={csvData.data}
                      showPagination={false}
                      minRows={0}
                      resizable={false}
                    />}
                  </M.Step>}
                  {isBulkUploading && <M.Step>
                    <M.P>Please wait while uploading.</M.P>
                    <LoadingIndicator/>
                  </M.Step>
                  }
                  {bulkUploadComplete && <M.Step>
                    <M.P>Upload complete. We will email you once your users have been added.</M.P>
                  </M.Step>
                  }
                </React.Fragment>
              }
              linksStyle={bulkUploadComplete ? { justifyContent: 'flex-end' } : {}}
            >
              {!bulkUploadComplete && <React.Fragment>
                <ModalClose disabled={isBulkUploading} onPress={() => this.handleModalClose('bulk_upload')}>
                  {bulkUploadComplete ? 'Close' : 'Cancel'}
                </ModalClose>
                <M.Upload uploaded={csvUploaded} disabled={isBulkUploading} onPress={() => this.handleCsvSubmit(true)}>Upload
                  ›</M.Upload>
              </React.Fragment>
              }
              {bulkUploadComplete &&
                  <M.Upload style={{ backgroundColor: colours.primary }} disabled={isBulkUploading} onPress={() => this.handleModalClose('bulk_upload')}>Done</M.Upload>
              }
            </Modal>
            }

            {modal.cards &&
            <Modal
              h1="Order Blank Cards"
              close={() => this.handleModalClose('cards')}
              extra={
                <React.Fragment>
                  {cardsActiveStep === 0 && <M.Step>
                    <M.P>How many blank cards would you like to
                      order? {tier < 2 && `It must be in multiples of 8.${profile.availableVehicles && ` You currently have up to ${profile.availableVehicles} cards available to order.`}`}</M.P>
                    <M.Amount placeholder={`Maximum ${this.maxCards(tier, profile.availableVehicles)}`}
                      max={this.maxCards(tier, profile.availableVehicles)} type={'integer'}
                      value={cardsOrdered === 0 ? null : cardsOrdered}
                      onChange={e => this.handleOrderCardsChange(e)}/>
                    {tier < 2 && <M.B>Total cards to be ordered: {cardsOrderedOutput}</M.B>}
                  </M.Step>}
                  {cardsActiveStep === 1 && <M.Step>
                    <M.P>You are about to order {tier < 2 ? cardsOrderedOutput : cardsOrdered} blank cards. Are you sure
                      you want to do this?</M.P>
                    {modalNotification && <Notification type={modalNotificationType}>{modalNotification}</Notification>}
                  </M.Step>}
                </React.Fragment>
              }
            >
              <ModalClose onPress={() => this.handleModalClose('cards')}>Cancel</ModalClose>
              <Primary disabled={(tier < 2 && cardsOrderedOutput > profile.availableVehicles) || cardsOrderedDisabled}
                onPress={() => this.handleOrderCards()}>{cardsActiveStep === 0 ? 'Next ›' : 'Order ›'}</Primary>
            </Modal>
            }
            {modal.logins &&
            <Modal
              h1="Download App Logins"
              close={() => this.handleModalClose('logins')}
              extra={
                <React.Fragment>
                  {loginsActiveStep === 0 && <M.Step>
                    <M.P>How many app logins would you like to download?</M.P>
                    <M.Amount value={loginsOrdered}
                      onChange={e => this.setState({ loginsOrdered: isNaN(e.target.value) ? 0 : e.target.value })}/>
                  </M.Step>}
                  {loginsActiveStep === 1 && <M.Step>
                    <M.P>You are about to download {loginsOrdered} app login{loginsOrdered !== '1' ? 's' : ''}. Are you
                      sure you want to do this?</M.P>
                    {modalNotification && <Notification type={modalNotificationType}>{modalNotification}</Notification>}
                  </M.Step>}
                </React.Fragment>
              }
            >
              <ModalClose onPress={() => this.handleModalClose('logins')}>Cancel</ModalClose>
              <Primary
                onPress={() => this.handleOrderLogins()}>{loginsActiveStep === 0 ? 'Next ›' : 'Download ›'}</Primary>
            </Modal>
            }
            {modal.apps &&
            <Modal
              h1="Order Blank App Logins"
              close={() => this.handleModalClose('apps')}
              linksStyle={appsActiveStep === 3 ? { justifyContent: 'flex-end' } : {}}
              extra={
                <React.Fragment>
                  {appsActiveStep === 0 && <M.Step>
                    <M.P>How many blank app logins would you like to
                      order?
                    </M.P>
                    <M.P>
                      You can order up to {`${maxApps}`} per request.
                    </M.P>
                    <M.Amount placeholder={`Maximum ${maxApps}`}
                      max={maxApps} type={'integer'}
                      value={appsOrderedOutput === 0 ? '' : appsOrderedOutput}
                      onChange={e => this.handleOrderAppsChange(e)}/>
                    <M.B>Total to be ordered: {appsOrderedOutput}</M.B>
                  </M.Step>}
                  {appsActiveStep === 1 && <M.Step>
                    <M.P>You are about to order and download {appsOrdered} app login{ appsOrdered > 1 ? 's' : '' }. Are you sure
                      you want to do this?</M.P>
                  </M.Step>}
                  {appsActiveStep === 2 && <M.Step>
                    <M.P style={orderProcessLoadingContainerStyle}>
                      Preparing Download
                      <LoadingIndicator style={orderProcessLoadingIndicatorStyle} size="small"/>
                    </M.P>
                    <ProgressBar max={appsOrderedOutput} progress={appsOrderedCompleted}/>
                    {modalNotification && <Notification type={modalNotificationType}>{modalNotification}</Notification>}
                  </M.Step>}
                  {appsActiveStep === 3 && <M.Step>
                    <M.P>
                      Download Ready
                    </M.P>
                    <M.P>
                      Your app logins have been ordered, check your downloads folder for the CSV list.
                    </M.P>
                  </M.Step>
                  }
                </React.Fragment>
              }
            >
              {appsActiveStep !== 3 && <ModalClose onPress={() => { this.setState({ appsActiveStep: 0 }); this.handleModalClose('apps') }}>Cancel</ModalClose>}
              <Primary disabled={(tier < 3) || maxApps === 0 || appsActiveStep === 2}
                onPress={() => this.handleOrderApps()}>{appNextButtonText}</Primary>

            </Modal>
            }
          </View>
        )}
      </Layout>
    )
  }
}
