import React from 'react'
import Layout from './components/Layout'
import { Text, View, Dimensions, StyleSheet } from 'react-native'
import isWeb from './helpers/isWeb'
import getDeviceId from './helpers/deviceId'
import { colours, headingStyles, subHeadingStyles } from './styles/constants'
import ListOffer from './components/ListOffer'
import ListOffers from './components/ListOffers'
import storage from './helpers/storage'
import api from './helpers/boost-client-js-library/api'
import noData from './helpers/noData'
import TableSearch from './components/TableSearch'
import LoadingIndicator from './components/LoadingIndicator'
import Notification from './components/Notification'
import 'abortcontroller-polyfill'
import TrackVisibility from 'react-on-screen'
import InViewPort from './components/InViewPort'
import checkRole from './helpers/checkRole'
import EntityFacade, { TEMP_MAX_ITEMS } from './helpers/EntityFacade'
import ConnectionManager from './helpers/ConnectionManager'
import PropTypes from 'prop-types'
import moment from 'moment'
import UpdateMessage from './components/UpdateMessage'
import BreakpointWatcher from './helpers/BreakpointWatcher'
import offerCartAddable from './helpers/offerCartAddable'
import { preCacheOffers } from './helpers/preCache'
import consoleLog from './helpers/consoleLog'
import RetailerOffersHeader from './components/RetailerOffersHeader'
import eventBus from './helpers/eventBus'

const { height } = Dimensions.get('window')

const wrapperStyles = StyleSheet.create({
  wrapperAboveMedium: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  categoryWrapper: {
    marginBottom: 12
  }
})

class HeaderWrapper extends React.Component {
  render () {
    const styles = []
    const {
      children,
      categorywrapper,
      exceedsMediumBreakpoint
    } = this.props

    if (isWeb) {
      if (exceedsMediumBreakpoint) {
        styles.push(wrapperStyles.wrapperAboveMedium)
      }
    } else if (categorywrapper) {
      styles.push(wrapperStyles.categoryWrapper)
    }

    return <View style={styles}>
      {children}
    </View>
  }
}

const headerHeadingStyles = StyleSheet.create({
  heading: {
    fontFamily: headingStyles.fontFamily,
    fontSize: headingStyles.fontSize,
    color: 'black'
  },
  headingLarge: {
    fontSize: headingStyles.fontSizeLarge
  },
  headingApp: {
    color: colours.primary
  },
  headingFeaturedWebSmall: {
    textAlign: 'center',
    position: 'relative',
    marginBottom: 20
  },

  categoryTitle: {
    color: 'black',
    fontSize: 17,
    marginTop: 10,
    marginBottom: -5
  }
})

const webFeaturedStyles = isWeb ? StyleSheet.create(
  {
    headingFeaturedWebSmallAfter: {
      backgroundImage: `url(${require('./images/hand-swipe.png')})`,
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 0,
      backgroundPositionY: 4,
      backgroundSize: '24px',
      paddingVertical: 5,
      paddingHorizontal: 0,
      paddingLeft: 35,
      display: 'inline-block',
      marginTop: -15,
      fontFamily: 'greycliff',
      fontSize: 16,
      color: colours.black60,
      position: 'absolute',
      left: '50%',
      transform: [{ translateX: '-50%' }],
      top: '100%',
      width: 190
    }
  }
) : null

class HeaderHeading extends React.Component {
  render () {
    const {
      children,
      type,
      inSmallBreakpoint,
      exceedsMediumBreakpoint,
      exceedsLargeBreakpoint,
      categorytitle,
      ...rest
    } = this.props

    const styles = [headerHeadingStyles.heading]

    if (isWeb) {
      if (exceedsLargeBreakpoint) {
        styles.push(headerHeadingStyles.headingLarge)
      }

      if (type === 'featured' && inSmallBreakpoint) {
        styles.push(headerHeadingStyles.headingFeaturedWebSmall)
      }
    } else {
      styles.push(headerHeadingStyles.headingApp)

      if (categorytitle) {
        styles.push(headerHeadingStyles.categoryTitle)
      }
    }

    return <React.Fragment>
      <Text style={styles} {...rest}>
        {children}
      </Text>
      {inSmallBreakpoint && type === 'featured' && <Text style={webFeaturedStyles.headingFeaturedWebSmallAfter}>
        Swipe to see more!
      </Text>}
    </React.Fragment>
  }
}

const headerSubHeadingStyles = StyleSheet.create({
  subHeading: {
    fontFamily: subHeadingStyles.fontFamily,
    fontSize: subHeadingStyles.fontSize,
    color: colours.black60
  }
})

class HeaderSubHeading extends React.Component {
  render () {
    const {
      children,
      ...rest
    } = this.props

    return <Text style={headerSubHeadingStyles.subHeading} {...rest}>
      {children}
    </Text>
  }
}

const searchStyles = StyleSheet.create({
  root: {
    alignItems: 'flex-end'
  },
  input: {
    maxWidth: 200
  },
  rootWebSmall: {
    alignItems: 'flex-start',
    marginBottom: 20
  }

})

class Search extends React.Component {
  render () {
    const {
      children,
      inSmallBreakpoint
    } = this.props

    const styles = [searchStyles.root]

    if (inSmallBreakpoint) {
      styles.push(searchStyles.rootWebSmall)
    }

    return <View style={styles}>
      {children}
    </View>
  }
}

const categoryCompare = (a, b) => {
  const aName = a.name.toLowerCase()
  const bName = b.name.toLowerCase()

  if (aName > bName) { return 1 }
  if (bName > aName) { return -1 }

  return 0
}

export const categoryList = (categories) => {
  categories = categories.sort(categoryCompare)

  return categories.map(item => {
    item.id = item.ID
    item.title = item.name
    item.link = `/offers/categories/${item.ID}/${encodeURIComponent(item.name.toLowerCase())}`
    return item
  })
}

class CategoryOffers extends React.Component {
  static propTypes = {
    searching: PropTypes.bool,
    handleCartEvent: PropTypes.func,
    items: PropTypes.array,
    options: PropTypes.array,
    getCategoryOffers: PropTypes.func,

    appKey: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
  }

  state = {
    loadOffers: true,
    categoryOffers: {},
    categoryNext: {},
    maxVisible: 0
  }

  categoryOffersRequested = {}

  shouldComponentUpdate () {
    return !(isWeb && this.props.searching)
  }

  handleCartEvent = (e, item) => {
    this.props.handleCartEvent(e, item)
    this.setState({ loadOffers: false })
  }

  renderListOffer = (item, i, isLarge) => {
    const { items, isOnline } = this.props
    const isInCart = items.filter(i => i.ID === item.ID).length > 0
    return <ListOffer key={i} isInCart={isInCart} isLarge={isLarge} isOnline={isOnline}
      canAddToCart={offerCartAddable(item)}
      cartAddOrRemove={e => this.handleCartEvent(e, item)} {...item} />
  }

  updateVisibleIndex = (maxVisible) => {
    const newMaxVisible = maxVisible + 1
    if (newMaxVisible > this.state.maxVisible) { this.setState({ maxVisible: newMaxVisible }) }
  }

  render () {
    const {
      options,
      getCategoryOffers,
      inSmallBreakpoint,
      exceedsMediumBreakpoint,
      exceedsLargeBreakpoint,
      isOnline
    } = this.props
    const {
      loadOffers,
      categoryOffers,
      categoryNext,
      maxVisible
    } = this.state

    return <React.Fragment>
      {options.map((categoryObject, index) => {
        const categoryId = categoryObject.ID
        if (typeof (this.categoryOffersRequested[categoryId]) === 'undefined') {
          this.categoryOffersRequested[categoryId] = true
          getCategoryOffers(categoryObject).then(res => {
            let items = []
            let next = null
            if (res !== null && res !== undefined) {
              if (res.items) {
                items = res.items
              }
              next = res.next
            }
            this.setState(prevState => {
              prevState.categoryOffers[categoryId] = items
              prevState.categoryNext[categoryId] = next
              return prevState
            })
          })
          return null
        }

        const offersForCategory = typeof (categoryOffers[categoryId]) === 'undefined' ? [] : categoryOffers[categoryId]
        const nextForCategory = typeof (categoryNext[categoryId]) === 'undefined' ? null : categoryNext[categoryId]

        return isWeb
          ? <TrackVisibility key={index} once>
            {({ isVisible }) =>
              <ListOffers
                isOnline={isOnline}
                offerRenderer={this.renderListOffer}
                last={(index + 1) === options.length ? 1 : 0}
                offers={isVisible && offersForCategory}
                next={isVisible && nextForCategory}
                loadOffers={loadOffers}
              >
                <HeaderWrapper
                  exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                >
                  <HeaderHeading
                    type="all"
                    categorytitle
                    inSmallBreakpoint={inSmallBreakpoint}
                    exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                    exceedsLargeBreakpoint={exceedsLargeBreakpoint}
                  >{categoryObject.title}</HeaderHeading>
                </HeaderWrapper>
              </ListOffers>
            }
          </TrackVisibility>
          : <InViewPort key={index}
            catIndex={index}
            maxVisible={maxVisible}
            updateVisibleIndex={(visibleIndex) => this.updateVisibleIndex(visibleIndex)}
            render={isVisible =>
              <ListOffers
                isOnline={isOnline}
                offer={this.renderListOffer}
                last={(index + 1) === options.length}
                offers={isVisible && offersForCategory}
                next={isVisible && nextForCategory}
              >
                <HeaderWrapper
                  exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                >
                  <HeaderHeading
                    type="all"
                    categorytitle
                    inSmallBreakpoint={inSmallBreakpoint}
                    exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                    exceedsLargeBreakpoint={exceedsLargeBreakpoint}
                  >{categoryObject.title}</HeaderHeading>
                </HeaderWrapper>
              </ListOffers>
            }
          />
      })
      }
      {(!isWeb && maxVisible < options.length) && <LoadingIndicator/>}
    </React.Fragment>
  }
}

export default class Offers extends React.Component {
  static propTypes = {
    searching: PropTypes.bool,
    handleCartEvent: PropTypes.func,
    items: PropTypes.object,
    options: PropTypes.object,
    getCategoryOffers: PropTypes.func,
    match: PropTypes.object,
    appKey: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
  }

  state = {
    pageTitle: ' ',
    featuredOffers: [],
    offers: [],
    options: [],
    cartItems: [],
    isCategoryPage: this.props.match.path.includes('/offers/categories/:id'),
    id: this.props.match.params.id,
    offersPerLoad: 5,
    search: '',
    searching: false,
    loading: true,
    error: null,
    boostUpdateMessage: null,
    name: '',
    inSmallBreakpoint: false,
    exceedsMediumBreakpoint: false,
    exceedsLargeBreakpoint: false,
    isOnline: isWeb ? true : null,
    retailerId: this.props.match.params.retailerId
  }

  mounted = false
  preCacheComplete = false
  controller = new AbortController()
  offersByCategory = {}

  constructor (props) {
    super(props)
    this.layoutRef = React.createRef()
  }

  setupBoostUpdateMessage = (access, isOnline) => {
    const ef = new EntityFacade(isOnline, access)
    // Boost update message
    // We want the latest data so don't cache this

    ef.getVehicle(access, true).then(res => {
      if (res === null) { return }
      if (res.startupMessage && res.startupStartDate && res.startupEndDate) {
        const message = res.startupMessage
        const startDate = moment.utc(res.startupStartDate).toDate()
        const endDate = moment.utc(res.startupEndDate).toDate()
        const today = moment.utc().toDate()

        if (today >= startDate && today <= endDate) {
          this.setState({
            boostUpdateMessage: message
          })
        }
      }
    })
  }

  getData = () => {
    const {
      appKey,
      history
    } = this.props

    const { retailerId } = this.state

    const offersPerLoad = TEMP_MAX_ITEMS

    // this.props.match.params.name is partially encoded (spaces not encoded) hence this weird re-encode
    const categoryName = decodeURIComponent(this.props.match.params.name)
    const categoryIdFilter = this.props.match.params.name ? `&category=${encodeURIComponent(categoryName)}` : ''
    const signal = this.controller.signal

    storage.token.get(appKey).then(token => {
      const access = {
        token,
        device: getDeviceId()
      }
      if (!isWeb && !this.preCacheComplete) {
        preCacheOffers(access, false).then(() => {
          this.preCacheComplete = true
          consoleLog('Remaining offers precached')
        })
      }

      ConnectionManager.getManager().checkApiConnectivity().then((isOnline) => {
        const ef = new EntityFacade(isOnline, access)

        if (isWeb) {
          api.profile.get(token).then(profile => {
            if (!checkRole(profile.roles).includes('su')) {
              history.push('/profile/dashboard')
              return false
            } else {
              this.setState({ userID: profile.ID })
            }
          })
        } else {
          this.setupBoostUpdateMessage(access, isOnline)
        }
        // featured offers
        if (!retailerId) {
          ef.getOffers(signal, `offset=0&limit=${5}&featured=true${categoryIdFilter}`).then(offers => {
            if (!this.mounted) { return }

            let featuredOffers = []
            let featureMore = null

            if (offers !== null && offers.items && offers.items.length !== 0) {
              featuredOffers = offers.items
              featureMore = offers.next
            }

            this.setState({
              featuredOffers,
              featureMore,
              loading: false
            })
          })
        }

        if (retailerId) {
          // we don't know if the retailer ID is for an internal or external supplier so fetch both, one will 404
          Promise.all([api.suppliers.single({ token }, retailerId), api.suppliers.singleExternal({ token }, retailerId)]).then(([supplierData, externalSupplierData]) => {
            let retailer
            let offerFetchMethod

            if (supplierData.message && externalSupplierData.message) {
              // contains an error message on 404, so this means both are not found
              history.push('/retailers')
              return
            }

            if (externalSupplierData.message) {
              retailer = supplierData
              offerFetchMethod = api.suppliers.singleOffers
            } else {
              retailer = externalSupplierData
              offerFetchMethod = api.suppliers.singleExternalOffers
            }
            this.setState({
              retailer
            })
            offerFetchMethod({ token }, retailerId).then(offers => {
              if (offers === undefined || offers === null || (!offers && !offers.items)) return false
              this.mounted && this.setState({
                offers: offers.items,
                loading: false,
                pageTitle: `${retailer.tradingName} Offers`
              })
            })
          })
        } else {
          ef.getOfferCategories().then((categories) => {
            if (noData(categories.items)) {
              return
            }

            const categoriesWithOffers = []

            categories.items.forEach((categoryObject) => {
              const categoryNameEncoded = encodeURIComponent(categoryObject.name.toLowerCase())
              let offersReq
              if (isWeb) {
                offersReq = ef.getOffers(
                  this.controller.signal,
                  `offset=0&limit=${offersPerLoad}&category=${categoryNameEncoded}`
                )
              } else {
                offersReq = ef.getOffersInCategory(this.controller.signal, categoryObject.ID, offersPerLoad)
              }
              offersReq.then((offersResponse) => {
                if (offersResponse == null) {
                  return
                }

                // the structure is different based on the offer getting method. getOffers returns a response and
                // filterOffers returns an array
                const hasOffers = (isWeb && offersResponse.totalCount !== 0) || (!isWeb && offersResponse.length > 0)

                if (hasOffers) {
                  const offersInCategory = isWeb ? offersResponse.items : offersResponse
                  categoriesWithOffers.push(categoryObject)
                  this.offersByCategory[categoryObject.ID] = offersInCategory
                  this.mounted && this.setState({
                    options: categoryList(categoriesWithOffers)
                  })
                  this.categoriesLoaded(categoryIdFilter, categoriesWithOffers)
                }
              })
            })
          })

          // single category offers
          if (categoryIdFilter !== '') {
            ef.getOffers(signal, `offset=0&limit=${offersPerLoad}${categoryIdFilter}`).then(offers => {
              if (offers === undefined || offers === null || (!offers && !offers.items)) return false
              this.mounted && this.setState({
                offers: offers.items,
                offersMore: offers.next
              })
            })
          }
        }
      })
    })
  }

  categoriesLoaded (categoryIdFilter, categories) {
    // single category title
    if (categoryIdFilter) {
      const categoryId = this.props.match.params.id
      const singleCategory = categoryList(categories).filter(i => i.id === categoryId)
      const pageTitle = singleCategory.length && singleCategory[0] && singleCategory[0].name ? singleCategory[0].name : ''
      this.mounted && this.setState({
        pageTitle
      })
    } else {
      this.mounted && this.setState({
        pageTitle: `${isWeb ? 'Boost ' : ''}Offers`
      })
    }
  }

  getCategoryOffers = async categoryObject => {
    /**
     * Get the offers for a particular Category
     */
    return {
      items: this.offersByCategory[categoryObject.ID],
      next: null
    }
  }

  componentDidMount () {
    this.mounted = true
    BreakpointWatcher.addComponent(this)
    if (!isWeb) {
      const { appKey } = this.props
      ConnectionManager.getManager().checkApiConnectivity().then((isOnline) => {
        this.setState({ isOnline })
      })
      storage.token.get(appKey).then(token => {
        const access = {
          token,
          device: getDeviceId()
        }
        const ef = new EntityFacade(false, access)
        ef.getProfile(access).then((profile) => {
          if (profile == null) return

          const firstName = profile.firstName
          this.setState({ name: firstName })
        })
      })
    }

    this.getData()
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
    this.mounted = false
    this.controller.abort()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.getData()
    }
  }

  handleCartEvent = (isAdd, offer) => {
    eventBus.dispatch('cartEvent', { offer, isAdd })
  }

  handleSearch = e => {
    e.preventDefault()
    // search function, web only
    const {
      offersPerLoad,
      search
    } = this.state
    const signal = this.controller.signal
    const searchValue = search ? `&search_query=${search}` : ''
    this.setState({ loading: true })
    storage.token.get().then(token => {
      // filter featured
      api.offers.get(token, signal, `offset=0&limit=${offersPerLoad}&featured=true${searchValue}`).then(offers => {
        if (offers.items === undefined) return false
        this.setState({
          featuredOffers: offers.items,
          loading: false,
          searching: false
        })
      })
    })
  }

  handleReset = () => {
    // reset search function, web only
    this.setState({
      search: '',
      searching: false,
      loading: true
    })
    this.getData()
  }

  renderListOffer = (item, i, isLarge) => {
    const {
      cartItems,
      isOnline
    } = this.state
    const isInCart = cartItems.filter(i => i.ID === item.ID).length > 0
    return <ListOffer key={i} isInCart={isInCart} isLarge={isLarge} isOnline={isOnline}
      canAddToCart={offerCartAddable(item)}
      cartAddOrRemove={isAdd => this.handleCartEvent(isAdd, item)} {...item} />
  }

  render () {
    const {
      pageTitle,
      options,
      featuredOffers,
      featuredMore,
      cartItems,
      offers,
      offersMore,
      isCategoryPage,
      search,
      searching,
      loading,
      error,
      userID,
      boostUpdateMessage,
      inSmallBreakpoint,
      exceedsMediumBreakpoint,
      exceedsLargeBreakpoint,
      isOnline,
      retailerId,
      retailer
    } = this.state

    return (
      <Layout
        title={pageTitle}
        optionsTitle="Offer categories"
        optionsClassName="categories"
        optionsData={ retailerId != null ? null : options }
        categorySearch={{ categories: options }}
        cart={{
          onCartItemsLoaded: (cartItems) => { this.setState({ cartItems }) },
          userID
        }}
        style={!isWeb && {
          backgroundColor: colours.lighterGrey,
          minHeight: height
        }}
      >
        {error && <Notification>{error}</Notification>}
        {isWeb && !isCategoryPage && inSmallBreakpoint && this.getSearch(inSmallBreakpoint, search)}
        {loading && !error
          ? <LoadingIndicator/>
          : !error && <React.Fragment>
            {!isWeb && <UpdateMessage boostUpdateMessage={boostUpdateMessage}/>}

            <ListOffers isOnline={isOnline} offers={featuredOffers} next={featuredMore} offerRenderer={this.renderListOffer} isLarge={true} shouldCarousel>
              <HeaderWrapper
                exceedsMediumBreakpoint={exceedsMediumBreakpoint}
              >
                <HeaderHeading
                  type="featured"
                  inSmallBreakpoint={inSmallBreakpoint}
                  exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                  exceedsLargeBreakpoint={exceedsLargeBreakpoint}
                >Featured Offers</HeaderHeading>
                {isWeb && !isCategoryPage && exceedsMediumBreakpoint && this.getSearch(inSmallBreakpoint, search)}
              </HeaderWrapper>
            </ListOffers>

            {!isWeb && !isCategoryPage &&
          <HeaderWrapper categorywrapper
            exceedsMediumBreakpoint={exceedsMediumBreakpoint}
          >
            <HeaderHeading
              type="all"
              inSmallBreakpoint={inSmallBreakpoint}
              exceedsMediumBreakpoint={exceedsMediumBreakpoint}
              exceedsLargeBreakpoint={exceedsLargeBreakpoint}
            >All offers</HeaderHeading>
            <HeaderSubHeading>Your Boost perks, all in one place.</HeaderSubHeading>
          </HeaderWrapper>
            }

            {isCategoryPage || retailerId
              ? <ListOffers isOnline={isOnline} offers={offers} next={offersMore} offerRenderer={this.renderListOffer}>
                <HeaderWrapper
                  exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                >
                  <HeaderHeading
                    type="all"
                    inSmallBreakpoint={inSmallBreakpoint}
                    exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                    exceedsLargeBreakpoint={exceedsLargeBreakpoint}
                  >{pageTitle}</HeaderHeading>
                </HeaderWrapper>
                {retailerId && <RetailerOffersHeader retailer={retailer}/>}
              </ListOffers>
              : <CategoryOffers
                options={options}
                items={cartItems}
                getCategoryOffers={this.getCategoryOffers}
                renderListOffer={this.renderListOffer}
                searching={searching}
                handleCartEvent={this.handleCartEvent}
                inSmallBreakpoint={inSmallBreakpoint}
                exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                exceedsLargeBreakpoint={exceedsLargeBreakpoint}
                isOnline={isOnline}
              />
            }
          </React.Fragment>
        }
      </Layout>
    )
  }

  getSearch (inSmallBreakpoint, search) {
    return <Search inSmallBreakpoint={inSmallBreakpoint}>
      <TableSearch
        id="search"
        value={search}
        onChange={e => this.setState({
          search: e.target.value,
          searching: true
        })}
        onSubmit={e => this.handleSearch(e)}
        onReset={() => this.handleReset()}
        placeholder="Search All Offers"
        formStyle={inSmallBreakpoint ? {
          maxWidth: '100%'
        } : null}
        inputStyle={{ maxWidth: 200 }}
      />
    </Search>
  }
}
