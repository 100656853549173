import React from 'react'
import { Redirect } from 'react-router-dom'
import Layout from '../../components/Layout'
import { View, Text, Image } from 'react-native'
import isWeb from '../../helpers/isWeb'
import { colours, breakpoints } from '../../styles/constants'
import styled from 'styled-components'
import { P, H1, H2, TextShared, Bold, Checkboxes, superSection } from '../../styles/globalClasses'
import { Primary } from '../../components/Inputs/Buttons'
import getSuperOptions from '../../data/superOptions'
import ProgressBar from '../../components/ProgressBar'
import Steps from '../../components/Steps'
import storage from '../../helpers/storage'
import api from '../../helpers/boost-client-js-library/api'
import ScrollBarTable from '../../components/ScrollBarTable'
import Modal from '../../components/Modal'
import LoadingIndicator from '../../components/LoadingIndicator'
import Notification from '../../components/Notification'
import checkRole from '../../helpers/checkRole'
import Card from '../../components/Card'
import consoleLog from '../../helpers/consoleLog'
import reSuperOptions from '../../helpers/reSuperOptions'
import { TEMP_MAX_ITEMS } from '../../helpers/offline'
import PropTypes from 'prop-types'
import BreakpointWatcher from '../../helpers/BreakpointWatcher'

// progress
const Progress = isWeb && {
  Section: styled.div`
        ${superSection}
        display: flex;
        align-items: stretch;
        flex-direction: column;
        @media(min-width: ${breakpoints.medium}px){
            flex-direction: row;
            align-items: center;
            padding-bottom: 30px;
            border-bottom: 1px solid ${colours.light50};
            margin-bottom: 35px;
        }
    `,
  H1: styled(H1)`
        margin-bottom: 15px;
    `,
  Top: styled.div`
        margin-bottom: 22px;
        display: flex;
        align-items: stretch;
        flex-direction: column;
        @media(min-width: ${breakpoints.medium}px){
            margin-bottom: 0;
            margin-right: 30px;
            width: 325px;
            max-width: 100%;
        }
    `,
  Bottom: styled.div`
        flex: 1 1 auto;
        display: flex;
        align-items: stretch;
        flex-direction: column;
        ${props => props.hasButton && `
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        `}
        p {
            line-height: 18px;
        }
    `,
  Button: ({ style = {}, disabled, children, onPress, ...props }) => {
    const _style = {
      backgroundColor: colours.green,
      marginLeft: 15,
      ...(isWeb ? { cursor: 'pointer' } : {
      }),
      ...(disabled ? {
        cursor: 'not-allowed',
        backgroundColor: colours.black50
      } : {}),
      ...style
    }

    if (disabled) { onPress = null }

    return <Primary style={_style} onPress={onPress} disabled={disabled} {...props}>{children}</Primary>
  },
  Status: styled.div`
        display: flex;
        align-items: stretch;
        flex-direction: column;
        margin-bottom: 5px;
        flex: 1;
    `,
  StatusSuppliers: styled.div`
        display: flex;
        align-items: stretch;
        flex-direction: column;
        margin-bottom: 5px;
    `,
  Title: styled.p`
            margin: 0;
            ${TextShared};
            font-family: greycliff-bold;
            span {            
                color: ${colours.primary};
            }
            .text {
                color: black;
                font-family: greycliff;
            }
            @media(min-width: ${breakpoints.medium}px){
                margin-right: 4px;
                + p {
                    margin-top: 0;
                }
            }
        `
}

// card and app
const cardBackDimensions = {
  width: 266,
  height: 460
}
const Custom = isWeb && {
  Sections: styled.div`
        display: flex;
        align-items: stretch;
        flex-direction: column;
        ${superSection}
        @media(min-width: ${breakpoints.medium}px){
            padding-bottom: 30px;
            border-bottom: 1px solid ${colours.light50};
            margin-bottom: 35px;
        }
    `,
  Section: styled.div`
        display: none;
        align-items: stretch;
        flex-direction: column;
        @media(min-width: ${breakpoints.medium}px){
            flex-direction: row;
            flex-wrap: wrap;
        }
        ${props => props.step === props.active && `
            display: flex;
        `}
    `,
  Images: styled.div`
        margin: 30px 0 80px;
        display: flex;
        align-items: stretch;
        flex-direction: column;
        @media(min-width: ${breakpoints.medium}px){
            flex-direction: row;
        }
    `,
  WMImage: styled.img`
        width: ${props => props.width ? props.width : '100'}px;
        max-width: 100%;
        margin-bottom: 15px;
        @media(min-width: ${breakpoints.medium}px){
            margin-bottom: 0;
            margin-right: 25px;
        }
    `,
  ItemsWrap: styled.div`
        display: flex;
        align-items: stretch;
        flex-direction: column;
        ${superSection}
        margin-bottom: 30px;
        @media(min-width: ${breakpoints.medium}px){
            flex-direction: row;
            align-items: flex-end;
            justify-content: center;
            margin-left: -10px;
        }
        > * {
            padding-left: 10px;
            margin-bottom: 20px;
            @media(min-width: ${breakpoints.medium}px){
                margin-bottom: 0;
            }
        }
        @media(min-width: ${breakpoints.medium}px){
            margin-left: -30px;
            margin-bottom: 0;
            > * {
                padding-left: 30px;
            }
        }
        @media(min-width: ${breakpoints.large}px){
            margin-right: 60px;
        }
    `,
  ItemWrap: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        ${props => props.card && `
            > * {
                width: 200px;
                word-break: break-all;
            }
        `}
        @media(min-width: ${breakpoints.medium}px){    
            align-items: center;
        }
    `,
  Approved: styled.div`
        display: flex;
        align-items: stretch;
        flex-direction: column;
        ${superSection}
        a {
            color: black;
        }
        .table-wrap {
            margin-top: 50px;
            max-width: 100%;
            overflow: scroll;
        }
        .table {
            width: 100%;
            ${TextShared}
            th,
            td {
                padding-right: 15px;
            }
            th {
                font-family: greycliff-bold;
                text-align: left;
                padding-bottom: 20px;
                &:first-child {
                    padding-left: 50px;
                }
            }
            td {
                padding-bottom: 15px;
            }
            .index {
                position: relative;
                margin-left: 50px;
                &:before {
                    content: attr(data-index);
                    border-right: 1px solid ${colours.black30};
                    position: absolute;
                    right: 100%;
                    top: 50%;
                    margin-right: 30px;
                    padding-right: 10px;
                    transform: translateY(-50%);
                }
            }
            .field {
                border: 1px solid ${colours.black30};
                border-radius: 4px;
                padding: 8px;
                user-select: none;
                min-width: 135px;
                &.email {
                    min-width: 180px;
                }
                
            }
            .checks {
                display: flex;
                flex-direction: row;
            }
            .check {
                display: block;
                position: relative;
                padding-left: 30px;
                + .check {
                    margin-left: 20px;
                }
                &:before,
                &:after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 4px;
                }
                &:before {
                    content: '';
                    width: 19px;
                    height: 19px;
                    background: white;
                    border: 1px solid ${colours.black30};
                    left: 0;
                    margin-right: 5px;
                }
            }
            .checked {
                &:after {
                    content: '';
                    left: 2px;
                    width: 15px;
                    height: 15px;
                    background: ${colours.primary};
                }
            }
        }
    `,
  AppImage: Image,
  Logo: Image,
  ItemText: Text,
  Content: styled.div`
      display: flex;
      align-items: stretch;
      flex-direction: column;
       flex: 1;
       justify-content: center;
       @media(min-width: ${breakpoints.medium}px){
           margin-left: 30px;
       }
    `,
  ButtonWrap: styled.div`
      display: flex;
      align-items: stretch;
      flex-direction: column;
      flex-flow: wrap;
    `,
  CardBackWrap: styled.div`
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        align-items: center;
        .card {
            padding: 12px 20px 5px;
            border: 1px solid #f0f0f0;
            box-shadow: 0 0 11px 0px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            width: ${cardBackDimensions.width}px;
            height: ${cardBackDimensions.height}px;
            max-width: 100%;
            margin-bottom: 13px;
            text-align:center;
        }
        .barcode {
            position: relative;
            font-size: 9px;
            line-height: 11px;
            font-family: greycliff-light;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            margin-right: 5px;
            .boxBarcode{
                width:100%;
            }
            .image {
                width: 165px;
                height: 62px;
                margin: 0 auto 5px auto;
            }
            .barcode-number {
                position: absolute;
                top: -10px;
                right: -14px;
                transform: rotate(-90deg);
                transform-origin: 100% 100%;
            }
            .number {
                font-size: 15px;
                line-height: 18px;
                margin-left: 5px;
            }
        }
        .info-text {
            font-size: 7px;
            line-height: 9px;
            text-align: center;
            color: ${colours.ebBlue};
        }
        small.info-text {
            font-family: greycliff-light;
            display: block;
        }
        .list {
            font-size: 8px;
            line-height: 10px;
            text-align:left;
            > *:not(:last-child) {
                border-bottom: 1px solid ${colours.ebBlue};
                padding-bottom: 3px;
                margin-bottom: 3px;
                display: block;
            }
        }
    `,
  Suppliers: styled.div`
        display: flex;
        align-items: stretch;
        flex-direction: column;
        margin-top: 30px;
        @media(min-width: ${breakpoints.medium}px){
        }
        .table-wrapper {
            width: 100%;
        }
        .table {
            border: 0;
            border-collapse: collapse;
            width: 100%;
            td {
                padding: 8px 4px;
                border-bottom: 1px solid ${colours.light20};
                cursor: pointer;
            }
            td:not(:last-child) {
                border-right: 1px solid ${colours.light20};
                width: 55px;
            }
        }
        .label {
            font-size: 10px;
            line-height: 12px;
            font-family: greycliff;
            cursor: pointer;
            display: block;
            &.checked {
                font-family: greycliff-bold;
            }
        }
        ${Checkboxes}
    `,
  ScrollTable: styled(ScrollBarTable)`
        margin-top: 0;
        margin-right: 10px;
        margin-bottom: 20px;
        .ScrollbarsCustom {
            height: 360px!important;
        }
        @media(min-width: ${breakpoints.medium}px){
            flex-direction: row;
            width: 260px;
        }
    `,
  ConfirmWrapper: styled.div`
        display: flex;
        align-items: stretch;
        flex-direction: column;
        width: 100%;
    `,
  Confirm: styled.div`
        display: flex;
        align-items: stretch;
        flex-direction: column;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
        @media(min-width: ${breakpoints.medium}px){
            margin-bottom: 0;
            flex-direction: row;
            align-items: flex-end;
            justify-content: center;
        }
        .card-confirm,
        .app-confirm {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .card-confirm {
            margin-bottom: 20px;
            @media(min-width: ${breakpoints.medium}px){
                margin-bottom: 0;
            }
            .inner {
                @media(min-width: ${breakpoints.medium}px){
                    display: flex;
                    margin-left: -20px;
                    flex-wrap: wrap;
                    margin-bottom: 30px;
                    &.scale-down {
                        transform: scale(.7);
                        transform-origin: bottom;
                    }
                }
            }
            .inner-item {
                max-width: 100%;
                margin-bottom: 20px;
                @media(min-width: ${breakpoints.medium}px){
                    margin-bottom: 0;
                    padding-left: 20px;
                    display: flex;
                    align-items: flex-end;
                }
                > * {
                    width: ${cardBackDimensions.width}px;
                    height: ${cardBackDimensions.height}px;
                    margin: 0;
                }
            }
            .card {
                max-width: 100%;
                width: ${cardBackDimensions.width}px;
                height: ${cardBackDimensions.height}px;
                margin-bottom: 0;
            }
        }
        .app-confirm {

        }
    `,
  BottomButtonsWrap: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1 100%;    
        margin-top: 15px;
        > * {
            margin-bottom: 10px;
            flex: 1 1 100%;
            width: 100%;
        }
        @media(min-width: ${breakpoints.small}px){
            flex-direction: row;
            justify-content: space-between;
            > * {
                margin-bottom: 0;
                flex: 0 0 auto;
                width: auto;
            }
        }
        @media(min-width: ${breakpoints.medium}px){
            margin-top: 30px;
        }
    `,
  SubmitWrap: styled.div`
        display: flex;
        align-items: stretch;
        flex-direction: column;
        > * {
            flex: 1 1 100%;
            width: 100%;
        }
        @media(min-width: ${breakpoints.medium}px){
            flex-direction: row;
            justify-content: flex-end;
            > * {
                flex: 0 0 auto;
                width: auto;
            }
        }
    `,
  Back: Primary,
  Next: ({ style = {}, exceedsMediumBreakpoint, disabled, children, onPress, ...props }) => {
    const _style = {
      marginTop: 10,
      ...(exceedsMediumBreakpoint ? {
        marginTop: 0, marginLeft: 10
      } : {}),
      ...(disabled ? {
        cursor: 'not-allowed',
        backgroundColor: colours.black50
      } : {}),
      ...style
    }

    if (disabled) { onPress = null }

    return <Primary style={_style} onPress={onPress} disabled={disabled} {...props}>{children}</Primary>
  }
}

const Style = {
  Custom: {
    AppImage: {
      width: 218,
      height: 436,
      marginBottom: 20
    },
    Logo: {
      width: 80,
      height: 40,
      position: 'absolute',
      top: '50%',
      transform: [{ translateY: '-50%' }],
      marginTop: -50,
      marginHorizontal: 'auto',
      marginBottom: 0,
      left: 0,
      right: 0
    },
    ItemText: {
      textTransform: 'uppercase',
      textAlign: 'center',
      fontSize: 12,
      fontFamily: 'greycliff-bold, sans-serif',
      color: colours.black60
    },
    Back: {
      backgroundColor: colours.taupe
    }
  },
  ModalClose: {
    backgroundColor: colours.urgent
  },
  SuppliersCount: {
    fontFamily: 'greycliff-bold, sans-serif',
    textAlign: 'center',
    flex: 1
  }
}

const Bottom = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    ${superSection}
`

const ModalClose = Primary

const Right = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
`

export const statusType = code => {
  switch (code) {
  case 969760000:
    return {
      type: 'open',
      title: 'Add your logo',
      text: 'Click the "Start" button to add your logo',
      buttonText: 'Start',
      request: 'put',
      modal: false
    }
  case 969760001:
    return {
      type: 'submitted',
      title: 'Pending',
      text: 'Your customisation has been sent to the approvers for approval. If you wish to start again click the "Start again" button. Note this will override the current customisation.',
      buttonText: 'Start Again',
      request: 'put',
      modal: true
    }
  case 969760002:
    return {
      type: 'declined',
      title: 'Declined',
      text: 'Your customisation has been declined. To start again click the "Start again" button.',
      buttonText: 'Start Again',
      request: 'post',
      modal: false
    }
  case 969760003:
    return {
      type: 'approved',
      title: 'Approved',
      text: 'Your customisation has been approved. If you wish to update your company logo or name, click the "Update" button. Note this will override the current customisation. If you recently updated your logo, please allow up to 10 minutes for the new logo to show.',
      buttonText: 'Update',
      request: 'put',
      modal: true
    }
  case 'error':
    return {
      type: 'error',
      title: 'Error: An error has occurred. Please try again. If the problem persists please contact your account manager.'
    }
  default:
    return {
      type: 'default',
      title: 'No Customisation',
      text: 'Click the "Start" button to begin the customisation process',
      buttonText: 'Start',
      request: 'put',
      modal: false
    }
  }
}

const optionsWithSuppliers = getSuperOptions(true).map(i => {
  if (i.title === 'Customisation') {
    i.subOptions = [{ id: 14, title: 'Suppliers', link: '/profile/customisation/suppliers' }]
  }
  return i
})

export default class Super extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    appKey: PropTypes.object
  }

  state = {
    type: this.props.match.path.includes('suppliers') ? 'suppliers' : 'customise',
    tier: '',
    status: {},
    carData: {},
    carProof: {},
    started: false,
    active: this.props.match.path.includes('suppliers') ? 1 : 0,
    steps: ['Customise Logo', 'Customise Suppliers'],
    checked: [],
    loadingSuppliers: true,
    suppliers: [],
    chosenSuppliers: [],
    maxSuppliers: 16,
    maxCharLength: 45,
    nextButtonDisabled: !this.props.match.path.includes('suppliers'),
    modal: false,
    errorModal: false,
    successModal: false,
    statusModal: false,
    loading: true,
    isCardLoading: true,
    error: null,
    errorCardLoading: null,
    exceedsMediumBreakpoint: false
  }

  controller = new AbortController()

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
    const { history } = this.props
    const { type } = this.state
    const signal = this.controller.signal
    storage.token.get().then(token => {
      api.profile.get(token, true).then(profile => {
        const roles = checkRole(profile.roles)
        if (!roles.includes('bp')) { // you must be a BP to access this page
          history.push('/profile')
          return false
        } else {
          this.setState({ logo: profile.companyLogo })
        }
        api.organisation.get(token).then(profile => {
          if (profile.code && profile.code !== 200) this.setState({ error: profile.message ? profile.message : 'Something went wrong. Please try again.' })
          if (!profile.tier) { // you must have a tier to access this page
            history.push('/profile')
            return false
          }
          if (profile.tier && Number(profile.tier) < 2) { // tiers 0 and 1 cannot access Customisation
            history.push('/profile/error/customisation-error')
            return false
          }
          if (type === 'suppliers' && profile.tier && (Number(profile.tier) < 2 || Number(profile.tier) === 3)) { // Suppliers section is for tier 2, 4 only
            history.push('/profile/customisation')
            return false
          }
          this.setState({
            tier: Number(profile.tier),
            options: reSuperOptions(profile.tier, getSuperOptions(roles.includes('su')), type, optionsWithSuppliers),
            loading: false
          })
        })
      })
      api.suppliers.get(token, signal, `&sort=name:asc&allow_card_back_print=true&offset=0&limit=${TEMP_MAX_ITEMS}`).then(suppliers => {
        if (suppliers === undefined) return false
        if (!suppliers.items) {
          this.setState({ loadingSuppliers: false })
          return false
        }
        this.setState({ suppliers: suppliers.items.filter(i => i.restricted === false), loadingSuppliers: false }) // only show unrestricted suppliers
      })

      api.card.status.get(token).then(res => {
        const code = res && res.statusCode ? res.statusCode : 'error'
        let approved = res.proof && res.proof.Proof_Status && res.proof.Proof_Status === 'approved'

        // code is a stub since there are no open ones, check if they have an approved one
        if (code === -1 && res.hasApprovedCAR) approved = true

        const checkCode = approved ? 969760003 : code // if approved from WM, change statusCode to approved
        const getStatusType = statusType(checkCode)
        this.setState(state => {
          state.status.type = getStatusType.type
          state.status.title = getStatusType.title
          state.status.text = getStatusType.text
          state.status.buttonText = getStatusType.buttonText
          state.status.buttonTextLoaded = getStatusType.buttonText
          state.status.buttonDisabled = false
          state.status.request = getStatusType.request
          state.status.modal = getStatusType.modal
          state.carData = res.data
          state.carProof = res.proof
          return state
        }, () => {
          if (checkCode === 969760000) {
            this.handleStart()
          }
        })
      })
    })

    window.addEventListener('message', e => {
      consoleLog(e)
      if (e.origin === process.env.REACT_APP_WM_URL && e.data && typeof e.data === 'boolean') { // check origin is correct and the data returned is successful
        this.setState({ nextButtonDisabled: false })
      }
    })
  }

  handleBack = () => {
    const { history } = this.props
    const { type, active } = this.state
    if (active === 1) {
      this.setState({
        active: active - 1
      }, () => {
        if (type === 'suppliers') history.push('/profile/customisation')
      })
    } else {
      this.setState({
        started: false
      })
    }
  }

  handleNext = () => {
    const { history } = this.props
    const { type, active } = this.state

    if (active === 1) {
      this.setState({
        modal: true
      })
    } else {
      this.setState({
        active: active + 1
      }, () => {
        if (type === 'customise') history.push('/profile/customisation/suppliers')
      })
    }
  }

  handleRedirect = () => {
    const { history } = this.props
    history.push('/profile/offer-management')
  }

  handleSupplierUpdate = value => {
    const { chosenSuppliers, maxSuppliers } = this.state
    const hasValue = chosenSuppliers.includes(value)
    this.setState(state => {
      if (hasValue) {
        state.chosenSuppliers = chosenSuppliers.filter(i => i !== value)
      } else {
        if (chosenSuppliers.length < maxSuppliers) state.chosenSuppliers.push(value)
      }
      return state
    })
  }

  handleModalClose = () => {
    this.setState({
      modal: false,
      successModal: false,
      errorModal: false,
      statusModal: false
    })
  }

  handleSubmit = () => {
    const { appKey, history } = this.props
    const { chosenSuppliers } = this.state
    const suppliers = []
    chosenSuppliers.forEach(i => suppliers.push({ ID: i.ID }))
    storage.token.get(appKey).then(token => {
      api.backCardSuppliers(token, { suppliers }).then(res => {
        const success = res.code === 200
        this.setState({
          modal: false,
          successModal: success,
          errorModal: !success,
          statusModal: false
        }, () => {
          if (success) {
            setTimeout(() => history.push('/profile/offer-management'), 3000)
          }
        })
      })
    })
  }

  renderApp = () => {
    const { logo } = this.state
    return (
      <React.Fragment>
        <View>
          <Custom.AppImage style={Style.Custom.AppImage} source={require('../../images/app-preview.png')} />
          <Custom.Logo style={Style.Custom.Logo} resizeMode="contain" source={{ uri: logo }} />
        </View>
        <Custom.ItemText style={Style.Custom.ItemText}>App preview</Custom.ItemText>
      </React.Fragment>
    )
  }

  renderCardFront = () => {
    const { carData, logo } = this.state
    const companyName = carData && carData.COMPANY_NAME_LINE1 && carData.COMPANY_NAME_LINE2 !== undefined ? carData.COMPANY_NAME_LINE1 + ' ' + carData.COMPANY_NAME_LINE2 : ''
    const cardDetails = {
      firstName: carData && carData.CONTACT_NAME ? carData.CONTACT_NAME : null,
      lastName: '',
      companyLogo: logo
    }
    const card = {
      companyName,
      expiry: carData && carData.EXPIRY_DATE ? carData.EXPIRY_DATE : null
    }
    return (
      <React.Fragment>
        <Card cardDetails={cardDetails} card={card} />
        <Custom.ItemText style={Style.Custom.ItemText}>Card preview</Custom.ItemText>
      </React.Fragment>
    )
  }

  renderCardBack = (showLabel = true) => {
    const { chosenSuppliers, maxCharLength } = this.state
    return (
      <Custom.CardBackWrap>
        <div className="card">
          <div className="barcode">
            <Image className="image" resizeMode="stretch" source={require('../../images/barcode1.png')} />
            <span className="barcode-number">12345</span>
            <Bold>Card ID No. <span className="number">BT00000000</span></Bold>
            <Bold>PIN. <span className="number">WWWWWWW</span></Bold>
          </div>
          <Bold className="info-text">Access exclusive offers and discounts with these retailers</Bold>
          <div className="list">
            {chosenSuppliers.map(s => {
              const name = s.tradingName ? s.tradingName : s.name
              return <Bold
                key={s.ID}>{name.length > maxCharLength ? `${name.substr(0, maxCharLength - 1)}...` : name}</Bold>
            })}
          </div>
          <Bold className="info-text">View more suppliers and download vouchers at myboost.co.nz</Bold>
          <small className="info-text">Terms & conditions apply. Subject to change.</small>
        </div>
        {showLabel && <Custom.ItemText style={Style.Custom.ItemText}>Card preview</Custom.ItemText>}
      </Custom.CardBackWrap>
    )
  }

  renderStatus = hasStarted => {
    const { started, status, active, nextButtonDisabled, exceedsMediumBreakpoint } = this.state
    const isSubmitted = !nextButtonDisabled
    const heading = isSubmitted ? 'Approved' : 'Add your logo'
    const title = status.type ? 'Status: ' : 'Loading status...'
    const text = isSubmitted ? 'Your customisation has been approved.' : 'Complete the customisation process below.'
    if (hasStarted) {
      return (
        <Progress.Bottom>
          {active === 0
            ? <Progress.Status>
              <Progress.Title><span>{title}</span> {heading}. </Progress.Title>
              <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>{text}</P>
            </Progress.Status>
            : <React.Fragment>
              <Progress.StatusSuppliers>
                <Progress.Title><span>Status:</span> Waiting for your Suppliers.</Progress.Title>
                <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Add suppliers from the list below and click confirm.</P>
              </Progress.StatusSuppliers>
              <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>If you do not select any a default list will be chosen for you.</P>
            </React.Fragment>
          }
        </Progress.Bottom>
      )
    } else {
      if (status.type === 'error') {
        return (
          <Progress.Bottom hasButton={!started}>
            <Progress.Status>
              <Progress.Title><span>{title}</span> {status.title} </Progress.Title>
            </Progress.Status>
          </Progress.Bottom>
        )
      } else {
        return (
          <Progress.Bottom hasButton={!started}>
            <Progress.Status>
              <Progress.Title><span>{title}</span> {status.title} </Progress.Title>
              <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>{status.text}</P>
            </Progress.Status>
            {status.buttonText && <Progress.Button disabled={status.buttonDisabled}
              onPress={() => status.modal ? this.setState({ statusModal: true }) : this.handleStart()}>{status.buttonText}</Progress.Button>}
          </Progress.Bottom>
        )
      }
    }
  }

  renderProgress = () => {
    const { status, started } = this.state
    const progress = 50
    const cardProgress = () => {
      if (status.type === 'default') return 0
      if (status.type === 'open') return 33
      if (status.type === 'submitted' || status.type === 'declined') return 66
      if (status.type === 'approved') return 100
      return progress
    }
    if (started) {
      return <ProgressBar progress={progress} max={100} />
    } else {
      return <ProgressBar progress={cardProgress()} type={status.type === 'declined' && colours.urgent} max={100} />
    }
  }

  handleStart = () => {
    const { status } = this.state
    this.setState(state => {
      state.status.buttonText = 'Loading...'
      state.status.buttonDisabled = true
      state.nextButtonDisabled = true
      return state
    })
    storage.token.get().then(token => {
      if (status.request === 'get') {
        api.card.front.get(token).then(res => {
          this.handleLoadCardInfo(res)
        })
      }
      if (status.request === 'put') {
        api.card.front.update(token).then(res => {
          this.handleLoadCardInfo(res)
        })
      }
      if (status.request === 'post') {
        api.card.front.create(token).then(res => {
          this.handleLoadCardInfo(res)
        })
      }
      // this.handleLoadCardInfo({ID: 1}) // FOR TESTING
    })
  }

  handleLoadCardInfo = res => {
    if (res && res.ID) {
      this.setState(state => {
        state.sessionID = res.ID
        state.started = true
        return state
      })
    } else {
      this.setState({ errorModal: true })
    }
    this.setState(state => {
      state.statusModal = false
      state.status.buttonText = this.state.status.buttonTextLoaded
      state.status.buttonDisabled = false
      return state
    })
  }

  onLoadIframe = () => {
    this.setState({
      isCardLoading: false,
      errorCardLoading: false
    })
  }

  onErrorIframe = () => {
    this.setState({
      isCardLoading: false,
      errorCardLoading: 'Oops, something went wrong. Please try again later'
    })
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
    this.controller.abort()
  }

  render () {
    const { type, options, started, tier, active, steps, loadingSuppliers, suppliers, chosenSuppliers, maxSuppliers, modal, successModal, errorModal, statusModal, status, carProof, loading, isCardLoading, sessionID, error, errorCardLoading, nextButtonDisabled, exceedsMediumBreakpoint } = this.state
    const hasStarted = started || type === 'suppliers'
    const approvedHeadings = ['Name', 'Email address', 'Access', 'Status', 'Date']
    if (type === 'suppliers' && tier === 3) return <Redirect to={{ pathname: '/profile/customisation' }} />
    return (
      <Layout
        title="Customisation"
        optionsData={options}
        isSuperLayout={true}
      >
        {error && <Notification>{error}</Notification>}
        {loading && !error ? (
          <LoadingIndicator />
        ) : (
          !error && <View>
            {hasStarted && <Steps active={active} steps={tier !== 3 ? steps : ['Customise Logo']} />}
            <Progress.H1>Customisation{hasStarted && ' approved'}</Progress.H1>
            <Progress.Section>
              <Progress.Top>
                {this.renderProgress()}
              </Progress.Top>
              {this.renderStatus(hasStarted)}
            </Progress.Section>

            {hasStarted
              ? <React.Fragment>
                <Custom.Sections>
                  <Custom.Section step={0} active={active}>
                    {nextButtonDisabled
                      ? <React.Fragment>
                        {isCardLoading && !errorCardLoading && <LoadingIndicator />}
                        {errorCardLoading && <Notification>{errorCardLoading}</Notification>}
                        {sessionID && <iframe title="iframe" src={`${process.env.REACT_APP_WM_URL}/${sessionID}`}
                          style={{ border: 0, width: '100%', height: '1024px' }}
                          onLoad={this.onLoadIframe} onError={this.onErrorIframe} />}
                      </React.Fragment>
                      : <React.Fragment>
                        <H1>Design approved</H1>
                        <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Your logo has been approved. Please allow up to 10 minutes for the changes to be seen on the website and app.</P>
                      </React.Fragment>
                    }
                  </Custom.Section>

                  {tier !== 3 &&
                    <Custom.Section step={1} active={active}>
                      {this.renderCardBack()}
                      <Custom.Content>
                        <H1>Customise Boost Card</H1>
                        <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Select up to 16 suppliers to be printed on the back of the Boost card. They will be ordered in
                          alphabetical order.</P>
                        <Custom.Suppliers>
                          <Custom.ScrollTable height={360}>
                            <div className="ReactTable table-wrapper">
                              {loadingSuppliers && <LoadingIndicator />}
                              <table className="table">
                                <tbody>
                                  {suppliers.map(item =>
                                    <tr key={item.ID}>
                                      <td>
                                        <input
                                          type="checkbox"
                                          id={item.ID}
                                          className="checkbox"
                                          checked={chosenSuppliers.includes(item)}
                                          onChange={() => this.handleSupplierUpdate(item)}
                                        />
                                        <label htmlFor={item.ID} className="custom-check">Enable/disable</label>
                                      </td>
                                      <td>
                                        <label htmlFor={item.ID}
                                          className={`label ${chosenSuppliers.includes(item) ? 'checked' : ''}`}>{item.tradingName}</label>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Custom.ScrollTable>
                          <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} style={Style.SuppliersCount}>Suppliers selected - {chosenSuppliers.length}/{maxSuppliers}</P>
                        </Custom.Suppliers>
                      </Custom.Content>
                    </Custom.Section>
                  }

                  <Custom.BottomButtonsWrap active={active}>
                    <Custom.Back style={Style.Custom.Back} onPress={() => this.handleBack()}>Back</Custom.Back>
                    <Custom.SubmitWrap>
                      {tier !== 3
                        ? <Custom.Next disabled={nextButtonDisabled}
                          onPress={() => !nextButtonDisabled && this.handleNext()}>{active === 1 ? 'Confirm ›' : 'Next ›'}</Custom.Next>
                        : <Custom.Next disabled={nextButtonDisabled}
                          onPress={() => (status.type === 'submitted' || !nextButtonDisabled) && this.handleRedirect()}>Done
                          ›</Custom.Next>
                      }
                    </Custom.SubmitWrap>
                  </Custom.BottomButtonsWrap>
                </Custom.Sections>

                <Bottom>
                  <H2>What happens next?</H2>
                  <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Once you submit your customisation, your logo will be updated on the website and app in 10 minutes. If you are printing a Boost Card, the printing order will be placed on the first Friday of the month. Delivery will take 1-2 weeks.
                  </P>
                </Bottom>
              </React.Fragment>
              : <React.Fragment>
                {status.type !== 'submitted'
                  ? <Custom.ItemsWrap>
                    {tier !== 3 && <Custom.ItemWrap card>{this.renderCardFront()}</Custom.ItemWrap>}
                    {tier >= 3 && <Custom.ItemWrap>{this.renderApp()}</Custom.ItemWrap>}
                  </Custom.ItemsWrap>
                  : <React.Fragment>
                    {carProof !== null && <Custom.Approved>
                      <H2>Design Approval</H2>
                      <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Below is the list of users approvers and viewers you have nominated.</P>
                      <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}><a href={carProof.Proof_URL} target="_blank" rel="noopener noreferrer">Click here</a> to view a
                        proof of the customisation.</P>
                      <div className="table-wrap">
                        <table className="table">
                          <thead>
                            <tr>
                              {approvedHeadings.map(i => <th key={i}>{i}</th>)}
                            </tr>
                          </thead>
                          <tbody>
                            {carProof.Approval_Users.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <div className="field index" data-index={i + 1}>{item.Name}</div>
                                  </td>
                                  <td>
                                    <div className="field">{item.Email}</div>
                                  </td>
                                  <td>
                                    <div className="checks">
                                      <span
                                        className={[item.Level === 'approver' ? 'checked check' : 'check']}>Approver</span>
                                      <span
                                        className={[item.Level === 'viewer' ? 'checked check' : 'check']}>Viewer</span>
                                    </div>
                                  </td>
                                  <td>{item.Decision}</td>
                                  <td>{item.Decision_Made_On ? item.Decision_Made_On : carProof.Created_On}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Custom.Approved>}
                  </React.Fragment>
                }
              </React.Fragment>
            }
            {modal &&
              <Modal
                h1="Are you sure?"
                p={chosenSuppliers.length === 0 ? 'You have not chosen any suppliers. The default list will be printed on the back of the card. Are you sure you want to proceed?' : 'Are you happy with the suppliers you have selected to be printed on the back of the Boost card?'}
                close={() => this.handleModalClose()}
              >
                <ModalClose style={Style.ModalClose} onPress={() => this.handleModalClose()}>No</ModalClose>
                <Primary onPress={() => this.handleSubmit()}>Yes ›</Primary>
              </Modal>
            }
            {successModal &&
              <Modal
                h1={'Suppliers added!'}
                p="Your chosen suppliers have been added! Redirecting you to Offer Management..."
                close={() => this.handleModalClose()}
              >
                <Right>
                  <Primary onPress={() => this.handleModalClose()}>Close</Primary>
                </Right>
              </Modal>
            }
            {errorModal &&
              <Modal
                h1={'Well this sucks'}
                p="This action cannot be completed due to an error. Please try again. If the problem persists contact your account manager."
                close={() => this.handleModalClose()}
              >
                <Right>
                  <Primary onPress={() => this.handleModalClose()}>Close</Primary>
                </Right>
              </Modal>
            }
            {statusModal &&
              <Modal
                h1="Are you sure?"
                p="This will override your current customisation. This cannot be undone."
                close={() => this.handleModalClose()}
              >
                <ModalClose style={Style.ModalClose} onPress={() => this.handleModalClose()}>No</ModalClose>
                <Primary disabled={status.buttonDisabled}
                  onPress={() => !status.buttonDisabled && this.handleStart('modal')}>Yes ›</Primary>
              </Modal>
            }
          </View>
        )}
      </Layout>
    )
  }
}
