import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { colours } from '../styles/constants'
import isWeb from '../helpers/isWeb'
import moment from 'moment'

const ExpiryBarComponent = props => {
  const expiryBarStyles = {
    height: props.height ? props.height : 8,
    borderRadius: 15,
    background: props.type === 'back' ? '#fba591' : 'transparent',
    width: props.progress ? `${props.progress}%` : 'auto'
  }

  // For when props.type === 'progress' is given
  const expiryBarStylesProgress = {
    background: colours.urgent,
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1
  }
  return (
    <View style={props.type === 'progress' ? { ...expiryBarStyles, ...expiryBarStylesProgress } : expiryBarStyles} />
  )
}

const Expiry = props => {
  const expiryStyles = {
    marginTop: isWeb ? 5 : 2,
    fontFamily: 'greycliff-bold',
    fontSize: isWeb ? 12 : 8,
    color: colours.black60,
    textAlign: 'center'
  }

  // For when props.cart === 'true'
  const expiryStylesCart = {
    color: colours.urgent,
    fontSize: 14,
    margin: 0,
    textAlign: 'left',
    fontFamily: 'greycliff-medium'
  }
  return (
    <Text style={props.cart ? { ...expiryStyles, ...expiryStylesCart } : expiryStyles}>{props.children}</Text>
  )
}

const ExpiryBar = props => {
  const { type, expires, starts, height, cart } = props
  if (!starts || !expires || type !== 'lto') return false
  const currDate = moment.utc()
  const startDate = moment.utc(starts) // add 12 hours due to discrepancy between API and CRM
  const expDate = moment.utc(expires)
  const exp = {
    day: expDate.format('D'),
    month: expDate.format('MMMM')
  }
  const daysDiff = Math.floor(expDate.diff(currDate, 'days'))
  const hoursDiff = Math.floor(expDate.diff(currDate, 'hours'))
  const days = hoursDiff < 24 ? 0 : daysDiff // if there are less than 24 hours remaining, there are 0 days left
  const daysRemaining = days === 1 ? `${days} day` : `${days} days`

  const total = Math.floor(expDate.diff(startDate, 'days'))
  const prog = Math.floor(currDate.diff(startDate, 'days'))
  const progress = days === 0 ? 100 : days === 1 ? 99 : Math.floor(prog / (total / 100)) // if no days left, make 100% progress, if 1 day left, make 99% progress, otherwise do calculation
  if (days < 0) return false // if there are less then 0 days left this offer has expired
  if (cart) return <Expiry cart="true">Expires {exp.day} {exp.month} - <Text style={styles.remaining}>{daysRemaining} left</Text></Expiry>
  return (
    <View>
      <View style={styles.expiryBars}>
        <ExpiryBarComponent height={height} type="progress" progress={progress || '0'} />
        <ExpiryBarComponent height={height} type="back" />
      </View>
      <Expiry>Offer expires {exp.day} {exp.month} - <Text style={styles.remaining}>{daysRemaining} left</Text></Expiry>
    </View>
  )
}

export default ExpiryBar

const styles = StyleSheet.create({
  expiryBars: {
    marginTop: 8
  },
  remaining: {
    color: colours.urgent
  }
})
