/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react'
import Layout from '../../components/Layout'
import getSuperOptions from '../../data/superOptions'
import reSuperOptions from '../../helpers/reSuperOptions'
import storage from '../../helpers/storage'
import api from '../../helpers/boost-client-js-library/api'
import checkRole from '../../helpers/checkRole'
import DashboardBase, { List, Section, styles, Wrapper } from './DashboardBase'
import { Text } from 'react-native'
import styled from 'styled-components'
import { breakpoints } from '../../styles/constants'
import { Primary } from '../../components/Inputs/Buttons'
import { withRouter } from 'react-router-dom'
import Modal from '../../components/Modal'
import InlineLoadingIndicator from '../../components/InlineLoadingIndicator'
import queryString from 'query-string'
import moment from 'moment'
import LoadingIndicator from '../../components/LoadingIndicator'
import BreakpointWatcher from '../../helpers/BreakpointWatcher'

const ButtonWrap = styled.div`
    margin-top: 15px;
    flex-direction: row;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
      flex-direction: column;
      @media (min-width: ${breakpoints.medium - 1}px) {
        flex-direction: row;
      }
  `

const CancelBack = Primary
const CancelConfirm = Primary

const Styles = {
  CancelBack: {
    backgroundColor: '#D8C0B1'
  },
  CancelConfirm: {
    backgroundColor: '#F64A22'
  }
}

class CancelSubscription extends DashboardBase {
  state = {
    tier: '',
    superOptions: [],
    accountName: '',
    currentPlanMaxUsers: '',
    planName: '',
    subscriptionRenewDate: '',
    showConfirmModal: false,
    confirmationFailed: false,
    failureReason: '',
    quoteError: false,
    quoteErrorHeading: '',
    quoteErrorMessage: '',
    quoteLoaded: false,
    exceedsMediumBreakpoint: false
  }

  mounted = false

  componentDidMount () {
    this.mounted = true
    BreakpointWatcher.addComponent(this)

    const queryValues = queryString.parse(this.props.location.search)
    const hashId = queryValues.q

    if (hashId === undefined || hashId === '') {
      this.setState({
        quoteError: true,
        quoteErrorHeading: 'Missing Quote ID',
        quoteErrorMessage: 'No quote ID was provided in the URL.'
      })
      return
    }

    const { appKey, history } = this.props
    storage.token.get(appKey).then(token => {
      api.profile.get(token).then(profile => {
        this.handleApiError(profile)
        const roles = checkRole(profile.roles)
        if (!roles.includes('bp')) {
          history.push('/profile')
          return false
        }

        api.quote.get(hashId, token).then(quote => {
          if (quote.code !== undefined) {
            this.setState(
              {
                quoteError: true,
                quoteErrorHeading: 'Error Loading Quote',
                quoteErrorMessage: quote.message
              }
            )
            return
          }

          api.organisation.get(token).then(organisation => {
            this.handleApiError(organisation)
            this.mounted && this.setState({
              accountName: organisation.accountName,
              tier: Number(organisation.tier),
              planName: quote.currentPlanName,
              currentPlanMaxUsers: organisation.currentPlanMaxUsers,
              superOptions: reSuperOptions(organisation.tier, getSuperOptions(roles.includes('su'))),
              subscriptionRenewDate: moment(quote.subscriptionEndDate).format('DD/MM/YY')
            })

            this.setState({
              hashId,
              quoteLoaded: true
            })
          })
        })
      })
    })
  }

  componentWillUnmount () {
    this.mounted = false
    BreakpointWatcher.removeComponent(this)
  }

  navigateToDashboard () {
    const { history } = this.props

    history.push('/profile/dashboard')
  }

  hideConfirmModal () {
    const { cancelInProgress } = this.state

    if (cancelInProgress) {
      return
    }

    this.setState({ showConfirmModal: false, confirmationFailed: false })
  }

  performCancel () {
    this.setState({ cancelInProgress: true })

    const { appKey } = this.props
    const { hashId } = this.state
    storage.token.get(appKey).then(token => {
      api.quote.cancel(hashId, token).then(res => {
        if (res.code && res.code >= 400) {
          this.cancellationFailed(res.message)
        } else {
          this.cancellationCompleted()
        }
      })
    })
  }

  cancellationCompleted () {
    const { history } = this.props
    this.setState({ showConfirmModal: false })
    history.push('/profile/cancel/complete')
  }

  cancellationFailed (failureReason) {
    this.setState({ cancelInProgress: false, confirmationFailed: true, failureReason: failureReason })
  }

  renderModalButtons () {
    const { confirmationFailed, cancelInProgress } = this.state
    if (confirmationFailed) {
      return <Primary onPress={() => { this.hideConfirmModal() }} disabled={cancelInProgress}>
        <Text>Close</Text>
      </Primary>
    }

    return <React.Fragment>
      <CancelBack style={Styles.CancelBack} onPress={() => { this.hideConfirmModal() }} disabled={cancelInProgress}>
        <Text>Back</Text>
      </CancelBack>
      <CancelConfirm style={Styles.CancelConfirm} onPress={() => this.performCancel()} disabled={cancelInProgress}>
        {!cancelInProgress && <Text>Yes I&rsquo;m Sure ›</Text>}
        {cancelInProgress && <InlineLoadingIndicator/>}
      </CancelConfirm>
    </React.Fragment>
  }

  renderFailureModalContent () {
    const { failureReason } = this.state

    return <React.Fragment>
      <p>This action cannot be completed due to an error. Please try again. If the problem persists contact your
        account manager.</p>
      <p>{`Error Message - ${failureReason}`}</p>
    </React.Fragment>
  }

  render () {
    const {
      tier,
      superOptions,
      currentPlanMaxUsers,
      planName,
      subscriptionRenewDate,
      showConfirmModal,
      confirmationFailed,
      quoteError,
      quoteErrorHeading,
      quoteErrorMessage,
      quoteLoaded,
      accountName,
      exceedsMediumBreakpoint
    } = this.state

    const modalTitle = confirmationFailed ? 'Well this sucks' : 'Are you sure you want to cancel?'
    const modalContent = confirmationFailed ? this.renderFailureModalContent() : null

    return (
      <Layout
        title="Cancel Boost Subscription"
        optionsData={superOptions}
        isSuperLayout={true}
      >
        {showConfirmModal &&
        <Modal h1={modalTitle} close={() => { this.hideConfirmModal() }} p={modalContent}>
          {this.renderModalButtons()}
        </Modal>
        }
        {quoteError &&
          <Wrapper>
            <Section exceedsMediumBreakpoint={exceedsMediumBreakpoint} tier={tier}>
              <List.Wrapper exceedsMediumBreakpoint={exceedsMediumBreakpoint} isFirstWrapper>
                <Text style={styles.heading}>{ quoteErrorHeading }</Text>
                <List.Text>
                  { quoteErrorMessage }
                </List.Text>
              </List.Wrapper>
            </Section>
          </Wrapper>
        }
        {!quoteLoaded &&
          <LoadingIndicator/>
        }
        {!quoteError && quoteLoaded &&
        <Wrapper>
          <Section exceedsMediumBreakpoint={exceedsMediumBreakpoint} tier={tier}>
            <List.Wrapper exceedsMediumBreakpoint={exceedsMediumBreakpoint} isFirstWrapper>
              <Text style={styles.heading}>Subscription Details</Text>
              <List.Text><strong>{ accountName }</strong> is currently part
                of <strong>{planName}</strong> with <strong>{currentPlanMaxUsers}</strong>
                licenses.
              </List.Text>
              <List.Text>The subscription renewal date is <strong>{ subscriptionRenewDate}</strong> unless you cancel
                your membership by clicking the cancel button below.</List.Text>
              <List.Text>
                <strong>Are you sure you want to cancel?</strong>
              </List.Text>
            </List.Wrapper>
          </Section>
          <ButtonWrap>
            <CancelBack onPress={() => this.navigateToDashboard()}>
              <Text>Back to Dashboard</Text>
            </CancelBack>
            <CancelConfirm onPress={() => { this.setState({ showConfirmModal: true }) }}>
              <Text>Yes, Cancel my Boost Subscription</Text>
            </CancelConfirm>
          </ButtonWrap>
        </Wrapper>
        }
      </Layout>
    )
  }
}

export default withRouter(CancelSubscription)
