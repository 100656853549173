/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react'
import { View } from 'react-native'
import isWeb from '../helpers/isWeb'
import { colours, breakpoints } from '../styles/constants'
import styled from 'styled-components'
import Scrollbar from 'react-scrollbars-custom'
import BreakpointWatcher from '../helpers/BreakpointWatcher'

const Scroll = {
  Wrapper: ({ style = {}, exceedsMediumBreakpoint, children, ...props }) => {
    const _style = {
      position: 'relative',
      marginVertical: 20,
      marginHorizontal: 0,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colours.light,
      ...(exceedsMediumBreakpoint ? {
        marginRight: -15
      } : {}),
      ...style
    }

    return <View style={_style} {...props}>{children}</View>
  },
  Inner: styled(Scrollbar)`
        ${props => props.height ? `height: ${props.height}px!important` : ''}
        overflow: hidden;
        .ScrollbarsCustom-Wrapper {
            bottom: 0 !important;
            right: 20px !important;
        }
        .ScrollbarsCustom-Scroller {
            // padding: 0!important;
            // margin: 0!important;
            // margin-right: 10px!important;
        }
        .ScrollbarsCustom-Track {
            background: white!important;
            width: 20px!important;
            border-radius: 0!important;
            height: calc(100% - 44px)!important;
            top: 50%!important;
            transform: translateY(-50%);
            border-left: 1px solid ${colours.light};
        }
        .ScrollbarsCustom-Thumb {
            display: block!important;
            border-radius: 0!important;
            background: ${colours.light20}!important;
        }
        .ScrollbarsCustom-TrackY {
            display: block!important;    
        }
        .ScrollbarsCustom-TrackX {
            display: none!important;
        }
        .rt-table {
            overflow: inherit;
        }
        .ReactTable {
            margin: 0;
            border: 0;
        }
        @media(max-width: ${breakpoints.medium - 1}px){
            height: auto!important;
            overflow: scroll!important;
            .ScrollbarsCustom-Wrapper,
            .ScrollbarsCustom-Scroller {
                position: static!important;
                overflow: inherit!important;
            }
            .ScrollbarsCustom-TrackY {
                display: none!important;
            }
        }
    `,
  Button: isWeb && styled.button`
        appearance: none;
        border: 0;
        background: white url(${require('../images/arrow-up-scroll.png')}) center center / 11px 6px no-repeat;
        height: 23px;
        width: 20px;
        border-left: 1px solid ${colours.light};
        position: absolute;
        right: 0;
        z-index: 1;
        font-size: 0;
        cursor: pointer;
        ${props => props.direction === 'down'
    ? `
            bottom: 0;
            background-image: url(${require('../images/arrow-down-scroll.png')})
        `
    : `
            top: 0;
        `}
        @media(max-width: ${breakpoints.medium - 1}px) {
          display: none !important;
        }
    }
    `
}

export default class ScrollBarTable extends React.Component {
  state = {
    init: false,
    height: '',
    exceedsMediumBreakpoint: false
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
    const customHeight = Number(this.props.height)
    const getInnerHeight = this.scrollbar.contentElement.querySelector('.ReactTable').getBoundingClientRect().height
    const innerHeight = getInnerHeight === 0 ? customHeight : getInnerHeight
    this.setState({
      init: this.scrollbar,
      innerHeight: customHeight > innerHeight ? innerHeight : customHeight
    })
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  handleScroll = (direction = 'up') => {
    const scrollbar = this.scrollbar
    const currentPosition = scrollbar.scrollValues.scrollTop
    const height = scrollbar.scrollValues.clientHeight
    const step = 10
    if (direction === 'up' && currentPosition !== 0) {
      return scrollbar.scrollTo(null, currentPosition - step)
    }
    if (direction === 'down' && currentPosition !== height) {
      return scrollbar.scrollTo(null, currentPosition + step)
    }
  }

  render () {
    const { init, innerHeight, exceedsMediumBreakpoint } = this.state
    const { className, children, ...props } = this.props
    const hasScroll = init && init.scrollValues.scrollYPossible
    return (
      <Scroll.Wrapper exceedsMediumBreakpoint={exceedsMediumBreakpoint} className={className} {...props}>
        <Scroll.Button className="scroll-button" onClick={() => this.handleScroll('up')}>Up</Scroll.Button>
        <Scroll.Inner ref={ref => { this.scrollbar = ref } } height={innerHeight} scroll={hasScroll ? 1 : 0}>
          {children}
        </Scroll.Inner>
        <Scroll.Button className="scroll-button" onClick={() => this.handleScroll('down')} direction="down">Down</Scroll.Button>
      </Scroll.Wrapper>
    )
  }

  static defaultProps = {
    height: 500
  }
}
