import React from 'react'
import { withRouter } from 'react-router-dom'
import { Dimensions, Image, ImageBackground, StyleSheet, TouchableOpacity, View } from 'react-native'
import isWeb from '../helpers/isWeb'
import { colours } from '../styles/constants'

const { width, height } = Dimensions.get('window')

class Component extends React.Component {
  render () {
    const { children, width, history, home } = this.props
    const wrapperWidth = isWeb ? { width: 500, maxWidth: '100%' } : width ? { width: `${width}%` } : ''
    return (
      <ImageBackground source={!isWeb ? require('../images/boost-splash.jpg') : ''} style={styles.bg}>
        {!home && <TouchableOpacity onPress={() => history.goBack()} style={styles.back}><Image source={require('../images/arrow-left-black.png')} style={styles.backIcon} /></TouchableOpacity>}
        <View style={[styles.wrapper, wrapperWidth]}>
          {children}
        </View>
      </ImageBackground>
    )
  }
}

export default withRouter(Component)

const styles = StyleSheet.create({
  bg: {
    backgroundColor: isWeb ? 'white' : colours.lighterGrey,
    width,
    height,
    flex: 1,
    alignItems: 'center',
    position: 'relative'
  },
  back: {
    position: 'absolute',
    top: 50,
    left: 15,
    zIndex: 1,
    width: 50,
    height: 21
  },
  backIcon: {
    width: 12,
    height: '100%'
  },
  wrapper: {
    paddingTop: '25%',
    width: '55%',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: 20
  },
  company: {
    width: 130,
    height: 30
  }
})
