import React from 'react'
import Confirmation, { WebLink } from '../../components/Confirmation'

export default class Customoffers extends React.Component {
  render () {
    return (
      <Confirmation
        title="Add Custom Offer"
        h1="Success!"
        p="We have added the custom offers."
      >
        <WebLink to="/profile/offer-management">Back to Offer Management ›</WebLink>
        <WebLink to="/profile/dashboard">Dashboard ›</WebLink>
      </Confirmation>
    )
  }
}
