// Progress bar which take a prop to fill a set amount (web only)
import React from 'react'
import { View } from 'react-native'
import { colours } from '../styles/constants'

const ProgressBar = View
const ProgressBarCompleted = View

const progressBarStyle = {
  height: 28,
  width: '100%',
  backgroundColor: colours.light40,
  borderRadius: 4,
  position: 'relative',
  overflow: 'hidden'
}

const Component = props => {
  const { children } = props
  let progress
  if (props.progress && props.max) {
    progress = (props.progress / props.max) * 100
  } else {
    progress = 0
  }

  const progressBarCompletedStyle = {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    backgroundColor: props.type ? props.type : colours.primary,
    width: `${progress}%`
  }

  return <ProgressBar style={progressBarStyle}>
    <ProgressBarCompleted style={progressBarCompletedStyle}/>
    { children }
  </ProgressBar>
}

export default Component
