// Numbered steps at top of page, usually found in super admin views (web only)
import React from 'react'
import { View } from 'react-native'
import { colours, breakpoints } from '../styles/constants'
import isWeb from '../helpers/isWeb'
import styled from 'styled-components'
import BreakpointWatcher from '../helpers/BreakpointWatcher'

const Steps = isWeb && {
  Wrapper: ({ exceedsMediumBreakpoint, style = {}, children, steps, ...props }) => {
    const _style = {
      flexDirection: 'row',
      alignContent: 'stretch',
      borderRadius: 4,
      overflow: 'hidden',
      marginBottom: 30,
      ...(exceedsMediumBreakpoint ? {
        borderRadius: 0,
        marginBottom: 20,
        marginLeft: -45
      } : {}),
      ...((steps === 3 && !exceedsMediumBreakpoint) ? {
        flexDirection: 'column'
      } : {}),
      ...style
    }
    return <View style={_style} {...props}>{children}</View>
  },
  Item: styled.div`
        flex: 1;
        @media (min-width: ${breakpoints.medium}px) {
            padding-left: 45px;
            &:first-child p:before {
                display: none;
            }
            &:last-child:not(:only-child) p:after {
                display: none;
            }
            &:only-child {
                padding-right: 14px;
            }
        }
    `,
  Inner: styled.p`
        margin: 0;
        font-family: greycliff-bold;
        font-size: 14px;
        line-height: 16px;
        color: ${props => props.active ? 'white' : 'black'};
        background: ${props => props.active ? `${colours.primary}` : `${colours.light40}`};
        padding: 5px;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        ${props => props.completed && `
            background: black;
            color: white;
        `}
        @media (min-width: ${breakpoints.medium}px) {
            padding: 11px;
            position: relative;
            height: inherit;
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 19px 0 19px 14px;
            }
            &:before {
                left: 0;
                border-color: transparent transparent transparent white;
            }
            &:after {
                left: 100%;
                border-color: transparent transparent transparent ${props => props.active ? `${colours.primary}` : `${colours.light40}`};
                ${props => props.completed && `
                    border-color: transparent transparent transparent black;
                `}
            }
        }
    `,
  Number: styled.span`
        padding-right: 1px;
        span {
            @media (max-width: ${breakpoints.medium - 1}px) {
                display: none;
            }
        }
    `
}

export default class StepsComponent extends React.Component {
  state = {
    exceedsMediumBreakpoint: false
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  render () {
    const { steps, active, showCompleted } = this.props
    const { exceedsMediumBreakpoint } = this.state

    const isActive = active || 0
    return (
      <Steps.Wrapper exceedsMediumBreakpoint={exceedsMediumBreakpoint} steps={steps.length}>
        {steps.map((step, i) => (
          <Steps.Item key={i}>
            <Steps.Inner active={i === isActive ? true : undefined} completed={showCompleted && i < isActive}>
              <Steps.Number><span>Step </span>{i + 1}:&nbsp;</Steps.Number>{step}
            </Steps.Inner>
          </Steps.Item>
        ))}
      </Steps.Wrapper>
    )
  }
}
