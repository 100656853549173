/* eslint-disable react/prop-types */
import React from 'react'
import { colours } from '../../styles/constants'
import { Text } from 'react-native'
import isWeb from '../../helpers/isWeb'
import BreakpointWatcher from '../../helpers/BreakpointWatcher'
import lineHeight from '../../helpers/lineHeight'

const getButtonStyle = (exceedsMediumBreakpoint, secondary, disabled, isHovered, isActive, style) => {
  if (exceedsMediumBreakpoint === undefined) {
    console.warn('getButtonStyle called with undefined exceedsMediumBreakpoint')
  }

  const _baseStyle = {
    color: secondary ? 'black' : 'white',
    ...(secondary ? { borderWidth: 1, borderStyle: 'solid', borderColor: '#b3b3b3' } : {}),
    fontFamily: 'greycliff-bold',
    backgroundColor: secondary ? 'white' : (disabled ? '#ccc' : colours.primary),
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 15,
    textAlign: 'center',
    overflow: 'hidden',
    fontSize: 16,
    lineHeight: lineHeight(19)
  }
  let _webStyle = {}
  if (isWeb) {
    _webStyle = {
      cursor: disabled ? 'default' : 'pointer',
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
      userSelect: 'none',
      transitionProperty: 'opacity',
      transitionDuration: '0.1s',
      ...(exceedsMediumBreakpoint ? {
        fontSize: 14,
        lineHeight: lineHeight(17)
      } : {}),
      ...(disabled ? {
        backgroundColor: '#ccc',
        color: 'white'
      } : {})
    }
    if (isActive) {
      _webStyle.opacity = 1
    } else if (isHovered) {
      _webStyle.opacity = disabled ? 1 : 0.8
    }
  }
  return { ..._baseStyle, ..._webStyle, ...style }
}

class Button extends React.Component {
  state = {
    isHovered: false,
    isActive: false,
    exceedsMediumBreakpoint: false
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  onMouseEnter () {
    this.setState({ isHovered: true })
  }

  onMouseLeave () {
    this.setState({ isHovered: false })
  }

  onMouseDown () {
    this.setState({ isActive: true })
  }

  onMouseUp () {
    this.setState({ isActive: false })
  }

  render () {
    const { style, onPress, disabled, children, secondary, ...props } = this.props
    const { isHovered, isActive, exceedsMediumBreakpoint } = this.state
    if (disabled) {
      props.pointerEvents = 'none'
    }
    return <Text
      style={getButtonStyle(exceedsMediumBreakpoint, secondary, disabled, isHovered, isActive, style)}
      onPress={disabled ? () => {} : onPress}
      onMouseEnter={() => this.onMouseEnter()}
      onMouseLeave={() => this.onMouseLeave()}
      onMouseUp={() => this.onMouseUp()}
      onMouseDown={() => this.onMouseDown()}
      {...props}
    >
      {children}
    </Text>
  }
}

export const Primary = ({ style, onPress, disabled, children, ...props }) => {
  return (
    <Button onPress={onPress} style={style} disabled={disabled} {...props}>{children}</Button>
  )
}

export const Secondary = ({ style, onPress, children, disabled, ...props }) => {
  return (
    <Button onPress={onPress} style={style} disabled={disabled} secondary="true" {...props}>{children}</Button>
  )
}

export const Disabled = ({ style, children, ...props }) => {
  return (
    <Button style={style} disabled={true} {...props}>{children}</Button>
  )
}
