const checkRole = roles => {
  if (roles === undefined || roles === '' || roles === []) return ''
  const bp = 'myboost.co.nz BP'
  const su = 'myboost.co.nz SU'
  if (roles.includes(bp) && roles.includes(su)) return 'bpsu'
  if (roles.includes(bp)) return 'bp'
  if (roles.includes(su)) return 'su'
}

// scenarios
// bp and su
// bp
// su

export default checkRole
