import React from 'react'
import Layout from './components/Layout'
import RetailersBase from './RetailersBase'
import LoadingIndicator from './components/LoadingIndicator'
import { Image, View } from 'react-native-web'
import BreakpointWatcher from './helpers/BreakpointWatcher'
import { Text } from 'react-native-svg'
import { colours } from './styles/constants'
import queryString from 'query-string'
import api from './helpers/boost-client-js-library/api'
import checkRole from './helpers/checkRole'
import storage from './helpers/storage'

const styles = {
  grid: {
    fontFamily: 'greycliff',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  retailer: {
    cursor: 'pointer',
    width: 280,
    paddingHorizontal: 30,
    paddingTop: 15,
    paddingBottom: 20,
    borderBottom: '1px solid #ccc',
    marginBottom: 15
  },
  retailerMobile: {
    width: '100%'
  },
  logoWrap: {
    justifyContent: 'center',
    flexDirection: 'row'
  },
  retailerName: {
    fontFamily: 'greycliff-bold',
    fontSize: 16,
    verticalAlign: 'text-top'
  },
  retailerTextMobile: {
    textAlign: 'center'
  },
  retailerLogo: {
    height: 80,
    width: 140,
    marginBottom: 24
  },
  retailerCategories: {
    color: colours.black60,
    fontSize: 14
  }
}

export default class RetailersWeb extends RetailersBase {
  state = {
    loading: true,
    retailers: [],
    displayRetailers: [],
    categoryOptions: [],
    categoryNames: [],
    inSmallBreakpoint: false,
    exceedsMediumBreakpoint: false,
    selectedCategory: 'all'
  }

  componentDidMount () {
    super.componentDidMount()
    BreakpointWatcher.addComponent(this)

    const { appKey } = this.props

    storage.token.get(appKey).then(token => {
      api.profile.get(token).then(profile => {
        if (!checkRole(profile.roles).includes('su')) {
          history.push('/profile/dashboard')
          return false
        }
      })
    })
  }

  componentWillUnmount () {
    super.componentWillUnmount()
    BreakpointWatcher.removeComponent(this)
  }

  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      this.filterByCategory()
    }
  }

  navigateToRetailer (retailer) {
    const { history } = this.props
    history.push(`/retailers/${retailer.ID}`)
  }

  renderRetailer (retailer) {
    const { inSmallBreakpoint } = this.state

    return <View
      key={retailer.ID}
      style={{ ...styles.retailer, ...(inSmallBreakpoint ? styles.retailerMobile : {}) }}
      onClick={() => this.navigateToRetailer(retailer)}
    >
      <View style={styles.logoWrap}>
        <Image source={{ uri: retailer.logo }} style={styles.retailerLogo} resizeMode="contain" alt={`${retailer.tradingName} Logo`}/>
      </View>
      <Text
        style={{ ...styles.retailerName, ...(inSmallBreakpoint ? styles.retailerTextMobile : {}) }}>
        {retailer.tradingName}
      </Text>
      <Text
        style={{ ...styles.retailerCategories, ...(inSmallBreakpoint ? styles.retailerTextMobile : {}) }}>
        {(retailer.categories && retailer.categories.length) ? retailer.categories.join(', ') : ' '}
      </Text>
    </View>
  }

  postLoad () {
    const { retailers } = this.state

    const categoryNames = []

    retailers.forEach(retailer => {
      retailer.categories.forEach(category => {
        if (!categoryNames.includes(category)) {
          categoryNames.push(category)
        }
      })
    })
    categoryNames.sort()
    const categoryOptions = categoryNames.map(category => {
      const qs = queryString.stringify({ category })

      return {
        id: category,
        link: `/retailers?${qs}`,
        title: category
      }
    })

    categoryOptions.splice(0, 0, {
      id: 'all',
      link: '/retailers?category=all',
      title: 'All'
    })

    this.setState({
      loading: false,
      categoryOptions,
      categoryNames
    })
    this.filterByCategory()
  }

  filterByCategory () {
    const {
      categoryNames,
      retailers
    } = this.state
    const queryValues = queryString.parse(this.props.location.search)

    let displayRetailers = []

    const category = queryValues.category
    if (category && category !== 'all' && categoryNames.includes(category)) {
      displayRetailers = retailers.filter(retailer => retailer.categories.includes(category))
      this.setState({
        selectedCategory: category,
        displayRetailers
      })
    } else {
      this.setState({
        selectedCategory: 'all',
        displayRetailers: retailers
      })
    }
  }

  render () {
    const {
      loading,
      displayRetailers,
      categoryOptions,
      selectedCategory
    } = this.state

    if (loading) {
      return <LoadingIndicator/>
    }

    const title = selectedCategory === 'all' ? 'Boost Retailers' : `${selectedCategory} Retailers`

    return <Layout
      cart
      title={title}
      hasSidebar
      optionsTitle="Categories"
      optionsData={categoryOptions}
      selectedOptionId={selectedCategory}
    >
      <View style={styles.grid}>
        {
          displayRetailers.map(retailer => {
            return this.renderRetailer(retailer)
          })
        }
      </View>
    </Layout>
  }
}
