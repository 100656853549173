/* eslint-disable react/prop-types */
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { colours } from '../styles/constants'
import isWeb from '../helpers/isWeb'
import BreakpointWatcher from '../helpers/BreakpointWatcher'
import lineHeight from '../helpers/lineHeight'

export const MODAL_OPEN_CLASS = 'modal-open'
export default class Component extends React.Component {
  state = {
    exceedsMediumBreakpoint: false
  }

  componentDidMount () {
    document.body.classList.add(MODAL_OPEN_CLASS)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    BreakpointWatcher.addComponent(this)
  }

  componentWillUnmount () {
    document.body.classList.remove(MODAL_OPEN_CLASS)
    BreakpointWatcher.removeComponent(this)
  }

  close = () => {
    const { close } = this.props
    if (close) close()
  }

  render () {
    const { h1, p, children, extra, linksStyle } = this.props
    return (
      <Background>
        <Wrapper exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint}>
          <Close onClick={() => this.close()} title="Close modal">Close</Close>
          <Inner exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint}>
            <View>
              <Text style={styles.h1}>{h1}</Text>
              {p && <Text style={styles.p}>{p}</Text>}
              {extra && extra}
            </View>
            <View style={{ ...links, ...linksStyle }}>{children}</View>
          </Inner>
        </Wrapper>
      </Background>
    )
  }
}

const Background = props => {
  const backgroundStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, .5)',
    zIndex: 10
  }
  return (
    <View style={isWeb ? backgroundStyles : {}}>{props.children}</View>
  )
}

const Wrapper = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const wrapperStyles = {
    borderRadius: 4,
    paddingTop: 35,
    paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: 'white',
    position: 'absolute',
    width: '100%',
    maxWidth: exceedsMediumBreakpoint ? 544 : 'calc(100% - 30px)',
    top: '150px',
    left: '50%',
    transform: [{ translate: '-50%, 0' }],
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 12,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 11
  }
  return (
    <View style={exceedsMediumBreakpoint ? { ...wrapperStyles, minHeight: 290 } : wrapperStyles}>{props.children}</View>
  )
}

const Inner = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const innerStyles = {
    justifyContent: 'space-between',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto'
  }
  return (
    <View style={exceedsMediumBreakpoint ? { ...innerStyles, minHeight: 240 } : innerStyles}>{props.children}</View>
  )
}

const Close = props => {
  const closeStylesCss = `
    .close-style {
        appearance: none;
        border: 0;
        background: transparent url(${require('../images/close-grey.png')}) center center / 100% 100% no-repeat;
        width: 19px;
        height: 19px;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        font-size: 0;
    }
    .close-style:active {
      opacity: 0.7
    }
  `
  return (
    <>
      {isWeb && <><style type='text/css'>{closeStylesCss}</style>
        <button onClick={() => props.onClick()} className='close-style'>{props.children}</button></>}
    </>
  )
}

const styles = StyleSheet.create({
  h1: {
    fontSize: 32,
    lineHeight: lineHeight(38),
    color: colours.primary,
    fontFamily: 'greycliff-extrabold',
    marginBottom: 5
  },
  p: {
    fontSize: 16,
    lineHeight: lineHeight(22),
    color: 'black',
    fontFamily: 'greycliff'
  }
})

const links = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 30
}
