import React from 'react'
import Layout from '../../components/Layout'
import { breakpoints } from '../../styles/constants'
import { H1, P, TextShared } from '../../styles/globalClasses'
import getSuperOptions from '../../data/superOptions'
import styled from 'styled-components'
import storage from '../../helpers/storage'
import api from '../../helpers/boost-client-js-library/api'
import checkRole from '../../helpers/checkRole'
import isWeb from '../../helpers/isWeb'
import reSuperOptions from '../../helpers/reSuperOptions'
import BreakpointWatcher from '../../helpers/BreakpointWatcher'
import { View } from 'react-native'

const Sections = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    @media(min-width: ${breakpoints.medium}px){
        flex-direction: row;
        margin-left: -40px;
        > * {
            flex: 1;
            padding-left: 40px;
        }
    }
`

const Section = View

const Style = {
  Section: {
    marginBottom: 30,
    paddingRight: 0
  },
  H1: {
    margin: 0
  },
  P: {
    marginTop: 10,
    marginBottom: 10
  },
  A: {
    textDecorationLine: 'none'
  }
}

const A = isWeb && styled.a`
    ${TextShared}
    color: black;
    font-family: greycliff-bold;
`

const data = [
  {
    title: 'Boost Collateral',
    text: 'Download posters, brochures and more to be displayed in your offices to promote Boost.',
    link: `${process.env.REACT_APP_BASE_URL}/collateral`,
    linkText: 'View Collateral ›'
  },
  {
    title: 'FAQs',
    text: 'Download Boost FAQs to upload to your own website or email to staff.',
    link: `${process.env.REACT_APP_BASE_URL}/faq`,
    linkText: 'View FAQs (PDF) ›'
  },
  {
    title: 'How-to Guides',
    text: 'Handy PDF guides that you can distribute to staff.',
    link: `${process.env.REACT_APP_BASE_URL}/guides`,
    linkText: 'View Guides ›'
  },
  {
    title: 'Roll-out Checklist',
    text: 'View our recommended steps to ensure your staff get the most out of the Boost programme.',
    link: `${process.env.REACT_APP_BASE_URL}/checklist`,
    linkText: 'View Checklist (PDF) ›'
  }
]

export default class Resources extends React.Component {
  state = {
    options: [],
    exceedsMediumBreakpoint: false
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
    const { history } = this.props
    storage.token.get().then(token => {
      api.profile.get(token).then(profile => {
        const roles = checkRole(profile.roles)

        if (!roles.includes('bp')) {
          history.push('/profile')
          return false
        }
        api.organisation.get(token).then(organisation => {
          if (!organisation.tier) return false
          this.setState({
            options: reSuperOptions(organisation.tier, getSuperOptions(roles.includes('su')))
          })
        })
      })
    })
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  render () {
    const { options, exceedsMediumBreakpoint } = this.state
    return (
      <Layout
        title="Resources"
        optionsData={options}
      >
        <Sections>
          {data.map((item, i) => (
            <Section style={Style.Section} key={i}>
              <H1 style={Style.H1}>{item.title}</H1>
              <P style={Style.P} exceedsMediumBreakpoint={exceedsMediumBreakpoint}>{item.text}</P>
              <A style={Style.A} href={item.link}>{item.linkText}</A>
            </Section>
          ))}
        </Sections>
      </Layout>
    )
  }
}
