import React from 'react'
import { View } from 'react-native'
import { gutter, colours } from '../styles/constants'
import isWeb from '../helpers/isWeb'
import WelcomeMessage from './WelcomeMessage'
import SearchBar from './Filters/SearchBar'
import Dropdown from './Filters/Dropdown'
import Options from './Options'
import OffersCartBar from './OffersCartBar'
import TabBar from './TabBar'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import BreakpointWatcher from '../helpers/BreakpointWatcher'

const Layout = props => {
  const { style, isSuperLayout, exceedsMediumBreakpoint, children, ...rest } = props
  const layoutStyles = {
    backgroundColor: 'white',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
    width: '100%'
  }

  if (isWeb) {
    layoutStyles.minHeight = 'calc(100vh - 171px)'
  }

  if (isSuperLayout && !exceedsMediumBreakpoint) {
    layoutStyles.backgroundColor = colours.grey
  }

  return (
    <View style={ { ...layoutStyles, ...style } } {...rest}>{children}</View>
  )
}

const Inner = props => {
  const { exceedsMediumBreakpoint, exceedsLargeBreakpoint, children, style = {}, ...rest } = props
  const webStyles = {
    ...(exceedsMediumBreakpoint ? {
      marginTop: 50,
      paddingTop: 0,
      paddingRight: gutter.medium,
      paddingBottom: 0,
      paddingLeft: gutter.medium
    } : {
      display: 'flex',
      flexDirection: 'column-reverse'
    }),
    ...(exceedsLargeBreakpoint ? {
      display: 'flex',
      maxWidth: 1140,
      paddingLeft: 0,
      paddingRight: 0
    } : {})
  }

  const _style = {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    flexDirection: 'row',
    paddingTop: 0,
    paddingBottom: isWeb ? 0 : 120,
    paddingLeft: gutter.small,
    paddingRight: gutter.small,
    display: isWeb ? 'block' : 'flex',
    ...(isWeb ? webStyles : {}),
    ...style
  }

  return (
    <View style={_style} {...rest}>{children}</View>
  )
}

const Content = props => {
  const { exceedsLargeBreakpoint, exceedsMediumBreakpoint, style = {}, hasSidebar, children, ...rest } = props
  const _style = {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    paddingBottom: hasSidebar ? 0 : 20,
    ...(hasSidebar ? {
      ...(exceedsMediumBreakpoint ? {
        display: 'block',
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: 960
      } : {}),
      ...(exceedsLargeBreakpoint ? {
        paddingRight: 30
      } : {})
    } : {}),
    ...style
  }

  return (
    <View
      style={_style}
      {...rest}>{children}</View>
  )
}

class Component extends React.Component {
  state = {
    exceedsMediumBreakpoint: false,
    exceedsLargeBreakpoint: false
  }

  static propTypes = {
    title: PropTypes.string,
    smallTitle: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    subHeading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    optionsTitle: PropTypes.string,
    optionsClassName: PropTypes.string,
    back: PropTypes.object,
    search: PropTypes.object,
    categorySearch: PropTypes.object,
    optionsData: PropTypes.array,
    isLoggedIn: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    cart: PropTypes.object,
    style: PropTypes.any,
    className: PropTypes.string
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  render () {
    const { title, smallTitle, subHeading, children, optionsTitle, optionsData, optionsClassName, back, search, categorySearch, cart, style, isSuperLayout = false, selectedOptionId = null } = this.props
    const hasSidebar = isWeb && optionsData
    const hasCategorySearch = categorySearch !== undefined && !isWeb // if this has data and app only
    const isLoggedIn = this.props.isLoggedIn !== false
    const { exceedsLargeBreakpoint, exceedsMediumBreakpoint } = this.state

    return (
      <React.Fragment>
        {isWeb && <style type="text/css">
          {`@media print {
            #id_welcome_message {
              display: none !important;
            }
            
            #id_offers_cart_bar {
              display: none !important;
            }
          }`}
        </style>
        }
        <Helmet>
          <title>{title || ''}</title>
        </Helmet>
        <Layout style={style} isSuperLayout={isSuperLayout} exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
          <WelcomeMessage title={title} smallTitle={smallTitle} subHeading={subHeading} back={{ ...back }} nativeID="id_welcome_message"/>
          {hasSidebar && <Options optionsTitle={optionsTitle} optionsData={optionsData}
            optionsClassName={[optionsClassName, 'hide-on-medium']} selectedOptionId={selectedOptionId}/>}
          {search !== undefined && <SearchBar {...search} />}
          {hasCategorySearch && <Dropdown {...categorySearch} />}
          {cart && isWeb && <OffersCartBar {...cart} nativeID="id_offers_cart_bar"/>}
          <Inner exceedsLargeBreakpoint={exceedsLargeBreakpoint} exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
            <Content exceedsLargeBreakpoint={exceedsLargeBreakpoint} exceedsMediumBreakpoint={exceedsMediumBreakpoint} hasSidebar={hasSidebar}>{children}</Content>
            {hasSidebar && isLoggedIn && <Options optionsTitle={optionsTitle} optionsData={optionsData}
              optionsClassName={[optionsClassName, 'show-on-medium']} selectedOptionId={selectedOptionId}/>}
          </Inner>
        </Layout>
        {!isWeb && <TabBar/>}
      </React.Fragment>
    )
  }
}

export default Component
