/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react'
import Layout from '../../components/Layout'
import { Text, View } from 'react-native'
import isWeb from '../../helpers/isWeb'
import getDeviceId from '../../helpers/deviceId'
import { colours, breakpoints, backButtonStyle } from '../../styles/constants'
import styled from 'styled-components'
import { Fields, P, formStyles } from '../../styles/globalClasses'
import { Primary } from '../../components/Inputs/Buttons'
import getSuperOptions from '../../data/superOptions'
import Circle from '../../components/Circle'
import storage from '../../helpers/storage'
import api from '../../helpers/boost-client-js-library/api'
import LoadingIndicator from '../../components/LoadingIndicator'
import Notification from '../../components/Notification'
import Modal from '../../components/Modal'
import checkRole from '../../helpers/checkRole'
import consoleLog from '../../helpers/consoleLog'
import moment from 'moment'
import { CheckField, TextField } from '../../components/Inputs/Fields'
import InlineLoadingIndicator from '../../components/InlineLoadingIndicator'
import BreakpointWatcher from '../../helpers/BreakpointWatcher'

const Add = {
  Options: styled.div`
        display: flex;
        align-items: stretch;
        flex-direction: column;
        @media(min-width: ${breakpoints.medium}px){
            flex-direction: row;
            justify-content: space-between;
            margin-left: -30px;
        }
    `,
  Option: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        flex: 1;
        @media(min-width: ${breakpoints.medium}px){
            padding-left: 30px;
        }
    `,
  Circle: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: exceedsMediumBreakpoint ? 45 : 15,
      ...style
    }
    return <Circle exceedsMediumBreakpoint={exceedsMediumBreakpoint} style={_style} {...props}>{children}</Circle>
  },
  // buttons
  Buttons: styled.div`
        display: flex;
        align-items: stretch;
        flex-direction: column;
        width: 600px;
        margin: 40px auto; 
        > * {
            margin: 0 0 10px;
        }
        @media(min-width: ${breakpoints.medium}px){
            flex-direction: row;
            justify-content: space-between;
        }
    `,
  Back: Primary
}

const Types = isWeb && {
  Wrapper: styled.table`
        font-family: greycliff;
        font-size: 16px;
        line-height: 19px;
        border-collapse: collapse;
        width: 100%;
        max-width: 600px;
        margin: auto;

        th,
        td {
            padding: 8px 0;
            text-align: left;
            &:last-child {
                text-align: right;
            }
        }

        th {
            border-bottom: 2px solid black;
            &:nth-child(2){
                text-align: center;
            }
        }

        th,
        .current {
            font-family: greycliff-bold;
        }

        td {
            border-bottom: 1px solid ${colours.light20};
            padding-bottom: 15px;
            &:nth-child(2){
                text-align: center;
            }
        }

        ${Fields}
        input {
            width: 133px;
            height: 45px;
            max-width: 100%;
            font-size: 16px!important;
            &[readonly]{
                border-color: ${colours.light20}!important;
            }
        }
    `
}

const notesStyle = {
  maxWidth: 600,
  margin: 'auto',
  marginTop: 20,
  fontSize: 16
}

const paymentsNotesStyle = {
  ...notesStyle,
  paddingTop: 15,
  width: 600
}

const Notes = isWeb && P

const PaymentNotes = isWeb && P

const infoStyle = {
  fontSize: 20,
  margin: 'auto',
  marginTop: 20,
  width: 600
}

const Info = isWeb && P

const Right = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
`

const PaymentTypeStyle = {
  CheckBoxItem: {
    width: '50%',
    display: 'flex'
  },
  CheckBoxBase: {
    marginTop: 15,
    marginBottom: 5,
    flexDirection: 'row',
    width: 600,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  Label: {
    fontSize: 14
  }
}

const PONumberContainer = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    width: 600px;
    margin: auto;
`

const confirmationBodyStyle = {
  fontFamily: 'greycliff'
}

const subscriptionExpiresSoon = (expiryDate) => {
  // We consider a subscription expire "soon" if expiry is in the next 9 weeks. So don't let them add users.
  // Refer ticket BH-1951
  const expiresSoonWeeks = 9
  const expiresSoonFutureDate = moment().add(expiresSoonWeeks * 7, 'days')
  // if expiry date is before 9 weeks from now, then the subscription expires soon
  return expiryDate < expiresSoonFutureDate
}

const confirmCancelStyle = {
  backgroundColor: '#D8C0B1'
}

const ConfirmCancel = Primary

const mainProceedText = 'Confirm'

export default class Super extends React.Component {
  state = {
    tier: '',
    numVehiclesInput: 1,
    cost: '',
    max: 250,
    error: null,
    btnProceedTxt: mainProceedText,
    isProceeding: false,
    errorModal: false,
    IsErrorPayment: this.getParamValue('fail'),
    superOptions: [],
    licenseAddAllowed: null,
    paymentType: 'credit_card',
    confirmModalOpen: false,
    poNumber: '',
    exceedsMediumBreakpoint: false
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
    const { appKey, history } = this.props
    storage.token.get(appKey).then(token => {
      api.profile.get(token).then(profile => {
        const roles = checkRole(profile.roles)
        if (!roles.includes('bp')) {
          history.push('/profile')
          return false
        }

        this.setState({
          superOptions: getSuperOptions(roles.includes('su'))
        }
        )

        api.organisation.get(token).then(organisation => {
          if (organisation.code && organisation.code !== 200) this.setState({ error: organisation.message ? organisation.message : 'Something went wrong. Please please again.' })
          if (!organisation.tier) {
            history.push('/profile')
            return false
          }

          const subscriptionExpiry = moment(organisation.expiry)

          this.setState({
            tier: Number(organisation.tier),
            numVehicles: organisation.numberOfVehicles,
            currentPlanMaxUsers: organisation.currentPlanMaxUsers,
            licenseAddAllowed: !subscriptionExpiresSoon(subscriptionExpiry)
          })
        })
      })
      api.subscriptions.getAmount(token).then(res => {
        if (res && (res.totalAmount || res.totalAmount === 0)) {
          this.setState({ cost: res.totalAmount.toFixed(2) })
        }
      })
    })
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  getParamValue (name) {
    const param = window.location.search.split(name + '=')[1]
    if (param) {
      return param.split('&')[0]
    } else {
      return false
    }
  }

  handlePaymentTypeChange (paymentType) {
    this.setState({ paymentType })
  }

  confirmModalShow () {
    this.setState({ confirmModalOpen: true })
  }

  handlePayment = (paymentType, poNumber) => {
    const { appKey } = this.props
    this.setState({
      isProceeding: true,
      errorModal: false
    })

    storage.token.get(appKey).then(token => {
      const access = { token, device: getDeviceId() }
      const quantity = this.state.numVehiclesInput
      try {
        api.subscriptions.licenses(access, quantity, paymentType, poNumber).then(res => {
          if (res.url) {
            window.location.href = res.url
          } else if (paymentType === 'invoice') {
            const { history } = this.props
            history.push('/profile/confirmation/add-licenses')
          } else {
            consoleLog(res)
            this.setState({
              isProceeding: false,
              btnProceedTxt: mainProceedText,
              errorModal: true
            })
          }
        })
      } catch (e) {
        consoleLog(e)
        this.setState({
          isProceeding: false,
          btnProceedTxt: mainProceedText,
          errorModal: true
        })
      }
    })
  }

  hideConfirmModal () {
    this.setState({
      confirmModalOpen: false
    })
  }

  handleModalClose = () => {
    this.setState({
      IsErrorPayment: false,
      errorModal: false
    })
  }

  renderConfirmationModalButtons () {
    const { poNumber, isProceeding, paymentType } = this.state
    const buttonText = paymentType === 'credit_card' ? 'Proceed to Payment ›' : 'Confirm ›'

    return <React.Fragment>
      <ConfirmCancel style={confirmCancelStyle} onPress={() => { this.hideConfirmModal() }} disabled={isProceeding}>
        <Text>Back</Text>
      </ConfirmCancel>
      <Primary onPress={() => this.handlePayment(paymentType, poNumber)} disabled={isProceeding}>
        {!isProceeding && <Text>{ buttonText }</Text>}
        {isProceeding && <InlineLoadingIndicator/>}
      </Primary>
    </React.Fragment>
  }

  renderConfirmationModalContent (numVehicles, totalCost, paymentType, poNumber) {
    return <View style={confirmationBodyStyle}>
      <p>You are about to order <strong>{numVehicles}</strong> Boost License{ numVehicles === 1 ? '' : 's'} at a total cost of <strong>${totalCost}</strong> (including GST).</p>
      {paymentType === 'credit_card' && <p>You will be redirected to the credit card payment page.</p>}
      {paymentType === 'invoice' && <p>Are you sure you want to order additional licenses and be charged by invoice?</p>}
      {(paymentType === 'invoice' && poNumber !== '') && <p>Purchase Order Number: <strong>{poNumber}</strong></p>}
    </View>
  }

  render () {
    const { history } = this.props
    const {
      tier,
      numVehicles,
      numVehiclesInput,
      currentPlanMaxUsers,
      cost,
      max,
      error,
      btnProceedTxt,
      errorModal,
      IsErrorPayment,
      superOptions,
      licenseAddAllowed,
      paymentType,
      confirmModalOpen,
      poNumber,
      exceedsMediumBreakpoint
    } = this.state
    const costPerVehicle = cost || 0
    const thead = [
      'Boost Licenses',
      'Cost per Licenses',
      'Total'
    ]
    const total = isNaN(numVehiclesInput) || numVehiclesInput < 0 || numVehiclesInput > max ? '' : numVehiclesInput * costPerVehicle
    const isLicensesOverMax = numVehiclesInput > max
    return (
      <Layout
        title="Add More Licenses"
        optionsData={superOptions}
        tier={tier}
      >
        {error && <Notification>{error}</Notification>}
        {tier.length === 0 && !error
          ? <LoadingIndicator />
          : <React.Fragment>
            {tier <= 1
              ? <Notification>You do not have access to this page.</Notification>
              : <View>
                <Add.Options>
                  <Add.Option>
                    <Add.Circle exceedsMediumBreakpoint={exceedsMediumBreakpoint} amount={numVehicles} max={currentPlanMaxUsers} />
                  </Add.Option>
                </Add.Options>

                {licenseAddAllowed === true && <React.Fragment><Types.Wrapper>
                  <thead>
                    <tr>{thead.map(item => <th key={item}>{item}</th>)}</tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input value={numVehiclesInput}
                          onChange={e => this.setState({ numVehiclesInput: e.target.value })}/>
                      </td>
                      <td>${costPerVehicle}</td>
                      <td>
                        <input value={`$${total && total.toFixed(2)}`} readOnly/>
                      </td>
                    </tr>
                  </tbody>
                </Types.Wrapper>
                <Notes style={notesStyle} exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                                    Note: you can add a maximum of {max} at a time. If you would like to add more
                                    than {max}, please contact your Account Manager.
                </Notes>

                <PaymentNotes style={paymentsNotesStyle} exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                                    How would you like to make payment?
                </PaymentNotes>
                <CheckField
                  id="payment_type_select"
                  type="radio"
                  items={
                    [
                      { label: 'Credit Card', value: 'credit_card' },
                      { label: 'Invoice', value: 'invoice' }
                    ]
                  }
                  checked={paymentType}
                  onChange={value => this.handlePaymentTypeChange(value)}
                  editable={true}
                  baseStyle={PaymentTypeStyle.CheckBoxBase}
                  labelStyle={PaymentTypeStyle.Label}
                  itemStyle={PaymentTypeStyle.CheckBoxItem}
                />
                {paymentType === 'invoice' && <PONumberContainer>
                  <Text style={formStyles.text}>Purchase Order Number (Optional)</Text>
                  <TextField
                    id="id_n3PoNumber"
                    placeholder="Purchase Order Number"
                    placeholderTextColor={colours.black60}
                    onChange={e => { this.setState({ poNumber: e }) }}
                    value={poNumber}
                    baseStyle={formStyles.fieldWrap}
                    style={formStyles.field}
                    maxlength={50}
                  />
                </PONumberContainer>
                }
                <Info style={infoStyle} exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Price includes GST</Info>
                <Add.Buttons>
                  <Add.Back style={backButtonStyle} onPress={() => history.goBack()}>‹ Back</Add.Back>
                  <Primary onPress={() => {
                    let licenseNum = parseFloat(numVehiclesInput)
                    if (isNaN(licenseNum)) { licenseNum = 1 } else { licenseNum = Math.round(licenseNum) }

                    this.setState({
                      numVehiclesInput: licenseNum
                    })
                    this.confirmModalShow()
                  }
                  }
                  disabled={isLicensesOverMax}> {btnProceedTxt} ›</Primary>
                </Add.Buttons>
                </React.Fragment>
                }
                {licenseAddAllowed === false && <React.Fragment>
                  <Notes style={notesStyle} exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                                    Your Boost Subscription is due to renew soon. Please contact your account manager
                                    if you wish to add more licenses.
                  </Notes>

                </React.Fragment>}
              </View>
            }
          </React.Fragment>
        }
        { confirmModalOpen && <Modal
          close={() => { this.hideConfirmModal() }}
          h1={'Confirm License Purchase'}
          extra={
            this.renderConfirmationModalContent(numVehiclesInput, `${total && total.toFixed(2)}`, paymentType, poNumber)
          }
        >
          {this.renderConfirmationModalButtons()}
        </Modal>
        }
        {(errorModal || IsErrorPayment) &&
                    <Modal
                      h1={'Well this sucks' }
                      p="The transaction failed. Please try again. If the problem persists contact your account manager."
                      close={() => this.handleModalClose()}
                    >
                      <Right>
                        <Primary onPress={() => this.handleModalClose()}>Close</Primary>
                      </Right>
                    </Modal>
        }
      </Layout>
    )
  }
}
