// search box for tables, usually used in super admin views, web only
import React from 'react'
import styled from 'styled-components'
import { colours, breakpoints } from '../styles/constants'
import isWeb from '../helpers/isWeb'

const Search = isWeb && styled.form`
    font-family: greycliff;
    line-height: 19px;
    position: relative;
    display: flex;
    max-width: 335px;
    align-items: center;
    @media(min-width: ${breakpoints.medium}px){
        flex-direction: row;
    }

    label {
        font-size: 16px;
        margin-right: 5px;
        margin-bottom: 5px;
        @media(min-width: ${breakpoints.medium}px){
            margin-bottom: 0;
        }
    }

    input {
        appearance: none;
        font-size: 12px;
        border: 1px solid ${colours.black30};
        border-radius: 4px;
        padding: 8px 5px;
        padding-right: 30px;
        width: 100%;
        @media(min-width: ${breakpoints.medium}px){
            width: 281px;
            max-width: 100%;
            flex: 1 1 281px;
        }
        ::placeholder {
            color: black;
        }
    }

    button {
        cursor: pointer;
        font-size: 0;
        border: 0;
        width: 21px;
        height: 21px;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        background: transparent url(${require('../images/magnifying-glass.png')}) 0 0 / 100% 100% no-repeat;
        @media(max-width: ${breakpoints.small}px){
            transform: none;
            top: 40px;
        }
    }
    button[type="reset"]{
        right: 30px;
        width: 15px;
        height: 15px;
        background-image: url(${require('../images/close-grey.png')})
    }
    @media(max-width: ${breakpoints.small}px){
        button[type="reset"]{
            top: 42px !important;
        }
    }`

const Component = props => {
  const { onChange, inputStyle, formStyle, submitStyle, resetStyle, ...rest } = props

  return (
    <Search style={formStyle} className={props.className} onSubmit={e => props.onSubmit(e)}>
      <label htmlFor={props.id}>Search:</label>
      <input
        onChange={e => onChange(e)}
        placeholder={props.placeholder ? props.placeholder : 'Type here'}
        style={inputStyle}
        {...rest}
      />
      <button title="Search" type="submit" style={submitStyle}>Submit</button>
      {props.onReset && <button type="reset" title="Reset search" onClick={() => props.onReset()} style={resetStyle}>Reset</button>}
    </Search>
  )
}

export default Component
