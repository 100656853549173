// lazy load component for app
import React from 'react'
import { View, Dimensions } from 'react-native'

export default class InViewPort extends React.Component {
  state = {
    isVisible: false
  }

  rectTop = 0
  rectBottom = 0
  rectWidth = 0
  measureInProgress = false
  mounted = false
  firstLoad = true

  componentDidMount () {
    this.mounted = true
    /* if (!this.props.disabled) {
        this.startWatching()
      } */
  }

  componentWillUnmount () {
    this.mounted = false
    this.stopWatching()
  }

  componentDidUpdate (prevProps) {
    if (this.state.isVisible) {
      return
    }

    if (this.props.maxVisible > this.props.catIndex) {
      this.setState({ isVisible: true })
      return
    }

    if (this.firstLoad || this.props.maxVisible !== prevProps.maxVisible || prevProps.disabled !== this.props.disabled) {
      this.firstLoad = false
      if (this.props.disabled) {
        this.stopWatching()
      } else if (this.props.maxVisible >= (this.props.catIndex - 1)) {
        this.lastValue = null
        this.startWatching()
      }
    }
  }

  startWatching () {
    if (this.interval) {
      return
    }
    this.interval = setInterval(() => {
      if (!this.myview || this.measureInProgress) {
        return
      }
      this.measureInProgress = true

      this.myview.measure((x, y, width, height, pageX, pageY) => {
        this.measureInProgress = false
        this.rectTop = pageY
        this.rectBottom = pageY + height
        this.rectWidth = pageX + width
        this.checkIsInViewPort()
      })
    }, this.props.delay || 100)
  }

  stopWatching () {
    if (this.interval != null) {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  checkIsInViewPort () {
    if (!this.mounted) { return }

    const window = Dimensions.get('window')
    const isVisible =
        this.rectBottom !== 0 &&
        this.rectTop >= 0 &&
        this.rectBottom <= window.height &&
        this.rectWidth > 0 &&
        this.rectWidth <= window.width
    if (this.lastValue !== isVisible) {
      this.lastValue = isVisible
      if (isVisible) {
        this.stopWatching()
      }
      this.mounted && this.setState({ isVisible: isVisible }, () => {
        if (isVisible) { this.props.updateVisibleIndex(this.props.catIndex) }
      })
    }
  }

  render () {
    const { isVisible } = this.state
    return (
      <View
        collapsable={false}
        ref={component => {
          this.myview = component
        }}
        {...this.props}
      >
        {this.props.render(isVisible)}
      </View>
    )
  }
}
