import React from 'react'
import AppRouter, { routes } from './AppRouter'
import Profile from './profile/Profile'
import ChangePassword from './profile/ChangePassword'
import Dashboard from './profile/super/Dashboard'
import Upgrade from './profile/super/Upgrade'
import Customisation from './profile/super/Customisation'
import UsersAndOrdering from './profile/super/UsersAndOrdering'
import AddUsers from './profile/super/AddUsers'
import OrderCards from './profile/super/OrderCards'
import OfferManagement from './profile/super/OfferManagement'
import AddOffers from './profile/super/AddOffers'
import Resources from './profile/super/Resources'
import Renewal from './profile/super/Renewal'
import Billing from './profile/super/Billing'
import Confirmation from './profile/confirmation/Confirmation'
import AddLicenses from './profile/confirmation/AddLicenses'
import AddUser from './profile/confirmation/AddUser'
import CallBack from './profile/confirmation/CallBack'
import InstantQuote from './profile/confirmation/InstantQuote'
import UpgradeMembership from './profile/confirmation/UpgradeMembership'
import CustomisationError from './profile/error/CustomisationError'
import PayMembership from './profile/confirmation/PayMembership'
import RenewalMembership from './profile/confirmation/RenewalMembership'
import Customoffers from './profile/confirmation/Customoffers'
import NoMatchPage from './components/NoMatchPage'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import ReactGA from 'react-ga4'
import CancelSubscription from './profile/super/CancelSubscription'
import CancelSubscriptionConfirm from './profile/super/CancelSubscriptionConfirm'
import WebDevSignIn from './auth/WebDevSignIn'
import RetailersWeb from './RetailersWeb'
import Offers from './Offers'
import { createRoot } from 'react-dom/client'

ReactGA.initialize(process.env.REACT_APP_GA_CODE)

const webRoutes = routes.concat([
  { path: '/retailers', component: RetailersWeb, loggedIn: true, restricted: 'web' },
  { path: '/retailers/:retailerId', component: Offers, loggedIn: true, restricted: 'web' },

  // profile
  { path: '/profile/change-password', component: ChangePassword, loggedIn: true },

  // super user
  { path: '/profile/dashboard', component: Dashboard, loggedIn: true, restricted: 'web' },
  { path: '/profile/upgrade', component: Upgrade, loggedIn: true, restricted: 'web' },
  { path: '/profile/organisation', component: Profile, loggedIn: true, restricted: 'web' },
  { path: '/profile/customisation', component: Customisation, loggedIn: true, restricted: 'web' },
  { path: '/profile/customisation/suppliers', component: Customisation, loggedIn: true, restricted: 'web' },
  { path: '/profile/user-management-and-ordering', component: UsersAndOrdering, loggedIn: true, restricted: 'web' },
  { path: '/profile/user-management-and-ordering/add-users', component: AddUsers, loggedIn: true, restricted: 'web' },
  { path: '/profile/user-management-and-ordering/add-licenses', component: OrderCards, loggedIn: true, restricted: 'web' },
  { path: '/profile/offer-management', component: OfferManagement, loggedIn: true, restricted: 'web' },
  { path: '/profile/offer-management/add-offers', component: AddOffers, loggedIn: true, restricted: 'web' },
  { path: '/profile/resources', component: Resources, loggedIn: true, restricted: 'web' },
  { path: '/profile/billing', component: Billing, loggedIn: true, restricted: 'web' },
  { path: '/profile/renewal', component: Renewal, loggedIn: true, restricted: 'web' },
  { path: '/profile/pay', component: Renewal, loggedIn: true, restricted: 'web' },
  { path: '/profile/cancel', component: CancelSubscription, loggedIn: true, restricted: 'web' },
  { path: '/profile/cancel/complete', component: CancelSubscriptionConfirm, loggedIn: true, restricted: 'web' },
  { path: '/profile/confirmation', component: Confirmation, loggedIn: true, restricted: 'web' },
  { path: '/profile/confirmation/add-licenses', component: AddLicenses, loggedIn: true, restricted: 'web' },
  { path: '/profile/confirmation/add-user', component: AddUser, loggedIn: true, restricted: 'web' },
  { path: '/profile/confirmation/call-back', component: CallBack, loggedIn: true, restricted: 'web' },
  { path: '/profile/confirmation/instant-quote', component: InstantQuote, loggedIn: true, restricted: 'web' },
  { path: '/profile/confirmation/custom-offers', component: Customoffers, loggedIn: true, restricted: 'web' },
  { path: '/profile/error/customisation-error', component: CustomisationError, loggedIn: true, restricted: 'web' },
  { path: '/profile/confirmation/pay-membership', component: PayMembership, loggedIn: true, restricted: 'web' },
  { path: '/profile/confirmation/renewal-membership', component: RenewalMembership, loggedIn: true, restricted: 'web' },
  // upgrade, with a hash, doesn't require login
  { path: '/profile/upgrade-plan', component: Renewal, restricted: 'web' },
  // post upgrade also does not request a login (just shows a message)
  { path: '/profile/confirmation/upgrade-membership', component: UpgradeMembership, restricted: 'web' },

  // only for developers when running locally
  { path: '/dev-sign-in', component: WebDevSignIn, restricted: 'web' },

  // 404
  { path: '*', component: NoMatchPage, loggedIn: true, restricted: 'web' }
])

const root = createRoot(document.getElementById('root'))

root.render(
  <div>
    <Router>
      <Switch>
        <AppRouter routes={webRoutes} />
      </Switch>
    </Router>
  </div>
)
