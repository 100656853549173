/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react'
import Layout from './components/Layout'
import { View, Text, Image, StyleSheet, Dimensions, Linking } from 'react-native'
import isWeb from './helpers/isWeb'
import getDeviceId from './helpers/deviceId'
import { colours, gutter } from './styles/constants'
import { Primary } from './components/Inputs/Buttons'
import Link from './components/Link'
import { categoryList } from './Offers'
import ExpiryBar from './components/ExpiryBar'
import storage from './helpers/storage'
import api from './helpers/boost-client-js-library/api'
import offerType from './helpers/offerType'
import checkRole from './helpers/checkRole'
import ImageManager from './helpers/ImageManager'
import ConnectionManager from './helpers/ConnectionManager'
import EntityFacade, { EntityType } from './helpers/EntityFacade'
import PropTypes from 'prop-types'
import BoostAnalytics, { AnalyticDimension } from './BoostAnalytics'
import offerCartAddable from './helpers/offerCartAddable'
import BreakpointWatcher from './helpers/BreakpointWatcher'
import lineHeight from './helpers/lineHeight'
import categoryJoiner from './helpers/categoryJoiner'
import eventBus from './helpers/eventBus'
import Constants from 'expo-constants'
import { P } from './styles/globalClasses'
import moment from 'moment'

const gutterSmall = gutter.small
const width = Dimensions.get('window').width
const imageSize = width - (gutterSmall * 2) - 30

const Wrapper = props => {
  const { children, exceedsMediumBreakpoint, exceedsLargeBreakpoint } = props
  const wrapperStyles = {
    justifyContent: isWeb ? 'center' : 'space-between',
    ...(!isWeb ? {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 15,
      paddingLeft: 15
    } : {}),
    ...(isWeb && exceedsMediumBreakpoint ? {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    } : {}),
    ...(isWeb && exceedsLargeBreakpoint ? {
      flexDirection: 'row'
    } : {})
  }
  return (
    <View style={wrapperStyles}>{children}</View>
  )
}

const Back = props => {
  const { children, exceedsMediumBreakpoint, exceedsLargeBreakpoint, bottom, ...rest } = props
  const backStyles = {
    fontFamily: 'greycliff-extrabold',
    fontSize: 18,
    lineHeight: lineHeight(24),
    textAlign: 'center',
    marginBottom: 15,
    ...(exceedsMediumBreakpoint ? { textAlign: 'left' } : {}),
    ...(exceedsLargeBreakpoint ? { display: 'none' } : {}),
    ...(bottom ? {
      marginTop: 15,
      ...(exceedsMediumBreakpoint ? { display: 'none' } : {})
    } : {})
  }
  return (
    <Text style={backStyles} {...rest}>{children}</Text>
  )
}

const Header = props => {
  const { children, exceedsMediumBreakpoint, small, ...rest } = props
  const headerStyles = {
    marginBottom: 20,
    ...(small ? { display: 'none' } : {}),
    ...(isWeb ? {
      display: 'none',
      ...(exceedsMediumBreakpoint ? { display: 'flex' } : {})
    } : {}),
    ...(small && isWeb ? {
      display: 'flex',
      alignItems: 'center',
      ...(exceedsMediumBreakpoint ? { display: 'none' } : {})
    } : {})
  }
  return (
    <View style={headerStyles} {...rest}>{children}</View>
  )
}

const ImagesWrap = props => {
  const { children, exceedsMediumBreakpoint, exceedsLargeBreakpoint, ...rest } = props
  const imagesWrapStyles = {
    ...(isWeb && exceedsMediumBreakpoint ? {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 355
    } : {}),
    ...(isWeb && exceedsLargeBreakpoint ? {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 555,
      maxWidth: 555,
      width: 555
    } : {})
  }
  return (
    <View style={imagesWrapStyles} {...rest}>{children}</View>
  )
}

const ImageWrap = props => {
  const { children, exceedsMediumBreakpoint, exceedsLargeBreakpoint, height, ...rest } = props
  const imageWrapStyles = {
    width: '100%',
    height: height && !isWeb ? height : 335,
    borderRadius: 8,
    maxWidth: '100%',
    marginBottom: 10,
    ...(isWeb ? {
      width: 355,
      margin: 'auto',
      borderRadius: 4,
      ...(exceedsMediumBreakpoint ? { margin: 0, width: 355 } : {}),
      ...(exceedsLargeBreakpoint ? { height: 555, width: 555 } : {})
    } : {})
  }
  return (
    <View style={imageWrapStyles} {...rest}>{children}</View>
  )
}

const Content = props => {
  const { children, exceedsMediumBreakpoint, exceedsLargeBreakpoint, ...rest } = props
  const contentStyles = {
    flex: 1,
    ...(isWeb ? {
      ...(exceedsMediumBreakpoint ? { marginRight: 40 } : {}),
      ...(exceedsLargeBreakpoint ? { marginRight: 0, marginLeft: 60 } : {})
    } : {})
  }
  return (
    <View style={contentStyles} {...rest}>{children}</View>
  )
}

const TextWrapper = props => {
  const { children, inSmallBreakpoint, ...rest } = props
  const textWrapperStyles = {
    ...(!isWeb ? { flexDirection: 'row' } : {}),
    ...(isWeb && !inSmallBreakpoint ? { flexDirection: 'row' } : {})
  }
  return (
    <View style={textWrapperStyles} {...rest}>{children}</View>
  )
}

const Logo = props => {
  const { exceedsLargeBreakpoint, underImage, ...rest } = props
  const logoStyles = {
    width: isWeb ? 165 : 70,
    height: isWeb ? 122 : 50,
    marginLeft: 20,
    ...(isWeb ? {
      marginTop: 20,
      marginBottom: 15,
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'none',
      ...(exceedsLargeBreakpoint ? {
        marginTop: 0,
        marginRight: 0,
        marginBottom: 0,
        marginLeft: 20,
        display: 'flex'
      } : {})
    } : {}),
    ...(underImage && isWeb ? {
      display: 'flex',
      ...(exceedsLargeBreakpoint ? { display: 'none' } : {})
    } : {})
  }
  return (
    <Image style={logoStyles} {...rest}/>
  )
}

const Button = {
  Wrap: props => {
    const { children, exceedsMediumBreakpoint, ...rest } = props
    const wrapStyles = {
      marginTop: isWeb ? 30 : 15,
      flexDirection: 'row',
      justifyContent: 'space-between',
      ...(isWeb ? {
        flexDirection: 'column',
        ...(exceedsMediumBreakpoint ? { flexDirection: 'row' } : {})
      } : {})
    }
    return (
      <View style={wrapStyles} {...rest}>{children}</View>
    )
  },
  Link: props => {
    const { children, exceedsMediumBreakpoint, ...rest } = props
    const linkStyles = {
      ...(isWeb ? {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'auto',
        color: 'white',
        fontFamily: 'greycliff-bold',
        width: '100%',
        marginBottom: 10,
        ...(exceedsMediumBreakpoint ? {
          marginBottom: 0,
          marginRight: 10
        } : {})
      } : {})
    }
    return (
      <Link style={linkStyles} {...rest}>{children}</Link>
    )
  },
  ExtLink: props => {
    const { children, exceedsMediumBreakpoint, exceedsLargeBreakpoint, ...rest } = props
    const extLinkStyles = {
      ...(isWeb ? {
        display: 'flex',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'auto',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: 10,
        fontFamily: 'greycliff-bold',
        fontSize: 18,
        ...(exceedsMediumBreakpoint ? { fontSize: 14 } : {}),
        ...(exceedsLargeBreakpoint ? { fontSize: 18 } : {}),
        ...(exceedsMediumBreakpoint ? {
          marginBottom: 0,
          marginRight: 10
        } : {})
      } : {})
    }
    return (
      <Text style={extLinkStyles} {...rest}>{children}</Text>
    )
  },
  Cart: props => {
    const { children, isInCart, exceedsMediumBreakpoint, exceedsLargeBreakpoint, ...rest } = props
    const cartStyles = {
      fontSize: 18,
      backgroundColor: 'black',
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 'auto',
      ...(isInCart ? {
        backgroundColor: colours.black10,
        color: 'black'
      } : {}),
      ...(isWeb ? {
        ...(exceedsMediumBreakpoint ? {
          fontSize: 14,
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: 165
        } : {}),
        ...(exceedsLargeBreakpoint ? {
          fontSize: 18,
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: 195
        } : {})
      } : {})
    }
    return (
      <Primary style={cartStyles} {...rest}>{children}</Primary>
    )
  }
}

const statusTextAndExternal = offer => {
  /**
   * Returns a list where the first item is the "statusText" (text to display in the action button) and the second
   * item is whether to open it as a link or in-app (`true` if it is an external link)
   */
  if (offer.externalLink) {
    return ['Visit Website', true]
  }

  return ['Use Now', false]
}

export default class Offer extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object
    }),
    appKey: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    PageHit: PropTypes.func,
    Event: PropTypes.func
  }

  state = {
    id: this.props.match.params.id,
    cartItems: [],
    offer: {},
    typeLink: '/',
    webLink: null,
    textCollapsed: true,
    isInCart: false,
    offerImage: null,
    supplierLogo: null,
    inSmallBreakpoint: false,
    exceedsMediumBreakpoint: false,
    exceedsLargeBreakpoint: false,
    profile: {},
    organisation: {}
  }

  profileId = null

  mounted = false
  getData = () => {
    const { appKey, history, location } = this.props
    const { id } = this.state
    const openedByUrl = location.state && !!location.state.openedByUrl
    const route = location.pathname
    storage.token.get(appKey).then(token => {
      const access = { token, device: getDeviceId() }

      if (isWeb) {
        api.offers.categories(access).then(category => {
          if (category === undefined || category.items === undefined) return false
          this.mounted && this.setState({
            options: categoryList(category.items)
          })
        })
      }

      ConnectionManager.getManager().checkApiConnectivity().then((isOnline) => {
        const ef = new EntityFacade(isOnline, access)
        Promise.all([
          ef.getProfile(access),
          ef.getOffer(id)
        ]).then(([profile, offer]) => {
          this.profileId = profile.ID

          const { ID, externalLink } = offer
          const link = externalLink || ''
          const appLink = `/card/offer/${ID}?openedByUrl=${ openedByUrl ? 'true' : 'false' }}`

          if (isWeb) {
            if (!checkRole(profile.roles).includes('su')) {
              history.push('/profile/dashboard')
              return false
            } else {
              this.setState({ userID: profile.ID })
            }
          }
          const ba = new BoostAnalytics(this.profileId)

          ba.recordPageView(route, offer.title)
          ba.recordEvent('boost_offer_view',
            {
              [AnalyticDimension.offer_id]: ID,
              [AnalyticDimension.page_category]: categoryJoiner(offer.categories),
              [AnalyticDimension.contact_id]: this.profileId,
              [AnalyticDimension.opened_from_notification]: openedByUrl ? 'true' : 'false'
            }
          )
          this.setState({
            offer,
            type: offerType(offer.type),
            typeLink: isWeb ? link : appLink,
            webLink: link
          })
        })
      })
    })
  }

  getOfferImages (offer) {
    ConnectionManager.getManager().checkApiConnectivity().then((isOnline) => {
      const im = ImageManager.getManager(isOnline)

      im.getImageUrl(offer.ID, offer.image, EntityType.OFFER).then((offerImage) => {
        if (this.mounted) this.setState({ offerImage })
      })

      im.getImageUrl(offer.supplierID, offer.supplierLogo, EntityType.SUPPLIER).then((supplierLogo) => {
        if (this.mounted) this.setState({ supplierLogo })
      })
    })
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
    this.mounted = true
    this.getData()
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
    this.mounted = false
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!this.mounted) return

    if (prevState.offer === this.state.offer) return

    const { offer } = this.state

    this.getOfferImages(offer)
  }

  handleCartEvent = () => {
    const { isInCart, offer } = this.state
    this.setState({ isInCart: !isInCart })
    eventBus.dispatch('cartEvent', { offer, isAdd: !isInCart })
  }

  handleExternal = link => {
    const { offer, openedByUrl } = this.state

    const ba = new BoostAnalytics(this.profileId)

    ba.recordEvent('boost_offer_claim', {
      [AnalyticDimension.offer_id]: offer.ID,
      [AnalyticDimension.contact_id]: this.profileId,
      [AnalyticDimension.opened_from_notification]: openedByUrl ? 'true' : 'false',
      [AnalyticDimension.offer_url]: link,
      [AnalyticDimension.claim_type]: 'link'
    })

    if (isWeb) {
      window.open(link, '_blank')
    } else {
      Linking.openURL(link)
    }
  }

  renderCtaButton = () => {
    const { offer, type, typeLink, isInCart, webLink } = this.state

    const [buttonName, openExternal] = statusTextAndExternal(offer)
    const style = [styles.button, type === 'lto' ? styles.ltoButton : styles.generalButton]
    if (!offer.title) return false

    const button = () => {
      if ((isWeb && buttonName === 'Use Now')) return false // dont render Use Now on website OR no button at all if external offer
      if (isWeb) { // external link for web
        return (
          <Button.ExtLink nativeID="id_cta_button" exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint} exceedsLargeBreakpoint={this.state.exceedsLargeBreakpoint} style={style} onPress={() => this.handleExternal(typeLink)}>
            <Text>{buttonName}</Text>
            <Image style={styles.buttonIcon} source={require('./images/external-link-icon.png')} />
          </Button.ExtLink>
        )
      } else {
        if (openExternal) {
          return (
            <Button.ExtLink
              nativeID="id_cta_button"
              onPress={() => this.handleExternal(webLink)}
              css={{ display: 'flex' }}
              style={style}
              exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint}
              exceedsLargeBreakpoint={this.state.exceedsLargeBreakpoint}
            >
              <Text>{buttonName}</Text>
            </Button.ExtLink>
          )
        }
        return (
          <Button.Link
            nativeID="id_cta_button"
            link={typeLink}
            css={{ display: 'flex' }}
            style={style}
            exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint}
          >
            <Text style={{ width: '100%' }}>{buttonName}</Text>
          </Button.Link>
        )
      }
    }

    return (
      <Button.Wrap exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint} nativeID="id_cart_action_button" >
        {button()}
        {(isWeb && offerCartAddable(offer)) && <Button.Cart isInCart={isInCart} exceedsMediumBreakpoint={this.state.exceedsMediumBreakpoint} exceedsLargeBreakpoint={this.state.exceedsLargeBreakpoint}
          onPress={() => this.handleCartEvent()}>{isInCart ? 'Remove from Cart' : 'Add to Cart'}</Button.Cart>}
      </Button.Wrap>
    )
  }

  updateInCartFlag () {
    const { cartItems, id } = this.state

    const isInCart = cartItems.filter(item => item.ID === id).length > 0
    this.setState({ isInCart })
  }

  render () {
    const { history } = this.props
    const { offer, type, textCollapsed, userID, offerImage, supplierLogo, exceedsLargeBreakpoint, exceedsMediumBreakpoint, inSmallBreakpoint } = this.state
    const hideText = !isWeb && textCollapsed
    const title = isWeb ? offer.title ? `Offer: ${offer.title}` : ' ' : 'Offer details'

    const RenderHtml = global.RenderHtml

    const htmlViewTagStyles = {
      div: {
        fontFamily: 'greycliff',
        fontSize: 12,
        lineHeight: lineHeight(14),
        flexShrink: 1
      }
    }

    const htmlViewStyle = hideText ? { height: 45, overflow: 'hidden' } : {}
    const windowWidth = Dimensions.get('window').width

    let expiryText = null

    if (type === 'lto' && offer.expires) {
      const expDate = moment.utc(offer.expires)

      expiryText = expDate.format('DD/MM/YYYY')
    }

    return (
      <Layout
        title={title}
        smallTitle={isWeb && 'Boost Offers'}
        cart={{
          userID,
          onCartItemsLoaded: (cartItems) => { this.setState({ cartItems }, () => { this.updateInCartFlag() }) }
        }}
        back={{ show: true, link: () => history.goBack() }}
      >
        {isWeb && <style>
          {`
            #id_print_offer_image {
              display: none;
             }
             
             #id_print_offer_subtitle {
              display: none;
            }
                         
            #id_print_header {
              display: none !important;
            }
            
            #id_print_offer_logo {
              display: none !important;
            }

            @media print {
              #id_redeem_online_instructions {
                display: none !important;
              }
              
              #id_offer_header {
                display: none !important;
              }
              
              #id_cta_button {
                display: none !important;
              }
              
              #id_cart_action_button {
                display: none !important;
              }
              
              #id_print_offer_image_wrapper {
                height: auto !important;
                width: auto !important;
                flex-basis: 50%;
              }
              
              #id_print_offer_image {
                display: block;
                max-height: 330px !important;
              }
              
              #id_display_offer_image {
                display: none !important;
              }
              
              #id_back_button {
                display: none !important;
              }
              
              #zsiqwidget, #zsiqbtn {
                display: none !important;
              }
              
              #id_offers_lower_back {
                display: none !important;
              }
             
              #id_print_header {
                display: flex !important;
              }
             
              #id_display_header {
                display: none !important;
              }
              
              #id_content_wrapper {
                flex-direction: row !important;
                flex-wrap: wrap !important;
              }
              
              #id_print_offer_subtitle {
                display: flex;
                flex-basis: 50% !important;
                padding-left: 10px !important;
                padding-right: 10px !important;
              }
              
              #id_print_offer_logo {
                display: flex !important;
                flex-basis: 100%;
                padding: 10px 0 15px 0;
              }
              
              #id_print_offer_logo img {
                max-height: 100px;
                width: auto;
                display: flex; 
                align-self: center;
              }
              
              #id_display_offer_logo {
                display: none !important;
                flex-basis: 100%;
              }
              
              #id_offer_t_and_c_header {
                color: black !important;
                font-family: greycliff-extrabold;
                font-size: 20px !important;
              }
              
              #id_offer_t_and_c_text {
                color: black !important;
                font-size: 14px !important;
                line-height: 17px !important;
                padding: 0;
              }
              
              #id_redeem_header {
                margin-top: -5px !important;
                font-size: 24px !important;
                color: ${colours.primary} !important
              }
              
              #id_offer_expiry_text {
                font-family: greycliff-bold;
                font-size: 20px;
                margin-top: 20px;
                margin-bottom: -10px;
              }
            }
          `}
        </style>}
        {isWeb && <Back nativeID="id_back_button" exceedsMediumBreakpoint={exceedsMediumBreakpoint} exceedsLargeBreakpoint={exceedsLargeBreakpoint} onPress={() => history.goBack()}>‹ Back </Back>}
        <Wrapper exceedsMediumBreakpoint={exceedsMediumBreakpoint} exceedsLargeBreakpoint={exceedsLargeBreakpoint}>
          {isWeb && <Header exceedsMediumBreakpoint={exceedsMediumBreakpoint} small="true" nativeID="id_print_header">
            <Text style={[styles.title, type === 'lto' ? styles.ltoTitle : styles.generalTitle]}>{offer.title}</Text>
          </Header> }
          <ImagesWrap exceedsMediumBreakpoint={exceedsMediumBreakpoint} exceedsLargeBreakpoint={exceedsLargeBreakpoint} nativeID="id_content_wrapper">
            <Header exceedsMediumBreakpoint={exceedsMediumBreakpoint} small="true" nativeID="id_display_header">
              <Text style={[styles.title, type === 'lto' ? styles.ltoTitle : styles.generalTitle]}>{offer.title}</Text>
              {offer.subtitle && <Text style={styles.subtitle}>{offer.subtitle}</Text>}
            </Header>
            <ImageWrap exceedsMediumBreakpoint={exceedsMediumBreakpoint} exceedsLargeBreakpoint={exceedsLargeBreakpoint} height={imageSize} nativeID="id_print_offer_image_wrapper">
              {isWeb && <img src={offerImage} id="id_print_offer_image"/>}
              {offerImage && <Image source={{ uri: offerImage }} style={styles.image} nativeID="id_display_offer_image"/>}
            </ImageWrap>
            {isWeb && offer.subtitle && <View nativeID="id_print_offer_subtitle">
              <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} style={styles.subtitle}>{offer.subtitle}</P>
            </View> }
            {isWeb && <View nativeID="id_print_offer_logo"><img src={supplierLogo}/></View>}
            {isWeb && <Logo exceedsLargeBreakpoint={exceedsLargeBreakpoint} source={{ uri: supplierLogo }} resizeMode="contain" underImage="true" nativeID="id_display_offer_logo"/>}
          </ImagesWrap>

          <Content exceedsMediumBreakpoint={exceedsMediumBreakpoint} exceedsLargeBreakpoint={exceedsLargeBreakpoint}>
            <Header exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
              {
                offer.isRestricted &&
                <TextWrapper inSmallBreakpoint={inSmallBreakpoint}>
                  <Text style={styles.restrictedMessage}>This offer is disabled and is not visible to your users</Text>
                </TextWrapper>
              }
              <Text style={[styles.title, type === 'lto' ? styles.ltoTitle : styles.generalTitle]}>{offer.title}</Text>
              {offer.subtitle && <Text style={styles.subtitle}>{offer.subtitle}</Text>}
            </Header>
            {offer.description && <React.Fragment>
              {isWeb &&
                <TextWrapper inSmallBreakpoint={inSmallBreakpoint}>
                  <Text style={styles.description} numberOfLines={hideText ? 3 : 100}
                    ellipsizeMode={'tail'} ><div dangerouslySetInnerHTML={{ __html: offer.description }} /></Text>
                  {supplierLogo && <Logo exceedsLargeBreakpoint={exceedsLargeBreakpoint} source={{ uri: supplierLogo }} resizeMode="contain" />}
                </TextWrapper>
              }
              {!isWeb &&
                <React.Fragment>
                  <RenderHtml
                    contentWidth={windowWidth}
                    source={ { html: `<div>${offer.description}</div>` } }
                    baseStyle={htmlViewStyle}
                    tagsStyles={htmlViewTagStyles}
                    systemFonts={['greycliff', ...Constants.systemFonts]}
                  />
                  <View style={styles.more}>
                    <Text
                      style={styles.extraTitle}
                      onPress={() => this.setState({ textCollapsed: !textCollapsed })}>
                      Read {textCollapsed ? 'more' : 'less'}
                    </Text>
                    <Image source={require('./images/arrow-blue-down.png')}
                      style={[styles.moreArrow, !textCollapsed && { transform: [{ rotate: '180deg' }] }]} />
                  </View>
                </React.Fragment>
              }
            </React.Fragment>}
            {offer.redeemMessageInStore && <View style={styles.extra}>
              {isWeb && <Text style={styles.extraTitle} nativeID="id_redeem_header">How to redeem in-store</Text>}
              {!isWeb && <Text style={styles.extraTitle}>To redeem in-store: </Text>}
              <Text style={styles.extraText}>
                {offer.redeemMessageInStore}
              </Text>
            </View>}
            {offer.redeemMessageOnline && <View style={styles.extra} nativeID="id_redeem_online_instructions">
              {isWeb && <Text style={styles.extraTitle}>How to redeem online</Text>}
              {!isWeb && <Text style={styles.extraTitle}>To redeem online: </Text>}
              <Text style={styles.extraText}>
                {offer.redeemMessageOnline}
              </Text>
            </View>}
            {/* TODO: add things_to_know for app only */}
            {/* TODO: add supplier_message for app only */}
            {isWeb && expiryText && <Text nativeID="id_offer_expiry_text" style={styles.expiry}>
              Offer valid until {expiryText}.
            </Text>
            }
            {type === 'lto' && <View style={styles.expiry}>
              <ExpiryBar type="lto" expires={offer.expires} height={10} starts={offer.starts} />
            </View>}
            {this.renderCtaButton()}
            {offer.terms && <View style={styles.terms}>
              {isWeb && <Text style={styles.termsTitle} nativeID="id_offer_t_and_c_header">Offer Terms & Conditions</Text>}
              <Text style={styles.termsText} nativeID="id_offer_t_and_c_text">{offer.terms}</Text>
            </View>}
          </Content>
        </Wrapper>
        {isWeb && <Back nativeID="id_offers_lower_back" exceedsMediumBreakpoint={exceedsMediumBreakpoint} exceedsLargeBreakpoint={exceedsLargeBreakpoint} onPress={() => history.goBack()} bottom="true">‹ Back </Back>}
      </Layout>
    )
  }
}

const styles = StyleSheet.create({
  wrapper: {
    justifyContent: isWeb ? 'center' : 'space-between',
    paddingVertical: !isWeb ? 0 : 'inherit',
    paddingHorizontal: !isWeb ? 15 : 'inherit'
  },
  image: {
    flex: 1,
    borderRadius: 8,
    width: '100%',
    height: '100%',
    resizeMode: isWeb ? 'cover' : 'contain'
  },
  logo: {
    width: isWeb ? 165 : 70,
    height: isWeb ? 122 : 50,
    marginLeft: 20
  },
  title: {
    fontFamily: 'greycliff-extrabold',
    fontSize: isWeb ? 24 : 30
  },
  generalTitle: {
    color: colours.primary
  },
  ltoTitle: {
    color: colours.urgent
  },
  subtitle: {
    fontFamily: 'greycliff-medium',
    fontSize: 16,
    color: colours.black60
  },
  description: {
    fontFamily: 'greycliff',
    fontSize: isWeb ? 14 : 12,
    lineHeight: lineHeight(isWeb ? 20 : 14),
    flexShrink: 1
  },
  more: {
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 1
  },
  moreArrow: {
    width: 9,
    height: 7,
    resizeMode: 'contain',
    marginLeft: 3
  },
  extra: {
    marginTop: isWeb ? 30 : 10
  },
  extraTitle: {
    fontFamily: 'greycliff-extrabold',
    fontSize: isWeb ? 20 : 12,
    lineHeight: lineHeight(isWeb ? 20 : 14),
    marginBottom: isWeb ? 10 : 0
  },
  extraText: {
    fontFamily: 'greycliff',
    fontSize: isWeb ? 14 : 12,
    lineHeight: lineHeight(isWeb ? 17 : 14)
  },
  buttonWrap: {
    marginTop: isWeb ? 30 : 15
  },
  button: {
    color: 'white',
    paddingVertical: 6,
    paddingHorizontal: 10,
    borderRadius: isWeb ? 4 : 10,
    overflow: 'hidden',
    textAlign: 'center',
    fontFamily: 'greycliff-bold',
    fontSize: isWeb ? 18 : 20,
    width: isWeb ? 228 : '100%',
    alignContent: 'center',
    justifyContent: 'center',
    maxWidth: '100%'
  },
  generalButton: {
    backgroundColor: colours.primary
  },
  ltoButton: {
    backgroundColor: colours.urgent
  },
  buttonIcon: {
    width: 15,
    height: 15,
    marginLeft: 8
  },
  expiry: {
    fontFamily: 'greycliff',
    marginTop: 15
  },
  terms: {
    marginTop: isWeb ? 30 : 10
  },
  termsTitle: {
    fontFamily: 'greycliff-medium',
    fontSize: 12,
    marginBottom: 5,
    color: colours.black50
  },
  termsText: {
    fontFamily: 'greycliff',
    fontSize: isWeb ? 10 : 8,
    lineHeight: lineHeight(isWeb ? 12 : 8),
    color: colours.black60
  },
  restrictedMessage: {
    fontFamily: 'greycliff-bold',
    fontSize: 15,
    color: colours.urgent
  }
})
