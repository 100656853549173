import React from 'react'
import Layout from '../../components/Layout'
import isWeb from '../../helpers/isWeb'
import getDeviceId from '../../helpers/deviceId'
import { colours, breakpoints } from '../../styles/constants'
import { superSection } from '../../styles/globalClasses'
import styled, { css } from 'styled-components'
import { Primary } from '../../components/Inputs/Buttons'
import storage from '../../helpers/storage'
import api from '../../helpers/boost-client-js-library/api'
import checkRole from '../../helpers/checkRole'
import LoadingIndicator from '../../components/LoadingIndicator'
import consoleLog from '../../helpers/consoleLog'
import BreakpointWatcher from '../../helpers/BreakpointWatcher'

const activeColour = '#e5f7ff'

const Shared = css`
  @media (max-width: ${breakpoints.large - 1}px) {
    display: ${props => props.active ? 'flex' : 'none'};
    flex-wrap: wrap;
  }
  @media (min-width: ${breakpoints.large}px) {
    ${props => props.isCurrent && `background-color: ${activeColour};`}
  }
`
const Inner = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  ${superSection} @media(max-width: ${breakpoints.large - 1}px) {
  display: flex;
  flex-direction: column-reverse;
}
`

const Callback = {
  Button: ({
    style,
    exceedsLargeBreakpoint,
    children,
    ...props
  }) => {
    const _style = {
      width: '100%',
      marginTop: 15,
      backgroundColor: colours.green,
      ...(exceedsLargeBreakpoint ? { display: 'none' } : {}),
      ...style
    }
    return <Primary style={_style} {...props}>{children}</Primary>
  }
}

const tableButtonStyle = {
  minWidth: 160
}

const Table = isWeb && {
  Nav: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: row;
    /*border: 1px solid black;*/
    border-radius: 4px;
    margin-top: 30px;
    @media (min-width: ${breakpoints.large}px) {
      display: none;
    }

    > div {
      flex: 1;
      /*border-right: 1px solid black;*/

      &:last-child {
        border: 0;
      }
    }

    button {
      cursor: pointer;
      appearance: none;
      padding: 8px;
      border: 0;
      font-family: greycliff;
      text-align: center;
      position: relative;
      width: 100%;
      border: solid 1px black;

      span {
        display: block;
        font-family: greycliff-bold;
      }
    }

    div:first-child button {
      border-radius: 0 4px 4px 0;
    }

    div:last-child button {
      border-radius: 4px 4px 0 0;
    }

    div:only-child button {
      border-radius: 4px;
    }
  `,
  NavButton: styled.button`
    background: ${props => props.active ? `${colours.light10}` : 'white'};
  `,
  Wrapper: styled.table`
    width: 100%;
    max-width: 100%;
    font-family: greycliff;
    font-size: 16px;
    border-collapse: collapse;
    margin-bottom: 40px;
    @media (min-width: ${breakpoints.large}px) {
      width: auto;
      font-size: 14px;
      margin: 0 auto 40px;
    }

    th,
    td {
      font-weight: normal;
      text-align: left;
      vertical-align: top;
      padding: 10px 0;
      @media (min-width: ${breakpoints.large}px) {
        text-align: center;
        padding: 8px 15px;
      }
    }

    th {
      border-radius: 4px 4px 0 0;
    }

    tr td:first-child,
    tr th:first-child {
      text-align: left;
      font-family: greycliff-bold;
      @media (max-width: ${breakpoints.large - 1}px) {
        display: none;
      }
    }

    td {
      @media (max-width: ${breakpoints.large - 1}px) {
        &:before {
          content: attr(data-key);
          display: inline-block;
          font-family: greycliff-bold;
          flex-basis: 45%;
        }
      }
      @media (min-width: ${breakpoints.large}px) {
        border-top: 1px solid ${colours.light};
      }
    }

    tr:last-child td {
      border-radius: 0 0 4px 4px;
      @media (min-width: ${breakpoints.large}px) {
        border-top: none;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  `,
  Th: styled.th`
    ${Shared}
  `,
  Td: styled.td`
    ${Shared}
  `,
  Header: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex: 1;
    @media (min-width: ${breakpoints.large}px) {
      flex-direction: column-reverse;
    }
  `,
  Options: styled.div`
    padding: 10px 0;
    align-items: center;
    display: none;
    @media (min-width: ${breakpoints.large}px) {
      flex-direction: column;
      display: flex;
      padding: ${props => props.bottom ? '10px 0' : '40px 0 20px'};
    }
  `,
  Button: Primary,
  CurrentWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    margin: 0 -1px 1px;
    padding-bottom: 4px;
    padding-top: 5px;
    border-radius: 4px 4px 0 0;
    @media (min-width: ${breakpoints.large}px) {
      margin: 0;
      padding: 0;
      background: transparent;
      position: static;
      min-height: 37px; // match with button height
      bottom: 0;
    }
    ${props => props.current && `
            background: ${colours.light10};
            @media (min-width: ${breakpoints.large}px) {
                background: transparent;
            }
        `}
  `,
  Current: styled.div`
    display: block;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    ${props => !props.secondline && 'letter-spacing: 1px;'}
    font-family: ${props => props.secondline ? 'greycliff' : 'greycliff-bold'};
    ${props => props.large && `
            font-family: greycliff-medium;
            position: relative;
            small {
                position: absolute;
                left: -8px;
            }
        `}
    text-transform: ${props => props.secondline ? 'none' : 'uppercase'};
    color: ${props => props.secondline ? colours.black60 : colours.primary};
    @media (min-width: ${breakpoints.large}px) {
      font-size: 11px;
      line-height: 14px;
      ${props => props.large && `
                font-size: 30px;
                line-height: 36px;
                > small {
                    font-size: 14px;
                    vertical-align: text-bottom;
                }
            `}
    }
  `,
  Small: styled.div`
    display: block;
    font-size: 12px;
    line-height: 9px;
    text-align: center;
    font-family: greycliff;
  `,
  Heading: styled.div`
    font-size: 24px;
    font-family: greycliff;
    margin-bottom: 8px;
    display: block;
    @media (min-width: ${breakpoints.large}px) {
      text-align: center;
    }

    > span {
      font-family: greycliff-bold;
      padding-left: 3px;
    }
  `,
  SubLabel: styled.div`
    display: block;
    font-size: 14px;
    font-family: greycliff-medium;
    text-align: center;
  `
}

const boostData = [
  {
    name: 'Boost Core',
    subLabel: 'All the core features of Boost for one low annual price.',
    boostCard: 'Basic Card',
    boostApp: '',
    offers: ['Card', 'Website', 'Email'],
    memberDashboard: '✓',
    userManagement: '✓',
    supplierRestrictions: '',
    customSuppliers: '',
    pcode: 1
  },
  {
    name: 'Boost Plus',
    subLabel: 'Upgrade for customised Boost Cards and Offers Restrictions.',
    boostCard: 'Customised with Logo',
    boostApp: '',
    offers: ['Card', 'Website', 'Email'],
    memberDashboard: '✓',
    userManagement: '✓',
    supplierRestrictions: '✓',
    customSuppliers: '',
    pcode: 2
  },
  {
    name: 'Boost Ultra',
    subLabel: 'Add custom Suppliers and get the Boost App for iPhone & Android.',
    boostCard: '',
    boostApp: 'Customised with Logo',
    offers: ['App', 'Website', 'Email'],
    memberDashboard: '✓',
    userManagement: '✓',
    supplierRestrictions: '✓',
    customSuppliers: '✓',
    pcode: 3
  },
  {
    name: 'Boost Ultra+',
    subLabel: 'Customised Boost Cards, add custom Suppliers and get the Boost App for iPhone & Android.',
    boostCard: 'Customised with Logo',
    boostApp: 'Customised with Logo',
    offers: ['App', 'Card', 'Website', 'Email'],
    memberDashboard: '✓',
    userManagement: '✓',
    supplierRestrictions: '✓',
    customSuppliers: '✓',
    pcode: 4
  }
]

export default class Super extends React.Component {
  state = {
    tier: '',
    data: boostData,
    maxEmp: 2000,
    slider: 0,
    planAttributes: [
      {
        name: 'Boost Card',
        key: 'boostCard'
      },
      {
        name: 'Boost App',
        key: 'boostApp'
      },
      {
        name: 'Offers',
        key: 'offers'
      },
      {
        name: 'Member Dashboard',
        key: 'memberDashboard'
      },
      {
        name: 'User Management',
        key: 'userManagement'
      },
      {
        name: 'Supplier Restrictions',
        key: 'supplierRestrictions'
      },
      {
        name: 'Custom Suppliers',
        key: 'customSuppliers'
      }
    ],
    profile: {},
    isLoading: true,
    activePcode: null,
    isRequestingQuote: {},
    isRequestingCallback: {}
  }

  checkTierPlan = tier => {
    const slicedData = () => {
      if (tier <= 1) return boostData
      return boostData.slice(tier - 1)
    }
    const getCurrentPlan = () => {
      let name
      switch (tier) {
      case 0:
      case 1:
        name = boostData[0].name
        break
      case 2:
        name = boostData[1].name
        break
      case 3:
        name = boostData[2].name
        break
      case 4:
        name = boostData[3].name
        break
      default:
        break
      }
      return name
    }

    this.setState({
      tier,
      data: slicedData(),
      currentPlan: getCurrentPlan(),
      active: getCurrentPlan()
    })
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
    const {
      appKey,
      history
    } = this.props
    storage.token.get(appKey).then(token => {
      api.profile.get(token).then(profile => {
        consoleLog(checkRole(profile.roles).includes('bp'))
        if (!checkRole(profile.roles).includes('bp')) {
          history.push('/profile')
          return false
        }
      })
      api.organisation.get(token).then(profile => {
        consoleLog(profile)
        if (!profile.tier || profile.tier === '4') {
          history.push('/profile')
          return false
        }
        this.setState({
          profile,
          slider: profile.numberOfVehicles ? profile.numberOfVehicles : 0,
          isLoading: false
        })
        this.checkTierPlan(Number(profile.tier))
      })
      api.vehicle(token).then(item => {
        if (!item) return false
        this.setState({
          expiry: item.expiry,
          isLoading: false
        })
      })
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.tier !== this.state.tier) {
      this.checkTierPlan(this.state.tier)
    }
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  handleSliderChange = e => {
    if (e && e.target) {
      this.setState({
        slider: e.target.value
      })
    }
  }

  handleNiceName = name => {
    const splitName = name.split(' ')
    return splitName.map((word, i) => {
      if (i === 1) {
        return <span key={i}> {word}</span>
      }
      return word
    })
  }

  handleTableNav = (active, pcode) => {
    this.setState({
      active,
      activePcode: pcode
    })
  }

  handleRequestCallBack = (pcode) => {
    const {
      history,
      appKey
    } = this.props
    const { isRequestingCallback } = this.state
    isRequestingCallback[pcode] = true
    this.setState({ isRequestingCallback })
    const errorPage = `${process.env.REACT_APP_BASE_URL}/failure-page?type=c`

    try {
      storage.token.get(appKey).then(token => {
        const access = {
          token,
          device: getDeviceId()
        }
        api.subscriptions.callback(access, pcode).then(res => {
          if (res && res.message && res.message.indexOf('successfully') >= 0) {
            history.push('confirmation/call-back')
          } else {
            consoleLog(res)
            storage.token.get().then(token => {
              api.auth.logout(token, getDeviceId()).then(() => {
                storage.token.delete().then(() => {
                  window.location = errorPage
                })
              })
            })
          }
        })
      })
    } catch (e) {
      consoleLog(e)
      storage.token.get().then(token => {
        api.auth.logout(token, getDeviceId()).then(() => {
          storage.token.delete().then(() => {
            window.location = errorPage
          })
        })
      })
    }
  }

  handleGetQuote = (pcode) => {
    const {
      history,
      appKey
    } = this.props
    const errorPage = `${process.env.REACT_APP_BASE_URL}/failure-page?type=q`

    const { isRequestingQuote } = this.state
    isRequestingQuote[pcode] = true
    this.setState({ isRequestingQuote })
    try {
      storage.token.get(appKey).then(token => {
        const access = {
          token,
          device: getDeviceId()
        }
        api.subscriptions.quote(access, pcode).then(res => {
          if (res && res.message && res.message.indexOf('successfully') >= 0) {
            history.push('confirmation/instant-quote')
          } else {
            consoleLog(res)
            storage.token.get().then(token => {
              api.auth.logout(token, getDeviceId()).then(() => {
                storage.token.delete().then(() => {
                  window.location = errorPage
                })
              })
            })
          }
        })
      })
    } catch (e) {
      consoleLog(e)
      storage.token.get().then(token => {
        api.auth.logout(token, getDeviceId()).then(() => {
          storage.token.delete().then(() => {
            window.location = errorPage
          })
        })
      })
    }
  }

  renderTableNav = () => { // mobile only
    const {
      data,
      currentPlan,
      active
    } = this.state
    return (
      <Table.Nav>
        {data.map((item, i) => {
          const current = item.name === currentPlan
          const isActive = item.name === active
          return (
            <div key={i}>
              <Table.NavButton active={isActive} onClick={() => this.handleTableNav(item.name, item.pcode)}>
                {current && this.renderCurrentText()}
                {this.handleNiceName(item.name)}
              </Table.NavButton>
            </div>
          )
        })}
      </Table.Nav>
    )
  }

  renderCurrentText = () => {
    const {
      active,
      currentPlan,
      expiry
    } = this.state
    const onCurrent = active === currentPlan
    const date = new Date(expiry)
    const checkDate = date instanceof Date && !isNaN(date)
    const getYear = checkDate && date.getFullYear() ? date.getFullYear().toString().slice(-2) : ''
    const exp = checkDate ? `${date.getDate()}/${date.getMonth() + 1}/${getYear}` : ''
    return (
      <Table.CurrentWrapper current={onCurrent ? 1 : 0}>
        <Table.Current>CURRENT PLAN</Table.Current>
        <Table.Current secondline="true">Ends {exp}</Table.Current>
      </Table.CurrentWrapper>
    )
  }

  renderTableItem = item => {
    if (Array.isArray(item)) return <ul>{item.map(line => <li key={line}>{line}</li>)}</ul>
    if (item === '') return '-'
    return item
  }

  render () {
    const {
      tier,
      data,
      currentPlan,
      planAttributes,
      active,
      isLoading,
      activePcode,
      exceedsLargeBreakpoint,
      isRequestingQuote,
      isRequestingCallback
    } = this.state
    const onCurrent = active === currentPlan
    return (
      <Layout
        title={'Upgrade Plan'}
        tier={tier}
        isSuperLayout={true}
      >
        {isLoading ? <LoadingIndicator/>
          : <Inner>
            {!onCurrent && <Callback.Button
              exceedsLargeBreakpoint={exceedsLargeBreakpoint}
              onPress={() => this.handleRequestCallBack(activePcode)}
              disabled={isRequestingCallback[activePcode]}
            >
              {isRequestingCallback[activePcode] ? 'Please wait…' : 'Request a Call Back'}
            </Callback.Button>}
            <div>
              {this.renderTableNav()}

              <Table.Wrapper>
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    {data.map((item, i) => {
                      const current = item.name === currentPlan
                      const isActive = item.name === active
                      return (
                        <Table.Th key={i} isCurrent={current} active={isActive}>
                          <Table.Header>
                            <Table.Options>
                              {current
                                ? <React.Fragment>{this.renderCurrentText()}</React.Fragment>
                                : <Table.Button
                                  style={tableButtonStyle}
                                  onPress={() => this.handleRequestCallBack(item.pcode)}
                                  disabled={isRequestingCallback[item.pcode]}
                                >
                                  {isRequestingCallback[item.pcode] ? 'Please wait…' : 'Request a Call Back'}
                                </Table.Button>
                              }
                            </Table.Options>
                            <div>
                              <Table.Heading>{this.handleNiceName(item.name)}</Table.Heading>
                              <Table.SubLabel>{item.subLabel}</Table.SubLabel>
                            </div>
                          </Table.Header>
                        </Table.Th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {planAttributes.map((attr, i) => (
                    <tr key={i}>
                      <td>{attr.name}</td>
                      {data.map((item, j) => (
                        <Table.Td
                          key={j}
                          data-key={attr.name}
                          isCurrent={item.name === currentPlan}
                          active={item.name === active}
                        >
                          {this.renderTableItem(item[attr.key])}
                        </Table.Td>
                      ))}
                    </tr>
                  ))}
                  <tr>
                    <td>&nbsp;</td>
                    {data.map((item, i) => {
                      const current = item.name === currentPlan
                      return (
                        <Table.Td key={i} isCurrent={current} active={false}>
                          <Table.Header>
                            <Table.Options bottom>
                              {current
                                ? <React.Fragment>
                                  <React.Fragment>
                                    {this.renderCurrentText()}
                                  </React.Fragment>
                                </React.Fragment>
                                : <Table.Button
                                  style={tableButtonStyle}
                                  onPress={() => this.handleGetQuote(item.pcode)}
                                  disabled={isRequestingQuote[item.pcode]}
                                >{isRequestingQuote[item.pcode] ? 'Please wait…' : 'Request a Quote'}</Table.Button>
                              }
                            </Table.Options>
                          </Table.Header>
                        </Table.Td>
                      )
                    })}
                  </tr>
                </tbody>
              </Table.Wrapper>
            </div>
          </Inner>
        }
      </Layout>
    )
  }
}
