/*
    How to use
    query the api like this:
    api.auth.validate(data).then(res => {

    })
*/

const token = access => typeof access === 'object' ? access.token : access
const device = access => typeof access === 'object' ? access.device : ''

const jsonFetch = async function (path, method, headers, body) {
  /**
   * This should be used throughout the fetch API calls below, but updated piece by piece so it can be tested.
   */
  const url = `${process.env.REACT_APP_API_URL}${path}`

  if (headers['Content-Type'] === undefined) {
    headers['Content-Type'] = 'application/json'
  }
  let error = null
  let res = null
  let data = null

  try {
    res = await fetch(url, {
      method,
      headers,
      body
    })
  } catch (e) {
    error = e
  }

  if (res !== null) {
    try {
      data = await res.json()
    } catch (e) {
      error = e
    }
  }
  // return the response, a success flag, and the data or error
  return [res, error === null, data || error]
}

const simpleJsonFetch = async function (path, method, headers, body) {
  /**
   * A wrapper around `jsonFetch`, to return just the third element (data or error) from the response array.
   */
  const response = await jsonFetch(path, method, headers, JSON.stringify(body))
  return response[2]
}

const api = {
  auth: {
    login: data => {
      return simpleJsonFetch('/auth/token', 'POST', data)
    },
    logout: token => {
      return simpleJsonFetch('/auth/revoke', 'POST', { Authorization: `Bearer ${token}` })
    },
    validate: async access => {
      const [res, success, dataOrError] = await jsonFetch('/auth/validate', 'POST',
        { Authorization: `Bearer ${token(access)}`, Device: device(access) })

      if (success) {
        return {
          apiVersion: res.headers.get('api-version'),
          data: dataOrError
        }
      }

      return dataOrError
    },
    refresh: access => {
      return fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-token`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token(access)}`, Device: device(access), Scope: access.scope }
      })
        .then(res => res.json())
        .catch(err => err)
    }
  },
  checkConnection: () => {
    return fetch(`${process.env.REACT_APP_API_URL}/`, { method: 'GET' })
      .then(res => {
        return !res.ok ? 'offline' : 'online'
      })
      .catch(() => {
        return 'offline'
      })
  },
  profile: {
    get: access => {
      return fetch(`${process.env.REACT_APP_API_URL}/info`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token(access)}`, Device: device(access) }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    update: (access, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/info`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token(access)}`,
          Device: device(access),
          'content-type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    },
    changePassword: (token, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/auth/change-password`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    },
    forgotPassword: (email) => {
      return fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
        method: 'PUT',
        headers: { Email: email }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    resetPassword: (token, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
        method: 'POST',
        headers: { Token: `${token}`, 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    },
    resetPIN: (token, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/info/resetPIN`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    }
  },
  organisation: {
    get: (access) => {
      return fetch(`${process.env.REACT_APP_API_URL}/info/organisation`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token(access)}`, Device: device(access) }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    update: (token, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/info/organisation`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    }
  },
  rollOuts: {
    get: (token) => {
      return fetch(`${process.env.REACT_APP_API_URL}/preferences/roll-outs`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    update: (token, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/preferences/roll-outs`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    }
  },
  resources: {
    get: (token) => {
      return fetch(`${process.env.REACT_APP_API_URL}/preferences/resources`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    update: (token, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/preferences/resources`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    }
  },
  email: {
    get: (access) => {
      return fetch(`${process.env.REACT_APP_API_URL}/preferences/emails`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token(access)}`, Device: device(access) }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    update: (access, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/preferences/emails`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token(access)}`,
          Device: device(access),
          'content-type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    }
  },
  sms: {
    get: (access) => {
      return fetch(`${process.env.REACT_APP_API_URL}/preferences/sms`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token(access)}`, Device: device(access) }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    update: (access, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/preferences/sms`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token(access)}`,
          Device: device(access),
          'content-type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    }
  },
  vehicle: (access) => {
    return fetch(`${process.env.REACT_APP_API_URL}/info/vehicles`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token(access)}`, Device: device(access) }
    })
      .then(res => res.json())
      .catch(err => err)
  },
  backCardSuppliers: (token, data) => {
    return fetch(`${process.env.REACT_APP_API_URL}/preferences/card/back`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}`, 'content-type': 'application/json' },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .catch(err => err)
  },
  card: {
    front: {
      get: (token) => {
        return fetch(`${process.env.REACT_APP_API_URL}/preferences/card/front`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` }
        })
          .then(res => res.json())
          .catch(err => err)
      },
      create: (token) => {
        return fetch(`${process.env.REACT_APP_API_URL}/preferences/card/front`, {
          method: 'POST',
          headers: { Authorization: `Bearer ${token}` }
        })
          .then(res => res.json())
          .catch(err => err)
      },
      update: (token) => {
        return fetch(`${process.env.REACT_APP_API_URL}/preferences/card/front`, {
          method: 'PUT',
          headers: { Authorization: `Bearer ${token}` }
        })
          .then(res => res.json())
          .catch(err => err)
      }
    },
    back: {
      updateSuppliers: (token, data) => {
        return fetch(`${process.env.REACT_APP_API_URL}/preferences/card/back`, {
          method: 'POST',
          headers: { Authorization: `Bearer ${token}`, 'content-type': 'application/json' },
          body: JSON.stringify(data)
        })
          .then(res => res.json())
          .catch(err => err)
      }
    },
    status: {
      get: (token) => {
        return fetch(`${process.env.REACT_APP_API_URL}/preferences/card/front/status`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` }
        })
          .then(res => res.json())
          .catch(err => err)
      },
      post: (token) => {
        return fetch(`${process.env.REACT_APP_API_URL}/preferences/card/front/status`, {
          method: 'POST',
          headers: { Authorization: `Bearer ${token}` }
        })
          .then(res => res.json())
          .catch(err => err)
      }
    }
  },
  offers: {
    get: (access, signal, args = '') => {
      return fetch(`${process.env.REACT_APP_API_URL}/offers?${args}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token(access)}`, Device: device(access) },
        signal
      })
        .then(res => res.json())
        .catch(err => err)
    },
    single: (access, id) => {
      return fetch(`${process.env.REACT_APP_API_URL}/offers/${id}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token(access)}`, Device: device(access) }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    categories: (access, args = '') => {
      return fetch(`${process.env.REACT_APP_API_URL}/offers/categories?${args}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token(access)}`, Device: device(access) }
      })
        .then(res => res.json())
        .catch(err => err)
    }
  },
  offersCart: {
    get: (token) => {
      return fetch(`${process.env.REACT_APP_API_URL}/info/cart`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    add: (token, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/info/cart`, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${token}`, 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    },
    checkout: (token, args = '') => {
      return fetch(`${process.env.REACT_APP_API_URL}/info/cart?${args}`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    remove: (token, id) => {
      return fetch(`${process.env.REACT_APP_API_URL}/info/cart/${id}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    removeAll: (token) => {
      return fetch(`${process.env.REACT_APP_API_URL}/info/cart`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    }
  },
  info: {
    nz_post_token: (token) => {
      return fetch(`${process.env.REACT_APP_API_URL}/third-parties/nzpost`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    }
  },
  orders: {
    add: (token, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/orders`, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${token}`, 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    }
  },
  users: {
    get: (token, args = '') => {
      return fetch(`${process.env.REACT_APP_API_URL}/users?${args}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    add: (token, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/users`, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${token}`, 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    },
    remove: (token, id) => {
      return fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    removeMultiple: (token, contactIds) => {
      return simpleJsonFetch('/users/delete', 'POST',
        { Authorization: `Bearer ${token}` }, { contact_ids: contactIds }
      )
    },
    updateSingle: (token, id, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    },
    getLogins: (token, args = '') => {
      return fetch(`${process.env.REACT_APP_API_URL}/users/vehicles?${args}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    replaceCards: (token, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/users/vehicles`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    },
    createVehicles: (token, quantity) => {
      return fetch(`${process.env.REACT_APP_API_URL}/users/vehicle-create`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, 'content-type': 'application/json' },
        body: JSON.stringify({ quantity })
      })
        .then(res => res.json())
        .catch(err => err)
    },
    invite: (token, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/users/invite`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    },
    groups: {
      list: (token) => {
        return simpleJsonFetch(`/users/groups`, 'GET', { Authorization: `Bearer ${token}` })
      },
      create: (token, name, logoUrl) => {
        return simpleJsonFetch(`/users/groups`, 'POST', { Authorization: `Bearer ${token}` }, { name, logoUrl })
      },
      update: (token, groupId, name, logoUrl) => {
        return simpleJsonFetch(`/users/groups/${groupId}`, 'PUT', { Authorization: `Bearer ${token}` }, { name, logoUrl })
      },
      delete: (token, groupId) => {
        return simpleJsonFetch(`/users/groups/${groupId}`, 'DELETE', { Authorization: `Bearer ${token}` })
      },
      addContactToGroup: (token, groupId, contactId) => {
        return simpleJsonFetch(`/users/groups/${groupId}/contacts/${contactId}`, 'PUT', { Authorization: `Bearer ${token}` })
      },
      removeContactFromGroup: (token, groupId, contactId) => {
        return simpleJsonFetch(`/users/groups/${groupId}/contacts/${contactId}`, 'DELETE', { Authorization: `Bearer ${token}` })
      }
    },
  },
  locked: {
    get: (token) => {
      return fetch(`${process.env.REACT_APP_API_URL}/orders/status`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    }
  },
  suppliers: {
    get: (access, signal, args = '') => {
      return fetch(`${process.env.REACT_APP_API_URL}/internal-suppliers?restricted=false${args}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token(access)}`, Device: device(access) },
        signal
      })
        .then(res => res.json())
        .catch(err => err)
    },
    getAll: (access, signal, args = '') => {
      return fetch(`${process.env.REACT_APP_API_URL}/internal-suppliers?${args}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token(access)}`, Device: device(access) },
        signal
      })
        .then(res => res.json())
        .catch(err => err)
    },
    getExternal: (access, signal, args = '') => {
      return fetch(`${process.env.REACT_APP_API_URL}/external-suppliers?${args}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token(access)}`, Device: device(access) },
        signal
      })
        .then(res => res.json())
        .catch(err => err)
    },
    single: (access, id) => {
      return fetch(`${process.env.REACT_APP_API_URL}/internal-suppliers/${id}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token(access)}`, Device: device(access) }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    singleExternal: (access, id) => {
      return fetch(`${process.env.REACT_APP_API_URL}/external-suppliers/${id}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token(access)}`, Device: device(access) }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    singleOffers: (access, id, args = '') => {
      return fetch(`${process.env.REACT_APP_API_URL}/internal-suppliers/${id}/offers?${args}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token(access)}`, Device: device(access) }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    singleExternalOffers: (access, id, args = '') => {
      return fetch(`${process.env.REACT_APP_API_URL}/external-suppliers/${id}/offers?${args}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token(access)}`, Device: device(access) }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    addOffer: (token, id, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/external-suppliers/${id}/offers`, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${token}`, 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    },
    removeOffer: (token, id, offerID) => {
      return fetch(`${process.env.REACT_APP_API_URL}/external-suppliers/${id}/offers/${offerID}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    updateOffer: (token, id, offerID, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/external-suppliers/${id}/offers/${offerID}`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    },
    enableOffers: (token, id) => {
      return fetch(`${process.env.REACT_APP_API_URL}/internal-suppliers/${id}/restriction`, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    disableOffers: (token, id) => {
      return fetch(`${process.env.REACT_APP_API_URL}/internal-suppliers/${id}/restriction`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    enableOffer: (token, id, offerID) => {
      return fetch(`${process.env.REACT_APP_API_URL}/internal-suppliers/${id}/offers/${offerID}/restriction`, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    disableOffer: (token, id, offerID) => {
      return fetch(`${process.env.REACT_APP_API_URL}/internal-suppliers/${id}/offers/${offerID}/restriction`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    getOfferNotifications: (token) => {
      return fetch(`${process.env.REACT_APP_API_URL}/preferences/supplier-offer-notification`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    setOfferNotifications: (token, prefValue) => {
      return fetch(`${process.env.REACT_APP_API_URL}/preferences/supplier-offer-notification/${prefValue}`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    }
  },
  membership: {
    org: (id) => {
      return fetch(`${process.env.REACT_APP_API_URL}/memberships/organisations/${id}`, { method: 'GET' })
        .then(res => res.json())
        .catch(err => err)
    },
    user: (id) => {
      return fetch(`${process.env.REACT_APP_API_URL}/memberships/accounts/${id}`, { method: 'GET' })
        .then(res => res.json())
        .catch(err => err)
    },
    confirmMembership: (id, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/memberships/accounts/${id}`, {
        method: 'POST',
        headers: {'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    },
    addUser(id, method, data) {
      return this.confirmMembership(id, method, data)
    },
    confirmMembershipPayment: (id, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/memberships/accounts/${id}/pay`, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    },
    addOrg: (id, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/memberships/organisations/${id}`, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    },
    validate: (data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/memberships/contacts`, {
        method: 'GET',
        headers: data
      })
        .then(res => res.json())
        .catch(err => err)
    },
    createUser: (id, pin, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/memberships/contacts`, {
        method: 'PUT',
        headers: { id, pin, 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    },
    contactVerify: (id, pin, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/memberships/contacts/verify`, {
        method: 'PUT',
        headers: { id, pin, 'content-type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    },
    deactivate: (token, device) => {
      return fetch(`${process.env.REACT_APP_API_URL}/memberships/deactivate`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          Device: device
        }
      }).then(res => res.json()).catch(err => err)
    },
    getDetailByInvoice: (token, invoiceNumber) => {
      return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/invoices/${invoiceNumber}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    payByInvoice: (token, invoiceNumber) => {
      return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/invoices/${invoiceNumber}`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => res.json())
        .catch(err => err)
    }
  },
  subscriptions: {
    subscription: (hashID, method, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/subscription`, {
        method: 'POST',
        headers: { Quote: `${hashID}`, Method: `${method}` },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .catch(err => err)
    },
    licenses: (access, quantity, paymentType, poNumber) => {
      return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/licenses`, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${token(access)}`, Quantity: `${quantity}`, PaymentType: paymentType, PONumber: poNumber }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    pay: (access, hashID) => {
      return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/pay`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token(access)}`, Quote: `${hashID}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    renew: (access, hashID, method) => {
      return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/renew`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token(access)}`, Quote: `${hashID}`, Method: `${method}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    upgrade: (hashID, paymentMethod) => {
      return simpleJsonFetch('/subscriptions/upgrade', 'POST', { Quote: `${hashID}`, Method: `${paymentMethod}` })
    },
    vehicles: (access, args = '') => {
      return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/vehicles?${args}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token(access)}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    callback: (access, pcode) => {
      return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/callbacks/${pcode}`, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${token(access)}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    quote: (access, pcode) => {
      return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/callbacks/${pcode}`, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${token(access)}` }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    getAmount: (access, hashID) => {
      return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/amounts${hashID ? `?quote=${hashID}` : ''}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token(access)}` }
      })
        .then(res => res.json())
        .catch(err => err)
    }
  },
  uploads: {
    imageUpload: (access, data, extension) => {
      return fetch(`${process.env.REACT_APP_API_URL}/uploads`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token(access)}`,
          'Content-Type': 'application/octet-stream',
          Extension: extension,
          Device: device(access)
        },
        body: data
      })
        .then(res => res.json())
        .catch(err => err)
    },
    imageDelete: (access, filename) => {
      return fetch(`${process.env.REACT_APP_API_URL}/uploads/${filename}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token(access)}`, Device: device(access) }
      })
        .then(res => res.json())
        .catch(err => err)
    },
    userList: (access, data) => {
      return fetch(`${process.env.REACT_APP_API_URL}/uploads/user-list`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token(access)}`,
          'Content-Type': 'application/octet-stream',
          Extension: 'csv'
        },
        body: data
      })
        .then(res => res.json())
        .catch(err => err)
    }
  },
  quote: {
    get: (hashId, access) => {
      let headers = {}
      if (access != null) {
        headers = {
          Authorization: `Bearer ${token(access)}`
        }
      }

      return simpleJsonFetch(`/quotes/hash/${hashId}`, 'GET', headers)
    },
    updateRollOutDate (hashId, rollOutDate) {
      return simpleJsonFetch(`/quotes/hash/${hashId}/roll-out-date`, 'POST', {}, { rollOutDate })
    },
    cancel: (hashId, access) => {
      return simpleJsonFetch(`/quotes/hash/${hashId}/cancel`, 'PUT', {
        Authorization: `Bearer ${token(access)}`
      })
    }
  }
}

export default api
