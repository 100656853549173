/* eslint-disable react/prop-types */
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { colours } from '../styles/constants'
import lineHeight from '../helpers/lineHeight'

const Component = props => {
  const { numberOfPages, active, onPress, style = {} } = props
  return (
    <View style={[paginationNumberStyles.paginationWrapper, style]}>
      <Text style={paginationNumberStyles.paginationTitle}>Pages: </Text>
      {[...Array(numberOfPages)].map((_, i) => {
        const id = i + 1
        return <PaginationNumber key={i} onPress={() => onPress(i + 1)} active={id === active}>{id}</PaginationNumber>
      })}
    </View>
  )
}

export default Component

const PaginationNumber = props => {
  const { children, onPress, active, ...rest } = props
  const _style = {
    fontSize: 12,
    lineHeight: lineHeight(16),
    fontFamily: active ? 'greycliff' : 'greycliff-bold',
    color: active ? 'black' : colours.primary,
    marginRight: 7
  }
  return (
    <Text style={_style} onPress={() => onPress()} {...rest}>{children}</Text>
  )
}

export const paginationNumberStyles = StyleSheet.create({
  paginationWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  paginationTitle: {
    fontSize: 12,
    lineHeight: lineHeight(16),
    fontFamily: 'greycliff-bold',
    color: 'black',
    marginRight: 4
  }
})
