// app only
import React from 'react'
import { Image, Text, TouchableOpacity, View, StyleSheet } from 'react-native'
import { colours, maxWidth } from '../styles/constants'
import { withRouter } from 'react-router-dom'

const imagePath = '../images/'
class Component extends React.Component {
  state = {
    pages: [
      {
        title: 'Offers',
        link: '/offers',
        icon: require(`${imagePath}offers-blue.png`),
        iconSelected: require(`${imagePath}offers-white-selected.png`)
      },
      {
        title: 'Retailers',
        link: '/retailers',
        icon: require(`${imagePath}retailers-blue.png`),
        iconSelected: require(`${imagePath}retailers-white-selected.png`)
      },
      {
        title: 'Card',
        link: '/card',
        icon: require(`${imagePath}card-blue.png`),
        iconSelected: require(`${imagePath}card-white-selected.png`)
      },
      {
        title: 'Profile',
        link: '/profile',
        icon: require(`${imagePath}avatar-blue.png`),
        iconSelected: require(`${imagePath}avatar-white-selected.png`)
      }
    ]
  }

  render () {
    const { isLoggedIn, history, location } = this.props
    if (!isLoggedIn || location.pathname === '/' || location.pathname === '/sign-in') return false // if not logged in, dont render tabbar
    const hasNotch = global.hasNotch

    const tabStyles = StyleSheet.create(
      {
        wrapper: {
          paddingTop: 10,
          paddingHorizontal: 0,
          paddingBottom: hasNotch() ? 25 : 15,
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: colours.primary
        },
        inner: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginVertical: 0,
          marginHorizontal: 'auto',
          alignSelf: 'center',
          width: '100%',
          maxWidth
        },
        tab: {
          alignItems: 'center',
          flexDirection: 'column'
        },
        icon: {
          width: '100%',
          height: '100%'
        }
      }
    )

    return (
      <View style={tabStyles.wrapper}>
        <View style={tabStyles.inner}>
          {history && this.state.pages.map((p, i) => {
            const isActive = () => {
              if (location.pathname.includes(p.link) && p.link !== '/') return true
              return location.pathname === p.link
            }
            const iconWidth = (() => {
              if (p.title === 'Card') return 29
              if (p.title === 'Profile') return 20
              return false
            })()

            const tabItemStyle = StyleSheet.create(
              {
                iconWrap: {
                  width: iconWidth || 23,
                  height: 26,
                  margin: 0,
                  marginBottom: 4
                },
                text: {
                  color: isActive() ? 'white' : colours.light,
                  fontFamily: 'greycliff-medium',
                  fontSize: 12
                }
              }
            )

            return (
              <TouchableOpacity
                key={i}
                onPress={() => history.push(p.link)}
                activeOpacity={0.5}
              >
                <View style={tabStyles.tab}>
                  <View style={tabItemStyle.iconWrap}><Image style={tabStyles.icon} source={isActive() ? p.iconSelected : p.icon} resizeMode="contain" /></View>
                  <Text style={tabItemStyle.text}>{p.title}</Text>
                </View>
              </TouchableOpacity>
            )
          })}
        </View>
      </View>
    )
  }
}

export default withRouter(Component)
