import isWeb from './isWeb'
import { breakpoints } from '../styles/constants'

class BreakpointWatcher {
  constructor () {
    if (!isWeb) {
      return
    }

    this.components = []

    this.inSmallBreakpointMediaMatch = window.matchMedia(`(max-width: ${breakpoints.medium - 2}px)`)
    this.mediumBreakpointMediaMatch = window.matchMedia(`(min-width: ${breakpoints.medium}px)`)
    this.largeBreakpointMediaMatch = window.matchMedia(`(min-width: ${breakpoints.large}px)`)
  }

  addListeners () {
    if (this.mediumBreakpointMediaMatch.addEventListener) {
      // chrome
      this.inSmallBreakpointMediaMatch.addEventListener('change', this.breakpointSmallChanged)
      this.mediumBreakpointMediaMatch.addEventListener('change', this.breakpointMediumChanged)
      this.largeBreakpointMediaMatch.addEventListener('change', this.breakpointLargeChanged)
    } else {
      // safari
      this.inSmallBreakpointMediaMatch.addListener(this.breakpointSmallChanged)
      this.mediumBreakpointMediaMatch.addListener(this.breakpointMediumChanged)
      this.largeBreakpointMediaMatch.addListener(this.breakpointLargeChanged)
    }
  }

  removeListeners () {
    if (this.mediumBreakpointMediaMatch.removeEventListener) {
      // chrome
      this.inSmallBreakpointMediaMatch.removeEventListener('change', this.breakpointSmallChanged)
      this.mediumBreakpointMediaMatch.removeEventListener('change', this.breakpointMediumChanged)
      this.largeBreakpointMediaMatch.removeEventListener('change', this.breakpointLargeChanged)
    } else {
      // safari
      this.inSmallBreakpointMediaMatch.removeListener(this.breakpointSmallChanged)
      this.mediumBreakpointMediaMatch.removeListener(this.breakpointMediumChanged)
      this.largeBreakpointMediaMatch.removeListener(this.breakpointLargeChanged)
    }
  }

  updateSmallBreakpoint (component) {
    component.setState({ inSmallBreakpoint: this.inSmallBreakpointMediaMatch.matches })
  }

  updateMediumBreakpoint (component) {
    component.setState({ exceedsMediumBreakpoint: this.mediumBreakpointMediaMatch.matches })
  }

  updateLargeBreakpoint (component) {
    component.setState({ exceedsLargeBreakpoint: this.largeBreakpointMediaMatch.matches })
  }

  addComponent (component) {
    if (!isWeb) {
      return
    }

    // notify this component of breakpoint changes
    this.components.push(component)
    this.updateSmallBreakpoint(component)
    this.updateMediumBreakpoint(component)
    this.updateLargeBreakpoint(component)

    if (this.components.length === 1) {
      this.addListeners()
    }
  }

  removeComponent (component) {
    if (!isWeb) {
      return
    }

    // stop notifying this component of breakpoint changes
    this.components = this.components.filter(c => c !== component)

    if (this.components.length === 0) {
      this.removeListeners()
    }
  }

  breakpointSmallChanged = () => {
    this.components.forEach(component => {
      this.updateSmallBreakpoint(component)
    })
  }

  breakpointMediumChanged = () => {
    this.components.forEach(component => {
      this.updateMediumBreakpoint(component)
    })
  }

  breakpointLargeChanged = () => {
    this.components.forEach(component => {
      this.updateLargeBreakpoint(component)
    })
  }
}

export default new BreakpointWatcher()
