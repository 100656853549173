import React from 'react'
import Confirmation from '../../components/Confirmation'

export default class Profile extends React.Component {
  render () {
    return (
      <Confirmation
        title="Confirmation"
        h1="Success!"
        p="We have saved those changes to your account and you are good to go."
      />
    )
  }
}
