import isWeb from '../helpers/isWeb'

export const breakpoints = {
  small: 500,
  medium: 769,
  large: 1280,
  xlarge: 1440
}

export const gutter = {
  small: 20,
  medium: 40
}

export const appMaxWidth = 85
export const medMaxWidth = '90%'
export const maxWidth = isWeb ? 1140 : `${appMaxWidth}%`

export const colours = {
  primary: '#00a5ff', // light blue a
  primary30: 'rgba(179,228,255,.3)',
  primary40: 'rgba(153,219,255,.4)',
  light: '#7fdbff', // light blue b
  light10: '#f2fbff',
  light20: '#ccedff',
  light30: '#d9f4ff',
  light40: '#ccf1ff',
  light50: '#bfedff',
  light60: '#b2e9ff',
  darkBlue: '#00536d',
  ebBlue: '#00addb',
  urgent: '#f64a22', // mainly used for LTO's
  black70: '#4d4d4d',
  black60: '#666',
  black50: '#808080',
  black40: 'rgba(0,0,0,.4)',
  black30: '#b3b3b3',
  black20: 'rgba(0,0,0,.2)',
  black10: 'rgba(0,0,0,.1)',
  yellow: '#fdc54c',
  green: '#63d07c',
  darkGreen: '#009a23',
  orange: '#f69130',
  taupe: '#d8c0b1',
  grey: '#f2f2f2',
  lighterGrey: '#f5f5f5'
}

// headings
export const headingStyles = {
  fontSize: isWeb ? 26 : 30,
  fontSizeLarge: 20,
  fontFamily: 'greycliff-extrabold',
  marginBottom: 20
}
export const subHeadingStyles = {
  fontSize: 18,
  fontFamily: 'greycliff-medium',
  marginBottom: 15
}

// component specific
export const welcomeMessage = {
  marginBottom: isWeb ? 50 : 25
}

export const backButtonStyle = {
  backgroundColor: colours.taupe
}
