import React from 'react'
import { View, Text, TextInput, TouchableWithoutFeedback } from 'react-native'
import { colours } from '../../styles/constants'
import isWeb from '../../helpers/isWeb'

const Base = (props) => {
  const { style, children, ...rest } = props
  let _styles
  if (isWeb) {
    const webBaseStyle = { paddingVertical: 6, paddingHorizontal: 0 }
    _styles = [webBaseStyle, style]
  } else {
    _styles = [styles.baseInput, style]
  }
  return <View style={_styles} {...rest}>
    {children}
  </View>
}

const checkAttr = (attr, fallback = '') => attr || fallback // check if an attribute exists, otherwise use a fallback

export class TextField extends React.Component {
  input = React.createRef()
  render () {
    const { baseStyle, label, labelStyle, value, editable, onChange, ...props } = this.props
    return (
      <Base style={baseStyle}>
        {label &&
          <React.Fragment>
            {isWeb
              ? <label style={{ ...styles.label, ...labelStyle }} className="link-reset" onClick={() => this.input.current.focus()}>{label}</label>
              : <Text style={[styles.label, labelStyle]} onPress={() => this.input.current.focus()}>{label}</Text>
            }
          </React.Fragment>
        }
        <TextInput ref={this.input} value={value} onChangeText={onChange} editable={editable} {...props} />
      </Base>
    )
  }
}

export const CheckField = ({ baseStyle, label, labelStyle, checked, onChange, linkStyle, ...props }) => {
  const { type, items, itemStyle, editable, style } = props
  const getFieldType = item => checkAttr(type, 'checkbox') === 'checkbox' ? checked.includes(item) : checked === item
  const isEditable = editable !== undefined ? editable : true
  return (
    <Base style={[baseStyle, style]}>
      {label && <Text style={[styles.label, labelStyle]}>{label}</Text>}
      {items && items.map(c => (
        <TouchableWithoutFeedback key={c.value} onPress={() => isEditable && onChange(c.value)}>
          <View style={[styles.checkWrap, itemStyle, linkStyle]}>
            <View style={styles.checkbox}>{getFieldType(c.value) && <View style={styles.checked}/>}</View>
            <View style={styles.checkText}>
              <Text style={[styles.checkLabel, labelStyle]}>{c.label}</Text>
              {c.subLabel && <Text style={[styles.checkLabel, styles.checkSubLabel]}>{c.subLabel}</Text>}
              {c.link && <Text style={[styles.checkLabel, styles.checkSubLabel, styles.checkLink, linkStyle]} onPress={c.link}>{c.linkText}</Text>}
            </View>
          </View>
        </TouchableWithoutFeedback>
      ))}
    </Base>
  )
}

const styles = {
  // global
  baseInput: {
    paddingVertical: 6
  },

  // input
  label: {
    fontFamily: 'greycliff-bold',
    fontSize: 14
  },

  // checkbox
  checkWrap: {
    flex: 1,
    flexBasis: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 5
  },
  checkbox: {
    width: 19,
    height: 19,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'rgba(179,179,179,.3)',
    alignItems: 'center',
    justifyContent: 'center'
  },
  checkText: {
    flex: 1,
    flexBasis: 'auto'
  },
  checked: {
    width: 15,
    height: 15,
    borderRadius: 5,
    backgroundColor: colours.primary
  },
  checkLabel: {
    fontFamily: 'greycliff',
    fontSize: isWeb ? 12 : 14,
    marginLeft: 10
  },
  checkSubLabel: {
    fontSize: 11
  },
  checkLink: {
    fontFamily: 'greycliff-bold',
    textDecorationLine: 'underline'
  }
}
