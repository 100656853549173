import React from 'react'
import Confirmation, { WebLink } from '../../components/Confirmation'

export default class Profile extends React.Component {
  render () {
    return (
      <Confirmation
        title="Add User"
        h1="We are working on it!"
        p="Your request is underway, it may take a few minutes. Once complete, you
          will receive a confirmation email from us. If you do not receive this email
          please contact your Account Manager."
      >
        <WebLink to="/profile/user-management-and-ordering">Back to User Management and Ordering ›</WebLink>
        <WebLink to="/profile/dashboard">Dashboard ›</WebLink>
      </Confirmation>
    )
  }
}
