import React from 'react'
import { colours, gutter } from '../../styles/constants'
import { View, Text, TextInput, Image } from 'react-native'

const Shared = {
  color: colours.primary,
  fontFamily: 'greycliff-medium'
}

const Shadow = {
  shadowOpacity: 0.15,
  shadowRadius: 8,
  shadowColor: 'black',
  shadowOffset: { width: 0, height: 1 },
  elevation: 3,
  borderRadius: 8
}

export const Wrapper = props => {
  const { children, ...rest } = props
  const wrapperStyles = {
    position: 'relative',
    paddingLeft: gutter.small,
    paddingRIght: gutter.small,
    top: -10,
    height: 30,
    width: '100%',
    zIndex: 1,
    background: colours.primary,
    alignItems: 'center',
    alignContent: 'center'
  }
  return (
    <View style={wrapperStyles} {...rest}>{children}</View>
  )
}

export const Button = props => {
  const { children, ...rest } = props
  const buttonStyles = {
    position: 'relative',
    width: '100%',
    zIndex: 1,
    alignItems: 'center',
    alignContent: 'center'
  }
  return (
    <View style={buttonStyles} {...rest}>{children}</View>
  )
}

export const Box = props => {
  const { children, ...rest } = props
  const boxStyles = {
    ...Shadow,
    backgroundColor: 'white',
    flex: 1,
    width: '100%',
    maxWidth: 270,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    position: 'absolute',
    bottom: -20,
    zIndex: 1
  }
  return (
    <View style={boxStyles} {...rest}>{children}</View>
  )
}

export const BoxButton = props => {
  const { children, ...rest } = props
  const boxButtonStyles = {
    ...Shadow,
    backgroundColor: colours.primary,
    flex: 1,
    width: '100%',
    padding: 10,
    marginBottom: 20
  }
  return (
    <View style={boxButtonStyles} {...rest}>{children}</View>
  )
}

export const BoxInner = props => {
  const { children, ...rest } = props
  const boxInnerStyles = {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  }
  return (
    <View style={boxInnerStyles} {...rest}>{children}</View>
  )
}

export const Search = props => {
  const { children, ...rest } = props
  return (
    <TextInput style={Shared} {...rest}>{children}</TextInput>
  )
}

export const Icon = props => {
  const { ...rest } = props
  const iconStyles = {
    width: 15,
    height: 15,
    marginRight: 5
  }
  return (
    <Image style={iconStyles} {...rest} />
  )
}

// dropdown
export const Dropdown = props => {
  const { color, children, ...rest } = props
  const dropdownStyles = {
    ...Shared,
    textAlign: 'center',
    width: '100%',
    ...(color ? { color: color } : {})
  }
  return (
    <Text style={dropdownStyles} {...rest}>{children}</Text>
  )
}

export const DropdownBox = props => {
  const { maxWidth, styles, children, ...rest } = props
  const dropdownBoxStyles = {
    ...Shadow,
    position: 'absolute',
    top: '100%',
    width: '100%',
    backgroundColor: 'white',
    padding: 20,
    marginTop: 35,
    flex: 1,
    zIndex: 1,
    ...(maxWidth ? { maxWidth: maxWidth } : {}),
    ...(styles || {})
  }
  return (
    <View style={dropdownBoxStyles} {...rest}>{children}</View>
  )
}

const SharedArrow = {
  position: 'absolute',
  top: 0,
  height: 0,
  width: 0,
  marginTop: -9,
  marginBottom: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: 'transparent',
  borderTopWidth: 0,
  borderTopColor: 'transparent',
  borderRightColor: 'transparent',
  borderLeftColor: 'transparent',
  alignSelf: 'center'
}

export const DropdownArrow = props => {
  const { children, ...rest } = props
  const dropdownArrowStyles = {
    ...SharedArrow,
    borderRightWidth: 10.5,
    borderBottomWidth: 9,
    borderLeftWidth: 10.5,
    borderBottomColor: 'white',
    zIndex: 2
  }
  return (
    <View style={dropdownArrowStyles} {...rest}>{children}</View>
  )
}

export const DropdownArrowBorder = props => {
  const { children, ...rest } = props
  const dropdownArrowBorderStyles = {
    ...SharedArrow,
    borderRightWidth: 11.5,
    borderBottomWidth: 10,
    borderLeftWidth: 11.5,
    borderBottomColor: 'rgba(0, 0, 0, .01)',
    zIndex: 1,
    top: -1
  }
  return (
    <View style={dropdownArrowBorderStyles} {...rest}>{children}</View>
  )
}

export const DropdownItem = props => {
  const { children, ...rest } = props
  const dropdownItemStyles = {
    borderColor: 'rgba(0, 0, 0, .1)',
    borderBottomWidth: 1
  }
  return (
    <View style={dropdownItemStyles} {...rest}>{children}</View>
  )
}

export const DropdownLink = props => {
  const { children, ...rest } = props
  const dropdownLinkStyles = {
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 0,
    paddingRight: 0,
    fontFamily: 'greycliff-medium',
    color: 'black'
  }
  return (
    <Text style={dropdownLinkStyles} {...rest}>{children}</Text>
  )
}
