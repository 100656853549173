import React from 'react'
import storage from './helpers/storage'
import getDeviceId from './helpers/deviceId'
import ConnectionManager from './helpers/ConnectionManager'
import EntityFacade from './helpers/EntityFacade'

export default class RetailersBase extends React.Component {
  mounted = false
  controller = new AbortController()

  getSortableName = (retailer) => {
    if (retailer.tradingName) {
      return retailer.tradingName.toUpperCase()
    }

    return retailer.name.toUpperCase()
  }

  handleSorting = (list) => list.sort((a, b) => {
    if (this.getSortableName(a) === this.getSortableName(b)) {
      return 0
    }
    return this.getSortableName(a) < this.getSortableName(b) ? -1 : 1
  })

  initData = () => {
    const { appKey } = this.props
    storage.token.get(appKey).then(token => {
      const access = {
        token,
        device: getDeviceId()
      }
      this.loadRetailers(access)
    })
  }

  postLoad () {
    this.mounted && this.setState({
      loading: false
    })
  }

  loadRetailers = access => {
    ConnectionManager.getManager().checkApiConnectivity().then((isOnline) => {
      const signal = this.controller.signal

      const ef = new EntityFacade(isOnline, access)

      Promise.all([
        ef.getSuppliers(signal, true),
        ef.getExternalSuppliers(signal, true)
      ]).then(([internalResp, externalResp]) => {
        const internal = (internalResp !== null && internalResp.items != null) ? internalResp.items : []
        const external = (externalResp !== null && externalResp.items != null) ? externalResp.items : []

        const idKeyedRetailers = {}

        internal.forEach(supplier => {
          // add if it doesn't exist, or it has a higher completeness
          supplier.isInternal = true
          if (idKeyedRetailers[supplier.ID] === undefined ||
            this.completenessScore(supplier) > this.completenessScore(idKeyedRetailers[supplier.ID])) {
            idKeyedRetailers[supplier.ID] = supplier
          }
        })

        external.forEach(supplier => {
          supplier.isInternal = false
          if (idKeyedRetailers[supplier.supplierAccountID] === undefined ||
            this.completenessScore(supplier) > this.completenessScore(idKeyedRetailers[supplier.supplierAccountID])) {
            idKeyedRetailers[supplier.supplierAccountID] = supplier
          }
        })

        const sortedRetailers = this.handleSorting(Object.values(idKeyedRetailers))

        this.mounted && this.setState({
          retailers: sortedRetailers.filter(i => i.restricted === false || !i.restricted),
          searchText: '',
          filtering: false
        }, _ => {
          this.postLoad()
        })
      })
    })
  }

  completenessScore (supplier) {
    // if we have two suppliers with the same ID, which one should be used? use this to compute a score
    let score = 0
    if (supplier.isInternal) {
      ++score
    }

    if (supplier.categories !== undefined && supplier.categories.length) {
      ++score
    }

    if (supplier.logo !== undefined) {
      score += 2
    }

    if (supplier.website !== undefined) {
      ++score
    }

    if (supplier.tradingName !== undefined) {
      ++score
    }

    return score
  }

  componentDidMount () {
    this.mounted = true
    this.initData()
  }

  componentWillUnmount () {
    this.mounted = false
    this.controller.abort()
    clearInterval(this.intervalId)
  }
}
