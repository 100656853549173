import React from 'react'
import api from '../helpers/boost-client-js-library/api'

function setCookie (tokenCookie) { // Set cookie using token object
  document.cookie = 'boost=' + JSON.stringify(tokenCookie) + '' + ',path=/'
}

export default class WebDevSignIn extends React.Component {
  state = {
    username: '',
    password: ''
  }

  doLogin (e) {
    e.preventDefault()
    api.auth.login({ Username: this.state.username, Password: this.state.password, Scope: 'website' }).then(res => {
      if (!res.code && res.access_token && res.refresh_token) {
        res.time = Math.floor(Date.now() / 1000)
        setCookie(res)
        window.location = 'http://localhost:3000/'
      } else {
        alert('Not right')
      }
    })
    return false
  }

  render () {
    return <div className="container div-signin" style={ { display: 'flex', justifyContent: 'center', marginTop: 20, paddingBottom: 700 } }>
      <form className="sign-in form-group" onSubmit={ (e) => this.doLogin(e) }>
        <div className="input-text form-group">
          <input type="text" placeholder="Username" onChange={ (e) => this.setState({ username: e.target.value }) }/>
        </div>
        <div className="input-pass form-group">
          <input id="password1" type="password" placeholder="Password" onChange={ (e) => this.setState({ password: e.target.value }) }/>
        </div>
        <div className="btn-div form-group">
          <input type="submit" value="Sign in" className="btn btn-primary"/>
        </div>
      </form>
    </div>
  }
}
