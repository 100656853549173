function getSuperOptions (showOffersLink) {
  const options = [
    { id: 1, title: 'Dashboard', link: '/profile/dashboard' },
    { id: 2, title: 'Organisation Info', link: '/profile/organisation' },
    { id: 3, title: 'Customisation', link: '/profile/customisation' },
    {
      id: 4,
      title: 'Offer Management',
      link: '/profile/offer-management',
      subOptions: [
        { id: 11, title: 'Add Custom Offers', link: '/profile/offer-management/add-offers' }
      ]
    },
    {
      id: 5,
      title: 'User Management & Ordering',
      link: '/profile/user-management-and-ordering',
      subOptions: [
        { id: 12, title: 'Add User', link: '/profile/user-management-and-ordering/add-users' }
      ]
    },
    { id: 6, title: 'Resources', link: '/profile/resources' },
    { id: 8, title: 'My Profile', link: '/profile' },
    { id: 9, title: 'Upgrade Plan', link: '/profile/upgrade' },
    { id: 10, title: 'Billing & Delivery', link: '/profile/billing' }
  ]

  if (showOffersLink) {
    options.splice(6, 0,
      { id: 7, title: 'Boost Offers', link: '/offers' }
    )
  }

  return options
}

export default getSuperOptions
