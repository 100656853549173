import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import Layout from '../components/Layout'
import isWeb from '../helpers/isWeb'
import getDeviceId from '../helpers/deviceId'
import options from '../data/profileOptions'
import { heading } from '../styles/globalClasses'
import { Primary } from '../components/Inputs/Buttons'
import { CheckField } from '../components/Inputs/Fields'
import storage from '../helpers/storage'
import api from '../helpers/boost-client-js-library/api'
import Notification from '../components/Notification'
import BreakpointWatcher from '../helpers/BreakpointWatcher'

const Wrapper = View
const Col1 = View

export default class Profile extends React.Component {
  state = {
    profile: {
      emails: [],
      sms: []
    },
    email: [
      { label: 'Limited time offers', subLabel: 'Approximately twice per month', value: 'limitedTimeOffers' },
      { label: 'Everyday Offer Updates', subLabel: 'Approximately once per month', value: 'everyDayOfferUpdates' },
      { label: 'Friends & Family', subLabel: 'Approximately three-four times per year', value: 'friendsAndFamily' },
      { label: 'Monthly newsletter', subLabel: 'Approximately once per month', value: 'monthlyNewsletter' },
      { label: 'Boost Updates', subLabel: 'Approximately three-four times per year', value: 'boostUpdates' }
    ],
    sms: [
      { label: 'Allow SMS communications', subLabel: 'Un-check to disable all SMS from Boost', value: 'Allow' },
      { label: 'Limited time offers', subLabel: 'Approximately twice per month', value: 'limitedTimeOffers' }
    ],
    notification: false,
    notificationType: 'error',
    exceedsMediumBreakpoint: false,
    exceedsLargeBreakpoint: false
  }

  mounted = false
  componentDidMount () {
    this.mounted = true
    BreakpointWatcher.addComponent(this)
    const { appKey } = this.props
    storage.token.get(appKey).then(token => {
      const access = { token, device: getDeviceId() }
      api.email.get(access).then(emails => {
        this.mounted && this.setState(state => {
          Object.entries(emails).map(i => {
            if (i[1]) state.profile.emails.push(i[0])
            return false
          })
          return state
        })
      })
      api.sms.get(access).then(sms => {
        this.mounted && this.setState(state => {
          Object.entries(sms).map(i => {
            if (i[1]) state.profile.sms.push(i[0])
            return false
          })
          return state
        })
      })
    })
  }

  componentWillUnmount () {
    this.mounted = false
    BreakpointWatcher.removeComponent(this)
  }

  handleCheck = (arr, value) => {
    const { profile } = this.state
    const hasValue = profile[arr].includes(value)
    this.mounted && this.setState(state => {
      if (hasValue) {
        if (value === 'Allow' && arr === 'sms') { // uncheck all
          state.profile[arr] = []
        } else {
          state.profile[arr] = profile[arr].filter(i => i !== value)
        }
      } else {
        state.profile[arr].push(value)
      }
      return state
    })
  }

  handleNotifications = res => {
    this.mounted && this.setState({
      notification: res.message,
      notificationType: res.code === 200 ? 'success' : 'error'
    }, () => {
      setTimeout(() => {
        this.mounted && this.setState({
          notification: false,
          notificationType: 'success'
        })
      }, 2000)
    })
  }

  handleSubmit = () => {
    const { appKey } = this.props
    const { profile } = this.state
    const emails = {}
    const sms = {}
    this.state.email.forEach(i => {
      emails[i.value] = profile.emails.includes(i.value)
    })
    this.state.sms.forEach(i => {
      sms[i.value] = profile.sms.includes(i.value)
    })
    this.mounted && this.setState({ notification: 'Updating preferences...', notificationType: 'success' })
    storage.token.get(appKey).then(token => {
      const access = { token, device: getDeviceId() }
      api.email.update(access, emails).then(res => {
        this.handleNotifications(res)
      })
      api.sms.update(access, sms).then(res => {
        this.handleNotifications(res)
      })
    })
  }

  render () {
    const { profile, email, sms, notification, notificationType, exceedsMediumBreakpoint, exceedsLargeBreakpoint } = this.state
    const { history } = this.props

    const wrapperStyle = {}
    const col1Style = {}

    if (isWeb) {
      if (exceedsMediumBreakpoint) {
        wrapperStyle.flexDirection = 'row'
        col1Style.marginRight = 40
      }

      if (exceedsLargeBreakpoint) {
        col1Style.marginRight = 80
      }
    }

    return (
      <Layout
        title="Preferences"
        back={{ show: !isWeb, link: () => history.goBack() }}
        optionsData={options}
      >
        <Wrapper style={wrapperStyle}>
          <Col1 style={col1Style}>
            <Text style={styles.heading}>Email communications</Text>
            <View>
              <CheckField
                items={email}
                checked={profile.emails}
                onChange={value => this.handleCheck('emails', value)}
                itemStyle={styles.fieldWrap}
              />
            </View>
          </Col1>
          <View>
            <Text style={styles.heading}>SMS communications</Text>
            <View>
              <CheckField
                items={sms}
                checked={profile.sms}
                onChange={value => this.handleCheck('sms', value)}
                itemStyle={styles.fieldWrap}
              />
            </View>
          </View>
        </Wrapper>
        {notification && <Notification type={notificationType}>{notification}</Notification>}
        <View style={styles.buttonWrap}>
          <Primary onPress={() => this.handleSubmit()}>Save preferences</Primary>
        </View>
      </Layout>
    )
  }
}

const styles = StyleSheet.create({
  heading: heading({
    marginBottom: isWeb ? 30 : 10
  }),
  fieldWrap: {
    marginBottom: 30
  },
  buttonWrap: {
    marginTop: 20,
    marginBottom: 20,
    flex: 1,
    flexDirection: 'row'
  }
})
