import React from 'react'
import Layout from '../../components/Layout'
import { breakpoints } from '../../styles/constants'
import { H1, P, A, Bold, Labels, Fields } from '../../styles/globalClasses'
import { Primary } from '../../components/Inputs/Buttons'
import getSuperOptions from '../../data/superOptions'
import styled from 'styled-components'
import storage from '../../helpers/storage'
import api from '../../helpers/boost-client-js-library/api'
import moment from 'moment'
import Notification from '../../components/Notification'
import checkRole from '../../helpers/checkRole'
import Autosuggest from 'react-autosuggest'
import consoleLog from '../../helpers/consoleLog'
import reSuperOptions from '../../helpers/reSuperOptions'
import getDeviceId from '../../helpers/deviceId'
import BreakpointWatcher from '../../helpers/BreakpointWatcher'

const Sections = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
   
    @media(min-width: ${breakpoints.medium}px){
        flex-direction: row;
        margin-left: -40px;
    }

`
const Section = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    &.active {z-index: 100}
    h1 {
        margin: 0 0 10px;
    }
  
    ${Labels}
    ${Fields}

    input:not([type="checkbox"]),
    textarea {
        display: block;
        width: 100%;
        margin-bottom: 20px;
    }

    textarea {
        resize: none;
        margin-top: 20px;
        margin-bottom: 5px;
    }

    .button-wrap {
        margin-top: 15px;
        display: flex;
        flex-direction: row;
    }
`
const AutoCompleteWrap = styled.div`
display: flex;
align-items: stretch;
flex-direction: row;
border-bottom:1px solid rgb(191, 237, 255);
padding: 10px 0;
position: relative;
z-index: 100;

.react-autosuggest__container {
    position: relative;
    flex-basis: 100%;
  }
  
  .react-autosuggest__input {
    position: relative;
    width: 100%;
    height: 38px;
    padding: 10px 20px 10px 10px;
    font-family: greycliff;
    font-weight: 300;
    font-size: 14px;
    border: 1px solid rgba(179, 179, 179, 0.3);
    border-radius: 4px;
    :focus {
        position: relative;
        z-index: 100
    }
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
    position:relative;
    z-index:1000;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 30px;
    width: 100%;
    border: 1px solid rgba(179, 179, 179, 0.3);
    background-color: #fff;
    font-weight: 300;
    font-size: 14px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #000;
  }
.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px 10px 10px;
    font-family: greycliff;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
`

const Styles = {
  Section: (exceedsMediumBreakpoint) => {
    return {
      marginBottom: 10,
      ...(exceedsMediumBreakpoint ? {
        flexGrow: 0,
        flexShring: 0,
        flexBasis: '33%',
        paddingLeft: 40
      } : {})
    }
  },
  A: {
    textDecorationLine: 'none'
  },
  NotFirstP: {
    // emulates the + p rule in globalClass TextShared
    marginTop: 5
  }
}

const getSuggestionValue = suggestion => suggestion
const renderSuggestion = suggestion => (<span>{suggestion}</span>)
export default class Super extends React.Component {
  state = {
    tier: '',
    options: [],
    profile: {},
    errorModal: false,
    addressPostal: '',
    suggestions: [],
    active: false,
    exceedsMediumBreakpoint: false
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
    const { appKey, history } = this.props
    storage.token.get(appKey).then(token => {
      api.profile.get(token).then(profile => {
        const roles = checkRole(profile.roles)

        if (!roles.includes('bp')) {
          history.push('/profile')
          return false
        }

        api.organisation.get(token).then(organisation => {
          if (!organisation.tier) {
            history.push('/profile')
            return false
          }
          this.setState({
            tier: Number(organisation.tier),
            options: reSuperOptions(organisation.tier, getSuperOptions(roles.includes('su'))),
            profile: organisation,
            addressPostal: organisation.addressPostal && organisation.addressPostal !== null && organisation.addressPostal !== undefined ? organisation.addressPostal.replace(/\n/g, ', ') : ''
          })
        })
      })
      api.vehicle(token).then(item => {
        this.setState({
          expiry: item.expiry
        })
      })
    })
    this.getOAuthToken()
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  handleModalClose = () => {
    this.setState({
      errorModal: false
    })
  }

  getOAuthToken = () => {
    const { appKey } = this.props

    storage.token.get(appKey).then(token => {
      const access = { token, device: getDeviceId() }
      api.info.nz_post_token(access).then(res => {
        const NZ_POST_TOKEN = res.accessToken
        this.setState({
          oAuthToken: NZ_POST_TOKEN
        })
      })
    })
  }

  onSuggestionsFetchRequested = (e) => {
    const value = e.value ? e.value : ''

    const headers = new Headers()
    headers.append('Authorization', this.state.oAuthToken)
    headers.append('Accept', 'application/json')
    const init = { method: 'GET', headers, mode: 'cors' }

    fetch(`https://api.nzpost.co.nz/addresschecker/1.0/suggest?q=${value}`, init)
      .then((response) => {
        return response.json()
      })
      .then((res) => {
        const tmp = []
        res.addresses && res.addresses.map(item => tmp.push(item.FullAddress && item.FullAddress))
        this.setState({
          suggestions: tmp
        })
      })
      .catch((e) => {
        consoleLog(e)
        this.setState({
          notification: 'Something went wrong. Please try again later',
          notificationType: 'error'
        })
      })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  handleSave = () => {
    const { appKey } = this.props
    const { profile } = this.state
    const data = {
      addressPostal: profile.addressPostal
    }
    this.setState({
      notification: 'Updating details...',
      notificationType: 'success'
    })
    storage.token.get(appKey).then(token => {
      api.organisation.update(token, data).then(res => {
        const defaultMessage = res.code === 200 ? 'Details updated' : 'This action cannot be completed due to an error. Please try again.'
        this.setState({
          notification: res.message ? res.message : defaultMessage,
          notificationType: res.code === 200 ? 'success' : 'error'
        }, () => setTimeout(() => { this.setState({ notification: false }) }, 2000))
      })
    })
  }

  handleChange = e => {
    e.persist()
    this.setState(state => {
      state.profile[e.target.id] = e.target.value
      return state
    })
  }

  onChangeAddress = (event, { newValue }) => {
    this.setState(state => {
      state.profile.addressPostal = newValue
      state.addressPostal = newValue
      return state
    })
  }

  render () {
    const { history } = this.props
    const { tier, options, profile, expiry, notification, notificationType, suggestions, addressPostal, active, exceedsMediumBreakpoint } = this.state
    if (tier.length === 0) return null
    const inputProps = (v) => {
      return {
        placeholder: addressPostal || v || 'Type your shipping address',
        value: addressPostal,
        onChange: this.onChangeAddress,
        onClick: () => this.setState({ active: true })
      }
    }
    return (
      <Layout
        title="Billing & Delivery"
        optionsData={options}
      >
        <Sections>
          <Section style={Styles.Section(exceedsMediumBreakpoint)}>
            <H1>Membership Details</H1>
            <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}><Bold>{profile.accountName}</Bold> is currently part of <Bold>{profile.planName}</Bold> with <Bold>{profile.currentPlanMaxUsers} licenses</Bold>.</P>
            {expiry && <P style={Styles.NotFirstP} exceedsMediumBreakpoint={exceedsMediumBreakpoint}>The membership renew date is <Bold>{moment(expiry).format('DD/MM/YYYY')}</Bold></P>}
          </Section>

          <Section style={Styles.Section(exceedsMediumBreakpoint)} className={active ? 'active' : ''}>
            <H1>Delivery Address</H1>
            <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Boost Cards will be delivered to this address.</P>
            <AutoCompleteWrap>
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={(e) => this.onSuggestionsFetchRequested(e)}
                onSuggestionsClearRequested={() => this.onSuggestionsClearRequested()}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps(addressPostal)}
              />
            </AutoCompleteWrap>

            {notification && <Notification type={notificationType}>{notification}</Notification>}
            <div className="button-wrap">
              <Primary onPress={() => this.handleSave()}>Save Changes</Primary>
            </div>
          </Section>

          <Section style={Styles.Section(exceedsMediumBreakpoint)}>
            <H1>Boost Plan</H1>
            {profile.planName && <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>You&rsquo;re on the <Bold>{profile.planName}</Bold> plan</P>}
            {tier > 1 && <P style={Styles.NotFirstP} exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
              <A style={Styles.A} to="/profile/user-management-and-ordering/add-users">Add Users ›</A><br/>
              <A style={Styles.A} to="/profile/user-management-and-ordering/add-licenses">Need more users? Add more licenses ›</A>
            </P>}
            {tier !== 4 &&
                        <React.Fragment>
                          <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Upgrade now for additional features.</P>
                          <div className="button-wrap">
                            <Primary onPress={() => history.push('/profile/upgrade')}>See Plans</Primary>
                          </div>
                        </React.Fragment>
            }
          </Section>
        </Sections>
      </Layout>
    )
  }
}
