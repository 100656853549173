import React from 'react'
import Layout from './Layout'
import { View, Text, Dimensions, StyleSheet } from 'react-native'
import { colours, breakpoints, headingStyles } from '../styles/constants'
import { H2 } from '../styles/globalClasses'
import isWeb from '../helpers/isWeb'
import styled from 'styled-components'
import lineHeight from '../helpers/lineHeight'

const { height } = Dimensions.get('window')

const Heading = isWeb && styled(H2)`
  text-align: center;
  font-size: ${headingStyles.fontSize}px;
  line-height: ${headingStyles.fontSize}px;
  margin-bottom: ${headingStyles.marginBottom}px;
  ${props => props.notFound && 'font-style: italic;'}
  ${isWeb && `
    @media(min-width: ${breakpoints.large}px){
      font-size: ${headingStyles.fontSizeLarge}px;
      line-height: ${headingStyles.fontSizeLarge}px;
    }
  `}
`

const Component = () => {
  return (
    <Layout
      title="404 Error: Page not found"
      style={!isWeb && { backgroundColor: colours.lighterGrey, minHeight: height }}
    >
      <View style={styles.nomatchWrapper}>
        {isWeb && <Heading>The page you requested could not be found.</Heading>}
        <Text style={styles.nomatchText}>
            Please use the menu above to browse this website, or go to our <a href={process.env.REACT_APP_BASE_URL}>homepage</a>.<br/>
            Need a hand? Please phone us on 0508 22 66 78 or email <a href="mailto:team@myboost.co.nz">team@myboost.co.nz</a> for help.
        </Text>
      </View>
    </Layout>
  )
}

export default Component

const styles = StyleSheet.create({
  nomatchWrapper: {
    marginTop: 0,
    marginBottom: 0,
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  nomatchText: {
    fontSize: 16,
    lineHeight: lineHeight(25),
    fontFamily: 'greycliff',
    textAlign: 'center'
  },
  nomatchA: {
    color: colours.primary,
    textDecorationLine: 'none'
  }
})
