import React from 'react'
import { View, ActivityIndicator } from 'react-native'
import { colours } from '../styles/constants'

// eslint-disable-next-line react/prop-types
const LoadingIndicator = props => <View style={{ ...indicatorWrapStyles, ...props.style }}><ActivityIndicator size={props.size ? props.size : 'large'} color={colours.primary} /></View>

export default LoadingIndicator

const indicatorWrapStyles = {
  marginTop: 50,
  marginBottom: 50,
  marginLeft: 0,
  marginRight: 0,
  alignItems: 'center'
}
