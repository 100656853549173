/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react'
import Layout from '../../components/Layout'
import { Image, Text, View } from 'react-native'
import isWeb from '../../helpers/isWeb'
import { breakpoints, colours } from '../../styles/constants'
import styled, { css } from 'styled-components'
import { A, Bold, DataTable, H1, P, superSection } from '../../styles/globalClasses'
import { Disabled, Primary, Secondary } from '../../components/Inputs/Buttons'
import getSuperOptions from '../../data/superOptions'
import ProgressBar from '../../components/ProgressBar'
import NumberedPagination, { paginationNumberStyles } from '../../components/NumberedPagination'
import Pagination from '../../components/Pagination'
import DatePicker from 'react-datepicker'
import TableSearch from '../../components/TableSearch'
import storage from '../../helpers/storage'
import api from '../../helpers/boost-client-js-library/api'
import ScrollBarTable from '../../components/ScrollBarTable'
import Modal from '../../components/Modal'
import Notification from '../../components/Notification'
import exportCSVFile from '../../helpers/exportCSVFile'
import LoadingIndicator from '../../components/LoadingIndicator'
import InlineLoadingIndicator from '../../components/InlineLoadingIndicator'
import checkRole from '../../helpers/checkRole'
import moment from 'moment'
import consoleLog from '../../helpers/consoleLog'
import reSuperOptions from '../../helpers/reSuperOptions'
import BreakpointWatcher from '../../helpers/BreakpointWatcher'
import GroupManagementModal from './GroupManagementModal'
import Select from 'react-select'
import foregroundColorFromBackground from '../../helpers/colors'
import BulkGroupUserManagementModal from './BulkGroupUserManagementModal'

const SUBSCRIPTION_SEND_DISALLOW_OFFSET_DAYS = 7
const SUBSCRIPTION_SEND_DISALLOW_OFFSET_SECONDS = SUBSCRIPTION_SEND_DISALLOW_OFFSET_DAYS * 24 * 3600

const Top = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-bottom: 20px;
  @media(min-width: ${breakpoints.medium - 1}px){
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    > * {
      flex: 1;
    }
    > *:last-child {
      margin-left: 15px;
    }
  }
  @media(min-width: ${breakpoints.large}px){
    padding-bottom: 30px;
    margin-bottom: 35px;
    border-bottom: 1px solid ${colours.light50};
    max-width: 850px;
  }
`

// active users
const Active = isWeb && {
  Section: styled.div`
    display: flex;
    align-items: stretch;
    ${superSection};
    flex-direction: column;
    margin-left: 0 !important;
    @media(min-width: ${breakpoints.medium}px){
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-end;
      flex: 1;
    }
  `,
  Progress: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    @media(min-width: ${breakpoints.medium}px){
      margin-bottom: 5px;
      margin-right: 20px;
      max-width: 360px;
    }
  `,
  Info: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    margin-bottom: 20px;
    @media(min-width: ${breakpoints.medium}px){
      margin-bottom: -10px;
    }
  `
}

// table
const Bottom = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-bottom: 20px;
  @media(min-width: ${breakpoints.medium}px){
    padding-bottom: 30px;
    border-bottom: 1px solid ${colours.light50};
    margin-bottom: 35px;
  }
`

const TableShared = css`
  @media(min-width: ${breakpoints.medium}px){
    flex-direction: row;
  }
`

const RightButtons = styled.div`
`

const getTableAddButtonStyle = (exceedsMediumBreakpoint, exceedsLargeBreakpoint) => {
  return {
    ...(exceedsMediumBreakpoint ? {
      flexGrow: 1,
      marginRight: 15,
      order: 2
    } : {}),
    ...(exceedsLargeBreakpoint ? {
      marginRight: 0,
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto'
    } : {})
  }
}

const getManageGroupsButtonStyle = (exceedsMediumBreakpoint, exceedsLargeBreakpoint, disabled) => {
  return {
    marginRight: 15,
    color: 'white',
    backgroundColor: disabled ? '#ccc' : colours.urgent,
    ...(exceedsMediumBreakpoint ? {
      order: 1
    } : {}),
    ...(exceedsLargeBreakpoint ? {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto'
    } : {})
  }
}

const saveDateStyle = {
  marginLeft: 15
}

export const Table = {
  Top: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    margin-bottom: 20px;
    z-index: 1; // to avoid overlapping issue with table
    @media(min-width: ${breakpoints.medium}px){
      flex-direction: row;
      margin-bottom: 0;
      justify-content: space-between;
      align-items: center;
    }
  `,
  DataTable: isWeb && styled(DataTable)`
    margin: 0;
    border-top: 0;
    border-bottom: 0;

    .editable-field {
      border-bottom: 1px solid #666;
      padding-bottom: 4px;
      margin-bottom: -4px;
    }

    .check-td {
      min-height: 30px;
    }
    
    .group-td {
      padding: 0;
    }

    .rt-tbody {
      padding-bottom: 1px;
      margin-bottom: -1px;
    }

    .rt-tbody {
      .rt-tr { 
        .rt-td {
          align-items: center;
          display: flex;
        }
      }
    }
    
    
    .rt-tbody .rt-tr-group:last-child {
      position: relative;
      &:before {
        content: '';
        background: ${colours.light};
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;
        z-index: 1;
      }
    }
  `,
  Header: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    ${TableShared}
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
    > *:first-child {
      margin-right: 15px;
    }
    @media(min-width: ${breakpoints.large}px){
      margin-bottom: 0;
      align-items: center;
      > *:first-child {
        margin-right: 50px;
        margin-bottom: 0;
      }
    }
  `,
  // This is safe to use styled() on
  TableSearch: styled(TableSearch)`
    max-width: 100%;
    @media(max-width: ${breakpoints.medium - 1}px){
      flex-direction: row;
      align-items: center;
      max-width: 460px;
      label {
        font-size: 0;
        margin-bottom: 0;
        margin-right: 15px;
        &:before {
          content: 'Search users';
          font-size: 18px;
          font-family: greycliff-bold;
        }
      }
      input {
        flex: 1;
        width: 100%;
      }
    }
    @media(max-width: ${breakpoints.small - 1}px){
      max-width: 100%;
      flex-direction: column;
      align-items: flex-start;
      label {
        margin-bottom: 10px;
      }
    }
  `,
  TopWrapper: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    ${superSection}
  `,
  ActionsWrapper: styled.div`
    align-items: stretch;
    flex-direction: column;
    display: none;
    min-width: 130px;
    position: relative;
    @media(min-width: ${breakpoints.medium - 1}px){
      max-width: 338px;
    }
    ${props => props.position === 'top' && `
            @media(min-width: ${breakpoints.medium}px){
                display: flex;
            }
        `}
    ${props => props.position === 'bottom' && `
            display: flex;
            @media(min-width: ${breakpoints.medium}px){
                display: none;
            }
        `}
  `,

  Actions: ({ style = {}, disabled, exceedsMediumBreakpoint, onPress, children, ...props }) => {
    const _style = {
      backgroundColor: 'black',
      position: 'relative',
      paddingRight: 32,
      ...(disabled ? {
        backgroundColor: '#ccc',
        color: 'white',
        cursor: 'default'
      } : {}),
      ...(exceedsMediumBreakpoint ? {
        backgroundColor: disabled ? '#ccc' : colours.urgent
      } : {
        paddingVertical: 8
      })
    }

    if (disabled) { onPress = null }

    const _afterStyle = {
      width: 14,
      height: 7,
      position: 'absolute',
      right: 10,
      top: '50%',
      transform: [{ translateY: '-50%' }]
    }

    return <React.Fragment>
      <Primary style={_style} onPress={onPress} {...props}>
        {(exceedsMediumBreakpoint && !disabled) && <Image source={require('../../images/arrow-white-down.png')} style={_afterStyle}/>}
        {children}
      </Primary>
    </React.Fragment>
  },
  Pagination: ({ style = {}, exceedsMediumBreakpoint, children, ...props }) => {
    const _style = {
      borderColor: colours.black20,
      marginTop: 20,
      marginHorizontal: -15,
      marginBottom: 5,
      paddingHorizontal: 15,
      ...style
    }
    if (exceedsMediumBreakpoint) {
      _style.display = 'none'
    }
    return <Pagination style={_style} {...props}>{children}</Pagination>
  },
  ActionList: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    background: white;
    border: 1px solid ${colours.black30};
    border-radius: 4px;
    padding: 10px;
    position: absolute;
    right: 0;
    bottom: 100%;
    margin: 3px 0;
    @media(min-width: ${breakpoints.medium}px){
      bottom: inherit;
      top: 100%;
    }

    > * {
      cursor: pointer;
      font-family: greycliff-bold;
      margin-bottom: 4px;
      &:active,&:hover {
        opacity: .7;
      }
    }

    @media(max-width: ${breakpoints.medium - 1}px){
      width: 100%;
    }
  `,
  Bottom: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    ${superSection}
    ${TableShared}
    @media(min-width: ${breakpoints.medium - 1}px){
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  `,
  BottomTitle: isWeb && styled(H1)`
    flex: 1 1 100%;
    @media(min-width: ${breakpoints.medium}px){
      display: none;
    }
  `,
  BottomButtons: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    ${TableShared}
    margin-bottom: 10px;
    @media(min-width: ${breakpoints.medium - 1}px){
      margin-bottom: 0;
      flex: 0 0 66%;
      flex-direction: row-reverse;
      align-items: center;
      order: 2;
      > *:first-child {
        flex: 1 !important;
        margin-left: 15px !important;
      }
    }
    @media(min-width: ${breakpoints.large}px){
      margin-bottom: 0;
      order: inherit;
      flex-direction: row;
      flex-basis: auto;
      > *:first-child {
        margin-left: 0 !important;
        flex: 0 0 auto  !important;
        margin-right: 20px !important;
      }
    }
    @media(max-width: ${breakpoints.medium - 1}px){
      .react-datepicker-wrapper {
        width: 100%;
        display: block;
        flex: 1;
        * {
          width: 100%;
          display: block;
        }
      }
    }
  `,
  // Styles in Upload below are copied from the button style and need fixing. They need to be kept updated
  // if we change how buttons look
  Upload: styled(DatePicker)`
    color: white;
    font-family: greycliff-bold;
    border-radius: 4px;
    padding: 10px 15px;
    text-align: center;
    overflow: hidden;
    font-size: 16px;
    line-height: 19px;
    cursor: 'pointer';
    flex: 0 0 auto;
    user-select: none;
    transition: opacity .1s;
    background-color: ${colours.primary};
    width: 250px;
    max-width: 100%;
    appearance: none;
    border: 1px solid #cccccc;
    ::placeholder {
      color: inherit;
    }
    @media(max-width: ${breakpoints.medium - 1}px){
      margin: 20px 0;
    }
    &:hover {
      opacity: .8;
    }
    &:active {
      opacity: 1;
    }
    @media(min-width: ${breakpoints.medium}px){
      font-size: 14px;
      line-height: 17px;
    }
    &[disabled]{
      cursor: default;
      pointer-events: none;
      background: #ccc;
      color: white;
      &:hover {
        opacity: 1;
      }
    }
  `,
  SaveDate: Secondary,
  ManageGroups: Secondary,
  ManageGroupsDisabled: Disabled,
  Add: Primary,
  AddDisabled: Disabled,
  DisabledText: ({ style = {}, exceedsMediumBreakpoint, exceedsLargeBreakpoint, children, ...props }) => {
    const _style = {
      color: '#00a5ff',
      ...(exceedsLargeBreakpoint ? {
        alignSelf: 'flex-end',
        textAlign: 'right'
      } : {
        marginVertical: 10,
        textAlign: 'left',
        flexGrow: 1,
        flexShrinl: 1,
        flexBasis: '100%'
      }),
      ...style
    }
    return <P style={_style} exceedsMediumBreakpoint={exceedsMediumBreakpoint} {...props}>{children}</P>
  },
  DisabledContainer: styled.div`
    align-items: stretch;
    flex-direction: column;
    display: flex;
  `,
  PaginationWrapper: styled.div`
    justify-content: space-between;
    flex-direction: row;
    display: flex;
  `,
  NumberedPagination: ({ style = {}, exceedsMediumBreakpoint, children, ...props }) => {
    const _style = {
      flexBasis: '50%',
      ...(exceedsMediumBreakpoint ? {} : { display: 'none' }),
      ...style
    }

    return <NumberedPagination style={_style} {...props}>{children}</NumberedPagination>
  },
  HelpText: P,
  PageSizeSelectWrapper: ({ style = {}, exceedsMediumBreakpoint, children, ...props }) => {
    const _style = {
      flexDirection: 'row',
      flexBasis: '50%',
      alignSelf: 'end',
      alignContent: 'middle',
      ...(exceedsMediumBreakpoint ? {} : { display: 'none' }),
      ...style
    }
    return <View style={_style} {...props}>{children}</View>
  }
}

const Styles = {
  Table: {
    HelpText: {
      fontSize: '75%',
      marginTop: 10
    }
  },
  ModalClose: {
    backgroundColor: colours.urgent
  },
  ModalLink: {
    backgroundColor: colours.green
  },
  ModalNotification: {
    display: 'flex',
    marginTop: 10,
    marginBottom: 0,
    marginHorizontal: 0
  },
  Tick: {
    marginLeft: 15,
    height: 30,
    width: 30
  },
  PageSizeSelect: {
    flexGrow: 0,
    marginLeft: 10,
    marginTop: -10,
    width: 70,
    appearance: 'auto',
    WebkitAppearance: 'auto'
  },
  PageSizeLabel: {
    textAlign: 'right',
    flexGrow: 1
  }

}

const ModalClose = Primary

const ModalLink = Primary

const ModalNotification = Notification

const Tick = Image

const TableSearchStyle = {
  select: {
    appearance: 'auto',
    WebkitAppearance: 'auto',
    fontFamily: 'greycliff'
  },
  label: {
    fontFamily: 'greycliff'
  },
  submitButton: {
    display: 'none'
  },
  resetButton: {
    right: 10
  },
  input: {
    width: 250
  }
}

const CSV_EXPORT_BATCH_SIZE = 1000

const UsersModal = ({ h1, p, close, modalNotification, modalNotificationType, isSuccess = true, disabled = false, hideYes = false, cancelIsClose = false, ...props }) => {
  return (
    <Modal
      /* eslint-disable react/prop-types */
      h1={h1}
      p={p}
      close={() => !disabled && close()}
    >
      {modalNotification &&
      <Notification type={modalNotificationType}>{modalNotification}</Notification>}
      {!isSuccess && <ModalClose style={Styles.ModalClose} disabled={disabled} onPress={() => close()}>{cancelIsClose ? 'Close' : 'No'}</ModalClose>}
      {(!isSuccess && !hideYes) && <Primary disabled={disabled} onPress={() => props.onPress()}>Yes ›</Primary>}
      {isSuccess && <Primary disabled={disabled} onPress={() => props.close()}>Close</Primary>}
    </Modal>
  )
}

const defaultErrorMessage = 'Oops! Something went wrong, please try again later.'

const cardStatusMapping = [
  { code: 'N/A', text: 'Default' },
  { code: '969760000', text: 'Pending' },
  { code: '969760001', text: 'Requested' },
  { code: '969760002', text: 'Ordered' },
  { code: '969760003', text: 'Printed' },
  { code: '969760004', text: 'Expired' }
]

const cardStatusLookup = {}

cardStatusMapping.forEach(status => {
  cardStatusLookup[status.code] = status.text
})

const appStatusMapping = [
  { code: 'N/A', text: 'Default' },
  { code: '969760000', text: 'Pending' },
  { code: '969760001', text: 'Invited' },
  { code: '969760002', text: 'Authenticated' },
  { code: '969760003', text: 'Expired' },
  { code: '969760004', text: 'Ready to Use' }
]

const userDownloadStatuses = {
  INITIAL_LOAD: 1,
  READY: 2,
  GENERATING: 3
}

const downloadButtonTextLookup = {
  [userDownloadStatuses.INITIAL_LOAD]: 'Loading…',
  [userDownloadStatuses.READY]: 'Download Users',
  [userDownloadStatuses.GENERATING]: 'Generating Download…'
}

const appStatusLookup = {}

appStatusMapping.forEach(status => {
  appStatusLookup[status.code] = status.text
})

const deactivated = obj => obj.cardStatus === '969760004' || obj.appStatus === '969760003' || obj.stateCode === '1' || obj.statusCode === '2'

function userIsRegistered (userData) {
  return userData.stateCode === '0' && userData.roles.includes('myboost.co.nz SU')
}

function statusTextFromCode (lookupMap, isRegistered, code) {
  if (isRegistered) {
    return 'Registered'
  }

  const statusText = lookupMap[code]

  if (typeof statusText !== 'undefined') {
    return statusText
  }

  return code
}

const statusTextCell = (isApp) => {
  return ({ original }) => {
    const userRegistered = userIsRegistered(original)
    const statusText = statusTextFromCode(isApp ? appStatusLookup : cardStatusLookup, userRegistered, isApp ? original.appStatus : original.cardStatus)

    if (!userRegistered) {
      return statusText
    }

    return <span style={ { color: colours.darkGreen } }>{ statusText }</span>
  }
}

const statusSort = (isApp) => {
  return (rowA, rowB, columnId, desc) => {
    return statusTextCell(isApp)({ original: rowA }).compare(statusTextCell(isApp)({ original: rowB })) * (desc ? -1 : 1)
  }
}

const groupSelectStyles = {
  control: (styles) => ({
    ...styles,
    fontFamily: 'greycliff',
    backgroundColor: 'transparent',
    borderWidth: 0,
    borderRadius: 0,
    width: 180
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontFamily: 'greycliff',
      fontSize: 12
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      fontFamily: 'greycliff',
      fontSize: 14,
      backgroundColor: `#${data.colour}`
    }
  },
  multiValueLabel: (styles, { data }) => {
    return {
      ...styles,
      color: foregroundColorFromBackground(data.colour)
    }
  },
  menuList: (styles) => {
    return {
      ...styles,
      fontFamily: 'greycliff',
      fontSize: 13
    }
  }
}

export default class UsersAndOrdering extends React.Component {
  state = {
    tier: '',
    options: [],
    profile: {},
    subscriptionExpiresSoon: false,

    // table attributes
    actions: false,
    date: null,
    itemsPerPage: 20,
    currentPage: 1,

    // table
    search: '',
    data: [],
    dataOriginalCount: 0,
    selected: [],
    selectAll: 0,
    editable: [],
    downloadData: [],
    userDownloadStatus: userDownloadStatuses.INITIAL_LOAD,

    modal: [],
    disableModalConfirm: false,
    notification: false,
    isLoading: true,
    error: null,
    modalNotification: false,
    isSuccess: false,
    dateSaving: false,
    dateSaved: false,
    dateSavable: false,
    searchField: '',
    isSearching: false,
    exceedsMediumBreakpoint: false,
    exceedsLargeBreakpoint: false,

    groupManagementModalVisible: false,
    userGroups: [],
    groupsLoading: true,
    userGroupUpdateCount: {},
    bulkGroupUserManagementdModalVisible: false,
    bulkManagementAddMode: true,
    pageChanging: false,
    userGroupOptions: []
  }

  groupDataLookup = {}

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
    const { history } = this.props
    const { itemsPerPage } = this.state
    storage.token.get().then(token => {
      api.profile.get(token).then(profile => {
        const roles = checkRole(profile.roles)

        if (!roles.includes('bp')) {
          history.push('/profile')
          return false
        }
        // get organisation info
        api.organisation.get(token).then(organisation => {
          if (!organisation.tier) {
            history.push('/profile')
            return false
          }
          if (organisation.code && organisation.code !== 200) this.setState({ error: organisation.message ? organisation.message : defaultErrorMessage })

          const subscriptionExpiry = moment.utc(organisation.expiry, 'YYYY-MM-DD hh:mm')
          let subscriptionExpiresSoon = false

          if (subscriptionExpiry) {
            const currentDateTimestamp = new Date().getTime()
            subscriptionExpiresSoon = ((subscriptionExpiry.toDate().getTime() - currentDateTimestamp) / 1000) < SUBSCRIPTION_SEND_DISALLOW_OFFSET_SECONDS
          }

          this.setState({
            tier: Number(organisation.tier),
            options: reSuperOptions(organisation.tier, getSuperOptions(roles.includes('su'))),
            profile: organisation,
            isLoading: false,
            date: organisation.rollOutDate ? new Date(organisation.rollOutDate) : null,
            subscriptionExpiresSoon: subscriptionExpiresSoon
          })
        })
      })

      // get users
      api.users.get(token, `offset=0&limit=${itemsPerPage}&include_groups=true`).then(users => {
        this.setState({ data: users.items, dataOriginalCount: users.totalCount, dataCount: users.totalCount, userDownloadStatus: userDownloadStatuses.READY })
      })

      // locked status
      api.locked.get(token).then(res => {
        this.setState({ creation_locked: res.status ? res.status : false })
      })

      // check customisation status
      api.card.status.get(token).then(res => {
        const hasApprovedCAR = res && res.hasApprovedCAR
        this.setState({
          statusType: hasApprovedCAR ? 'approved' : 'open'
        })
      })

      this.fetchGroups(token)
    })
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  fetchGroups (token) {
    this.setState({ groupsLoading: true })

    api.users.groups.list(token).then(res => {
      if (Array.isArray(res)) {
        this.groupDataLookup = {}

        for (const group of res) {
          this.groupDataLookup[group.ID] = group
        }

        this.setState({ userGroups: res, groupsLoading: false })
        this.saveUserGroupsAsOptions(res)
      } else {
        console.error(res)
      }
    })
  }

  saveUserGroupsAsOptions (userGroups) {
    this.setState({
      userGroupOptions: userGroups.map(ug => this.userGroupToOption(ug))
    })
  }

  userGroupToOption = (userGroup) => {
    return { value: userGroup.ID, label: userGroup.name, colour: userGroup.colour }
  }

  handleDownload = () => {
    const { dataCount } = this.state
    // get all users for download

    this.setState({ userDownloadStatus: userDownloadStatuses.GENERATING })
    storage.token.get().then(token => {
      const itemsFormatted = []
      const headers = {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'User Email',
        cardID: 'Card ID',
        cardStatus: 'Card Status',
        appID: 'App ID',
        appStatus: 'App Status',
        PIN: 'PIN',
        userStatus: 'User Status',
        groups: 'Groups'
      }

      const fetchNextBatch = (offset) => {
        const remainingToDownload = dataCount - itemsFormatted.length
        const batchSize = Math.min(CSV_EXPORT_BATCH_SIZE, remainingToDownload)

        api.users.get(token, `offset=${offset}&limit=${batchSize}&exclude_portal_roles=false&include_groups=true`).then(downloads => {
          downloads.items.forEach((item) => {
            const isRegistered = userIsRegistered(item)
            itemsFormatted.push({
              firstName: item.firstName !== null ? item.firstName : ' ',
              lastName: item.lastName !== null ? item.lastName : ' ',
              email: item.email !== null ? item.email : ' ',
              cardID: item.cardID,
              cardStatus: statusTextFromCode(cardStatusLookup, isRegistered, item.cardStatus),
              appID: item.appID,
              appStatus: statusTextFromCode(appStatusLookup, isRegistered, item.appStatus),
              PIN: item.PIN,
              userStatus: item.stateCode === '0' && item.statusCode === '1' ? 'Active' : 'Inactive',
              groups: item.groups.map(ug => ug.name).join(', ')
            })
          })

          if (itemsFormatted.length === dataCount || !downloads.items || downloads.items.length === 0) {
            exportCSVFile(headers, itemsFormatted, 'boost-users')
            this.setState({ userDownloadStatus: userDownloadStatuses.READY })
          } else {
            fetchNextBatch(offset + CSV_EXPORT_BATCH_SIZE)
          }
        })
      }
      fetchNextBatch(0)
    })
  }

  handleModalNoneSelected = () => {
    this.setState({
      modalNotification: 'You must select at least one user.',
      modalNotificationType: 'error'
    }, () => {
      setTimeout(() => {
        this.setState({
          modalNotification: '',
          modalNotificationType: 'success',
          modal: []
        })
      }, 2000)
    })
  }

  handleReplaceCard = () => {
    const { selected } = this.state
    const users = []
    selected.map(ID => users.push({ ID }))
    this.setState({
      modalNotification: 'Gathering users...',
      modalNotificationType: 'success',
      disableModalConfirm: true
    })
    storage.token.get().then(token => {
      api.users.replaceCards(token, { users }).then(res => {
        const success = res.success
        this.setState({
          modalNotification: success ? 'New cards have been sent!' : res.message ? res.message : defaultErrorMessage,
          modalNotificationType: success ? 'success' : 'error',
          isSuccess: success,
          disableModalConfirm: false
        })
      })
    })
  }

  handleSendAppInvite = () => {
    const { selected } = this.state
    this.setState({
      modalNotification: 'Gathering users...',
      modalNotificationType: 'success',
      disableModalConfirm: true
    })
    storage.token.get().then((token) => {
      api.users.invite(token, { contacts: selected }).then(res => {
        if (res.code === 200) {
          this.setState({
            modalNotification: res.message ? res.message : 'App invites sent!',
            modalNotificationType: 'success',
            isSuccess: true,
            disableModalConfirm: false,
            selected: []
          })
        } else {
          this.setState({
            modalNotification: res.message ? res.message : defaultErrorMessage,
            modalNotificationType: 'error',
            isSuccess: false,
            disableModalConfirm: false
          })
        }
      })
    })
  }

  handleDisable = () => {
    const { selected } = this.state
    if (selected.length <= 0) {
      this.handleModalNoneSelected()
      return false
    }
    this.setState({
      modalNotification: 'Disabling users...',
      modalNotificationType: 'success',
      disableModalConfirm: true
    })
    storage.token.get().then(token => {
      api.users.removeMultiple(token, selected).then(users => {
        const success = users.code === 200 || users.code === 202
        const defaultMessage = success ? 'Users disabled! Reloading page...' : defaultErrorMessage
        this.setState({
          modalNotification: users.message ? users.message : defaultMessage,
          modalNotificationType: success ? 'success' : 'error',
          disableModalConfirm: false
        }, () => setTimeout(() => { window.location.reload() }, 2000))
      })
    })
  }

  handlePagination = e => {
    const { currentPage, dataCount, itemsPerPage } = this.state
    const totalPages = Math.ceil(Number(dataCount) / itemsPerPage)
    if (currentPage <= totalPages) {
      this.setState({
        currentPage: isNaN(e) ? e === 'next' ? this.state.currentPage + 1 : this.state.currentPage - 1 : e,
        pageChanging: true
      }, () => {
        const chosenPage = isNaN(e) ? this.state.currentPage - 1 : e - 1
        storage.token.get().then(token => {
          api.users.get(token, `offset=${itemsPerPage * chosenPage}&limit=${itemsPerPage}&include_groups=true`).then(users => {
            const data = users.totalCount === 0 ? [] : users.items
            this.setState({ data, isLoading: false, pageChanging: false, selected: [] })
          })
        })
      })
    }
  }

  handleToggleRow (ID) {
    const { selected } = this.state
    const hasValue = selected.includes(ID)

    this.setState(() => {
      const newState = {
        selectAll: 2
      }

      if (hasValue) {
        newState.selected = selected.filter(i => i !== ID)
      } else {
        selected.push(ID)
        newState.selected = selected
      }
      return newState
    })
  }

  handleToggleSelectAll () {
    const { data, selectAll } = this.state
    const newSelected = []

    if (selectAll === 0) {
      data.forEach(x => {
        if (!deactivated(x) && x.ID) newSelected.push(x.ID)
      })
    }

    this.setState({
      selected: newSelected,
      selectAll: selectAll === 0 ? 1 : 0
    })
  }

  handleToggleEdit = (item, data) => {
    const { editable } = this.state
    const hasValue = editable.includes(item)
    if (hasValue) {
      this.setState({ notification: 'Updating user...' })
      storage.token.get().then(token => {
        api.users.updateSingle(token, item, data).then(res => {
          consoleLog(res)
          if (res.code !== 200) return false
          this.setState(state => {
            state.notification = res.message ? res.message : 'User updated!'
            state.editable = editable.filter(i => i !== item)
            return state
          }, () => {
            setTimeout(() => {
              this.setState({ notification: false })
            }, 2000)
          })
        })
      })
    } else {
      this.setState(state => {
        state.editable.push(item)
        return state
      })
    }
  }

  renderItem = cellInfo => {
    const { editable } = this.state
    const id = cellInfo.original.ID
    if (editable.includes(id)) {
      return (
        <div
          className="editable-field"
          contentEditable
          suppressContentEditableWarning
          onBlur={e => {
            const data = [...this.state.data]
            data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML
            this.setState({ data })
          }}
          dangerouslySetInnerHTML={{
            __html: this.state.data[cellInfo.index][cellInfo.column.id]
          }}
        />
      )
    } else {
      const rowData = this.state.data[cellInfo.index]

      if (!rowData) return ''

      return rowData[cellInfo.column.id]
    }
  }

  renderActions = position => {
    const { actions, selected, tier, exceedsMediumBreakpoint } = this.state
    return (
      <Table.ActionsWrapper position={position}>
        <Table.Actions exceedsMediumBreakpoint={exceedsMediumBreakpoint} disabled={selected.length <= 0}
          onPress={() => this.setState({ actions: !actions })}>Actions</Table.Actions>
        {actions &&
        <Table.ActionList>
          {(tier > 1 && tier !== 3) && <Text onPress={() => this.handleModalOpen('replace')}>Replace Card</Text>}
          {tier > 2 && <Text onPress={() => this.handleModalOpen('invite')}>Send App Invite</Text>}
          <Text onPress={() => { this.setState({ bulkGroupUserManagementdModalVisible: true, bulkManagementAddMode: true, actions: false }) }}>Add To Group</Text>
          <Text onPress={() => { this.setState({ bulkGroupUserManagementdModalVisible: true, bulkManagementAddMode: false, actions: false }) }}>Remove From Group</Text>
          <Text onPress={() => this.handleModalOpen('disable')}>Disable</Text>
        </Table.ActionList>
        }
      </Table.ActionsWrapper>
    )
  }

  handleModalOpen = type => {
    this.setState(state => {
      state.modal[type] = true
      state.actions = false
      return state
    })
  }

  handleModalClose = type => {
    this.setState(state => {
      state.modal[type] = false
      state.modalNotification = null
      state.isSuccess = false
      return state
    })
  }

  handleSearch = e => {
    e.preventDefault()
    const { itemsPerPage, search, searchField, isSearching } = this.state

    if (isSearching) { return }

    this.setState({ search, isSearching: true }, () => {
      storage.token.get().then(token => {
        let searchQuery
        if (searchField === '') {
          searchQuery = 'firstName,lastName,email,cardID,appID,PIN,groupName'
        } else {
          searchQuery = searchField
        }

        const encodedSearch = encodeURIComponent(this.state.search)

        api.users.get(token, `offset=0&limit=${itemsPerPage}&search=${searchQuery}:${encodedSearch}&include_groups=true`).then(users => {
          const data = users.totalCount === 0 ? [] : users.items
          this.setState({ data, dataCount: users.totalCount, isSearching: false })
        })
      })
    })
  }

  handleReset = () => {
    const { itemsPerPage } = this.state
    this.setState({ isSearching: true })
    storage.token.get().then(token => {
      api.users.get(token, `offset=0&limit=${itemsPerPage}&include_groups=true`).then(users => {
        const data = users.totalCount === 0 ? [] : users.items
        this.setState({ data, dataCount: users.totalCount, isSearching: false })
      })
    })
    this.setState({ search: '' })
  }

  navigateToAddUsers = () => {
    const { statusType } = this.state
    if (statusType === 'open') {
      this.handleModalOpen('status')
    } else {
      const { history } = this.props
      history.push('/profile/user-management-and-ordering/add-users')
    }
  }

  handleSetDateLater = () => {
    this.handleModalClose('rollOutDate')
    this.navigateToAddUsers()
  }

  handleAddUser = () => {
    if (this.rollOutDateUnset()) {
      this.handleModalOpen('rollOutDate')
      return
    }
    this.navigateToAddUsers()
  }

  handleSetRollOutDate = (date) => {
    if (date === this.state.date) {
      return
    }

    this.setState({ date, dateSavable: true, dateSaved: false })
  }

  saveRollOutDate = () => {
    const { date, dateSavable } = this.state

    if (date == null || dateSavable === false) {
      return
    }

    this.setState({
      dateSaving: true,
      dateSavable: false,
      dateSaved: false
    })

    const rollOutDate = moment(date).format('YYYY-MM-DD')
    const data = { rollOutDate: rollOutDate }
    storage.token.get().then(token => {
      api.organisation.update(token, data).then(() => {
        this.setState({ dateSaving: false, dateSavable: false, dateSaved: true })
      })
    })
  }

  rollOutDateUnset = () => {
    const { tier, date } = this.state
    return tier >= 3 && date == null
  }

  rollOutDateEditable = () => {
    const { tier, date } = this.state
    const isAfterToday = date ? (date.getTime() - new Date().getTime() > 0) : false
    return tier >= 3 && (date == null || isAfterToday)
  }

  saveGroup = (isCreate, name, logoUrl, groupId, successCallback, failureCallback) => {
    storage.token.get().then(token => {
      const saveCall = isCreate ? api.users.groups.create(token, name, logoUrl) : api.users.groups.update(token, groupId, name, logoUrl)

      saveCall.then(res => {
        if (res.code && res.code >= 400) {
          failureCallback(res.message || 'An unknown error occurred.')
        } else {
          this.fetchGroups(token)
          successCallback()
        }
      })
    })
  }

  deleteGroup = (groupId, successCallback, failureCallback) => {
    storage.token.get().then(token => {
      api.users.groups.delete(token, groupId).then(res => {
        if (res.code && res.code >= 400) {
          failureCallback(res.message || 'An unknown error occurred.')
        } else {
          this.fetchGroups(token)
          successCallback()
        }
      })
    })
  }

  groupSelectOptionsForUser = (apiGroups) => {
    // Iterate over groups in the format they come back from the API (object with ID and name) and conver to a format
    // that the select knows how to display
    return apiGroups.map(serverGroup => {
      const userGroup = this.groupDataLookup[serverGroup.ID]
      if (!userGroup) {
        return null
      }
      return this.userGroupToOption(userGroup)
    }
    ).filter(ug => ug !== null)
  }

  groupIdsDiff = (existingGroupIds, newGroupIds) => {
    // return two arrays, of the added group IDs and the removed group IDs. normally you would just have one of them
    // being a single element array and the other being empty, but we'll support multiple

    return {
      added: newGroupIds.filter(id => !existingGroupIds.includes(id)),
      removed: existingGroupIds.filter(id => !newGroupIds.includes(id))
    }
  }

  renderGroupCell = (cellInfo) => {
    const { userGroupOptions, userGroupUpdateCount } = this.state

    const updateCount = userGroupUpdateCount[cellInfo.original.ID]
    const updateInProgress = updateCount && updateCount > 0

    const value = this.groupSelectOptionsForUser(cellInfo.original.groups)

    let noOptionsMessage

    if (userGroupOptions.length) {
      noOptionsMessage = 'User is in all available groups'
    } else {
      noOptionsMessage = <p>Click <em>Manage Groups</em> to create a group</p>
    }

    return <Select
      placeholder="No groups assigned"
      closeMenuOnScroll={true}
      noOptionsMessage={() => noOptionsMessage}
      options={userGroupOptions}
      menuPortalTarget={document.body}
      styles={groupSelectStyles}
      isClearable={false}
      value={value}
      onChange={(values) => {
        this.selectChange(cellInfo, values)
      }
      }
      isLoading={updateInProgress}
      isDisabled={updateInProgress}
      isMulti
    />
  }

  selectChange = (cellInfo, values) => {
    const selectedGroupIds = values.map(value => value.value)

    const diff = this.groupIdsDiff(cellInfo.original.groups.map(group => group.ID), selectedGroupIds)

    const userId = cellInfo.original.ID
    diff.added.forEach(addedGid => this.addUserToGroup(addedGid, userId))
    diff.removed.forEach(removedGid => this.removeUserFromGroup(removedGid, userId))

    if (diff.added.length || diff.removed.length) {
      this.updateGroupUpdateCount(userId, 1)
      // convert back into the "API" format, although we don't need the name
      cellInfo.original.groups = selectedGroupIds.map(id => ({ ID: id }))
    }
  }

  updateGroupUpdateCount = (userId, delta) => {
    const { userGroupUpdateCount } = this.state

    let updateCount = userGroupUpdateCount[userId] || 0
    updateCount += delta

    userGroupUpdateCount[userId] = updateCount

    this.setState({ userGroupUpdateCount })
  }

  removeUserFromGroup (groupId, userId) {
    storage.token.get().then(token => {
      api.users.groups.removeContactFromGroup(token, groupId, userId).then(res => {
        this.updateGroupUpdateCount(userId, -1)
      })
    })
  }

  addUserToGroup (groupId, userId) {
    storage.token.get().then(token => {
      api.users.groups.addContactToGroup(token, groupId, userId).then(res => {
        this.updateGroupUpdateCount(userId, -1)
      })
    })
  }

  performGroupBulkUpdate (
    isAddMode,
    groupId,
    contactIds,
    progressUpdateCallback,
    successCallback,
    errorCallback
  ) {
    storage.token.get().then(token => {
      this.processNextGroupUpdate(token, isAddMode, groupId, contactIds, 0, progressUpdateCallback, successCallback, errorCallback)
    })
  }

  processNextGroupUpdate (
    token,
    isAddMode,
    groupId,
    contactIds,
    contactIndex,
    progressUpdateCallback,
    successCallback,
    errorCallback
  ) {
    if (contactIndex >= contactIds.length) {
      successCallback()
      return
    }

    let apiMethod

    if (isAddMode) {
      apiMethod = api.users.groups.addContactToGroup(token, groupId, contactIds[contactIndex])
    } else {
      apiMethod = api.users.groups.removeContactFromGroup(token, groupId, contactIds[contactIndex])
    }

    apiMethod.then(res => {
      if (res.code && res.code >= 400) {
        errorCallback(res.message || 'An unknown error occurred.')
        return
      }

      progressUpdateCallback()
      this.processNextGroupUpdate(token, isAddMode, groupId, contactIds, contactIndex + 1, progressUpdateCallback, successCallback, errorCallback)
    })
  }

  itemsPerPageCountChange (newIpp) {
    newIpp = parseInt(newIpp, 10)

    if (isNaN(newIpp)) {
      return
    }

    const { itemsPerPage, currentPage } = this.state

    if (newIpp !== itemsPerPage) {
      this.setState({ itemsPerPage: newIpp }, () => {
        this.handlePagination(currentPage)
      })
    }
  }

  render () {
    const { history } = this.props
    const {
      // eslint-disable-next-line camelcase
      creation_locked, tier, options, profile, date, dataOriginalCount, dataCount, itemsPerPage, currentPage,
      data, search, editable, notification, modal, disableModalConfirm, userDownloadStatus, isLoading, error,
      modalNotification, modalNotificationType, dateSaving, dateSavable, dateSaved, isSearching, exceedsMediumBreakpoint,
      exceedsLargeBreakpoint, groupManagementModalVisible, userGroups, groupsLoading, bulkGroupUserManagementdModalVisible,
      bulkManagementAddMode, pageChanging
    } = this.state
    const minDate = new Date()
    minDate.setDate(minDate.getDate() + 1) // min date is tomorrow

    const downloadButtonText = downloadButtonTextLookup[userDownloadStatus]

    const columns = [
      {
        Cell: ({ original }) => {
          if (deactivated(original) || !original.ID) return false
          return (
            <div>
              <input
                type="checkbox"
                className="checkbox"
                id={original.ID}
                checked={this.state.selected.includes(original.ID)}
                onChange={() => this.handleToggleRow(original.ID)}
              />
              <label htmlFor={original.ID} className="custom-check">Select</label>
            </div>
          )
        },
        Header: () => {
          return (
            <div>
              <input
                type="checkbox"
                className="checkbox"
                id="all"
                checked={this.state.selectAll === 1}
                ref={input => {
                  if (input) input.indeterminate = this.state.selectAll === 2
                }}
                onChange={() => this.handleToggleSelectAll()}
              />
              <label htmlFor="all" className="custom-check">Select</label>
            </div>
          )
        },
        sortable: false,
        width: 30,
        className: 'check-td'
      },
      {
        Header: 'First name',
        accessor: 'firstName',
        Cell: this.renderItem
      },
      {
        Header: 'Last name',
        accessor: 'lastName',
        Cell: this.renderItem
      },
      {
        Header: 'Group',
        Cell: this.renderGroupCell,
        width: 180,
        sortable: false,
        className: 'group-td'
      },
      {
        Header: 'User email',
        accessor: 'email'
      },
      {
        Header: 'Card ID',
        accessor: 'cardID',
        show: tier < 3 || tier === 4
      },
      {
        Header: 'Card status',
        accessor: 'cardStatus',
        show: tier < 3 || tier === 4,
        Cell: statusTextCell(false),
        sortType: statusSort
      },
      {
        Header: 'App ID',
        accessor: 'appID',
        show: tier >= 3,
        width: 80
      },
      {
        Header: 'App status',
        accessor: 'appStatus',
        width: 90,
        show: tier >= 3,
        Cell: statusTextCell(true),
        sortType: statusSort
      },
      {
        Header: 'PIN',
        accessor: 'PIN',
        width: 75
      },
      {
        Header: 'Edit',
        width: 40,
        sortable: false,
        Cell: ({ original }) => {
          const id = original.ID
          const text = editable.includes(id) ? 'Finish' : 'Edit'
          const fields = { firstName: original.firstName, lastName: original.lastName }
          if (!id) return false
          return (
            <div className="link" onClick={() => this.handleToggleEdit(id, fields)}>{text}</div>
          )
        }
      }
    ]

    const addButtonStyle = getTableAddButtonStyle(exceedsMediumBreakpoint, exceedsLargeBreakpoint)

    const manageGroupsStyle = getManageGroupsButtonStyle(
      exceedsMediumBreakpoint,
      exceedsLargeBreakpoint,
      // eslint-disable-next-line camelcase
      creation_locked === true)

    return (
      <Layout
        title="User Management & Ordering"
        optionsData={options}
        isSuperLayout={true}
      >
        {error && <Notification>{error}</Notification>}
        {((tier.length === 0 || isLoading) && !error) ? (
          <LoadingIndicator/>
        ) : (
          !error && <View>
            <Top>
              <Active.Section>
                <Active.Progress>
                  <H1>Your Boost Users</H1>
                  <ProgressBar progress={profile.numberOfVehicles} max={profile.currentPlanMaxUsers}/>
                </Active.Progress>
                <Active.Info>
                  <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}><Bold>{profile.numberOfVehicles}/{profile.currentPlanMaxUsers}</Bold> allocated users.</P>
                  {tier > 1 &&
                  <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Need more users? <A to={'/profile/user-management-and-ordering/add-licenses'}>Add more licenses</A></P>
                  }
                  {tier !== 4 && <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Want more features? <A to={'/profile/upgrade'}>Upgrade your plan</A></P>}
                </Active.Info>
              </Active.Section>
            </Top>

            <Bottom>
              <Table.TopWrapper>
                <Table.Top>
                  <Table.Header>
                    {this.renderActions('top')}
                  </Table.Header>
                  <Table.TableSearch
                    id="search"
                    value={search}
                    placeholder="Search by name, group, or email"
                    onChange={e => this.setState({ search: e.target.value })}
                    onSubmit={e => this.handleSearch(e)}
                    onReset={() => this.handleReset()}
                    inputStyle={TableSearchStyle.input}
                    submitStyle={TableSearchStyle.submitButton}
                    resetStyle={TableSearchStyle.resetButton}
                  />
                  <label style={TableSearchStyle.label}>In Column</label>
                  <select onChange={e => this.setState({ searchField: e.target.value })} style={TableSearchStyle.select}>
                    <option value="">All</option>
                    <option value="firstName">First Name</option>
                    <option value="lastName">Last Name</option>
                    <option value="email">User Email</option>
                    <option value="appID">App ID</option>
                    {tier < 3 || tier === 4}<option value="cardID">Card ID</option>
                    <option value="PIN">PIN</option>
                    <option value="groupName">Group</option>
                  </select>
                  {!isSearching && <Primary title="Search" disabled={search === ''} onPress={e => this.handleSearch(e)}>Search</Primary>}
                  {isSearching && <InlineLoadingIndicator style={{ marginLeft: 27, marginRight: 28 }}/>}
                </Table.Top>
                {notification && <Notification type="success">{notification}</Notification>}
                <ScrollBarTable height={335}>
                  <Table.DataTable
                    data={data}
                    columns={columns}
                    showPagination={false}
                    minRows={10}
                    pageSize={itemsPerPage}
                    resizable={false}
                    getTrProps={(state, rowInfo) => {
                      const cellData = rowInfo ? rowInfo.original : {}
                      return {
                        style: {
                          background: deactivated(cellData) && '#f2f2f2'
                        }
                      }
                    }}
                  />
                </ScrollBarTable>
                {this.renderActions('bottom')}
                {dataCount > itemsPerPage &&
                <Table.Pagination
                  exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                  current={currentPage}
                  total={Math.ceil(Number(dataCount) / itemsPerPage)}
                  handleNav={type => this.handlePagination(type)}
                />}
              </Table.TopWrapper>

              <Table.Bottom>
                <Table.BottomTitle>Tools</Table.BottomTitle>
                <Table.BottomButtons>
                  {dataOriginalCount > 0 &&
                  <Secondary onPress={() => this.handleDownload()} disabled={userDownloadStatus !== userDownloadStatuses.READY}>{downloadButtonText}</Secondary>}
                  {this.rollOutDateEditable() && <React.Fragment>
                    <Table.Upload
                      placeholderText="Email Invitation Date *"
                      selected={date}
                      onChange={this.handleSetRollOutDate}
                      dateFormat="dd/MM/yyyy"
                      secondary="true"
                      minDate={minDate}
                    />
                    <Table.SaveDate style={saveDateStyle} onPress={() => { this.saveRollOutDate() }} disabled={dateSaving || !dateSavable}>Save Date</Table.SaveDate>
                  </React.Fragment>
                  }
                  {dateSaving &&
                  <InlineLoadingIndicator/>
                  }
                  {
                    dateSaved && <Tick style={Styles.Tick} source={require('../../images/tick-circle-green.png')}/>
                  }
                </Table.BottomButtons>
                <RightButtons>
                  {/* eslint-disable-next-line camelcase */}
                  {creation_locked === true
                    ? <>
                      <Table.ManageGroupsDisabled style={manageGroupsStyle}>Manage Groups</Table.ManageGroupsDisabled>
                      <Table.AddDisabled style={addButtonStyle}>Add Users&hellip;</Table.AddDisabled>
                    </>
                    : <>
                      <Table.ManageGroups
                        style={manageGroupsStyle}
                        onPress={() => { this.setState({ groupManagementModalVisible: true }) }}>
                        Manage Groups
                      </Table.ManageGroups>
                      <Table.Add style={addButtonStyle} onPress={() => this.handleAddUser()}>Add Users&hellip;</Table.Add>
                    </>
                  }
                </RightButtons>
              </Table.Bottom>
              {
                this.rollOutDateEditable() &&
                <Table.HelpText style={Styles.Table.HelpText} exceedsMediumBreakpoint={exceedsMediumBreakpoint}>* This is the date that the email invite will be sent out at 10 am to your users</Table.HelpText>
              }{
              // eslint-disable-next-line camelcase
                creation_locked === true &&
              <Table.DisabledContainer>
                <Table.DisabledText exceedsLargeBreakpoint={exceedsLargeBreakpoint} exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Please wait as we add your users.<br/> Please refresh the page in 10 minutes</Table.DisabledText>
              </Table.DisabledContainer>
              }
            </Bottom>
            <Table.PaginationWrapper>
              {dataCount > itemsPerPage &&
                <Table.NumberedPagination exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                  numberOfPages={Math.ceil(Number(dataCount) / itemsPerPage)}
                  active={currentPage}
                  onPress={e => this.handlePagination(e)}/>
              }
              {dataCount > 20 && <Table.PageSizeSelectWrapper exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                <Text style={[paginationNumberStyles.paginationTitle, Styles.PageSizeLabel]}>Users Per Page</Text>
                {pageChanging ? <div style={ { width: 70, marginLeft: 10 } }><InlineLoadingIndicator/></div>
                  : <select style={Styles.PageSizeSelect}
                    value={itemsPerPage}
                    onChange={(e) => { this.itemsPerPageCountChange(e.target.value) }}>
                    {[20, 50, 100].map(o => <option key={o} value={o}>{ o }</option>)}
                  </select>
                }
              </Table.PageSizeSelectWrapper>}
            </Table.PaginationWrapper>
            {groupManagementModalVisible && <GroupManagementModal
              visible={groupManagementModalVisible}
              groups={userGroups}
              close={() => { this.setState({ groupManagementModalVisible: false }) }}
              groupsLoading={groupsLoading}
              saveGroup={
                (isCreate, name, logoUrl, groupId, successCallback, failureCallback) => {
                  return this.saveGroup(isCreate, name, logoUrl, groupId, successCallback, failureCallback)
                }
              }
              deleteGroup={
                (groupId, successCallback, failureCallback) => {
                  return this.deleteGroup(groupId, successCallback, failureCallback)
                }
              }
            />
            }
            {bulkGroupUserManagementdModalVisible && <BulkGroupUserManagementModal
              visible={bulkGroupUserManagementdModalVisible}
              users={data.filter(u => this.state.selected.includes(u.ID))}
              groups={userGroups}
              close={(shouldRefresh) => {
                if (!shouldRefresh) {
                  this.setState({ bulkGroupUserManagementdModalVisible: false })
                  return
                }
                this.handlePagination(this.state.currentPage)
                this.setState({ bulkGroupUserManagementdModalVisible: false, isLoading: true })
              }}
              isAddMode={bulkManagementAddMode}
              performSaveCallback={
                (isAddMode,
                  groupId,
                  contactIds,
                  progressUpdateCallback,
                  successCallback,
                  errorCallback) => this.performGroupBulkUpdate(
                  isAddMode,
                  groupId,
                  contactIds,
                  progressUpdateCallback,
                  successCallback,
                  errorCallback
                )
              }
            />
            }
            {modal.replace &&
            <UsersModal
              h1="Replace Card"
              p={
                <React.Fragment>
                  <React.Fragment>This will send the selected users a new Boost Card. For every user you have selected,
                    one license will be used. Are you sure you want to proceed?</React.Fragment>
                  {modalNotification &&
                  <ModalNotification style={Styles.ModalNotification} type={modalNotificationType}>{modalNotification}</ModalNotification>}
                </React.Fragment>
              }
              onPress={() => this.handleReplaceCard()}
              close={() => this.handleModalClose('replace')}
              isSuccess={this.state.isSuccess}
              disabled={disableModalConfirm}
            />
            }
            { modal.invite && this.getInviteModal(modalNotification, modalNotificationType, disableModalConfirm) }
            {modal.disable &&
            <UsersModal
              h1="Disable"
              p={
                <React.Fragment>
                  <React.Fragment>This will remove the user from Boost completely. Are you sure you want to disable the
                    selected users?</React.Fragment>
                  {modalNotification &&
                  <ModalNotification style={Styles.ModalNotification} type={modalNotificationType}>{modalNotification}</ModalNotification>}
                </React.Fragment>
              }
              onPress={() => this.handleDisable()}
              close={() => this.handleModalClose('disable')}
              isSuccess={this.state.isSuccess}
              disabled={disableModalConfirm}
            />
            }
            {modal.status &&
            <Modal
              h1="No Customisation"
              p={
                <React.Fragment>
                  It looks like you haven’t finished your customisation. If you add users your branding will not be shown on their Boost collateral.
                  Are you sure you want to proceed?<br/><br/>If you have approved your logo in the last 10 minutes please ignore this message.
                </React.Fragment>
              }
              close={() => this.handleModalClose('status')}
            >
              <ModalClose style={Styles.ModalClose} onPress={() => this.handleModalClose('status')}>No</ModalClose>
              <ModalLink style={Styles.ModalLink} onPress={() => history.push('/profile/customisation')}>Go to Customisation</ModalLink>
              <Primary onPress={() => history.push('/profile/user-management-and-ordering/add-users')}>Yes ›</Primary>
            </Modal>
            }

            {modal.rollOutDate &&
            <Modal
              h1="Missing App Release Date"
              close={() => this.handleModalClose('rollOutDate')}
              p={
                <React.Fragment>
                  It looks like you haven’t set a release date for your apps. Please enter one now
                  before you add your users, otherwise, the app invites will not be sent out.
                </React.Fragment>
              }
            >
              <Primary onPress={() => this.handleModalClose('rollOutDate')}>Set date now</Primary>
              <ModalClose style={Styles.ModalClose} onPress={() => this.handleSetDateLater()}>Set date later</ModalClose>
            </Modal>
            }
          </View>
        )}
      </Layout>
    )
  }

  getExpiresSoonContent () {
    return <p>
      Your Boost subscription will renew shortly.
      <br/><br/>
      Users who do not register
      before renewal will have a new App ID issued, and their old registration details will no longer work.
      <br/><br/>
      Please send the invite after your Boost subscription has been renewed.
    </p>
  }

  getInviteModal (modalNotification, modalNotificationType, disableModalConfirm) {
    const { subscriptionExpiresSoon, isSuccess, modal } = this.state

    const message = subscriptionExpiresSoon ? this.getExpiresSoonContent() : 'This will send the selected users an ' +
      'invitation and login information to the Boost App. Are you sure you want to do this?'

    return <UsersModal
      h1="App Invite"
      p={
        <React.Fragment>
          <React.Fragment>{message}</React.Fragment>
          {modalNotification &&
            <ModalNotification style={Styles.ModalNotification}
              type={modalNotificationType}>{modalNotification}</ModalNotification>}
        </React.Fragment>
      }
      onPress={() => {
        if (subscriptionExpiresSoon) {
          return
        }
        this.handleSendAppInvite()
      }}
      close={() => this.handleModalClose('invite')}
      isSuccess={isSuccess && !subscriptionExpiresSoon}
      modal={modal}
      cancelIsClose={true}
      hideYes={subscriptionExpiresSoon}
      disabled={disableModalConfirm}
    />
  }
}
