import React from 'react'
import { Dimensions, Image, Text, View } from 'react-native'
import { colours } from '../styles/constants'
import PropTypes from 'prop-types'
import Constants from 'expo-constants'

export default class UpdateMessage extends React.Component {
  static propTypes = {
    boostUpdateMessage: PropTypes.string
  }

  filterNotAllowedHTML (html) {
    return html.replace(/<img\s[^>]*?src\s*=\s*['"]([^'"]*?)['"][^>]*?>/g, '')
  }

  render () {
    const { boostUpdateMessage } = this.props

    if (boostUpdateMessage === '' || boostUpdateMessage == null) {
      return null
    }

    const RenderHtml = global.RenderHtml
    const windowWidth = Dimensions.get('window').width

    return <View style={componentStyles.view}>
      <View style={componentStyles.header}>
        <Image style={componentStyles.image} resizeMode="contain" source={require('../images/bell-icon.png')}/>
        <Text style={componentStyles.heading}>Boost Updates</Text>
      </View>
      <RenderHtml
        contentWidth={windowWidth}
        tagsStyles={htmlStyles}
        source={ { html: `${this.filterNotAllowedHTML(boostUpdateMessage)}` } }
        systemFonts={['greycliff', ...Constants.systemFonts]}
      />
    </View>
  }
}

const componentStyles = {
  view: {
    borderBottom: `1px solid ${colours.primary}`,
    paddingBottom: 20,
    marginBottom: 10
  },
  header: {
    flexDirection: 'row',
    alignContent: 'center'
  },
  heading: {
    fontFamily: 'greycliff-bold',
    fontSize: 16,
    marginBottom: 10
  },
  image: {
    width: 20,
    height: 20,
    marginRight: 5
  }
}

const htmlStyles = {
  p: {
    fontSize: 12,
    color: '#000',
    margin: 0,
    padding: 0,
    fontFamily: 'greycliff'
  },
  sup: {
    fontSize: 7,
    color: '#000',
    fontFamily: 'greycliff'
  },
  sub: {
    fontSize: 7,
    color: '#000',
    fontFamily: 'greycliff'
  },
  strong: {
    fontFamily: 'greycliff-bold'
  },
  b: {
    fontFamily: 'greycliff-bold',
    color: '#000'
  },
  big: {
    fontSize: 20,
    color: '#000',
    fontFamily: 'greycliff'
  },
  small: {
    fontSize: 9,
    color: '#000',
    fontFamily: 'greycliff'
  },
  span: {
    color: '#000',
    fontFamily: 'greycliff'
  },
  em: {
    color: '#000',
    fontFamily: 'greycliff'
  },
  a: {
    color: '#000',
    fontFamily: 'greycliff'
  }
}
