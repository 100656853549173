import React from 'react'
import Modal from '../../components/Modal'
import isWeb from '../../helpers/isWeb'
import styled from 'styled-components'
import { DataTable, P } from '../../styles/globalClasses'
import { colours } from '../../styles/constants'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import BreakpointWatcher from '../../helpers/BreakpointWatcher'
import { Primary, Secondary } from '../../components/Inputs/Buttons'
import ProgressBar from '../../components/ProgressBar'

const UserPreviewTable = isWeb && styled(DataTable)`
    margin: 10px 0 5px;
    
    .rt-td {
      display: flex;
      align-content: center;
      vertical-align: middle;
    }
  
    .actions-td {
      flex-grow: 1;
    }
  
    .name-td {
      font-size: 14px;
      line-height: 27px;
      flex-shrink: 1;
    }

    .rt-tbody {
      padding-bottom: 1px;
      margin-bottom: -1px;
    }

    .rt-tbody .rt-tr-group:last-child {
      position: relative;
      &:before {
        content: '';
        background: ${colours.light};
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;
        z-index: 1;
      }
    }
`

const TableWrapper = ({ style = {}, children, ...props }) => {
  const _style = {
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    ...style
  }

  return <View style={_style} {...props}>
    { children }
  </View>
}

const preambleStyle = {
  marginTop: -20
}

const groupSelectStyle = {
  marginTop: 10,
  marginBottom: 10,
  flexGrow: 1,
  marginLeft: 10,
  appearance: 'auto',
  WebkitAppearance: 'auto'
}

const groupSelectLabelStyle = {
  fontFamily: 'greycliff'
}

const buttonContainerStyle = {
  flexDirection: 'row',
  justifyContent: 'space-between'
}

const actionButtonStyle = {
  alignSelf: 'end'
}

const cancelButtonStyle = {}

const innerStyle = {
  minHeight: 167,
  alignContent: 'space-around',
  justifyContent: 'space-between',
  width: '100%'
}

const progressBarWrapperStyle = {
  marginTop: 10,
  marginBottom: 10
}

const errorTextStyle = {
  color: colours.urgent,
  fontFamily: 'greycliff-bold'
}

const formWrapperStyle = { display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 0 }

export default class BulkGroupUserManagementModal extends React.Component {
  selectedGroup = null

  static propTypes = {
    visible: PropTypes.bool,
    users: PropTypes.array,
    groups: PropTypes.array,
    isAddMode: PropTypes.bool,
    performSaveCallback: PropTypes.func
  }

  state = {
    exceedsMediumBreakpoint: false,
    processedCount: 0,
    saveInProgress: false,
    saveError: null
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.selectedGroup === null && this.props.groups.length) {
      this.selectedGroup = this.props.groups[0].ID
    }
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  incrementProcessedCount () {
    this.setState({ processedCount: this.state.processedCount + 1 })
  }

  performSave () {
    const { isAddMode, users } = this.props

    this.setState({ saveInProgress: true, saveError: null })
    this.props.performSaveCallback(isAddMode,
      this.selectedGroup,
      users.map(u => u.ID),
      () => { this.incrementProcessedCount() },
      () => { this.props.close(true) },
      (saveError) => { this.setState({ saveInProgress: false, saveError }) }
    )
  }

  render () {
    const { visible, users, groups, close, isAddMode } = this.props
    const { exceedsMediumBreakpoint, processedCount, saveInProgress, saveError } = this.state

    const columns = [
      {
        Header: 'First name',
        accessor: 'firstName'
      },
      {
        Header: 'Last name',
        accessor: 'lastName'
      },
      {
        Header: 'User email',
        accessor: 'email'
      }
    ]

    const action = isAddMode ? 'Add' : 'Remove'
    const actionPastTense = isAddMode ? 'added' : 'removed'
    const fromOrTo = isAddMode ? 'to' : 'from'

    return <Modal
      h1={`${action} Users ${fromOrTo} Group`}
      visible={visible}
      close={() => {
        if (saveInProgress) {
          return
        }
        close(false)
      }}
    >
      <View style={innerStyle}>
        <P style={preambleStyle} exceedsMediumBreakpoint={exceedsMediumBreakpoint}>These users will be { actionPastTense } { fromOrTo } the selected group:</P>
        <TableWrapper>
          <UserPreviewTable
            data={users}
            columns={columns}
            minRows={Math.min(users.length, 10)} showPagination={users.length > 10} defaultPageSize={10}
            showPageSizeOptions={false}
          />
        </TableWrapper>
        <View style={ formWrapperStyle }>
          <label style={groupSelectLabelStyle} htmlFor="idBulkUserGroupSelect">{action} {fromOrTo} Group:</label>
          <select id="idBulkUserGroupSelect" style={groupSelectStyle} onChange={(e) => { this.selectedGroup = e.target.value } }>
            {groups.map(group => <option value={group.ID} key={group.ID}>{group.name}</option>)}
          </select>
        </View>
        {saveInProgress && <View style={progressBarWrapperStyle}>
          <ProgressBar progress={processedCount} max={users.length}/>
        </View>
        }
        {saveError != null && <P exceedsMediumBreakpoint={exceedsMediumBreakpoint} style={errorTextStyle}>{saveError}</P>}

        <View style={buttonContainerStyle}>
          <Secondary style={cancelButtonStyle} onPress={() => close(false)} disabled={saveInProgress}>Cancel</Secondary>
          <Primary style={actionButtonStyle} onPress={() => this.performSave()} disabled={saveInProgress}>
            {action} {users.length} Users { fromOrTo } Selected Group
          </Primary>
        </View>
      </View>
    </Modal>
  }
}
