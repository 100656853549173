import React from 'react'
import { Text, Linking } from 'react-native'
import { withRouter, Link } from 'react-router-dom'
import isWeb from '../helpers/isWeb'
import styled, { css } from 'styled-components'

const webCSS = css`
    font-family: greycliff;
    color: black;
    text-decoration: none;
    transition: opacity .1s;
    &:hover,
    &:hover > *,
    &:focus,
    &:focus > * {
        color: ${props => props.hover && props.hover};
        outline: 0;
    }
    &:hover {
        opacity: .8;
    }
    &:active {
        opacity: 1;
    }
`
const Web = {
  Link: styled(Link)`${webCSS}`,
  a: isWeb && styled.a`${webCSS}`,
  Text: Text
}

class Component extends React.Component {
  render () {
    const { history, link, children, style, css, hover, className } = this.props
    const externalLink = link && /^https?:\/\//.test(link)
    if (isWeb) {
      const LinkText = () => <Web.Text style={style}>{children}</Web.Text>
      // if external link return href
      if (externalLink) return <Web.a href={link} style={css} className={className} hover={hover}><LinkText/></Web.a>
      // otherwise return Link
      return <Web.Link to={link} style={css} hover={hover} className={className}><LinkText/></Web.Link>
    } else {
      return (
        <Text
          style={style}
          onPress={() => externalLink ? Linking.openURL(link) : history.push(link)}>
          {children}
        </Text>
      )
    }
  }
}

export default withRouter(Component)
