// LTO, general offer component in a list of offers
import React from 'react'
import { View, Text, Image, TouchableWithoutFeedback, StyleSheet } from 'react-native'
import { colours, breakpoints, gutter } from '../styles/constants'
import { withRouter } from 'react-router-dom'
import Link from './Link'
import isWeb from '../helpers/isWeb'
import { Primary } from './Inputs/Buttons'
import ExpiryBar from './ExpiryBar'
import offerType from '../helpers/offerType'
import LoadingIndicator from './LoadingIndicator'
import ImageManager from '../helpers/ImageManager'
import { EntityType } from '../helpers/EntityFacade'
import PropTypes from 'prop-types'
import BreakpointWatcher from '../helpers/BreakpointWatcher'
import lineHeight from '../helpers/lineHeight'

export const buttonHeight = 217
export const buttonHeightLarge = 290

const generalCharLimit = {
  heading: {
    small: 20,
    smallHeight: 24,
    large: 24
  },
  desc: {
    small: 100,
    smallHeight: 68,
    large: 120
  }
}

const largeCharLimit = {
  heading: {
    small: 20,
    smallHeight: 24,
    large: 27
  },
  subheading: {
    small: 50,
    smallHeight: 14,
    large: 66
  },
  desc: {
    small: 150,
    smallHeight: 115,
    large: 190,
    largeHeight: 102
  },
  terms: {
    small: 150,
    smallHeight: 72,
    large: 170
  }
}
const ellipsis = '…'
const ellipsisLength = ellipsis.length
const renderField = (field, limit) => {
  if (field) {
    if (field.length - ellipsisLength > limit) return field.substring(0, limit).replace(/\s*$/, '') + ellipsis
    return field
  }
  return ''
}
const touchDevice = isWeb && (('ontouchstart' in window) || (typeof window.DocumentTouch !== 'undefined' && document instanceof window.DocumentTouch))

const extractTextContent = (html) => {
  const span = document.createElement('span')
  span.innerHTML = html
  return span.textContent || span.innerText
}

class ListOffer extends React.Component {
  static propTypes = {
    isInCart: PropTypes.bool,
    isLarge: PropTypes.bool,
    ID: PropTypes.string,
    image: PropTypes.string,
    termsSmall: PropTypes.string,
    terms: PropTypes.string,
    history: PropTypes.object,
    cartAddOrRemove: PropTypes.func,
    type: PropTypes.string,
    featured: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    supplierLogo: PropTypes.string,
    starts: PropTypes.string,
    expires: PropTypes.string,
    redeemMessageOnline: PropTypes.string,
    customCta: PropTypes.func,
    customCtaButtonName: PropTypes.string,
    canAddToCart: PropTypes.bool,
    isOnline: PropTypes.bool
  }

  state = {
    isLoading: true,
    isImageError: false,
    imageSrc: null,
    isInCart: this.props.isInCart,
    headingLimit: this.props.isLarge ? largeCharLimit.heading.small : generalCharLimit.heading.small,
    subheadingLimit: largeCharLimit.subheading.small,
    descLimit: this.props.isLarge ? largeCharLimit.desc.small : generalCharLimit.desc.small,
    termsLimit: largeCharLimit.terms.small,
    exceedsMediumBreakpoint: false,
    exceedsLargeBreakpoint: false
  }

  mounted = false

  descriptionText = null

  updateImage () {
    const { ID, image, isOnline } = this.props

    let src = null

    if (isWeb) {
      this.setState({ imageSrc: { uri: image }, isLoading: false })
    } else {
      this.setState({ isLoading: true })

      if (isOnline === null) { return }

      const im = ImageManager.getManager(isOnline)

      let isImageError = this.state.isImageError

      im.getImageUrl(ID, image, EntityType.OFFER).then((imageUri) => {
        if (imageUri == null) {
          isImageError = true
        } else {
          src = { uri: imageUri }
        }

        if (isImageError) {
          src = require('../images/placeholders/noimage.png')
        }
        if (this.mounted) { this.setState({ imageSrc: src, isImageError, isLoading: false }) }
      })
    }
  }

  componentDidMount () {
    this.mounted = true
    this.updateImage()
    BreakpointWatcher.addComponent(this)
  }

  componentWillUnmount () {
    this.mounted = false
    BreakpointWatcher.removeComponent(this)
  }

  componentDidUpdate (prevProps) {
    if (!this.mounted) { return }

    if (prevProps.isInCart !== this.props.isInCart) {
      this.setState({ isInCart: this.props.isInCart })
    }

    if (prevProps.image !== this.props.image || prevProps.isOnline !== this.props.isOnline) {
      this.updateImage()
    }

    if (prevProps.description !== this.props.description) {
      this.descriptionText = this.props.description
    }
  }

  renderTerms = () => {
    const { ID, termsSmall, terms, isLarge } = this.props
    const { termsLimit } = this.state
    if (isWeb) {
      return terms && <OfferTerms>{renderField(terms, termsLimit)} <Link style={styles.offerTermsLink} link={`/offers/${ID}`}>See full terms & conditions</Link></OfferTerms>
    } else {
      return termsSmall && <OfferTerms general={!isLarge}>{termsSmall}</OfferTerms>
    }
  }

  handleUpdateCart = () => {
    const { cartAddOrRemove } = this.props
    this.setState({
      isInCart: !this.state.isInCart
    }, () => {
      // is already toggled a couple of lines above
      const isAdd = this.state.isInCart
      cartAddOrRemove && cartAddOrRemove(isAdd)
    })
  }

  onImagePress = id => {
    const { history } = this.props
    if (touchDevice) {
      return false
    }
    history.push(`/offers/${id}`)
  }

  onImageLongPress = id => {
    const { history } = this.props
    if (touchDevice) {
      history.push(`/offers/${id}`)
    }
  }

  onImageLoaded = () => this.setState({ isLoading: false })
  onImageError = () => {
    this.setState({ isImageError: true, isLoading: false })
  }

  render () {
    const {
      type,
      featured,
      ID,
      image,
      title,
      subtitle,
      description,
      supplierLogo,
      history,
      starts,
      expires,
      redeemMessageOnline,
      isLarge,
      customCta,
      customCtaButtonName,
      canAddToCart
    } = this.props
    const { isInCart, isLoading, headingLimit, subheadingLimit, descLimit, imageSrc, exceedsLargeBreakpoint, exceedsMediumBreakpoint } = this.state
    const getType = !isLarge || type === undefined ? 'general' : offerType(type)
    const general = !isLarge

    if (isWeb && this.descriptionText === null) {
      this.descriptionText = extractTextContent(description)
    } else if (this.descriptionText === null) {
      this.descriptionText = description
    }

    return (
      <OfferWrapper exceedsLargeBreakpoint={exceedsLargeBreakpoint} exceedsMediumBreakpoint={exceedsMediumBreakpoint} general={general}>
        {isWeb && <style type="text/css">{listOfferCss}</style>}
        {image &&
                    <TouchableWithoutFeedback onLongPress={() => this.onImageLongPress(ID)} onPress={() => this.onImagePress(ID)}>
                      <OfferImageWrap exceedsLargeBreakpoint={exceedsLargeBreakpoint} exceedsMediumBreakpoint={exceedsMediumBreakpoint} general={general}>
                        {isLoading && <LoadingIndicator />}
                        <OfferImage onLoad={this.onImageLoaded} source={ imageSrc } onError={this.onImageError} />
                      </OfferImageWrap>
                    </TouchableWithoutFeedback>
        }
        {!isWeb && supplierLogo &&
                    <View style={styles.offerLogoWrap}>
                      <OfferLogo general={general} source={{ uri: supplierLogo }} resizeMode="contain" />
                    </View>
        }

        <View style={notWebStyles?.offerHeader}>
          <OfferHeading type={getType} featured={featured ? 1 : 0} onPress={() => history.push(`/offers/${ID}`)}>{renderField(title, headingLimit)}</OfferHeading>
          <OfferSubHeading type={getType}>{renderField(subtitle, subheadingLimit)}</OfferSubHeading>
        </View>

        {isWeb &&
                    <OfferDescription exceedsMediumBreakpoint={exceedsMediumBreakpoint} type={getType}>
                      <OfferDescText type={getType}>{renderField(this.descriptionText, descLimit)}</OfferDescText>
                      {supplierLogo && !general && <OfferLogo source={{ uri: supplierLogo }} resizeMode="contain" />}
                    </OfferDescription>
        }

        {!isWeb &&
                    <View style={styles.offerDetails}>
                      {this.renderTerms()}
                      {isLarge && <ExpiryBar type={'lto'} starts={starts} expires={expires} />}
                    </View>
        }

        <View style={styles.offerBottom}>
          <OfferButtonWrap type={getType}>
            <ButtonPrimary
              type={['main', getType]}
              onPress={() => customCta ? customCta(ID) : history.push(`/offers/${ID}`)}
              exceedsMediumBreakpoint={exceedsMediumBreakpoint}
            >
              {customCtaButtonName || 'View Offer'}
            </ButtonPrimary>
            {isWeb &&
                            <React.Fragment>
                              {canAddToCart && <ButtonPrimary
                                type={['cart', getType, isInCart ? 'remove' : 'add']}
                                onPress={() => this.handleUpdateCart()}
                                exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                              >
                                {isInCart ? 'Remove' : 'Add to Cart'}
                              </ButtonPrimary>
                              }
                              {general && redeemMessageOnline && <OfferOnline exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Online Shopping</OfferOnline>}
                            </React.Fragment>
            }
          </OfferButtonWrap>

          {isWeb && !general &&
                        <React.Fragment>
                          <ExpiryBar type={getType} starts={starts} expires={expires} />
                          {this.renderTerms()}
                        </React.Fragment>
          }
        </View>
      </OfferWrapper>
    )
  }
}

export default withRouter(ListOffer)

const borderRadius = 4

const webImageSize = {
  small: 314,
  medium: 352,
  large: buttonHeightLarge,
  gutterLarge: 40
}

const webImageSizeGeneral = {
  small: 195,
  medium: 230,
  large: buttonHeight
}

const webOfferGutter = {
  medium: 15,
  large: 30,
  largeGeneral: 20
}

const appImageSizeGeneral = 180

const OfferWrapper = ({ exceedsMediumBreakpoint, exceedsLargeBreakpoint, general, style = {}, children, ...rest }) => {
  const _style = {
    maxWidth: '100%',
    backgroundColor: 'white',
    ...(isWeb ? {
      paddingRight: gutter.small,
      width: webImageSize.small + gutter.small,
      marginBottom: 15
    } : {
      marginRight: gutter.small,
      width: 245,
      marginTop: 2,
      marginBottom: 2,
      shadowOpacity: 0.75,
      shadowRadius: borderRadius,
      shadowColor: 'rgba(0,0,0,.1)',
      shadowOffset: { width: 0, height: 0 },
      borderRadius: borderRadius
    }),
    ...((general && !isWeb) ? {
      width: appImageSizeGeneral,
      marginRight: 15
    } : {}),
    ...((isWeb && exceedsMediumBreakpoint) ? {
      paddingRight: webOfferGutter.medium,
      width: webImageSize.medium + webOfferGutter.medium,
      maxWith: `calc(50% + ${webOfferGutter.medium}px`
    } : {}),
    ...((isWeb && exceedsLargeBreakpoint) ? {
      paddingRight: webOfferGutter.large,
      width: webImageSize.large + webOfferGutter.large,
      maxWidth: '100%'
    } : {}),
    ...((general && isWeb) ? {
      width: '50%',
      ...(exceedsMediumBreakpoint ? {
        width: '25%'
      } : {}),
      ...(exceedsLargeBreakpoint ? {
        paddingRight: webOfferGutter.largeGeneral
      } : {})
    } : {}),
    ...style
  }

  return <View style={_style} {...rest}>{ children }</View>
}

const OfferImageWrap = ({ exceedsMediumBreakpoint, exceedsLargeBreakpoint, general, style = {}, children, ...rest }) => {
  const _style = {
    marginBottom: 10,
    width: '100%',
    height: webImageSize.small,
    overflow: 'hidden',
    ...(!isWeb ? {
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
      height: 235
    } : {
      ...(exceedsMediumBreakpoint ? {
        height: webImageSize.medium
      } : {}),
      ...(exceedsLargeBreakpoint ? {
        height: webImageSize.large
      } : {})
    }),
    ...((general && !isWeb) ? {
      height: appImageSizeGeneral
    } : {}),
    ...((general && isWeb) ? {
      height: webImageSizeGeneral.small,
      ...(exceedsMediumBreakpoint ? {
        height: webImageSizeGeneral.medium
      } : {}),
      ...(exceedsLargeBreakpoint ? {
        height: webImageSizeGeneral.large
      } : {})
    } : {}),
    ...style
  }

  return <View style={_style} {...rest}>{children}</View>
}

const listOfferCss = `
  .offer-image {
    ${isWeb && `
      border-radius: 4px;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
    `}
  }
  .offer-heading {
    line-height: 24px;
    display: block;
  }
  .offer-heading:active {
    opacity: .8;
  }
  @media(min-width: ${breakpoints.medium}px) {
    .offer-heading {
      font-size: 18px
    }
  }
  .offer-button-wrap {
    padding-top: ${isWeb ? '20px' : '10px'};
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: nowrap;
  }
  .offer-button-wrap > * {
    flex: 1;
    width: 100%;
    overflow: visible;
  }
  .offer-button-wrap > * + * {
    margin-top: 10px;
  }
`

const OfferImage = ({ _style, ...rest }) => {
  const offerImageStyles = {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    flexBasis: 0,
    ..._style
  }
  return (
    <Image style={offerImageStyles} className='offer-image' {...rest} />
  )
}

const OfferHeading = ({ exceedsMediumBreakpoint, type, style = {}, children, featured = false, ...props }) => {
  const _style = {
    fontFamily: 'greycliff-extrabold',
    height: type === 'general' ? generalCharLimit.heading.smallHeight : largeCharLimit.heading.smallHeight,
    ...(isWeb ? {
      fontSize: 22,
      lineHeight: lineHeight(24)
    } : {
      fontSize: 16,
      textAlign: 'center'
    }),
    ...(type === 'general' ? {
      textAlign: 'center',
      fontSize: isWeb ? 16 : 14
    } : {}),
    ...((type === 'lto' && isWeb) ? {
      color: colours.urgent
    } : {}),
    ...((featured && isWeb && type !== 'lto') ? {
      color: colours.primary
    } : {}),
    ...(exceedsMediumBreakpoint ? {
      fontSize: 18
    } : {}),
    ...style
  }

  if (isWeb && props.onPress) {
    delete props.onPress
  }

  return (
    <React.Fragment>
      {!isWeb ? <Text style={_style} {...props}>{children}</Text>
        : <div style={_style} className={'offer-heading'} {...props}>{children}</div>}
    </React.Fragment>
  )
}

const OfferSubHeading = props => {
  const offerSubHeadingStyles = {
    ...(!isWeb ? { textAlign: 'center' } : {}),
    color: colours.black60,
    height: largeCharLimit.subheading.smallHeight,
    fontFamily: 'greycliff',
    fontSize: isWeb ? 12 : 11,
    lineHeight: lineHeight(14),
    ...(props.type === 'general' && isWeb ? { display: 'none' } : {})
  }
  return (
    <Text style={offerSubHeadingStyles}>{props.children}</Text>
  )
}

const OfferLogo = props => {
  const offerLogoStyles = {
    width: (props.general && isWeb) ? 40 : isWeb ? 70 : 60,
    height: (props.general && isWeb) ? 20 : isWeb ? 50 : 30,
    ...(isWeb ? { marginLeft: 40 } : {})
  }
  return (
    <Image style={offerLogoStyles} source={props.source} resizeMode={props.resizeMode} />
  )
}

const OfferDescription = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const offerDescriptionStyles = {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: props.type === 'general' ? generalCharLimit.desc.smallHeight : largeCharLimit.desc.smallHeight
  }
  const offerDescriptionStylesNotGeneralIsWeb = {
    marginTop: 20,
    height: largeCharLimit.desc.largeHeight
  }
  return (
    <View style={(props.type !== 'general' && isWeb && exceedsMediumBreakpoint) ? { ...offerDescriptionStyles, ...offerDescriptionStylesNotGeneralIsWeb } : offerDescriptionStyles} >{props.children}</View>
  )
}

const OfferDescText = props => {
  const offerDescTextStyles = {
    fontFamily: 'greycliff',
    maxWidth: '100%',
    ...(props.type === 'general' ? { textAlign: 'center' } : {})
  }
  return (
    <Text style={offerDescTextStyles}>{props.children}</Text>
  )
}

const OfferTerms = props => {
  const offerTermsStyles = {
    ...(!isWeb ? { textAlign: 'center' } : {}),
    marginTop: isWeb ? 15 : 2,
    color: colours.black40,
    fontSize: (props.general && !isWeb) ? 6 : isWeb ? 10 : 8,
    fontFamily: 'greycliff',
    height: isWeb && largeCharLimit.terms.smallHeight
  }
  return (
    <Text style={offerTermsStyles}>{props.children}</Text>
  )
}

const OfferButtonWrap = ({ type, children, ...rest }) => {
  const buttonWrapStyles = {
    paddingTop: isWeb ? 20 : 10,
    paddingBottom: 10,
    flexDirection: 'row',
    justifyContent: type === 'general' ? 'space-evenly' : 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    ...(isWeb ? { display: 'flex' } : {})
  }
  return (
    <>
      {!isWeb ? <View style={type !== 'general' ? buttonWrapStyles : (type === 'general' && !isWeb) ? buttonWrapStyles : {}} {...rest}>{children}</View>
        : <div
          style={type !== 'general' ? buttonWrapStyles : (type === 'general' && !isWeb) ? buttonWrapStyles : {}}
          className={type === 'general' && isWeb ? 'offer-button-wrap' : ''}
          {...rest}
        >{children}</div>}
    </>
  )
}

const ButtonPrimary = props => {
  const exceedsMediumBreakpoint = props.exceedsMediumBreakpoint
  const buttonPrimaryStyles = {
    borderRadius: isWeb ? 4 : 8,
    paddingTop: 8,
    paddingBottom: 8,
    ...(isWeb && !exceedsMediumBreakpoint ? { fontSize: 12, padding: 5 } : {}),
    ...(props.type.includes('general') ? { width: '100%' } : {}),
    marginLeft: props.type.includes('general') ? 0 : props.type.includes('cart') ? 10 : 'auto',
    ...(props.type.includes('main') ? {
      flex: 1,
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 'auto'
    } : {}),
    backgroundColor: props.type.includes('lto') ? colours.urgent : props.type.includes('add') ? 'black' : props.type.includes('remove') ? colours.black10 : colours.primary,
    color: props.type.includes('remove') ? 'black' : 'white'
  }
  const buttonPrimaryStylesCart = {
    ...(isWeb ? {
      minWidth: 92
    } : {}),
    ...(isWeb && exceedsMediumBreakpoint ? { paddingLeft: 4, paddingRight: 4 } : {})
  }
  return (
    <Primary style={props.type.includes('cart') ? { ...buttonPrimaryStylesCart, ...buttonPrimaryStyles } : buttonPrimaryStyles} onPress={props.onPress}>{props.children}</Primary>
  )
}

const OfferOnline = props => {
  const offerOnlineStyles = {
    fontSize: 10,
    lineHeight: lineHeight(11),
    fontFamily: 'greycliff-bold',
    color: colours.taupe,
    marginTop: 10,
    textAlign: 'center',
    textTransform: 'uppercase'
  }
  return (
    <Text style={offerOnlineStyles}>{props.children}</Text>
  )
}

const styles = StyleSheet.create({
  offerLogoWrap: {
    marginBottom: 5,
    alignItems: 'center'
  },
  offerDetails: {
    flexDirection: 'column',
    ...(!isWeb ? {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 0,
      paddingLeft: 0
    } : {})
  },
  offerTermsLink: {
    color: colours.black40,
    fontFamily: 'greycliff-bold',
    textDecorationLine: 'underline'
  },
  remaining: {
    color: colours.urgent
  },
  offerBottom: {
    flexDirection: 'column',
    ...(!isWeb ? {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 'auto'
    } : {})
  }
})

const notWebStyles = !isWeb && StyleSheet.create({
  offerHeader: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 10
  }
})
