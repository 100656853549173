import React from 'react'
import Layout from '../components/Layout'
import { Image, Linking, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import isWeb from '../helpers/isWeb'
import getDeviceId from '../helpers/deviceId'
import { colours } from '../styles/constants'
import { heading } from '../styles/globalClasses'
import ProfileDetails from '../components/ProfileDetails'
import options from '../data/profileOptions'
import getSuperOptions from '../data/superOptions'
import Card from '../components/Card'
import storage from '../helpers/storage'
import api from '../helpers/boost-client-js-library/api'
import LoadingIndicator from '../components/LoadingIndicator'
import Notification from '../components/Notification'
import reSuperOptions from '../helpers/reSuperOptions'
import ConnectionManager from '../helpers/ConnectionManager'
import EntityFacade from '../helpers/EntityFacade'
import PropTypes from 'prop-types'
import BreakpointWatcher from '../helpers/BreakpointWatcher'
import checkRole from '../helpers/checkRole'
import handleSignOut from '../helpers/handleSignOut'

export default class Profile extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    appKey: PropTypes.object,
    history: PropTypes.object,
    appVersion: PropTypes.string,
    apiVersion: PropTypes.string
  }

  state = {
    page: this.props.match.path.includes('organisation') ? 'organisation' : 'profile',
    cardDetails: {},
    card: {},
    profileDetails: {},
    superOptionsState: [],
    appOptions: [
      { id: 1, title: 'About Boost', link: `${process.env.REACT_APP_BASE_URL}/about-boost` },
      { id: 2, title: 'Contact us', link: `${process.env.REACT_APP_BASE_URL}/enquiry-form` },
      { id: 3, title: 'FAQs', link: `${process.env.REACT_APP_BASE_URL}/about-boost/faq` },
      { id: 4, title: 'Delete Account…', action: () => { this.goToDelete() } },
      {
        id: 5,
        title: 'Terms of use',
        link: `${process.env.REACT_APP_BASE_URL}/about-boost/boost-user-terms-and-conditions/`
      },
      { id: 6, title: 'Privacy Policy', link: `${process.env.REACT_APP_BASE_URL}/privacy-policy/` },
      { id: 7, title: 'Sign out', action: () => { this.handleSignOut() } }
    ],
    social: [
      { id: 1, title: 'facebook', link: 'https://facebook.com/myboostnz/', icon: require('../images/facebook.png') },
      { id: 2, title: 'instagram', link: 'https://instagram.com/myboostnz/', icon: require('../images/instagram.png') },
      { id: 3, title: 'twitter', link: 'https://twitter.com/myboostnz', icon: require('../images/twitter.png') },
      {
        id: 4,
        title: 'linkedIn',
        link: 'https://linkedin.com/company/myboostnz',
        icon: require('../images/linkedin.png')
      }
    ],
    isLoading: true,
    error: null,
    exceedsMediumBreakpoint: false,
    exceedsLargeBreakpoint: false
  }

  mounted = false
  getData = () => {
    const { page } = this.state
    const { appKey } = this.props
    storage.token.get(appKey).then(token => {
      ConnectionManager.getManager().checkApiConnectivity().then((isOnline) => {
        const access = { token, device: getDeviceId() }
        const ef = new EntityFacade(isOnline, access)

        ef.getProfile(access).then(profile => {
          if (profile == null || !profile || !this.mounted) return false
          if (page === 'organisation') {
            api.organisation.get(access).then(organisation => {
              if (!this.mounted) return
              if (organisation && organisation.code && organisation.code !== 200) {
                this.setState({ error: organisation.message ? organisation.message : 'Something went wrong. Please try again.' })
              }
              if (organisation == null || !organisation) return

              this.setState(state => {
                state.profileDetails.accountName = organisation.accountName
                state.profileDetails.companyNo = organisation.companyNumber
                state.profileDetails.n3Account = organisation.n3Account
                state.profileDetails.addressShipping = organisation.addressShipping && organisation.addressShipping.replace(/\n/g, ', ')
                state.profileDetails.addressPostal = organisation.addressPostal && organisation.addressPostal.replace(/\n/g, ', ')
                state.profileDetails.webSite = organisation.webSite
                state.profileDetails.numStaff = organisation.numStaff
                state.superOptionsState = reSuperOptions(organisation.tier, getSuperOptions(checkRole(profile.roles).includes('su')))
                state.isLoading = false
                return state
              })
            })
          }
          this.setState(state => {
            state.cardDetails.firstName = profile.firstName
            state.cardDetails.lastName = profile.lastName
            state.cardDetails.companyLogo = profile.companyLogo
            if (page !== 'organisation') {
              state.profileDetails.firstName = profile.firstName
              state.profileDetails.lastName = profile.lastName
              state.profileDetails.email = profile.email
              state.profileDetails.phone = profile.phone
              state.profileDetails.title = profile.title
              state.profileDetails.dateOfBirth = profile.dateOfBirth
              state.profileDetails.postalCode = profile.postalCode
              state.profileDetails.genderCode = profile.genderCode
            }
            state.isLoading = false
            return state
          })
        })
        ef.getVehicle(access).then(item => {
          if (item == null || !item || !this.mounted) return false
          this.setState(state => {
            state.card = item
            return state
          })
        })
      })
    })
  }

  componentDidMount () {
    this.mounted = true
    BreakpointWatcher.addComponent(this)
    this.getData()
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
    this.mounted = false
  }

  handleSignOut = () => {
    const { history, appKey } = this.props
    handleSignOut(history, appKey)
  }

  renderAppLinks = () => {
    const links = [
      { id: 1, link: '/profile/details', icon: require('../images/avatar-black.png'), text: 'My details' },
      { id: 2, link: '/profile/preferences', icon: require('../images/settings.png'), text: 'Preferences' }
    ]
    const { history } = this.props
    const { appOptions } = this.state
    return (
      <View>
        <View style={{ marginBottom: 50 }}>
          {links.map(item => (
            <View key={item.id} style={styles.linkWrap}>
              <TouchableOpacity onPress={() => history.push(item.link)} style={styles.link}>
                <Image source={item.icon} style={styles.icon}/>
                <Text style={styles.linkText}>{item.text}</Text>
                <View style={styles.arrowWrap}>
                  <Image source={require('../images/arrow-right-black.png')} style={styles.arrow}/>
                </View>
              </TouchableOpacity>
            </View>
          ))}
        </View>

        {appOptions.map(item => (
          <View key={item.id} style={styles.linkWrap}>
            <TouchableOpacity onPress={() => item.link ? Linking.openURL(item.link) : item.action()}
              style={styles.appLink}>
              <Text style={styles.appLinkText}>{item.title}</Text>
            </TouchableOpacity>
          </View>
        ))}
      </View>
    )
  }

  goToDelete () {
    const { history } = this.props
    history.push('/delete-account')
  }

  hasUpdated = e => {
    if (isWeb && e) this.getData()
  }

  render () {
    const { appVersion, apiVersion } = this.props
    const {
      profileDetails,
      superOptionsState,
      social,
      cardDetails,
      card,
      page,
      isLoading,
      error,
      exceedsMediumBreakpoint,
      exceedsLargeBreakpoint
    } = this.state
    const isOrganisation = page === 'organisation'
    const title = isWeb ? isOrganisation ? 'Organisation Info' : 'My Profile' : 'Profile'

    return (
      <Layout
        title={title}
        optionsData={isOrganisation ? superOptionsState : options}
      >
        {error && <Notification>{error}</Notification>}
        {isLoading && !error ? (
          <LoadingIndicator/>
        ) : (
          !error && <View style={wrapperStyle(exceedsMediumBreakpoint)}>
            <View style={companyWrapperStyle(exceedsMediumBreakpoint)}>
              <Card cardDetails={cardDetails} card={card}/>

              {isWeb && card.boostID &&
              <Text style={styles.cardNumber}><Text style={styles.bold}>My ID Number </Text>{card.boostID}</Text>}
            </View>
            <View style={detailsViewStyle(exceedsMediumBreakpoint, exceedsLargeBreakpoint)}>
              {isWeb
                ? <React.Fragment>
                  <Text style={styles.heading}>{isOrganisation ? 'Organisation Info' : 'My Profile'}</Text>
                  <ProfileDetails type={page} profile={profileDetails} loading={isLoading}
                    hasUpdated={e => this.hasUpdated(e)}/>
                </React.Fragment>
                : this.renderAppLinks()
              }
            </View>
            {!isWeb &&
            <React.Fragment>
              <View style={styles.social}>
                <Text style={[styles.heading, styles.socialHeading]}>Boost social</Text>
                <View style={styles.socialLinks}>
                  {social.map((item, i) => {
                    return (
                      <TouchableOpacity key={i} style={styles.socialLink} onPress={() => Linking.openURL(item.link)}>
                        <Image source={item.icon} style={styles.socialIcon}/>
                        <Text style={styles.socialTitle}>{item.title}</Text>
                      </TouchableOpacity>
                    )
                  })}
                </View>
              </View>
              {appVersion && <Text style={styles.version}>App version: {appVersion}</Text>}
              {apiVersion && <Text style={styles.version}>API version: {apiVersion}</Text>}
            </React.Fragment>
            }
          </View>
        )}
      </Layout>
    )
  }
}

const wrapperStyle = isRowBased => (
  {
    margin: 0,
    marginBottom: 20,
    flexDirection: isRowBased ? 'row' : 'column'
  }
)

const companyWrapperStyle = (exceedsMediumBreakpoint) => {
  if (!isWeb) {
    return null
  }

  const style = {
    maxWidth: '200px',
    width: '100%',
    margin: 'auto'
  }

  if (exceedsMediumBreakpoint) {
    style.margin = 0
    style.flexBasis = '200px'
  }

  return style
}

const detailsViewStyle = (exceedsMediumBreakpoint, exceedsLargeBreakpoint) => {
  const style = {
    flex: 1
  }

  if (isWeb) {
    if (exceedsMediumBreakpoint) {
      style.marginVertical = 0
      style.marginHorizontal = '40px'
    }

    if (exceedsLargeBreakpoint) {
      style.marginHorizontal = '90px'
    }
  }

  return style
}

const styles = StyleSheet.create({
  // global
  heading: heading({
    marginBottom: 10
  }),

  // card and profile details
  company: {
    paddingLeft: 10,
    paddingRight: 30,
    paddingBottom: 20,
    marginBottom: 30,
    borderBottomWidth: 1,
    borderColor: colours.primary
  },
  companyWeb: {
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 20,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    overflow: 'hidden',
    maxWidth: 200
  },
  card: {
    width: '100%',
    height: 110
  },
  innerWrap: {
    padding: isWeb ? 20 : 0,
    flexDirection: isWeb ? 'column' : 'row',
    alignItems: isWeb ? 'flex-start' : 'center'
  },
  logo: {
    width: isWeb ? 47 : 90,
    height: isWeb ? 47 : 90,
    marginBottom: isWeb ? 15 : 0
  },
  table: {
    paddingLeft: 30
  },
  row: {
    flexDirection: 'row',
    marginBottom: 4
  },
  rowTitle: {
    flex: 1,
    flexBasis: '25%'
  },
  rowContent: {
    flex: 1,
    flexBasis: '75%'
  },
  text: {
    fontFamily: 'greycliff-medium',
    fontSize: isWeb ? 13 : 10,
    textTransform: isWeb ? 'uppercase' : 'none'
  },
  textExp: {
    color: colours.black40,
    marginTop: 2
  },
  bold: {
    fontFamily: 'greycliff-bold'
  },
  cardNumber: {
    fontSize: 12,
    fontFamily: 'greycliff',
    marginBottom: 20,
    textAlign: 'center'
  },

  // profile details
  linkWrap: {
    flex: 1,
    flexDirection: 'row'
  },
  link: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 15
  },
  linkText: {
    fontFamily: 'greycliff-bold',
    fontSize: 16,
    marginHorizontal: 8
  },
  icon: {
    width: 18,
    height: 18
  },
  arrowWrap: {
    height: 18,
    justifyContent: 'center'
  },
  arrow: {
    width: 9,
    height: 9
  },

  // app links
  appLink: {
    marginBottom: 8
  },
  appLinkText: {
    color: colours.black60,
    fontFamily: 'greycliff-bold'
  },

  // social (app only)
  social: {
    paddingTop: 20,
    marginTop: 30,
    marginBottom: 40,
    borderTopWidth: 1,
    borderColor: colours.primary
  },
  socialHeading: {
    color: colours.primary,
    fontSize: 20,
    marginBottom: 20
  },

  socialLinks: {
    flexDirection: 'row'
  },
  socialLink: {
    alignItems: 'center',
    marginRight: 25
  },
  socialIcon: {
    width: 25,
    height: 25,
    marginBottom: 8
  },
  socialTitle: {
    fontFamily: 'greycliff-medium',
    textTransform: 'capitalize',
    fontSize: 12,
    color: colours.black60
  },

  version: {
    fontFamily: 'greycliff',
    fontSize: 12,
    marginBottom: 5,
    color: colours.black60
  }
})
