import React from 'react'
import Layout from '../../components/Layout'
import { Link } from 'react-router-dom'
import { View } from 'react-native'
import isWeb from '../../helpers/isWeb'
import { colours, breakpoints } from '../../styles/constants'
import styled from 'styled-components'
import { H1, DataTable, superSection, Checkboxes } from '../../styles/globalClasses'
import { Primary } from '../../components/Inputs/Buttons'
import getSuperOptions from '../../data/superOptions'
import TableSearch from '../../components/TableSearch'
import storage from '../../helpers/storage'
import api from '../../helpers/boost-client-js-library/api'
import NumberedPagination from '../../components/NumberedPagination'
import Pagination from '../../components/Pagination'
import Notification from '../../components/Notification'
import LoadingIndicator from '../../components/LoadingIndicator'
import checkRole from '../../helpers/checkRole'
import reSuperOptions from '../../helpers/reSuperOptions'
import { TEMP_MAX_ITEMS } from '../../helpers/offline'
import PropTypes from 'prop-types'
import { Text } from 'react-native-web'
import BreakpointWatcher from '../../helpers/BreakpointWatcher'

const TableWrapper = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    ${superSection}
    @media(max-width: ${breakpoints.medium - 1}px){
        .search {
            display: none;
        }
    }
`

const OfferSearch = isWeb && styled(TableSearch)`
    position: relative;
    max-width: 335px;
`

const Table = {
  Pagination: ({ style = {}, exceedsMediumBreakpoint, children, ...props }) => {
    if (exceedsMediumBreakpoint) {
      style.display = 'none'
    }
    return <Pagination style={style} {...props}>{children}</Pagination>
  },
  NumberedPagination: ({ style = {}, exceedsMediumBreakpoint, children, ...props }) => {
    const _style = {
      marginVertical: 15,
      marginHorizontal: 0,
      ...(!exceedsMediumBreakpoint ? {
        display: 'none'
      } : {}),
      ...style
    }
    return <NumberedPagination style={_style} {...props}>{children}</NumberedPagination>
  }
}

const Heading = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    > * {
        margin-bottom: 20px;
    }
    @media(min-width: ${breakpoints.large}px){
        flex-direction: row;
        justify-content: space-between;
        > * {
            margin-bottom: 20px;
        }
    }
    ${Checkboxes}
    .custom-check {
        font-size: 11px;
        font-family: greycliff;
        padding-left: 30px;
        width: 285px;
        height: auto;
        margin: 0;
        max-width: 100%;
        .title {
            font-size: 12px;
            font-family: greycliff-bold;
            text-decoration: underline;
            display: block;
        }
    }
`

const OfferTable = isWeb && styled(DataTable)`
    &.suppliers {
        font-family: greycliff-bold;
        .rt-thead .rt-th:first-child {
            display: none; // expander column, hide this as cell is positioned
        }
        .rt-tbody .rt-tr {
            background: rgba(240,240,240,.6);
        }
        @media(max-width: ${breakpoints.medium - 1}px){
            height: auto!important;
        }
    }
    .rt-thead .rt-th {
        display: flex;
        align-items: center;
        white-space: pre-wrap;
    }

    /*.rt-tbody {
        padding-bottom: 1px;
        margin-bottom: -1px;
    }*/

    .rt-tbody .rt-expandable { // expander cell
        position: relative;
        left: 320px;
        border: 0!important;
        margin-left: -35px;
        .rt-expander {
            position: relative;
            font-size: 0;
            margin: 0;
            padding: 0;
            left: 4px;
        }
    }

    .edit-buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
        text-decoration: none;
        > * {
            color: black;
            cursor: pointer;
            appearance: none;
            border: 0;
            background: 0;
            padding: 0;
            font-size: 11px;
            font-family: greycliff-medium;
        }
        &.edit-disabled {
            pointer-events: none;
            > * {
                color: #f2f2f2;
            }
        }
    }
    .edit-button {
        position: relative;
        margin-right: 5px;
    }
    ${props => props.data && props.data.length === 0 && `
        .rt-noData {
            margin-top: 12px;
        }
    `}

    // sub table
    .rt-tbody {
        .ReactTable {
            font-family: greycliff-medium;
            border-top: 1px solid ${colours.light};
            .rt-td:first-child {
                background: white!important;
            }
        }
        .rt-noData {
            display: none;
        }
    }
`

const Loading = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    border: 1px solid ${colours.light};
    padding: 20px;
    ${props => props.large && `
        border-top: 0;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
        background: white;
    `}
`

const Buttons = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    > * {
        margin: 0;
    }
    @media(min-width: ${breakpoints.medium}px){
        align-items: flex-end;
    }
`

const TableStyles = {
  A: {
    color: 'black'
  },
  StatusText: {
    fontFamily: 'greycliff-bold',
    textAlign: 'center',
    width: '100%',
    display: 'block',
    fontSize: 12
  },
  DisabledText: {
    color: colours.urgent
  },
  ActiveText: {
    color: colours.green
  },
  ApprovedText: {
    color: colours.yellow
  }
}

const linkIconStyle = {
  width: 8,
  height: 8,
  display: 'inline-block',
  position: 'relative',
  top: 2,
  marginLeft: 4
}

const OFFER_STATUS_WIDTH = 100

const makeStatusTextCell = (restricted, statusId) => {
  if (restricted) {
    return <Text style={[TableStyles.StatusText, TableStyles.DisabledText]}>Disabled</Text>
  }

  if (statusId === '969760003') {
    return <Text style={[TableStyles.StatusText, TableStyles.ActiveText]}>Active</Text>
  }

  if (statusId === '969760001') {
    return <Text style={[TableStyles.StatusText, TableStyles.ApprovedText]}>Coming Soon</Text>
  }

  return null
}

const LoadingComponent = props => {
  return (
    // eslint-disable-next-line react/prop-types
    props.loading && <Loading large><LoadingIndicator/></Loading>
  )
}

class SubOfferTable extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    enabled: PropTypes.bool,
    renderArrayItem: PropTypes.func
  }

  state = {
    data: [],
    loading: true,
    error: false
  }

  componentDidMount () {
    const { id } = this.props
    storage.token.get().then(token => {
      api.suppliers.singleOffers(token, id, `&internal_only=true&skip_restrictions=true&include_approved=true&offset=0&limit=${TEMP_MAX_ITEMS}`).then(offers => {
        if (!offers.items) this.setState({ error: true })
        const data = offers.totalCount > 0 ? offers.items : []
        this.setState({ data, loading: false, noOffers: offers.totalCount })
      })
    })
  }

  componentDidUpdate (prevProps) {
    const { enabled } = this.props
    const { data } = this.state
    if (enabled !== prevProps.enabled) { // if disabling a supplier, disable all offers from it
      this.setState({
        data: data.map(i => {
          i.restricted = enabled
          return i
        })
      })
    }
  }

  handleSubCheck (value, cell) {
    const { id } = this.props
    const revert = (offers, type) => {
      if (offers.code !== 200) { // if API failed, revert checkbox
        this.setState(state => {
          state.data[cell.index].restricted = type
          return state
        })
      }
    }
    this.setState(state => {
      state.data[cell.index].restricted = value
      return state
    }, () => {
      const enabled = this.state.data[cell.index].restricted
      storage.token.get().then(token => {
        if (enabled) {
          api.suppliers.enableOffer(token, id, cell.original.ID).then(offers => revert(offers, false))
        } else {
          api.suppliers.disableOffer(token, id, cell.original.ID).then(offers => revert(offers, true))
        }
      })
    })
  }

  renderSubCheck = (cell, type) => {
    const id = cell.original.ID
    const checked = !cell.original.restricted
    return (
      <div>
        <input
          type="checkbox"
          id={`${id}-${type}`}
          className="checkbox"
          checked={checked}
          onChange={() => this.handleSubCheck(checked, cell)}
        />
        <label htmlFor={`${id}-${type}`} className="custom-check">Enable/disable</label>
      </div>
    )
  }

  render () {
    const { enabled, renderArrayItem } = this.props
    const { data, loading, error, noOffers } = this.state

    const columns = [{
      Header: '',
      width: 54,
      Cell: <span>&nbsp;</span>
    }, {
      Header: '',
      width: 54,
      sortable: false,
      Cell: cell => this.renderSubCheck(cell, 'enabledOffers')
    },
    {
      Header: '',
      width: 216,
      accessor: 'title',
      Cell: cell => {
        return <a target="_blank" rel="noreferrer" href={`/offers/${cell.original.ID}`} style={TableStyles.A}>
          {cell.original.title}
          <img alt="" src={require('../../images/external-link-icon-black.png')} style={linkIconStyle}/>
        </a>
      }
    },
    {
      Header: '',
      Cell: cell => makeStatusTextCell(cell.original.restricted, cell.original.statusID),
      width: OFFER_STATUS_WIDTH
    },
    {
      Header: '',
      accessor: 'categories',
      Cell: cell => renderArrayItem(cell.original.categories)
    },
    {
      Header: '',
      accessor: 'subtitle'
    }]
    if (loading) {
      return <Loading><LoadingIndicator/></Loading>
    }
    if (error) {
      return <Loading><Notification type="error">Error loading offers</Notification></Loading>
    }
    if (noOffers === 0) {
      return <Loading><Notification type="success">No offers found.</Notification></Loading>
    }
    return (
      <OfferTable
        columns={columns}
        data={data}
        showPagination={false}
        minRows={0}
        resizable={false}
        getTrProps={(state, rowInfo) => {
          return {
            style: {
              background: !enabled && !rowInfo.original.restricted && 'white'
            }
          }
        }}
      />
    )
  }
}

export default class Super extends React.Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    tier: '',
    profile: {},
    notifications: false,
    options: [],

    // suppliers
    search: '',
    sort: [{
      id: 'name',
      desc: false
    }],
    data: [],
    itemsPerPage: 1000,
    currentPage: 1,
    loading: true,
    collapseOffers: true,

    // custom suppliers table
    customSearch: '',
    customSuppliers: [],
    customItemsPerPage: 1000,
    customCurrentPage: 1,
    customLoading: true,
    customNotification: false,
    customDisabled: false,
    errorProfile: null,
    exceedsMediumBreakpoint: false
  }

  controller = new AbortController()

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
    const { history } = this.props
    const { itemsPerPage, customItemsPerPage } = this.state
    const signal = this.controller.signal
    storage.token.get().then(token => {
      api.profile.get(token).then(profile => {
        if (!checkRole(profile.roles).includes('bp')) {
          history.push('/profile')
          return false
        }

        api.organisation.get(token).then(organisation => {
          if (organisation.code && organisation.code !== 200) this.setState({ errorProfile: organisation.message ? organisation.message : 'Something went wrong. Please try again.' })
          if (!organisation.tier || (organisation.tier && Number(organisation.tier) < 2)) {
            history.push('/profile')
            return false
          }
          this.setState({
            tier: Number(organisation.tier),
            options: reSuperOptions(organisation.tier, getSuperOptions(checkRole(profile.roles).includes('su'))),
            organisation
          })
        })
      })

      const [sortColumn, sortDirection] = this.extractSortParams(this.state.sort)

      this.handleInternalSupplierFetch(0, itemsPerPage, null, sortColumn, sortDirection)

      api.offers.get(token, signal, `offset=0&limit=${customItemsPerPage}&external=true`).then(suppliers => {
        this.setState({
          customSuppliers: suppliers.items,
          customCount: suppliers.totalCount,
          customLoading: false
        })
      })
      api.suppliers.getOfferNotifications(token).then(res => {
        if (res) this.setState({ notifications: res.preference ? res.preference : false })
      })
    })
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
    this.controller.abort()
  }

  handleToggleCheck (value, cell) {
    const isParent = cell.original.logo
    const revert = (offers, type) => {
      if (offers.code !== 200) { // if API failed, revert checkbox
        this.setState(state => {
          state.data[cell.index].restricted = type
          state.collapseOffers = true
          return state
        })
      }
    }
    if (isParent) {
      this.setState(state => {
        state.data[cell.index].restricted = value
        state.collapseOffers = false
        return state
      }, () => {
        const enabled = this.state.data[cell.index].restricted
        storage.token.get().then(token => {
          if (enabled) {
            api.suppliers.enableOffers(token, cell.original.ID).then(offers => revert(offers, false))
          } else {
            api.suppliers.disableOffers(token, cell.original.ID).then(offers => revert(offers, true))
          }
        })
      })
    }
  }

  handleRemoveOffer = (item, cell) => {
    const { customSuppliers } = this.state
    this.setState({ customNotification: 'Removing offer...', customDisabled: true })
    storage.token.get().then(token => {
      api.suppliers.removeOffer(token, cell.supplierID, cell.ID).then(res => {
        const success = res.code === 200 || res.code === 202
        const defaultMessage = success ? 'Offer removed!' : 'Could not remove offer :('
        this.setState(state => {
          state.customNotification = res.message ? res.message : defaultMessage
          state.customNotificationType = success ? 'success' : 'error'
          state.customDisabled = false

          if (success) {
            state.customSuppliers = customSuppliers.filter((_, i) => i !== item)
          }
          return state
        }, () => {
          setTimeout(() => {
            this.setState({ customNotification: false })
          }, 2000)
        })
      })
    })
  }

  handleSearch = e => {
    e.preventDefault()
    const { search, itemsPerPage } = this.state
    const signal = this.controller.signal
    this.setState({ loading: true })
    storage.token.get().then(token => {
      const query = search !== '' ? `&search_query=${search}` : ''
      api.suppliers.getAll(token, signal, `&external=false&offset=0&limit=${itemsPerPage}${query}`).then(suppliers => {
        if (suppliers === undefined) return false
        const data = suppliers.totalCount === 0 ? [] : suppliers.items
        this.setState({
          data,
          dataCount: suppliers.totalCount,
          loading: false
        })
      })
    })
  }

  extractSortParams (sort) {
    let sortColumn = null
    let sortDirection = null

    if (sort && sort[0]) {
      sortColumn = sort[0].id
      sortDirection = sort[0].desc ? 'desc' : 'asc'
    }

    return [sortColumn, sortDirection]
  }

  handleReset = () => {
    const { itemsPerPage, sort } = this.state
    this.setState({ search: '', loading: true })
    const [sortColumn, sortDirection] = this.extractSortParams(sort)
    this.handleInternalSupplierFetch(0, itemsPerPage, '', sortColumn, sortDirection)
  }

  handlePagination = newPageNumber => {
    const { currentPage, dataCount, itemsPerPage, search, sort } = this.state
    const totalPages = Math.ceil(Number(dataCount) / itemsPerPage)
    if (currentPage <= totalPages) {
      this.setState({
        currentPage: isNaN(newPageNumber) ? (newPageNumber === 'next' ? this.state.currentPage + 1 : this.state.currentPage - 1) : newPageNumber,
        loading: true
      }, () => {
        const [sortColumn, sortDirection] = this.extractSortParams(sort)

        this.handleInternalSupplierFetch(this.state.currentPage - 1, itemsPerPage, search, sortColumn, sortDirection)
      })
    }
  }

  handleInternalSupplierFetch (pageIndex, itemsPerPage, search, sortColumn, sortDirection) {
    const offset = pageIndex * itemsPerPage
    const query = (search !== '' && search != null) ? `&search_query=${search}` : ''
    const sort = (sortColumn !== '' && sortColumn != null) ? `&sort=${sortColumn}:${sortDirection}` : ''
    const signal = this.controller.signal

    this.setState({ loading: true })

    storage.token.get().then(token => {
      api.suppliers.getAll(token, signal, `&has_offers=true&include_approved=true&external=false&offset=${offset}&limit=${itemsPerPage}${query}${sort}`).then(suppliers => {
        if (suppliers === undefined) return false
        const data = suppliers.totalCount === 0 ? [] : suppliers.items
        this.setState({
          currentPage: pageIndex + 1,
          data,
          dataCount: suppliers.totalCount,
          loading: false
        })
      })
    })
  }

  handleSort = s => {
    const { itemsPerPage, search } = this.state
    const sortColumn = s[0].id
    const sortDirection = s[0].desc ? 'desc' : 'asc'
    this.handleInternalSupplierFetch(0, itemsPerPage, search, sortColumn, sortDirection)
  }

  handleCustomSearch = e => {
    e.preventDefault()
    const { customSearch, customItemsPerPage } = this.state
    const signal = this.controller.signal
    this.setState({ customLoading: true })
    storage.token.get().then(token => {
      const query = customSearch !== '' ? `&search_query=${customSearch}` : ''
      api.offers.get(token, signal, `has_offers=true&=offset=0&limit=${customItemsPerPage}&external=true${query}`).then(suppliers => {
        const customSuppliers = suppliers.totalCount === 0 ? [] : suppliers.items
        this.setState({
          customSuppliers,
          customCount: suppliers.totalCount,
          customLoading: false
        })
      })
    })
  }

  handleCustomReset = () => {
    const { customItemsPerPage } = this.state
    const signal = this.controller.signal
    this.setState({ customSearch: '', customLoading: true })
    storage.token.get().then(token => {
      api.offers.get(token, signal, `offset=0&limit=${customItemsPerPage}&external=true`).then(suppliers => {
        const customSuppliers = suppliers.totalCount === 0 ? [] : suppliers.items
        this.setState({
          customSuppliers,
          customCount: suppliers.totalCount,
          customLoading: false
        })
      })
    })
  }

  handleCustomPagination = toPage => {
    const { customCurrentPage, customCount, customItemsPerPage, customSearch } = this.state
    const totalPages = Math.ceil(Number(customCount) / customItemsPerPage)
    const signal = this.controller.signal
    const query = customSearch !== '' ? `&search_query=${customSearch}` : ''
    if (customCurrentPage <= totalPages) {
      let newCustomCurrentPage
      if (isNaN(toPage)) {
        // assume toPage is either 'next' or 'prev' if not specified
        newCustomCurrentPage = (toPage === 'next') ? (this.state.customCurrentPage + 1) : (this.state.customCurrentPage - 1)
      } else {
        newCustomCurrentPage = toPage
      }

      this.setState({
        customCurrentPage: newCustomCurrentPage,
        customLoading: true
      }, () => {
        // subtract 1 of page to get to zero offset
        const chosenPage = newCustomCurrentPage - 1
        storage.token.get().then(token => {
          api.offers.get(token, signal, `has_offers=true&offset=${chosenPage * customItemsPerPage}&limit=${customItemsPerPage}&external=true${query}`).then(suppliers => {
            const customSuppliers = suppliers.totalCount === 0 ? [] : suppliers.items
            this.setState({
              customSuppliers,
              customLoading: false
            })
          })
        })
      })
    }
  }

  renderItem = cellInfo => {
    const { data } = this.state
    const item = data[cellInfo.index][cellInfo.column.id]
    if (item === '' || item === false) return '-'
    if (item === true) return 'Yes'

    return item
  }

  renderCheck = (cell, type) => {
    const id = cell.original.ID
    const checked = !cell.original.restricted
    return (
      <div>
        <input
          type="checkbox"
          id={`${id}-${type}`}
          className="checkbox"
          checked={checked}
          onChange={() => this.handleToggleCheck(checked, cell)}
        />
        <label htmlFor={`${id}-${type}`} className="custom-check">Enable/disable</label>
      </div>
    )
  }

  renderOfferItem = cellInfo => {
    const { customSuppliers } = this.state

    if (cellInfo.index >= customSuppliers.length) {
      return null
    }

    return <div className="field">{customSuppliers[cellInfo.index][cellInfo.column.id]}</div>
  }

  renderArrayItem = item => item ? item.toString().replace(/,/g, ', ') : ''

  handleOfferNotifications = () => {
    const { notifications } = this.state
    storage.token.get().then(token => {
      api.suppliers.setOfferNotifications(token, !notifications).then(() => this.setState({ notifications: !notifications }))
    })
  }

  render () {
    const { history } = this.props
    const {
      tier, profile, options,
      search, data, currentPage, dataCount, itemsPerPage, loading,
      customSearch, customSuppliers, customCurrentPage, customCount, customItemsPerPage, customLoading, customNotification, customNotificationType, customDisabled,
      notifications, errorProfile, collapseOffers, exceedsMediumBreakpoint
    } = this.state
    const columns = [
      {
        Header: <span title="Check the box to enable the offer to your team on web & App">Enable</span>,
        width: 54,
        sortable: false,
        Cell: cell => this.renderCheck(cell, 'enabledSuppliers')
      },
      {
        Header: 'Supplier name',
        Cell: cell => cell.original.tradingName ? cell.original.tradingName : cell.original.name,
        id: 'name',
        sortable: false,
        width: 270
      },
      {
        Header: 'Offer Status',
        Cell: cell => makeStatusTextCell(cell.original.restricted, ''),
        width: OFFER_STATUS_WIDTH
      },
      {
        Header: 'Category',
        accessor: 'categories',
        sortable: false,
        Cell: cell => this.renderArrayItem(cell.original.categories)
      },
      {
        Header: 'Offer',
        sortable: false,
        accessor: 'description'
      }
    ]
    const offerColumns = [
      {
        Header: 'Supplier name',
        accessor: 'externalSupplierName'
      },
      {
        Header: 'Offer Status',
        Cell: cell => makeStatusTextCell(cell.original.restricted, cell.original.statusID),
        width: OFFER_STATUS_WIDTH
      },
      {
        Header: 'Category',
        accessor: 'categories',
        Cell: cell => this.renderArrayItem(cell.original.categories)
      },
      {
        Header: 'Offer',
        accessor: 'title',
        Cell: this.renderOfferItem
      },
      {
        Header: <div className="action-title">Actions</div>,
        sortable: false,
        width: 100,
        Cell: cell => {
          const id = cell.index
          const item = cell.original
          return (<div className={`edit-buttons ${customDisabled ? 'edit-disabled' : ''}`}>
            <Link to={{ pathname: '/profile/offer-management/add-offers', data: item }}
              className="edit-buttons">Edit</Link>
            <button className="remove-button" onClick={() => this.handleRemoveOffer(id, item)}>Remove</button>
          </div>)
        }
      }
    ]
    const customNumberOfPages = Math.ceil(Number(customCount) / customItemsPerPage)
    return (
      <Layout
        title="Offer Management"
        optionsData={options}
        isSuperLayout={true}
      >
        {errorProfile && <Notification>{errorProfile}</Notification>}
        {tier.length === 0 && !errorProfile ? (
          <LoadingIndicator/>
        ) : (
          !errorProfile && <View>
            <TableWrapper>
              <Heading>
                <H1>Boost Suppliers</H1>
                <div>
                  <input
                    type="checkbox"
                    id="notifications"
                    className="checkbox"
                    checked={notifications}
                    onChange={this.handleOfferNotifications}
                  />
                  <label htmlFor="notifications" className="custom-check">
                    <span className="title">New Supplier Offer Notifications</span>
                    Receive email notifications about all new offers so I can disable them
                  </label>
                </div>
                <OfferSearch
                  id="search"
                  value={search}
                  onChange={e => this.setState({ search: e.target.value })}
                  className="search"
                  onSubmit={e => this.handleSearch(e)}
                  onReset={() => this.handleReset()}
                  placeholder={'Search Suppliers'}
                />
              </Heading>
              <OfferTable
                columns={columns}
                data={data}
                showPagination={false}
                minRows={0}
                resizable={false}
                className="suppliers"
                collapseOnDataChange={collapseOffers}
                loading={loading}
                LoadingComponent={LoadingComponent}
                onSortedChange={sort => this.handleSort(sort)}
                pageSize={data.length}
                getTrProps={(state, rowInfo) => {
                  return {
                    style: {
                      background: !rowInfo.original.restricted && 'white'
                    }
                  }
                }}
                SubComponent={row => {
                  return <SubOfferTable
                    id={row.original.ID}
                    renderCheck={this.renderCheck}
                    enabled={row.original.restricted}
                    renderArrayItem={this.renderArrayItem}
                  />
                }}
              />
              {dataCount > itemsPerPage &&
              <React.Fragment>
                <Table.NumberedPagination exceedsMediumBreakpoint={exceedsMediumBreakpoint} numberOfPages={Math.ceil(Number(dataCount) / itemsPerPage)}
                  active={currentPage} onPress={e => this.handlePagination(e)}/>
                <Table.Pagination
                  current={currentPage}
                  exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                  total={Math.ceil(Number(dataCount) / itemsPerPage)}
                  handleNav={type => this.handlePagination(type)}
                />
              </React.Fragment>
              }
            </TableWrapper>
            {tier >= 3 &&
            <TableWrapper>
              <Heading>
                {profile.tradingAs && <H1>{profile.tradingAs} offers</H1>}
                <OfferSearch
                  id="customSearch"
                  value={customSearch}
                  onChange={e => this.setState({ customSearch: e.target.value })}
                  onSubmit={e => this.handleCustomSearch(e)}
                  onReset={() => this.handleCustomReset()}
                />
              </Heading>
              {customNotification && <Notification
                type={customNotificationType || 'success'}>{customNotification}</Notification>}
              <OfferTable
                columns={offerColumns}
                data={customSuppliers}
                showPagination={false}
                minRows={0}
                resizable={false}
                loading={customLoading}
                LoadingComponent={LoadingComponent}
              />
              {customCount > customItemsPerPage &&
              <React.Fragment>
                <Table.NumberedPagination numberOfPages={customNumberOfPages}
                  active={customCurrentPage} onPress={e => this.handleCustomPagination(e)}/>
                <Table.Pagination
                  current={customCurrentPage}
                  total={customNumberOfPages}
                  handleNav={type => this.handleCustomPagination(type)}
                  style={ { paddingTop: 0, borderTopWidth: 0, marginTop: -10 } }
                />
              </React.Fragment>
              }
              <Buttons style={ { paddingTop: (customNumberOfPages > 1) ? 60 : 0 } } >
                <Primary onPress={() => history.push('/profile/offer-management/add-offers')}>+ Add Custom
                  Offers</Primary>
              </Buttons>
            </TableWrapper>
            }
          </View>
        )}
      </Layout>
    )
  }
}
