import React from 'react'
import { View } from 'react-native'
import Layout from '../components/Layout'
import isWeb from '../helpers/isWeb'
import options from '../data/profileOptions'
import { H2 } from '../styles/globalClasses'
import { TextField } from '../components/Inputs/Fields'
import { Primary } from '../components/Inputs/Buttons'
import Notification from '../components/Notification'
import storage from '../helpers/storage'
import api from '../helpers/boost-client-js-library/api'

const C = {
  Wrapper: View,
  H2: H2,
  TextField: TextField,
  ButtonWrap: isWeb && View
}

export default class ChangePassword extends React.Component {
  state = {
    title: 'Change Password',
    success: null,
    error: null,
    fields: [
      { name: 'password', label: 'Current Password', textContentType: 'password' },
      { name: 'newPassword', label: 'New Password', textContentType: 'newPassword' },
      { name: 'newPasswordConfirm', label: 'New Password (confirm)', textContentType: 'newPassword' }
    ]
  }

  handleSubmit = () => {
    const { password, newPassword, newPasswordConfirm } = this.state
    const checkField = item => item === undefined || item === ''
    if (checkField(password) || checkField(newPassword) || checkField(newPasswordConfirm)) {
      this.setState({ error: 'Please fill out all the fields' })
      return false
    }
    if (newPassword !== newPasswordConfirm) {
      this.setState({ error: 'Please make sure your confirmation password matches the new password' })
      return false
    }
    storage.token.get().then(token => {
      api.profile.changePassword(token, { password: newPasswordConfirm }).then(res => {
        if (res.code === 200) {
          this.setState({ success: res.message + ' Redirecting you to login page...', error: false })
          setTimeout(() => {
            if (isWeb) window.location = `${process.env.REACT_APP_BASE_URL}/sign-in`
          }, 4000)
        } else {
          this.setState({ error: res.message, success: false })
        }
      })
    })
  }

  render () {
    const { title, fields, success, error } = this.state
    return (
      <Layout
        title={title}
        optionsData={options}
      >
        <View style={styles.wrapper}>
          <C.H2 style={cStyles.H2}>{title}</C.H2>
          <C.Wrapper style={cStyles.Wrapper}>
            {fields.map((item, i) => (
              <C.TextField
                key={i}
                id={i}
                label={item.label}
                onChange={e => this.setState({ [item.name]: e, error: null, success: null })}
                autoComplete="password"
                secureTextEntry={true}
                textContentType={item.textContentType}
                baseStyle={styles.fieldWrap}
                labelStyle={styles.label}
                style={styles.field}
              />
            ))}
            {error && <Notification type="error">{error}</Notification>}
            {success && <Notification type="success">{success}</Notification>}
            <C.ButtonWrap style={cStyles.ButtonWrap}>
              <Primary onPress={() => this.handleSubmit()}>Update Password</Primary>
            </C.ButtonWrap>
          </C.Wrapper>
        </View>
      </Layout>
    )
  }
}

const styles = {
  fieldWrap: {
    marginBottom: 25
  },
  label: {
    fontFamily: 'greycliff',
    fontSize: isWeb ? 14 : 10,
    marginBottom: 8
  },
  field: {
    padding: 8,
    borderWidth: 1,
    borderColor: 'rgba(179,179,179,.3)',
    borderRadius: 4
  }
}

const cStyles = {
  Wrapper: {
    width: '100%',
    maxWidth: 360
  },
  H2: {
    marginBottom: 30
  },
  ButtonWrap: {
    marginVertical: 20,
    marginHorizontal: 0,
    flex: '1 1 auto',
    flexDirection: 'row'
  }
}
