/* eslint-disable react/prop-types */
// Pagination for news and articles
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { colours } from '../styles/constants'
import isWeb from '../helpers/isWeb'
import BreakpointWatcher from '../helpers/BreakpointWatcher'
import lineHeight from '../helpers/lineHeight'

class Component extends React.Component {
  state = {
    exceedsLargeBreakpoint: false
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  render () {
    const { exceedsLargeBreakpoint } = this.state
    const { current, handleNav, total, style = {}, ...rest } = this.props
    return (
      <Pagination exceedsLargeBreakpoint={exceedsLargeBreakpoint} style={style} {...rest}>
        <Arrow active={current === 1} onPress={() => handleNav && handleNav('prev')}>‹ Previous Page</Arrow>
        <Text style={styles.heading}>Page {current} of {total}</Text>
        <Arrow active={current === total } onPress={() => handleNav && handleNav('next')}>Next Page ›</Arrow>
      </Pagination>
    )
  }
}

export default Component

const styles = StyleSheet.create({
  heading: {
    fontSize: 12,
    lineHeight: lineHeight(20),
    fontFamily: 'greycliff'
  }
})

const Pagination = props => {
  const { exceedsLargeBreakpoint, children, style = {} } = props
  const paginationStyles = {
    borderTopWidth: 1,
    borderTopColor: colours.light50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: exceedsLargeBreakpoint ? 50 : 25,
    marginBottom: exceedsLargeBreakpoint ? -50 : 25,
    ...style
  }
  return (
    <View style={paginationStyles}>{children}</View>
  )
}

const Arrow = props => {
  const { active, children, ...rest } = props
  const arrowStyles = {
    fontSize: 12,
    lineHeight: lineHeight(20),
    fontFamily: 'greycliff-extrabold',
    ...(isWeb ? { cursor: 'pointer' } : {}),
    ...(active ? { opacity: 0 } : {})
  }
  return (
    <Text style={arrowStyles} {...rest}>{children}</Text>
  )
}
