import React from 'react'
import { Image, View } from 'react-native-web'
import { colours } from '../styles/constants'

const styles = {
  logo: {
    width: 140,
    height: 70
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center'
  },
  logoContainer: {
    justifyContent: 'center',
    height: 140
  },
  textContainer: {
    paddingLeft: 45,
    justifyContent: 'center'
  },
  textContent: {
  },
  name: {
    display: 'flex',
    fontSize: 24,
    fontFamily: 'greycliff-bold',
    marginBottom: 0
  },
  categories: {
    display: 'flex',
    fontSize: 18,
    color: colours.black60,
    fontFamily: 'greycliff',
    marginTop: 0
  }
}

export default class RetailerOffersHeader extends React.Component {
  render () {
    const { retailer } = this.props

    return <div style={styles.container}>
      <View style={styles.logoContainer}>
        <Image source={{ uri: retailer.logo }} style={styles.logo} resizeMode="contain" alt={`${retailer.tradingName} Logo`}/>
      </View>
      <View style={styles.textContainer}>
        <p style={{ ...styles.textContent, ...styles.name }}>{ retailer.tradingName }</p>
        <p style={{ ...styles.textContent, ...styles.categories }}>{ retailer.categories.join(', ') }</p>
      </View>
    </div>
  }
}
