import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colours } from '../styles/constants'
import { CheckField, TextField } from './Inputs/Fields'
import { Primary } from './Inputs/Buttons'
import isWeb from '../helpers/isWeb'
import getDeviceId from '../helpers/deviceId'
import DatePicker from 'react-datepicker'
import storage from '../helpers/storage'
import api from '../helpers/boost-client-js-library/api'
import Notification from '../components/Notification'
import LoadingIndicator from '../components/LoadingIndicator'
import Autosuggest from 'react-autosuggest'
import EntityFacade from '../helpers/EntityFacade'
import consoleLog from '../helpers/consoleLog'
import isDarkMode from '../helpers/darkMode'
import PropTypes from 'prop-types'
import BreakpointWatcher from '../helpers/BreakpointWatcher'

const Checks = View

const getChecksLinkStyle = (exceedsLargeBreakpoint) => {
  if (!isWeb || exceedsLargeBreakpoint) {
    return {}
  }

  return {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '100%',
    marginBottom: 10
  }
}

const labelSharedStyle = {
  fontFamily: 'greycliff-bold',
  flexBasis: '30%',
  fontSize: 14,
  paddingTop: 10,
  paddingRight: 20,
  paddingBottom: 10,
  paddingLeft: 0
}

const nDateStyles = {
  Label: { ...labelSharedStyle, flexBasis: '40%' },
  Field: { flex: 1, flexBasis: '60%' }
}

const NDate = {
  Label: Text,
  Field: View
}

const dateFieldStyle = {
  flexDirection: 'row',
  borderColor: colours.light50,
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 0,
  paddingRight: 0,
  display: 'flex'
}

const DateField = (props) => {
  const { className, children, ...rest } = props
  if (isWeb) {
    return <div style={dateFieldStyle} className={className} {...rest}>{children}</div>
  }

  return <View style={dateFieldStyle} {...rest}>{children}</View>
}
const dateFieldClassName = 'profile-details-datepicker'

const getDateFieldWebCss = (isEditable) => {
  return `
    .${dateFieldClassName} .react-datepicker-wrapper {
        flex: 1;
        flex-basis: 70%;
    }
      
    .${dateFieldClassName} .react-datepicker-wrapper input {
      -webkit-appearance: none;
      font-size: 14px;
      font-family: greycliff;
      padding: 10px 5px;
      border: 1px solid transparent;
      border-radius: 4px;
      width: 100%;
      background: white;
      opacity: 1; 
      ${isEditable && 'border-color: rgba(179,179,179,.3);'}
      }
      
    .${dateFieldClassName} .react-datepicker__input-container {
        display: block;
    }
  `
}

const autoCompleteWrapClassName = 'profile-details-autocomplete-wrap'

const autoCompleteWrapCss = `
  .${autoCompleteWrapClassName} {
    flex-direction: row;
    border-bottom: 1px solid rgb(191, 237, 255);
    padding: 10px 0; 
    display: flex;
  }
  
  .${autoCompleteWrapClassName}.active, .${autoCompleteWrapClassName}:hover, .${autoCompleteWrapClassName}:focus {
    position: relative;
    z-index: 100
  }
  
  .${autoCompleteWrapClassName} .react-autosuggest__container {
    position: relative;
    flex-basis: 70%;
  }
  
  .${autoCompleteWrapClassName} .react-autosuggest__input {
    position: relative;
    width: 100%;
    height: 38px;
    padding: 10px 20px 10px 10px;
    font-family: greycliff;
    font-weight: 300;
    font-size: 14px;
    border: 1px solid rgba(179, 179, 179, 0.3);
    border-radius: 4px;
  }
  
  .${autoCompleteWrapClassName} .react-autosuggest__input:focus {
    position: relative;
    z-index: 100
  }
  
  .${autoCompleteWrapClassName} .react-autosuggest__input--focused {
    outline: none;
  }
  
  .${autoCompleteWrapClassName} .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .${autoCompleteWrapClassName} .react-autosuggest__suggestions-container {
    display: none;
    position: relative;
    z-index: 1000;
  }
  
  .${autoCompleteWrapClassName} .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 30px;
    width: 100%;
    border: 1px solid rgba(179, 179, 179, 0.3);
    background-color: #fff;
    font-weight: 300;
    font-size: 14px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #000;
  }
  
  .${autoCompleteWrapClassName} .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .${autoCompleteWrapClassName} .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px 10px 10px;
    font-family: greycliff;
  }

  .${autoCompleteWrapClassName} .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
`

const nonEditable = ['email', 'accountName', 'companyNo', 'n3Account']

const getSuggestionValue = suggestion => suggestion
const getSuggestionValuePostal = suggestionPostal => suggestionPostal
const renderSuggestion = suggestion => (
  <span>
    {suggestion}
  </span>
)
const renderSuggestionPostal = suggestionPostal => (
  <span>
    {suggestionPostal}
  </span>
)

class ProfileDetails extends React.Component {
  static propTypes = {
    profile: PropTypes.object,
    appKey: PropTypes.object,
    type: PropTypes.string,
    hasUpdated: PropTypes.func,
    loading: PropTypes.bool,
    datetimepicker: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
  }

  state = {
    profile: this.props.profile,
    isDateTimePickerVisible: false,
    genderOptions: [
      { label: 'Male', value: '1' },
      { label: 'Female', value: '2' },
      { label: 'Not Specified', value: '969760000' }
    ],
    editable: false,
    notification: false,
    notificationType: 'error',
    value: '',
    valuePostal: '',
    suggestions: [],
    suggestionsPostal: [],
    activeShipping: false,
    activePostal: false,
    oAuthToken: null,
    exceedsLargeBreakpoint: false
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
    this.getOAuthToken()
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  onChangeShipping = (event, { newValue }) => {
    this.setState(state => {
      state.value = newValue
      state.profile.addressShipping = newValue
      return state
    })
  }

  onChangePostal = (event, { newValue }) => {
    this.setState(state => {
      state.valuePostal = newValue
      state.profile.addressPostal = newValue
      return state
    })
  }

  getOAuthToken = () => {
    const { appKey } = this.props

    storage.token.get(appKey).then(token => {
      const access = { token, device: getDeviceId() }
      api.info.nz_post_token(access).then(res => {
        const NZ_POST_TOKEN = res.accessToken
        this.setState({
          oAuthToken: NZ_POST_TOKEN
        })
      })
    })
  }

  onSuggestionsFetchRequested = (e, type) => {
    const value = e.value ? e.value : ''

    const headers = new Headers()
    headers.append('Authorization', this.state.oAuthToken)
    headers.append('Accept', 'application/json')
    const init = { method: 'GET', headers, mode: 'cors' }

    fetch(`https://api.nzpost.co.nz/addresschecker/1.0/suggest?q=${value}`, init)
      .then((response) => {
        return response.json()
      })
      .then((res) => {
        const tmp = []
        res.addresses && res.addresses.map(item => tmp.push(item.FullAddress && item.FullAddress))
        if (type && type === 'postal') {
          this.setState({
            suggestionsPostal: tmp
          })
        } else {
          this.setState({
            suggestions: tmp
          })
        }
      })
      .catch(() => {
        this.setState({
          notification: 'Something went wrong. Please try again later',
          notificationType: 'error'
        })
      })
  }

  onSuggestionsClearRequested = (type) => {
    if (type && type === 'postal') {
      this.setState({
        suggestionsPostal: []
      })
    } else {
      this.setState({
        suggestions: []
      })
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.profile !== this.props.profile) {
      this.setState({
        profile: this.props.profile
      })
    }
  }

  handleToggleEdit = () => {
    this.setState({
      editable: !this.state.editable,
      notification: false,
      notificationType: 'error'
    })
  }

  handleSubmit = () => {
    const { appKey, type } = this.props
    const { profile } = this.state
    if (isWeb && type) {
      const pattern = /^[0-9]*$/
      if (type === 'organisation') {
        if (!profile.addressShipping) {
          this.setState({ notification: 'Please add your shipping address', notificationType: 'error' })
          return false
        }
        if (!profile.addressPostal) {
          this.setState({ notification: 'Please add your postal address', notificationType: 'error' })
          return false
        }
        if (!profile.numStaff) {
          this.setState({ notification: 'Please add a Number of staff', notificationType: 'error' })
          return false
        }
        if (!(profile.numStaff && profile.numStaff.toString().match(pattern))) {
          this.setState({ notification: 'Only numbers are allowed', notificationType: 'error' })
          return false
        }
      } else {
        if (!profile.postalCode.match(pattern)) {
          this.setState({ notification: 'The postal code must only contain numbers', notificationType: 'error' })
          return false
        }
        if (profile.postalCode.length !== 0) {
          const postalCodeInt = parseInt(profile.postalCode)

          if (isNaN(postalCodeInt)) { // the regex function should take care of this number not being parseable, but just in case
            this.setState({ notification: 'The postal code must only contain numbers', notificationType: 'error' })
            return false
          }

          if (profile.postalCode.length !== 4 || postalCodeInt < 0 || postalCodeInt > 9999) {
            this.setState({ notification: 'The postal code must be four digits long', notificationType: 'error' })
            return false
          }
        }

        const phonePattern = /^\+?[0-9 -()]*$/
        if (!profile.phone.match(phonePattern)) {
          this.setState({ notification: 'Please enter your phone number', notificationType: 'error' })
          return false
        }
      }
    }

    const filtered = Object.keys(profile)
      .filter(key => !nonEditable.includes(key))
      .reduce((obj, key) => {
        obj[key] = profile[key]
        return obj
      }, {})

    const dob = filtered.dateOfBirth

    // if the form is submitted without setting the date, it will still be a string
    if (dob && typeof dob !== 'string') {
      // remove the timezone from the date
      filtered.dateOfBirth = `${dob.getFullYear()}-${dob.getMonth() + 1}-${dob.getDate()}T00:00:00`
    }

    this.setState({ notification: 'Updating information...', notificationType: 'success' })
    storage.token.get(appKey).then(token => {
      const access = type === 'profile' ? { token, device: getDeviceId() } : token
      api[type].update(access, filtered).then(res => {
        const { message, code } = res
        this.setState({
          notification: code === 200 ? message : code === 400 && type === 'organisation' ? 'We couldn\'t find that address, please choose from the dropdown' : 'Something went wrong. Please try again later',
          notificationType: code === 200 ? 'success' : 'error'
        }, () => {
          setTimeout(() => {
            this.setState({
              notification: false,
              editable: false
            })
          }, 2000)
        })
        this.props.hasUpdated && this.props.hasUpdated(code === 200)
        if (!isWeb && type === 'profile') { // update offline content
          EntityFacade.updateStoredProfile(profile).then(() => {
            consoleLog('Stored profile updated.')
          })
        }
      })
    })
  }

  toggleCheck = value => {
    this.setState(state => {
      state.profile.genderCode = value
      return state
    })
  }

  dateValue = item => {
    const date = new Date(item)
    if (date instanceof Date && !isNaN(date)) return date
    return null
  }

  nativeFormatDate = d => {
    const date = new Date(d)
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1)
    const yyyy = date.getFullYear()

    return `${day}/${MM}/${yyyy}`
  }

  handleChange = (key, val) => {
    this.setState(state => {
      state.profile[key] = val
      return state
    })
  }

  showDateTimePicker = () => {
    const { editable } = this.state
    if (editable) this.setState({ isDateTimePickerVisible: true })
  }

  hideDateTimePicker = () => {
    this.setState({ isDateTimePickerVisible: false })
  }

  handleDatePicked = date => {
    this.handleChange('dateOfBirth', date)
    this.hideDateTimePicker()
  }

  render () {
    const { loading } = this.props
    const {
      profile,
      genderOptions,
      editable,
      notification,
      notificationType,
      value,
      valuePostal,
      suggestions,
      suggestionsPostal,
      activeShipping,
      activePostal,
      exceedsLargeBreakpoint
    } = this.state
    const inputProps = (v) => {
      return {
        placeholder: value || v || 'Type your shipping address',
        value,
        onChange: this.onChangeShipping,
        onClick: () => this.setState({ activeShipping: true, activePostal: false })
      }
    }
    const inputPropsPostal = (v) => {
      return {
        placeholder: valuePostal || v || 'Type your postal address',
        value: valuePostal,
        onChange: this.onChangePostal,
        onClick: () => this.setState({ activePostal: true, activeShipping: false })
      }
    }
    const DateTimePicker = this.props.datetimepicker
    const dateProps = {
      dateFormat: 'dd/MM/yyyy',
      popperPlacement: 'top',
      popperModifiers: {
        flip: {
          behavior: ['top']
        },
        preventOverflow: {
          enabled: false
        },
        hide: {
          enabled: false
        }
      }
    }
    return (
      <View style={styles.wrapper}>
        {isWeb && <style>{getDateFieldWebCss(editable)}</style>}
        {isWeb && <style>{autoCompleteWrapCss}</style>}
        {loading && <LoadingIndicator/>}
        <View style={styles.fields}>
          {profile && Object.entries(profile).map((item, i) => {
            const key = item[0]

            const label = () => {
              if (key === 'firstName') return 'First Name'
              if (key === 'lastName') return 'Last Name'
              if (key === 'email') return 'Email Address'
              if (key === 'phone') return 'Mobile number'
              if (key === 'title') return 'Job title'
              if (key === 'webSite') return 'Website'
              if (key === 'dateOfBirth') return 'Date of birth'
              if (key === 'postalCode') return 'Post code'
              if (key === 'accountName') return 'Account name'
              if (key === 'tradingAs') return 'Trading as'
              if (key === 'companyNo') return 'Company number'
              if (key === 'n3Account') return 'n3 Account Number'
              if (key === 'addressShipping') return 'Physical Address'
              if (key === 'addressPostal') return 'Postal Address'
              if (key === 'numStaff') return 'Number of staff'
              if (key === 'genderCode') return 'Gender'
              return key
            }
            const value = () => {
              const value = item[1] ? item[1].toString() : item[1]
              if (value === 0) return '0'
              return value
            }
            const keyboardType = () => {
              if (key === 'email') return 'email-address'
              if (key === 'phone') return 'phone-pad'
              if (key === 'postalCode') return 'number-pad'
              return 'default'
            }
            if (key === 'genderCode') {
              return <Checks key={i}>
                <CheckField
                  id={i}
                  type="radio"
                  label={label()}
                  items={genderOptions}
                  checked={profile[key]}
                  onChange={value => this.toggleCheck(value)}
                  editable={editable}
                  baseStyle={[styles.fieldWrap, styles.checkFieldWrap]}
                  labelStyle={styles.label}
                  itemStyle={styles.checkField}
                  linkStyle={getChecksLinkStyle(exceedsLargeBreakpoint)}
                />
              </Checks>
            }
            if (key === 'dateOfBirth') {
              return <DateField className={dateFieldClassName} key={i}>
                {isWeb
                  ? <React.Fragment>
                    <label style={labelSharedStyle} htmlFor={key}>{label()}</label>
                    <DatePicker
                      id={key}
                      selected={this.dateValue(item[1])}
                      disabled={!editable}
                      onChange={e => this.handleChange(key, e)}
                      className="datepicker"
                      {...dateProps}
                    />
                  </React.Fragment>
                  : <React.Fragment>
                    <NDate.Label style={nDateStyles.Label}>{label()}</NDate.Label>
                    <NDate.Field style={nDateStyles.Field}>
                      <Text
                        onPress={this.showDateTimePicker}
                        style={[styles.field, editable && !nonEditable.includes(key) && styles.fieldBorder]}
                      >
                        {this.nativeFormatDate(item[1])}
                      </Text>
                      <DateTimePicker
                        date={new Date(item[1])}
                        isVisible={this.state.isDateTimePickerVisible}
                        onConfirm={this.handleDatePicked}
                        onCancel={this.hideDateTimePicker}
                        datePickerModeAndroid="spinner"
                        isDarkModeEnabled={isDarkMode()}
                      />
                    </NDate.Field>
                  </React.Fragment>
                }
              </DateField>
            }
            if (isWeb && editable && key === 'addressShipping') {
              return (
                <div key={i}
                  className={autoCompleteWrapClassName + (activeShipping ? ' active' : '')}>
                  <label style={labelSharedStyle}>{label()}</label>
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={(e) => this.onSuggestionsFetchRequested(e)}
                    onSuggestionsClearRequested={() => this.onSuggestionsClearRequested()}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps(value())}
                  />
                </div>
              )
            }
            if (isWeb && editable && key === 'addressPostal') {
              return (
                <div key={i} className={autoCompleteWrapClassName + (activePostal ? ' active' : '')}>
                  <label style={labelSharedStyle}>{label()}</label>
                  <Autosuggest
                    suggestions={suggestionsPostal}
                    onSuggestionsFetchRequested={(e) => this.onSuggestionsFetchRequested(e, 'postal')}
                    onSuggestionsClearRequested={() => this.onSuggestionsClearRequested('postal')}
                    getSuggestionValue={getSuggestionValuePostal}
                    renderSuggestion={renderSuggestionPostal}
                    inputProps={inputPropsPostal(value())}
                  />
                </div>
              )
            }
            return <TextField
              key={i}
              id={String(i)}
              label={label()}
              defaultValue={value()}
              onChange={e => this.handleChange(key, e)}
              editable={editable && !nonEditable.includes(key)}
              keyboardType={keyboardType()}
              baseStyle={styles.fieldWrap}
              labelStyle={{ ...(isWeb ? labelSharedStyle : {}), ...styles.label }}
              style={[styles.field, editable && !nonEditable.includes(key) && styles.fieldBorder]}
            />
          })}
        </View>
        <View style={styles.notifications}>
          {notification && <Notification type={notificationType}>{notification}</Notification>}
        </View>
        {Object.keys(profile).length > 0 && <View style={styles.buttons}>
          <Primary
            onPress={() => this.handleToggleEdit()}
            editable={editable} style={{ backgroundColor: editable ? '#ddd' : colours.green }}
          >
            {editable ? 'Cancel' : 'Edit'}
          </Primary>
          {editable && <Primary style={{ marginLeft: 10 }} onPress={() => this.handleSubmit()}>Submit</Primary>}
        </View>}
      </View>
    )
  }
}

export default ProfileDetails

const styles = StyleSheet.create({
  wrapper: {},

  fields: {
    marginBottom: 20
  },
  fieldWrap: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: colours.light50,
    paddingVertical: isWeb ? 6 : 8
  },
  checkFieldWrap: {
    flexWrap: 'wrap',
    flexDirection: isWeb ? 'row' : 'column'
  },
  label: {
    flexBasis: isWeb ? '30%' : '40%',
    fontSize: 14,
    paddingVertical: 10,
    paddingRight: 10
  },
  field: {
    flex: 1,
    flexBasis: isWeb ? '70%' : '60%',
    fontSize: 14,
    fontFamily: 'greycliff',
    paddingVertical: 10,
    paddingHorizontal: 5,
    borderWidth: 1,
    borderColor: 'transparent',
    borderRadius: 4
  },
  nativeDateField: {
    width: '100%'
  },
  nativeDate: {
    width: '100%',
    textAlign: 'left',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    fontSize: 14,
    fontFamily: 'greycliff',
    paddingVertical: 10,
    paddingHorizontal: 5,
    borderWidth: 1,
    borderColor: 'transparent',
    borderRadius: 4,
    backgroundColor: 'white'
  },
  fieldBorder: {
    borderColor: 'rgba(179,179,179,.3)'
  },
  checkField: {
    marginBottom: isWeb ? 0 : 4
  },

  notifications: {
    marginBottom: 20,
    zIndex: -1
  },
  buttons: {
    zIndex: -1,
    flexDirection: 'row'
  }
})
