import { Text } from 'react-native'
import Confirmation from '../../components/Confirmation'
import React from 'react'

export default class CancelSubscriptionConfirm extends React.Component {
  render () {
    return <Confirmation h1={'Sorry to see you go!'} p={'We have received your cancel request. Your account manager will be in contact shortly to see if there is anything we can do differently.'}>
      <Text/>
    </Confirmation>
  }
}
