import React from 'react'
import { Linking, StyleSheet, Text, View } from 'react-native'
import SplashLayout from '../components/SplashLayout'
import { Primary } from '../components/Inputs/Buttons'
import { colours } from '../styles/constants'
import storage from '../helpers/storage'
import api from '../helpers/boost-client-js-library/api'
import isWeb from '../helpers/isWeb'
import getDeviceId from '../helpers/deviceId'
import Notification from '../components/Notification'
import LoadingIndicator from '../components/LoadingIndicator'
import ConnectionManager from '../helpers/ConnectionManager'
import PropTypes from 'prop-types'
import SplashCompanyLogo from '../components/SplashCompanyLogo'
import globalVars from '../helpers/globalVars'

const L = {
  Buttons: props => {
    const { children, ...rest } = props
    return (
      <View style={{ marginTop: 25 }} {...rest}>{children}</View>
    )
  },
  Button: props => {
    const { children, register, ...rest } = props
    const buttonStyles = {
      paddingTop: 14,
      paddingBottom: 14,
      width: '100%',
      fontSize: 20,
      ...(register ? {
        color: colours.darkBlue,
        marginTop: 15,
        backgroundColor: 'white'
      } : {})
    }
    return (
      <Primary style={buttonStyles} {...rest}>{children}</Primary>
    )
  }
}

L.Buttons.displayName = 'L.Buttons'
L.Buttons.propTypes = {
  children: PropTypes.any
}
L.Button.displayName = 'L.Button'
L.Button.propTypes = {
  children: PropTypes.any,
  register: PropTypes.any
}

export default class Auth extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    appKey: PropTypes.object,
    location: PropTypes.object,
    isLoggedIn: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    offlineExpiredMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    invalidLoginMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  }

  state = {
    loading: true,
    logo: null
  }

  mounted = false

  componentDidMount () {
    this.mounted = true
    const { history, appKey, location, isLoggedIn, offlineExpiredMessage } = this.props
    const link = isWeb ? '/profile' : '/offers'
    const referrer = location.state && location.state.from && location.state.from.pathname ? location.state.from.pathname + location.state.from.search + location.state.from.hash : link
    const loggedOut = location.state && location.state.logout

    // if logged out function is called
    if (loggedOut) {
      this.mounted && this.setState({ loading: false })
      return false
    }

    storage.token.get(appKey).then(token => {
      const access = { token, device: getDeviceId() }
      ConnectionManager.getManager().checkApiConnectivity().then((isOnline) => {
        if (!isOnline && isLoggedIn) {
          history.push(link)
          return
        }
        if (!isOnline && offlineExpiredMessage) {
          this.mounted && this.setState({ loading: false })
        }
      })

      Promise.all([
        api.profile.get(access),
        api.auth.validate(access)
      ]).then(([profile, res]) => {
        if (res && res.data && res.data.email) {
          globalVars.companyLogoUrl = profile.companyLogo

          this.mounted && this.setState({
            logo: profile.companyLogo
          }, () => {
            setTimeout(() => {
              history.push(referrer)
            }, 2000)
          })
          return false
        } else {
          this.mounted && this.setState({ loading: false })
          // redirect to signin page
          if (isWeb) {
            if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
              window.location = `http://localhost:3000/dev-sign-in?&referrer=${referrer}`
            } else {
              window.location = `${process.env.REACT_APP_BASE_URL}/sign-in?&referrer=${referrer}`
            }
          }
        }
      })
    })
  }

  componentWillUnmount () {
    this.mounted = false
  }

  render () {
    const { loading, logo } = this.state
    const { history, location, offlineExpiredMessage, invalidLoginMessage } = this.props
    const path = location.state && location.state.from && location.state.from.pathname + location.state.from.search + location.state.from.hash
    if (isWeb) {
      return <SplashLayout home>
        {loading &&
        <React.Fragment>
          <View style={styles.loading}>
            <LoadingIndicator/>
          </View>
        </React.Fragment>}
      </SplashLayout>
    }
    return (
      <SplashLayout home>
        {loading
          ? <View style={styles.inner}>
            {logo !== null && <SplashCompanyLogo source={{ uri: logo }}/>}
            <View style={[styles.loading, logo ? { marginTop: -10 } : {}]}>
              <LoadingIndicator/>
            </View>
          </View>
          : <React.Fragment>
            <View style={styles.inner}>
              {offlineExpiredMessage &&
              <Notification type="error">Please connect to the internet and then Sign in to continue</Notification>}
              {invalidLoginMessage && <Notification type="error">{invalidLoginMessage}</Notification>}
              <L.Buttons>
                <L.Button onPress={() => history.push('/sign-in', { path: path })}>Sign in</L.Button>
                <L.Button register onPress={() => history.push('/card-register')}>Register</L.Button>
                <Text onPress={() => Linking.openURL(`${process.env.REACT_APP_BASE_URL}/enquiry-form`)}
                  style={styles.forgot}>Not a member?</Text>
              </L.Buttons>
            </View>
          </React.Fragment>
        }
      </SplashLayout>
    )
  }
}

const styles = StyleSheet.create({
  inner: {
    width: '100%',
    marginBottom: 50
  },
  loading: {
    marginTop: 168
  },
  button: {
    width: '100%',
    paddingVertical: 14,
    fontSize: 20
  },
  register: {
    color: colours.darkBlue,
    marginTop: 10,
    backgroundColor: 'white'
  },
  company: {
    width: 130,
    height: 30
  },
  forgot: {
    fontFamily: 'greycliff',
    fontSize: 16,
    textAlign: 'center',
    marginTop: 5,
    paddingVertical: 15
  }
})
