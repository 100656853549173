// console.log function which takes in a string. Will only show up on dev/staging
import isWeb from './isWeb'

let messageCache = []

const consoleLog = (message = '') => {
  if (process.env.REACT_APP_BASE_URL === 'https://test.myboost.co.nz') {
    if (isWeb) {
      console.log(message)
      return
    }

    const NetInfo = global.NetInfo

    if (!global.isDevice || !NetInfo) {
      // If there's no NetInfo, or if we're on a simulator (not on device)
      console.log(message)
      return
    }

    NetInfo.fetch().then((state) => {
      if (state.type === 'wifi') {
        // If the device has wifi then log the message, this will go back to the remote Expo console straight away
        console.log(message)
        messageCache.forEach((msg) => {
          console.log(msg)
        })
        messageCache = []
      } else {
        // If we have no connectivity cache the message. They will be printed/reset after the next message when we have
        // connectivity again
        messageCache.push(`[OfflineMessage]: ${message}`)
      }
    })
  }
  return false
}

export default consoleLog
