import React from 'react'

const childrenWithProps = (children, props) => {
  return React.Children.map(children, child => {
    if (React.isValidElement(child) && typeof child.type !== 'string') {
      return React.cloneElement(child, props)
    }
    return child
  })
}

export default childrenWithProps
